import ReporteService from "../../Repository/Reportes";
import {setEncontrarTerminal, setRecordsTerminales} from "../slice/reporteSlice";
import {closeGeneralLoader, openGeneralLoader} from "../slice/alertSlice";

export const listarTerminales = (data)=>{
    const reportex = new ReporteService();
    return async(dispatch, getState)=>{
        dispatch( openGeneralLoader() );
        const result = await reportex.terminales(data);
        if(result.status === 'ok') {
            const params = {
                ...result.response,
                filtro:data
            }
            dispatch(setRecordsTerminales(params));
            dispatch(closeGeneralLoader() );
        }
    }
}

export const listarTerminalesLite = (data)=>{
    const reportex = new ReporteService();
    return async(dispatch, getState)=>{
        dispatch( openGeneralLoader() );
        const result = await reportex.terminales({ textoBuscar: data.encontrar  });
        if(result.status === 'ok') {
            const params = {
                ...result.response,
                encontrar:data.encontrar
            }
            dispatch(setEncontrarTerminal(params));
            dispatch(closeGeneralLoader() );
        }
    }
}