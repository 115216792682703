

import Persona from "../../Repository/Banca/Personas";
import {
    personaEditing,
    personaSetConfig,
    personaSetTiposNotificaciones, setListadoLite,
    setPersonaLoading
} from "../slice/personaSlice";

export const editPersona =  (uuid)=>{
    const personax = new Persona();
    return async(dispatch, getState)=>{
        dispatch( setPersonaLoading() );
        const resNotificaciones = await personax.tipoNotificaciones();
        dispatch( personaSetTiposNotificaciones( resNotificaciones ) );
        const result = await personax.mostrar(uuid);
        if(result.status == 'ok') {
            const req = await personax.personaConfig(uuid);
            dispatch(personaSetConfig(req.response));
            dispatch(personaEditing(result.response.data));
        }
    }
}

export const consultaTiposNotificaciones= ()=>{
    const personax = new Persona();
    return async(dispatch, getState)=>{
        const resNotificaciones = await personax.tipoNotificaciones();
        dispatch( personaSetTiposNotificaciones( resNotificaciones ) );
    }
}

export const consultarMiNotificacionConsigurada = (uuid) =>{
    const personax = new Persona();
    return async(dispatch, getState)=>{
        const req = await personax.personaConfig(uuid);
        dispatch(personaSetConfig(req.response));
    }
}

export const listadoLitePersonas = (params = {}) =>{
    const personax = new Persona();
    return async(dispatch, getState)=>{
        const result = await personax.listar(params);
        dispatch( setListadoLite(result.response) );
    }
}