
import PropTypes from "prop-types";

import TableRow from "@mui/material/TableRow";
import { TableCell} from "@mui/material";
import colors from "assets/theme/base/colors";
import borders from "../../../../../assets/theme/base/borders";
import SoftBox from "../../../../../components/SoftBox";
import {useNumberFormat} from "../../../../../customHooks/useNumberFormat";
import TotalOperaciones from "./TotalOperaciones";

function Total({ label = "" ,retiros = "", depositos = "", saldo = "" } ) {
    const { borderWidth } = borders;
    const { numberFormat } = useNumberFormat();

    return (<>
            <TableRow >
                <TableCell sx={{ borderBottom:"0px", paddingTop:"0px" }}
                >
                    <SoftBox
                        pt={1}
                        color={ "color1" }
                        fontWeight={"bold"}
                        sx={{ fontSize:"14px" }}
                        textAlign={"right"}
                    >
                        { label  }
                    </SoftBox>
                </TableCell>
                <TableCell  sx={{ borderBottom:`3px solid ${ colors.color1.main }`, borderTop:`3px solid ${ colors.color1.main }`,paddingTop:"0px", bgcolor:`${ colors.color14.main }` }}
                >
                    <SoftBox
                        pt={1}
                        color={ "color6A" }
                        fontWeight={"bold"}
                        sx={{ fontSize:"14px", textAlign:"right" }}
                    >
                        { retiros  }
                    </SoftBox>
                </TableCell>
                <TableCell sx={{ borderBottom:`3px solid ${ colors.color1.main }`, borderTop:`3px solid ${ colors.color1.main }`,paddingTop:"0px", bgcolor:`${ colors.color14.main }` }}
                >
                    <SoftBox
                        color={ "color6A" }
                        fontWeight={"bold"}
                        sx={{ fontSize:"14px", textAlign:"right" }}
                    >
                        { depositos  }
                    </SoftBox>
                </TableCell>
                <TableCell  sx={{ borderBottom:`3px solid ${ colors.color1.main }`, borderTop:`3px solid ${ colors.color1.main }`,paddingTop:"0px", bgcolor:`${ colors.color14.main }` }}
                >
                    <SoftBox
                        color={ "color6A" }
                        fontWeight={"bold"}
                        sx={{ fontSize:"14px", textAlign:"right" }}
                    >
                        { saldo  }
                    </SoftBox>
                </TableCell>
            </TableRow>
            <TableRow >
                <TableCell sx={{ borderBottom:"0px", paddingTop:"0px" }} >

                </TableCell>
            </TableRow>
        </>
    );
}

Total.defaultProps = {
    retiros: "0",
    depositos: "0",
    saldo : "0",
    label: ""
};

// Typechecking props for the ListItem
Total.propTypes = {
    label: PropTypes.string.isRequired,
    retiros: PropTypes.string.isRequired,
    depositos: PropTypes.string.isRequired,
    saldo: PropTypes.string.isRequired,
};

export default Total;