import PropTypes from "prop-types";
import SoftBox from "../../../../components/SoftBox";
import Grid from "@mui/material/Grid";
import moment from "moment/moment";

export const PAYPAL = ({ transaccion = {} }) => {

    const fechaOperacionFormat = () =>{
        if(transaccion.fechaOperacion){
            return moment(transaccion?.fechaOperacion).format("DD-MM-YYYY HH:mm");
        }
        return "";
    }

    const transaccionIdFormat = ()=>{
        if(transaccion.transaccionId){
            return "****"+transaccion.transaccionId.slice(-8)
        }
        return "";
    }

    return (
        <SoftBox sx={{ border:"5px solid #f7f7f7", padding:1 }} >
            <Grid container >
                <Grid item md={2} lg={2} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Fecha de Operación</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ fechaOperacionFormat() } readOnly />
                </Grid>

                <Grid item md={1} lg={1} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Correo</label>
                </Grid>
                <Grid item md={3} lg={3} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.correoCliente } readOnly />
                </Grid>

                <Grid item md={1} lg={1} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Cliente</label>
                </Grid>
                <Grid item md={3} lg={3} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.nombreCliente } readOnly />
                </Grid>

                <Grid item md={2} lg={2} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>No. Orden</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccionIdFormat() } readOnly />
                </Grid>

                <Grid item md={2} lg={2} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Tipo de Transacción</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.tipoTransaccion } readOnly />
                </Grid>

            </Grid>

        </SoftBox>
    )
}

PAYPAL.propTypes = {
    transaccion: PropTypes.object,
};

export default PAYPAL;