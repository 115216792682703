import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {v4} from "uuid";
import Grid from "@mui/material/Grid";
import SoftBox from "../../../../components/SoftBox";
import AlphaTable from "../../../cuentas/components/AlphaTable";
import BtnCambioEstadoTpv from "../BtnCambioEstadoTpv";
import TpvModal2 from "../netpay_form/TpvModal2";
import FrmTpv from "../netpay_form/FrmTpv";
import ServicioContratado from "../../../../Repository/ServicioContratado";
import PropTypes from "prop-types";

const initialCredentials = {
    serialNumber:"",
    storeId:"",
    password:"",
}

const TPV2 = ({cuenta_uuid = ''}) => {
    const  servContratado = new ServicioContratado();
    const [contratados, setContratados] = useState([]);
    const dispatch = useDispatch();
    const [servicioAEditar, setServicioAEditar] = useState(null);
    const [openNew, setOpenNew ] = useState(false);
    const [rows, setRows] = useState([]);
    const [encontrar, setEncontrar] = useState("");

    const loadComponent = async() =>{
        const result = await servContratado.listar(cuenta_uuid);
        if(result.status === "ok")
            setContratados( result.response.contratados );
    }

    useEffect(()=>{
        loadComponent();
    },[])

    const onClickServicio = (event, element) =>{
        setOpenNew( true );
        setServicioAEditar( element );
    }

    const onClickAdd = (event)=>{
        setOpenNew(true);
    }

    const closeNewModal = (event) =>{
        setServicioAEditar(null);
        setOpenNew(false);
        loadComponent();
    }

    const headers = [
        { name:"Número de Serie", width:"5%" },
        { name:"StoreId", width:"5%" },
        { name:"Contraseña", width:"10%" },
        { name:"Creado", width:"5%" },
        { name:"Estatus", width:"5%" },
    ];

    useEffect(()=>{
        const rowsx = contratados.map(element =>{
            if(element.tipoServicio === 'NETPAY_TPV'){
                return (<tr key={ v4() }>
                    <td><label className={"label-container ILabelLink"} onClick={ (event)=> onClickServicio(event,element)}>{ element.credenciales.serialNumber }</label></td>
                    <td>{ element.credenciales.storeId }</td>
                    <td>{ element.credenciales.password }</td>
                    <td>{ element.created }</td>
                    <td>
                        <BtnCambioEstadoTpv device={ element } />
                    </td>
                </tr>);
            }
        })
        setRows( rowsx );
    }, [contratados]);

    const onInputChange = (event) =>{
        setEncontrar( event.target.value );
        if(event.target.value.length === 0 ) {
            filtrar(event.target.value);
        }
    }

    const handleEncontrar = (event)=> {
        if (event.key === "Enter") {
            filtrar(encontrar);
        }
    }

    const filtrar = (texto) =>{
        const rowsx = contratados.map(element =>{
            if(element.tipoServicio === 'NETPAY_TPV' && ( element.credenciales.serialNumber.includes( texto ) || element.credenciales.storeId.includes( texto ) )){
                return (<tr key={ v4() }>
                    <td><label className={"label-container ILabelLink"} onClick={ (event)=> onClickServicio(event,element)}>{ element.credenciales.serialNumber }</label></td>
                    <td>{ element.credenciales.storeId }</td>
                    <td>{ element.credenciales.password }</td>
                    <td>{ element.created }</td>
                    <td>
                        <BtnCambioEstadoTpv device={ element } />
                    </td>
                </tr>);
            }
        })
        setRows( rowsx );
    }

    return (
        <SoftBox >
            <Grid container  pt={1}>
                <Grid item xs={12} md={3} xl={3}  >
                    <button className={"btn-alpha-black pad-element"} type={"submit"} onClick={ onClickAdd }>
                        <i className="fa-solid fa-plus"></i> Agregar
                    </button>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>

                </Grid>
                <Grid item xs={12} md={3} xl={3} textAlign={"right"} pr={1} >
                    <input type={"search"} className={"ITxtEncontrar"} name={"encontrar"} value = { encontrar } maxLength={"50"} placeholder={"Encontrar"}
                           onChange={ onInputChange } onKeyDown={  handleEncontrar }
                    />
                </Grid>
            </Grid>

            <AlphaTable headers={ headers } records={ rows } />
            {
                openNew && <TpvModal2 fnModaCloseAction={ closeNewModal  } title={ "Agregar TPV" }
                                     formComponent={ <FrmTpv fnCloseModal={ closeNewModal } cuenta_uuid={ cuenta_uuid } credenciales={ servicioAEditar?.credenciales ?? initialCredentials }
                                                             device={ servicioAEditar ??{} } /> } modalSize="md" />
            }
        </SoftBox>
    );
}

TPV2.propTypes = {
    cuenta_uuid:PropTypes.string.isRequired,
}

export default TPV2;