import * as React from "react";
import Grid from "@mui/material/Grid";
import "flatpickr/dist/themes/dark.css";
import moment from "moment";
import {useForm} from "../../../../customHooks/useForm";
import {useEffect, useState} from "react";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import SoftBox from "../../../../components/SoftBox";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import BtnBusqueda from "./BtnBusqueda";
import BuscarCentroDeCostos from "./BuscarCentroDeCostos";
import {useNavigate} from "react-router-dom";
import ServicioContratado from "../../../../Repository/ServicioContratado";
import {useAlphaAlertModal} from "../../../../customHooks/useAlphaAlertModal";
import imgIrDet from "../../../../assets/alpha-theme/images/icons/imgIrDet.gif";
import SoftTypography from "../../../../components/SoftTypography";
import Servicio from "../../../../Repository/Servicio";
moment.locale("es-MX");

const initialForm = {
    storeId: "",
    serialNumber:"",
    password:"",
    cuenta_uuid:""
}

const formValidation = {
    cuenta_uuid: [(value)=> value.trim() !== "","Llave pública requerida."],
    serialNumber: [(value)=> value.trim() !== "","Llave pública requerida."],
    storeId: [(value)=> value.trim() !== "","Llave secreta requerida."],
    password: [(value)=> value.trim() !== "","Contraseña requerida."],
}

function FrmTerminal (props) {
    const servContratado = new ServicioContratado();
    const navigate = useNavigate();
    const {
        storeId,
        serialNumber,
        password,
        cuenta_uuid,
        onInputChange,
        isFormValid
    } = useForm(initialForm, formValidation);
    const [rfc, setRfc] = useState('');
    const [licencia, setLicencia] = useState('');
    const [rs, setRs] = useState('');
    const [servicioPlataforma_uuid, setServicioPlataforma_uuid] = useState('');
    const [ showWebModal, setShowWenModal ] = useState(false);
    const [saving, setSaving] = useState(false);
    const { setOpenAlert,alphaAlert,setMessageAlert,openAlert } = useAlphaAlertModal();

    useEffect(()=>{
        cargarServicio();
    },[]);

    const onClickGuardar = (event) => {
        event.preventDefault();
        if(saving) return false;
        if(!isFormValid) {
            setMessageAlert("Complete los datos solicitados para poder continuar");
            setOpenAlert(true);
            return false
        };
        setSaving(true);
        save();
    }

    const handleBack = (event) => {
        event.preventDefault();
        navigate("/terminales");
    }

    const mostrarBuscador = () => {
        setShowWenModal(true);
    }

    const onSelectedITem = (item) => {
        setShowWenModal(false);
        setLicencia( item.licencia );
        setRfc( item.rfc );
        setRs( item.rs );
        const objaux = {
            target:{
                name:'cuenta_uuid', value: item.uuid,
            }
        };
        onInputChange(objaux);
    }

    const onCloseWindow = () => {
        setShowWenModal(false);
    }

    const save = async() => {
        const params = {
            cuenta_uuid,
            credenciales:{
                serialNumber,
                storeId,
                password,
            },
            servicioPlataforma_uuid
        };
        const result = await servContratado.store(params);
        if(result.status === 'error') {
            setMessageAlert(result.response);
            return;
        }else{
            setMessageAlert("Registro almacenado con exito");
            setTimeout(()=>{
                navigate("/terminales");
            },2000)
        }
        setSaving(false);
        setOpenAlert(  true );
    }

    const cargarServicio = async ( ) => {
        const serviciox = new Servicio();
        const result = await serviciox.listar();
        if(result.status === false) return;
        const servicio = result.response.find(element => element.servicioTipo === 'NETPAY_TPV');
        if(servicio === null) return;
        setServicioPlataforma_uuid( servicio.uuid );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar light={true}/>
            <SoftBox sx={{ backgroundColor:"#f2f2f2", color:"black", padding:2,
                borderRadius:3, marginTop:2, marginLeft:1, marginRight:1, display:"flex" }} >
                <img src={ imgIrDet } width={50} height={50} /> <SoftTypography sx ={{ paddingTop:1,fontSize:"1.5rem",fontFamily:"TTCDemibold" }}>Agregar Terminal</SoftTypography>
            </SoftBox>
            <SoftBox sx={{border: "5px solid #f7f7f7", margin: 1}}>
                <Grid container p={1}>

                    <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                        <label className={"IGenericLabel"}>Razó social</label>
                    </Grid>
                    <Grid item xs={6} md={6} xl={6}>
                        <input type={"text"} className={"ITextbox"} name={"rs"} value={rs} readOnly/>
                    </Grid>
                    <Grid item xs={2} md={2} xl={2}>
                        <BtnBusqueda fnOnclick={mostrarBuscador}/>
                    </Grid>

                </Grid>
                <Grid container>
                    <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                        <label className={"IGenericLabel"}>RFC</label>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2}>
                        <input type={"text"} className={"ITextbox"} name={"rfc"} value={rfc} readOnly/>
                    </Grid>

                    <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                        <label className={"IGenericLabel"}>Licencia</label>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2}>
                        <input type={"text"} className={"ITextbox"} name={"licencia"} value={licencia} readOnly/>
                    </Grid>
                </Grid>
                <Grid container pr={1}>

                    <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                        <label className={"IGenericLabel"}>Número de serie</label>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2}>
                        <input type={"text"} className={"ITextbox"} name={"serialNumber"} value={serialNumber}
                               onChange={onInputChange}/>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                        <label className={"IGenericLabel"}>StoreID</label>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2}>
                        <input type={"text"} className={"ITextbox"} name={"storeId"} value={storeId}
                               onChange={onInputChange}/>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                        <label className={"IGenericLabel"}>Contraseña</label>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2}>
                        <input type={"text"} className={"ITextbox"} name={"password"} value={password}
                               onChange={onInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={2} xl={2} pl={1}>

                    </Grid>
                    <Grid item xs={12} md={2} xl={2} pl={1}>

                    </Grid>
                    <Grid item xs={12} md={2} xl={2} pl={1}>

                    </Grid>
                    <Grid item xs={6} md={3} xl={3} mt={2}>
                        <button className={"btn-alpha-success-2 pad-element"} type={"submit"} onClick={onClickGuardar}>
                            <i className="fa-solid fa-circle-check"></i> Aceptar
                        </button>
                    </Grid>
                    <Grid item xs={6} md={3} xl={3} mt={2} pl={1}>
                        <button className={"btn-alpha-danger pad-element"} type={"submit"} onClick={handleBack}>
                            <i className="fa-solid fa-circle-xmark"></i> Cancelar
                        </button>
                    </Grid>
                </Grid>
                {
                   showWebModal && <BuscarCentroDeCostos sizeAlert={"md"} fnOnSelectItem={onSelectedITem} fnOnCloseWindow={onCloseWindow}/>
                }
                {
                    openAlert && alphaAlert
                }
            </SoftBox>
        </DashboardLayout>
    );

}
export default FrmTerminal;