import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    message: "",
    icon:"",
    openModal: false,
    openNewModal: false,
    showLoading: false, //Mostrar el icono de aplha
    alphaModal:{
        catalogo:{
            open:false,
        },
    }
}

export const alertSlice = createSlice({
    name: 'alertas',
    initialState,
    reducers: {
        setAlertMessage: (state, action) => {
           state.message = action.payload.message;
           state.icon = action.payload.icon;
        },
        clearAlertMessage: (state) => {
            state.message = "";
            state.icon = "";
            state.openModal = false;
        },
        closeModal:(state) =>{
            state.openModal = false;
        },
        openModal:(state) =>{
            state.openModal = true;
        },
        openNewModal:(state) =>{
            state.openNewModal = true;
        },
        closeNewModal:(state) =>{
            state.openNewModal = false;
        },
        openGeneralLoader:(state)=>{
            state.showLoading = true;
        },
        closeGeneralLoader:(state)=>{
            state.showLoading = false;
        },
        openModalFromCatalogo:(state, action) => {
            state.alphaModal = action.payload;
        },
        closeModalFromCatalogo:(state, action) => {
            state.alphaModal = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setAlertMessage, clearAlertMessage, closeModal, openModal, openNewModal,closeNewModal, openGeneralLoader, closeGeneralLoader, openModalFromCatalogo, closeModalFromCatalogo } = alertSlice.actions

export default alertSlice.reducer