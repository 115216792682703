/**
=========================================================
* Banca Castelec - v0.0.0
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MenuItem from "./components/MenuItem.jsx";

/* componenets */

function Reportes() {

    return (
        <DashboardLayout>
            <DashboardNavbar light={true} />
            <SoftBox py={3}>
               <MenuItem /> 
            </SoftBox>
      </DashboardLayout>
    );
  
}

export default Reportes;
