import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
// @mui material components
import Grid from "@mui/material/Grid";
import Swal from 'sweetalert2';

import IconSave from '@mui/icons-material/Save';
import IconBack from '@mui/icons-material/ArrowBack';
import IconSearch from '@mui/icons-material/Search';

//
import CuentasRequest from 'Repository/Banca/Cuentas';
import ModalBusqueda from '../ModalBusqueda';
import ButtonCancelarCuenta from './ButtonCancelarCuenta';
import ActionMenu from './ActionMenu';
import Filtro from "../../../cuentas/components/Filtro";
import {v4} from "uuid";


function CuentaDetalle({ cuenta_uuid , onCloseViewDetalle, onMenuSelecteItem }) {

    const [cuenta, setCuenta] = useState({});
    const [alias, setAlias] = useState('');
    const [razon_social, setRazonSocial] = useState('');
    const [rfc, setRfc] = useState('');
    const [serie, setSerie] = useState('');
    const [nombreComercial, setNombreComercial] = useState('');

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [uuidSelected, setUuidSelected] = useState('');
    const [customerSelected, setCustomerSelected] = useState('');
    const [empresaUuid, setEmpresaUuid] = useState('');

    const [txtBuscar,setTxtBuscar ] = useState('');

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]); 

    const cuentax = new CuentasRequest();


    //#region events
    const showSearchPopup = (event)=>{
        event.preventDefault();
        setIsOpenModal(true);
    }

    const onHandleOpenForm = (event)=>{
        setIsOpenModal(false);
    }

    const handleOnSelectedItem = (uuid, nombre_completo)=>{
        setIsOpenModal(false);
        setUuidSelected(uuid);
        setCustomerSelected(nombre_completo)
    
    }

    const handleClickMenu = (value) => {
        //console.log('padre1', value);
        onMenuSelecteItem(value);
    }

    //#endregion

    //#region methods
    function mostrar(uuid){
        cuentax.mostrarDetalle(uuid).then(({response})=>{
            setCuenta( value => response.data );
            setAlias(response.data.alias? response.data.alias : '' );
            setCustomerSelected(response.data.propietario);
            setUuidSelected(response.data.persona_uuid);
        });
    }

    const handleUpdateCuenta = (event)=>{
        event.preventDefault();
        const params = {
            alias: alias,
            cuenta_uuid: cuenta_uuid,
            persona_uuid: uuidSelected,
        };
        cuentax.update( JSON.stringify(params)  , cuenta_uuid ).then((response)=>{
            if(response.status == 'ok'){
                Swal.fire({
                    icon: 'success',
                    title: 'Notificaciones',
                    text: response.response.text,
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar',
                  })
            }else{
                console.log('details');
            }
        });
    }

    const handleCancelarDetalle = ()=>{
        onCloseViewDetalle();
    }

      /* validacion en tiempo real de lo que se captura en los campos, marcar en rojo o verde segun lo que se esta ingresando */
    function getErrAlias(negative){
        if(negative){
            if(alias.length <= 3) return true;
            return false;
        }else{
            if(alias.length > 3) return true;
            return false;
        }
    }

  /* Nombres de los autorizadorees start   */
    function getErrCustomerSelected(negative){
        if(negative){
        if(customerSelected.length < 3) return true;
            return false;
        }else{
            if(customerSelected.length > 3 ) return true;
            return false;
        }
    }



  
  /* Nombres de los autorizadorees end   */

  //validacion otra vez todo de corrido
    function validate (){
        let hasError = false;
        let counter = 0;
        if(alias.length <= 3) { counter+=1; }
        if(uuidSelected.length == '') {counter+=1; }

        if(counter > 0){//XD no has capturado todo
        return (hasError = true);
        }
    
        return hasError;

    }
    //#endregion

    
    useEffect(()=>{
        mostrar( cuenta_uuid );
    },[]);

    const formularioDetalle =  <>
        <ModalBusqueda isOpen={isOpenModal} title="Buscar Cliente" onOpenForm={ (value)=>onHandleOpenForm(value) }
                       onSelectedItem = { (uuid, nombre_completo) => handleOnSelectedItem(uuid, nombre_completo) }
        />
        <SoftBox sx={{ border:"5px solid #f7f7f7", }}>
            <Card>


                <SoftBox pt={2} pb={3} px={3} component="form" role="form">
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={4} xl={3}>
                            <SoftBox lineHeight={0}>
                                <SoftTypography variant="caption" color="text" sx={{ fontFamily:"TTCRegular" }}>
                                    Alias
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox>
                                <input type={"text"} className={"ITextbox"} name={"alias"} value={ alias } maxLength={150}
                                       onChange={ e => setAlias(e.target.value) } autoComplete={"on"} />
                            </SoftBox>
                        </Grid>

                        <Grid item xs={12} md={6} xl={7}>
                            <SoftBox lineHeight={0}>
                                <SoftTypography variant="caption" color="text">
                                    Propietario
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox>
                                <input type={"text"} className={"ITextbox"} value={ customerSelected } maxLength={50}
                                       autoComplete={"on"} readOnly={true} />
                            </SoftBox>
                        </Grid>

                        <Grid item xs={12} md={2} xl={2}>
                            <SoftBox pt={2} >
                                <button className={"btn-alpha-info"} type={"submit"} onClick={ showSearchPopup }>
                                    <i className="fa-solid fa-magnifying-glass"></i> Buscar
                                </button>
                            </SoftBox>
                        </Grid>

                        <Grid item xs={12} md={3} xl={3}>
                            <ButtonCancelarCuenta  key={ v4() } cuenta={cuenta}  />
                        </Grid>
                        <Grid item xs={12} md={3} xl={5}>

                        </Grid>

                        <Grid item xs={12} md={3} xl={2}>
                            <SoftBox >
                                <button className={"btn-alpha-black pad-element"} type={"submit"} onClick={ handleCancelarDetalle }>
                                    <i className="fa-solid fa-chevron-left"></i> Volver al listado
                                </button>
                            </SoftBox>
                        </Grid>

                        <Grid item xs={12} md={3} xl={2}>
                            <SoftBox >
                                <button className={"btn-alpha-success-2 pad-element"} onClick={ handleUpdateCuenta }  >
                                    <i className="fa-solid fa-circle-check"></i>  Actualizar información
                                </button>

                            </SoftBox>
                        </Grid>
                    </Grid>
                </SoftBox>
            </Card>
        </SoftBox>
    </>;

    return(
       <Filtro bodyContent={ formularioDetalle } icon={""} title={"Información de la cuenta"} open={true} />
    )
    
}

CuentaDetalle.propTypes = {
    cuenta_uuid: PropTypes.string,
    onCloseViewDetalle: PropTypes.func,
    onMenuSelecteItem: PropTypes.func
};

export default CuentaDetalle;