import PropTypes from "prop-types";
import SoftBox from "../../../../components/SoftBox";
import Grid from "@mui/material/Grid";
import moment from "moment";

export const EFECTIVO = ({ transaccion = {} }) => {

    return (
        <SoftBox sx={{ border:"5px solid #f7f7f7", padding:1 }} >
            <Grid container >
                <Grid item md={2} lg={2} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Fecha de Operación</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ moment(transaccion?.fechaOperacion).format("DD-MM-YYYY HH:mm") } readOnly />
                </Grid>
                <Grid item md={2} lg={2} xs={6} textAlign={"right"}>
                    <label className={"IGenericLabel-sm"}>Referencia</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.referencia } readOnly />
                </Grid>
                <Grid item md={2} lg={2} xs={6} textAlign={"right"}>
                    <label className={"IGenericLabel-sm"}>Tipo transacción</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.tipoTransaccion } readOnly />
                </Grid>
                <Grid item md={2} lg={2} xs={6} textAlign={"right"}>
                    <label className={"IGenericLabel-sm"}>Monto</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.monto } readOnly />
                </Grid>
                <Grid item md={2} lg={2} xs={6} textAlign={"right"}>
                    <label className={"IGenericLabel-sm"}>Transacción ID</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ "****"+transaccion.transaccionId.slice(-8) } readOnly />
                </Grid>
            </Grid>
            <Grid container >
                <Grid item md={2} lg={2} xs={6} textAlign={"right"}>
                    <label className={"IGenericLabel-sm"}>Cliente</label>
                </Grid>
                <Grid item md={6} lg={6} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ `${ transaccion.nombres } ${transaccion.apellidos}` } readOnly />
                </Grid>
            </Grid>
        </SoftBox>
    )
}

EFECTIVO.propTypes = {
    transaccion: PropTypes.object,
};

export default EFECTIVO;