import * as React from "react";
import SoftBox from "../../../components/SoftBox";
import {Grid, useMediaQuery} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {v4} from "uuid";
import {useNumberFormat} from "../../../customHooks/useNumberFormat";
import DocumentToPay from "./DocumentToPay";
import PropTypes from "prop-types";
import CheckAllPartidas from "./CheckAllPartidas";
import Box from "@mui/material/Box";

const TableDocuments = ({partidas}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const { numberFormat } = useNumberFormat();
    const {  transaccion:{documentosPorPagar} } = useSelector(state => state.cobranza);

    const listar = (items,moneda)=>{
        if(items === undefined) return;
        return <SoftBox key={v4()} bgColor={"white"}   >
            <table className={"table-alpha-filter tabla-documento-por-pagar tabla-responsiva-movil clickable"} cellSpacing={"0"}>
                <thead>
                <tr>
                    <th className={"th-header"} width={"5%"} >
                        Fecha
                    </th>
                    <th className={"th-header"} width={"5%"} >
                        Folio
                    </th>
                    <th className={"th-header"} width={"5%"}>
                        Concepto
                    </th>
                    <th className={"th-header"} width={"5%"}>
                        Vencimiento
                    </th>
                    <th className={"th-header"} width={"5%"}>
                        Días Vencidos
                    </th>
                    <th className={"th-header"} width={"5%"}>
                        Monto
                    </th>
                    <th className={"th-header"} width={"5%"}>
                        Moneda
                    </th>
                    <th className={"th-header"} width={"5%"} align={"center"}>
                        Pagar <CheckAllPartidas moneda={ moneda } />
                    </th>
                </tr>
                </thead>
                <tbody>
                { items.map(item =>{
                    return <DocumentToPay key={ v4() } partida={item} />
                }) }
                </tbody>
                <tfoot>
                <tr key={ v4} >
                    <td colSpan={3}></td>
                    <td colSpan={2} align={"right"} className={ "bg-gris-1 m-0-cell" }  >
                        <label className={"lbl-etiqueta-2"}> Total en { moneda }</label>
                    </td>
                    <td align={"right"} className={ "bg-gris-1 m-0-cell" }>
                        <label className={"lbl-etiqueta-2"}> { numberFormat(documentosPorPagar.totales[moneda],2 )  }</label>
                    </td>
                    <td colSpan={2}></td>
                </tr>
                </tfoot>
            </table>
        </SoftBox>
    }

    return (
          <SoftBox key={v4()}  >
            { Object.keys(partidas).map(moneda =>{
                const partidasPorMoneda = partidas[moneda];
                return <Grid container key={ v4() } marginTop={2}>
                    <Grid item xs={12} md={12} xl={12}  textAlign={"center"}>
                        {
                            isMobile && <Box className={"lbl-etiqueta-mobile"}>Seleccionar documentos <CheckAllPartidas moneda={ moneda } /></Box>
                        }
                        { listar(partidasPorMoneda, moneda) }
                    </Grid>

                </Grid>
            }) }
          </SoftBox>
    );
}

TableDocuments.propTypes = {
    partidas: PropTypes.object,
}

export default TableDocuments;