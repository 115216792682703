import * as React from "react";
import SoftBox from "../../../components/SoftBox";
import Box from "@mui/material/Box";
import {Grid, useMediaQuery} from "@mui/material";
import {useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import {useNumberFormat} from "../../../customHooks/useNumberFormat";
import moment from "moment";
import {v4} from "uuid";
import logox from "../../../assets/alpha-theme/images/cards/cobranza3v2.png";
import Typography from "@mui/material/Typography";
import SectionHeader from "./SectionHeader";

const CardPrintInvoice = (props) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const componentRef = useRef();
    const { cuenta,liquidado } = useSelector(state => state.cobranza);
    const [ displayRows, setDisplayRows ]  = useState();
    const { numberFormat } = useNumberFormat();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const forDisplay = [
        {
            plataforma:['netpay','paypal'],
            row:<tr key={ v4() }  >
                <td width={"50%"} className={"IGenericLabel-Normal"} >
                    Cliente
                </td>
                <td width={"50%"} className={"IGenericLabel-Bold"} align={"right"}>
                    { liquidado.nombreCliente }
                </td>
            </tr>
        },
        {
            plataforma:['netpay','paypal'],
            row: <tr key={ v4() } >
                <td className={"IGenericLabel-Normal"}>
                    Pagado a
                </td>
                <td className={"IGenericLabel-Bold"} align={"right"}>
                    { cuenta.rs }
                </td>
            </tr>
        },
        {
            plataforma:['netpay'],
            row: <tr key={ v4() } >
                <td className={"IGenericLabel-Normal"}>
                    Banco
                </td>
                <td className={"IGenericLabel-Bold"} align={"right"}>
                    { liquidado.nombreBanco }
                </td>
            </tr>
        },
        {
            plataforma:['netpay'],
            row: <tr key={ v4() } >
                <td className={"IGenericLabel-Normal"}>
                    Medio
                </td>
                <td className={"IGenericLabel-Bold"} align={"right"}>
                    { liquidado.tipoTarjeta } **** { liquidado.ultimos4Digitos }
                </td>
            </tr>
        },
        {
            plataforma:['netpay','paypal'],
            row: <tr key={ v4() } >
                <td className={"IGenericLabel-Normal"}>
                    Fecha
                </td>
                <td className={"IGenericLabel-Bold"} align={"right"}>
                    { moment(liquidado.fechaOperacion).format("DD/MM/YYYY HH:mm:ss")  }
                </td>
            </tr>
        },
        {
            plataforma:['netpay'],
            row: <tr key={ v4() } >
                <td className={"IGenericLabel-Normal"}>
                    Transacción ID
                </td>
                <td className={"IGenericLabel-Bold"} align={"right"}>
                    ****{ liquidado.transaccionId ? liquidado.transaccionId.substring( liquidado.transaccionId.length - 8):"" }
                </td>
            </tr>
        },
        {
            plataforma:['paypal'],
            row: <tr key={ v4() } >
                <td className={"IGenericLabel-Normal"}>
                    Orden
                </td>
                <td className={"IGenericLabel-Bold"} align={"right"}>
                    ****{ liquidado.orden ? liquidado.orden.substring( liquidado?.orden.length - 8):"" }
                </td>
            </tr>
        },
        {
            plataforma:['netpay','paypal'],
            row: <tr key={ v4() } >
                <td className={"IGenericLabel-Normal"}>
                    Folio
                </td>
                <td className={"IGenericLabel-Bold "} align={"right"}>
                    { cuenta.id }****{ liquidado.folio.substring( liquidado.folio.length - 8) }
                </td>
            </tr>
        },
        {
            plataforma:['netpay','paypal'],
            row: <tr key={ v4() }  >
                <td className={"IGenericLabel-Normal"}>
                    Monto
                </td>
                <td className={"IGenericLabel-Bold"} align={"right"} >
                    { numberFormat(liquidado.monto,2)  }
                </td>
            </tr>
        }

    ]

    useEffect(()=>{
        const rows = forDisplay.map(element=>{
            const aplicaParaPlataforma = element.plataforma.find(item => item === liquidado.plataforma);
            if(aplicaParaPlataforma){
                return element.row;
            }
        })
        setDisplayRows( rows );
    },[liquidado])

    const webView =<>
        <SoftBox borderRadius={"12px"} sx={{ borderBottomColor:"#c3c3c3", margin:1, background:"linear-gradient(to bottom left, #D9D9E8, #FBFBFB)", paddingBottom:1 }} ref={componentRef}  >
            <Box sx={{ borderTopRightRadius:5,paddingTop: 2,paddingLeft:2, paddingRight:2, borderTopLeftRadius:5 }} >
                <SectionHeader cuenta={cuenta} />
            </Box>

            <SoftBox bgColor="color18" borderRadius="lg"
                     shadow="lg"
                     opacity={1} sx={{ margin:2, padding:2 }}>
                <Grid container justifyContent={"center"} >
                    <Grid item xs={12} md={6} xl={6} >
                        <Box >
                            <table width={"100%"} className={"ITable-recepit"} >
                                <tbody>
                                <tr >
                                    <td colSpan={2} className={"IExitosoLabel"} align={"center"}>
                                        PAGO EXITOSO
                                    </td>
                                </tr>
                                { displayRows }
                                </tbody>
                            </table>
                        </Box>
                    </Grid>

                </Grid>
            </SoftBox>
        </SoftBox>

        <SoftBox bgColor={"color18"} borderRadius={"5px"} sx={{ borderBottomColor:"#c3c3c3",paddingLeft:1,paddingRight:1 }} >
            <button className={"IBtn-alpha-success-z2"} type={"button"} onClick={ handlePrint }  >
                Imprimir
            </button>
        </SoftBox>
    </>;

    const mobileView =<>
        <SoftBox  sx={{ borderBottomColor:"#c3c3c3", margin:1, paddingBottom:1 }} ref={componentRef}  >
            <Box sx={{ borderTopRightRadius:5,paddingTop: 0,paddingLeft:0, paddingRight:0, borderTopLeftRadius:5 }} >
                <SectionHeader cuenta={cuenta} />
            </Box>

            <SoftBox
                     opacity={1} sx={{ margin:0, padding:1,border:"5px solid #E6E6E6",borderRadius:2 }}>
                <Grid container justifyContent={"center"} >
                    <Grid item xs={12} md={6} xl={6} >
                        <Box >
                            <table width={"100%"} className={"ITable-recepit"} >
                                <tbody>
                                <tr >
                                    <td colSpan={2} className={"IExitosoLabel"} align={"center"}>
                                        PAGO EXITOSO
                                    </td>
                                </tr>
                                { displayRows }
                                </tbody>
                            </table>
                        </Box>
                    </Grid>

                </Grid>
            </SoftBox>
        </SoftBox>

        <SoftBox bgColor={"color18"} borderRadius={"5px"} sx={{ borderBottomColor:"#c3c3c3",paddingLeft:1,paddingRight:1 }} >
            <button className={"IBtn-alpha-success-z2"} type={"button"} onClick={ handlePrint }  >
                Imprimir
            </button>
        </SoftBox>
    </>;

    return isMobile ? mobileView : webView;
}

export default CardPrintInvoice;