import React, {   useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";

import IconAddTask from '@mui/icons-material/AddTask';
import IconMenu from '@mui/icons-material/Menu';
import Icon from "@mui/material/Icon";
import IconAdd from "@mui/icons-material/Add"
import SoftInput from "components/SoftInput";
import Divider from "@mui/material/Divider";

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import GridCdcs from '../../personas/components/GridCdcs';
//
import CuentaRequest from 'Repository/Banca/Cuentas';
import Empresas from 'Repository/Banca/Empresas';
import Licencias from 'Repository/Banca/Licencias';

import { Card } from '@mui/material';
import AddCentroDeCosto from './AddCentroDeCosto';
import CentroDeCosto from 'Repository/Banca/CentroDeCosto';
import Loading from 'examples/Components/Loading';
import {v4} from "uuid";
import Box from "@mui/material/Box";


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({ color:"white", "borderStyle": "groove", "borderRadius": "5px", "fontSize":".8em","heigth":"5px",
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(121, 181, 221, .9)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function ItemCuenta({uuid,  onUpdate, refresh}) {

    const [visibleCdcs, setVisibleCdcs ] = useState(false);
    const [records, setRecords] = useState([]);
    const [recordsOrigin, setRecordsOrigin] = useState([]);
    const [txtBuscar, setTxtBuscar] = useState('');
    const [cuenta, setCuenta ] = useState({});
    const [idClienteCdc, setIdClienteCdc] = useState('');

    const [EmpresaList, setEmpresaList] = useState([]);
    const [SerieList, setSerieList] = useState([]);

    const [formOpen, setFormOpen] = useState(false)
    const [cdcToEdit, setCdcsToEdit] = useState({});
    const [loading, setLoading] = useState(false);

    const [resetFormCentroDeCosto, setResetFormCentroDeCosto] = useState(0);

    const cuentax = new CuentaRequest();
    const empresax = new Empresas();
    const licenciax = new Licencias();
    const cdcsx = new CentroDeCosto();
    
    //#region "events"
    useEffect(()=>{
        loadData();

    },[]);
    //#endregion

    //#region methods
    const loadData = async (  )=>{
        setLoading(true);
        cuentax.mostrar(uuid).then(response=>{
            setCuenta(response.response.data);
            cuentax.Cdc(uuid).then(response=>{
                setRecords(response.response.data);
                setRecordsOrigin(response.response.data);
                setLoading(false);
                console.log(response.response.data.length);
                if(response.response.data.length == 1){
                    setExpanded(() => ( response.response.data[0].rfc + response.response.data[0].serie ));
                }
            });
        });

        const dataEmpresa = await empresax.listar(uuid);
        const data = dataEmpresa.response.data.map(empresa=>{
            return {
                value: empresa.uuid,
                label: empresa.rs
            }
        }) /*dataEmpresa.response.data*/;

        setEmpresaList([
            {
                value: '',
                label: 'SELECCIONE UNA OPCIÓN'
            },
            ...data
        ]);

        const dataSeries = await licenciax.listar(uuid);
        const data1 = dataSeries.response.data.map(element => {
            return {
                value: element.uuid,
                label: element.serie
            }
        });
        setSerieList([
            {
                value: '',
                label: 'SELECCIONE UNA OPCIÓN'
            },
            ...data1
        ]);

        
    }

    const handleChangeBusqueda = (event)=>{
        setTxtBuscar(event.target.value);
        const filter = recordsOrigin.filter( item => (item.rfc.toLowerCase().includes(event.target.value) || item.serie.includes(event.target.value)  )  );
        setRecords(filter);
    }

    const onAddCdc = ()=>{
        loadData();
    }

    const onCancelCdc = () =>{
        setFormOpen(false);
        setCdcsToEdit({});
    }

    const onClickOpenForm = ()=>{
        getConsecutivo();
        setFormOpen(true);
        setResetFormCentroDeCosto( resetFormCentroDeCosto + 1 );
        loadData();
        
    }

    const onSelectToEdit = (cdcs_uuid)=>{
        setFormOpen(true);
        cdcsx.mostrar(cdcs_uuid).then((response) =>{
            setCdcsToEdit(response.response.data);
        });
        setIdClienteCdc('');
    }

    const getConsecutivo = async ()=>{
        const data = await cdcsx.getCliente();
        setIdClienteCdc(()=>data.response.data);
    }

    const handleEncontrar = (event) => {
        if (event.key === "Enter") {
            handleChangeBusqueda(event);
        }
    }

    //#endregion

    //#region  Accordion
      
        const [expanded, setExpanded] = useState('panel1');
      
        const handleChange = (panel) => (event, newExpanded) => {
            setExpanded(() => (newExpanded ? panel : false));
        };
    //#endregion

    return(
        <>
        <Loading loading={loading} />
        <Card  sx={{ border:"5px solid #f7f7f7", borderRadius:0, }}>
            <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                pl={1}
                pr={1}
                pb={1}
                mt={2} ml={2} mr={2} mb={1}
                >
                <SoftBox width="100%" >
                    <Grid container >
                        <Grid item xs={10} md={9} xl={9}>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <label className={"IGenericLabel-Normal2"} > Centros de Costos: { cuenta.alias ? cuenta.alias :'CUENTA SIN ALIAS' } </label>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={10} md={3} xl={3}>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <button className={"btn-alpha-black pad-element"} type={"button"} onClick={ (event) => onClickOpenForm(event) } >
                                    <i className="fa-solid fa-plus"></i> Agregar centro de costos
                                </button>
                            </SoftBox>
                        </Grid>
                    </Grid>

                    { formOpen && <AddCentroDeCosto cuenta_uuid={uuid} empresasList={EmpresaList} seriesList={SerieList} 
                        onAdd = { onAddCdc } onCancel = { onCancelCdc } editItem={ cdcToEdit } reset = { resetFormCentroDeCosto } 
                        idClienteCdc = { idClienteCdc }
                        /> }
                    <Grid container >
                        <Grid item  xs={8} md={9} xl={9}>
                        </Grid>
                        <Grid item  xs={4} md={3} xl={3} mb={1}>
                            <input type={"search"}  className={"ITxtEncontrar-Banca"} name={"encontrar"} value = { txtBuscar } maxLength={"50"} placeholder={"Buscar"}
                                   onChange={ (event)=> handleChangeBusqueda(event)  } onKeyDown={  handleEncontrar }  />
                        </Grid>
                    </Grid>

                {  records.map((cdc) =>{
                        return  (
                            <GridCdcs key={ v4() } centroDeCosto={cdc} onSelectToEdit = { (value) =>onSelectToEdit(value) } refresh={refresh}

                            />
                        )
                    })  }

                </SoftBox>
            </SoftBox>
        </Card>
            
        </>
    )
    
}

ItemCuenta.propTypes = {
    uuid: PropTypes.string,
    onUpdate : PropTypes.func,
    refresh: PropTypes.any
  };

export default ItemCuenta