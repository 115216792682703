/**
 =========================================================
 * Estado de cuenta netpay para el comercio
 =========================================================
 */

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftTypography from "../../../components/SoftTypography";
// Soft UI Dashboard React examples
import moment from "moment";
import {v4} from "uuid";

import Filtro from "../../cuentas/components/Filtro";
import Autorizador from "../../../Repository/Autorizador";
import {useForm} from "../../../customHooks/useForm";
import {useAlphaAlertModal} from "../../../customHooks/useAlphaAlertModal";
import AlphaTable from "../../cuentas/components/AlphaTable";
import Paginador from "../../terminales/components/Paginador";
import TableRow from "./../components/TableRow";
import Sumario from "./../components/detalle/Sumario";
import {useDispatch} from "react-redux";
import {closeGeneralLoader, openGeneralLoader} from "../../../store/slice/alertSlice";


const initialForm = {
    fechaaltadespuesde:moment().startOf('month').format('YYYY-MM-DD'),
    fechaaltaantesde:moment().endOf('month').format('YYYY-MM-DD'),
    cuenta_uuid:'',
    status:"",
}

const validationForm={
    cuenta_uuid: [(value)=> value.trim() !== "","Centro de costos requerido."],
    fechaaltadespuesde: [(value)=> value.trim() !== "","Desde requerido."],
    fechaaltaantesde: [(value)=> value.trim() !== "","Hasta requerido."],
    status: [(value)=> { return true; },""],
}

function EdoCuenta({ plataforma = "netpay" }) {
    /**
     * Variables
     */
    const dispatch = useDispatch();
    const autorizadorx = new Autorizador();
    const [cdcAutorizados, setCdcAutorizados] = useState([]);
    const [listadoOperaciones, setListadoOperaciones] = useState([]);
    const [ rows, setRows] = useState([]);
    const [ sumario, setSumario] = useState([]);
    const [ cursor, setCursor] = useState({});
    const { fechaaltadespuesde,fechaaltaantesde,cuenta_uuid,status, onInputChange, isFormValid, onCustomResetForm }  = useForm(initialForm, validationForm);
    const { alphaAlert,setOpenAlert,openAlert,setMessageAlert } = useAlphaAlertModal();

    /**
     * State events
     */
    useEffect(()=>{ loadComponent() }, []);

    useEffect(()=>{ fillTable() },[listadoOperaciones])

    /**
     * Custom Events
     */
    const loadComponent = async ()=> {
        dispatch( openGeneralLoader() );
        const result = await autorizadorx.cdcsAutorizados(plataforma);
        dispatch( closeGeneralLoader() );
        if(result.status === 'ok') {
            setCdcAutorizados(result.response);
            //if(result.response.length === 1){
                const event = {
                    target:{
                        name: 'cuenta_uuid',
                        value: result.response[0].uuid
                    }
                }
                onInputChange(event);
                const data = {
                    fechaaltadespuesde,
                    fechaaltaantesde,
                    cuenta_uuid:result.response[0].uuid,
                    plataforma,
                    status,
                }
                loadDataFromFiltro(data);
            //}
        }
    }

    const fillTable = () => {
        let rowIndex = 0;
        const records = listadoOperaciones.map(element=>{
            rowIndex+=1;
            return <TableRow key={ v4() } transaccion={ element} bgColor={ (rowIndex % 2) === 0? "table-alpha-filter-tr-pair":"" }  />;
        })
        setRows( records );
    }

    const loadDataFromFiltro = async (data = {}) =>{
        dispatch( openGeneralLoader() );
        const result = await autorizadorx.estadoCuenta(data);
        dispatch( closeGeneralLoader() );
        if(result.status === 'ok')
        {
            setListadoOperaciones( result.response.data );
            setCursor( result.response.cursor );
            setSumario( result.response.sumario );
        }
    }

    const onApliyFilter = ()=>{
        if(!isFormValid){
            setMessageAlert("No se puede realizar la consulta con los datos especificados");
            setOpenAlert(true);
            return;
        }
        const data = {
            fechaaltadespuesde,
            fechaaltaantesde,
            cuenta_uuid,
            plataforma,
            status,
        }
        loadDataFromFiltro(data);

    }

    const onExport = async() =>{
        const data = {
            fechaaltadespuesde,
            fechaaltaantesde,
            cuenta_uuid,
            plataforma,
            status,
        }
        await autorizadorx.exportEstadoCuentaToPdf(data);
    }

    const onExportExcel = async() =>{
        const data = {
            fechaaltadespuesde,
            fechaaltaantesde,
            cuenta_uuid,
            plataforma,
            status,
        }
        await autorizadorx.exportEstadoCuentaToExcel(data);
    }

    const resetForm = async ()=>{

        const data = {
            fechaaltadespuesde :moment().startOf('month').format('YYYY-MM-DD'),
            fechaaltaantesde : moment().endOf('month').format('YYYY-MM-DD'),
            cuenta_uuid,
            plataforma,
            status:"",
        }
        onCustomResetForm(data);
        await loadDataFromFiltro(data);
    }

    const nextEvent = ()=>{
        paginatorEvent("next")
    }
    const prevEvent = ()=>{
        paginatorEvent("prev")
    }

    const paginatorEvent = (type)=>{
        if(cursor){
            let query = '';
            if(type === 'next')
            {
                query = cursor.next;
            }else{
                query = cursor.prev;
            }
            listarPorCursor(query);
        }
    }

    const listarPorCursor = async (query) =>{
        loadDataFromFiltro(query)
    }

    /**
     * Elements
     */

    const cdcSelect = <select className={"ITextbox"} name={"cuenta_uuid"} value={ cuenta_uuid } onChange={ onInputChange }>
        { cdcAutorizados.map(element =>{
            return <option key={ v4() }  value={ element.uuid }> { element.rfcLicencia } </option>
        }) }
    </select>

    const inputSelec =<>
        <label className={"ITextbox"}    >{  cdcAutorizados[0]?.rfcLicencia }</label>
        <input type={"hidden"} name={"cuenta_uuid"} value={ cuenta_uuid }  />
    </>

    const cdcOptions = <>
        {
            cdcAutorizados.length > 1 ? cdcSelect : inputSelec
        }
    </>

    const filtroComponent =<>
        <SoftBox pl={1} pt={2} pb={1} component="form" role="form"  borderRadius="lg"  >
            <Grid container spacing={1} >

                <Grid item xs={12} md={5} xl={5}>
                    <SoftBox lineHeight={0}>
                        <SoftTypography variant="caption" color="color4" >
                            Centro de costos
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox>
                        {

                            cdcOptions
                        }
                    </SoftBox>
                </Grid>

                <Grid item xs={12} md={2} xl={2}>
                    <SoftBox lineHeight={0}>
                        <SoftTypography variant="caption" color="color4" >
                            Desde
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox>
                        <input type={"date"} className={"ITextbox"} name={"fechaaltadespuesde"} value={ fechaaltadespuesde }
                               maxLength={20}  onChange={ onInputChange } />
                    </SoftBox>
                </Grid>

                <Grid item xs={12} md={2} xl={2}>
                    <SoftBox lineHeight={0}>
                        <SoftTypography variant="caption"  color="color4" >
                            Hasta
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox>
                        <input type={"date"} className={"ITextbox"} name={"fechaaltaantesde"} value={ fechaaltaantesde }
                               maxLength={20}  onChange={ onInputChange } />
                    </SoftBox>
                </Grid>

                <Grid item xs={12} md={3} xl={2}>
                    <SoftBox lineHeight={0}>
                        <SoftTypography variant="caption"  color="color4" >
                            Estatus
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox>
                        <select name={"status"} id ="status" className={"ITextbox"} onChange={ onInputChange } value={ status }>
                            <option value={""}>TODOS</option>
                            <option value={"EN PROCESO"}>EN PROCESO</option>
                            <option value={"PAGADO"}>PAGADO</option>
                            <option value={"CANCELADO"}>CANCELADO</option>
                            <option value={"INTENTO FALLIDO"}>INTENTO FALLIDO</option>
                        </select>
                    </SoftBox>
                </Grid>

                <Grid item xs={6} md={2} xl={2}>
                    <SoftBox >
                        <button className={"btn-alpha-info"} type={"button"} onClick={ (event) => onApliyFilter(event) }   >
                            <i className="fa-solid fa-filter"></i> Filtrar
                        </button>
                    </SoftBox>
                </Grid>
                <Grid item xs={6} md={2} xl={2}>
                    <SoftBox >
                        <button className={"btn-alpha-gray-1"} type={"button"} onClick={ resetForm  }   >
                            <i className="fa-solid fa-filter-circle-xmark"></i> Quitar filtro
                        </button>
                    </SoftBox>
                </Grid>
                <Grid item xs={6} md={3} xl={2} >
                    <SoftBox >
                        <button className={"btn-alpha-info-3"} type={"button"} onClick={ onExport  }   >
                            <i className="fa-solid fa-file-arrow-down"></i> PDF
                        </button>
                    </SoftBox>
                </Grid>
                <Grid item xs={6} md={3} xl={2} >
                    <SoftBox >
                        <button className={"btn-alpha-success-2"} type={"button"} onClick={ onExportExcel  }   >
                            <i className="fa-solid fa-file-arrow-down"></i> Excel
                        </button>
                    </SoftBox>
                </Grid>

            </Grid>
        </SoftBox>


    </>

    const columns = [
        {  name:'Folio',width:"10%"  },
        {  name:'Fecha de Operación',width:"10%"  },
        {  name:'Estatus',width:"10%"  },
        {  name:'Monto',width:"8%"  },
        {  name:'Cliente',width:"10%"  },
        {  name:'Método',width:"10%"  },
    ];

    return (
            <>
                <SoftBox pt={1} >
                    <Filtro bodyContent={ filtroComponent } icon ={"fa-solid fa-filter"} title={ "Filtro" } open={true} />
                </SoftBox>
                <SoftBox pt={1} >
                    { rows.length > 0 && <Filtro bodyContent={ <Sumario totales={ sumario } plataforma={plataforma} /> } icon ={"fa-solid fa-arrow-up-short-wide"} title={ "Sumario" } open={false} /> }
                </SoftBox>
                <SoftBox mt={1}  bgColor={"white"} sx={{ border:"5px solid #f7f7f7", padding:1 }} >
                    <AlphaTable headers={ columns} records={ rows } cssClass={"table-alpha-filter-2" } />
                    {
                        cursor &&  cursor && <Paginador cursor={ cursor } nextEvent={ nextEvent } prevEvent={ prevEvent } />
                    }
                    { openAlert && alphaAlert }
                </SoftBox>
            </>
    );
}

EdoCuenta.propTypes = {
    plataforma: PropTypes.string.isRequired,
};

export default EdoCuenta;