import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import CentroDeCosto from 'Repository/Banca/CentroDeCosto';
import CuentaInfoCard from "./CuentaInfoCard";


function CentroItem({centroDeCosto }) {

    const [verCdcs, setVerCdcs] = useState(false);
    const [estadosDeCuenta, setEstadosDeCuenta] = useState([]);
    const [statex, setStatex] = useState(centroDeCosto.disabled == null );
    const centrox = new CentroDeCosto();
    //#region events
    
        const handleSeleccionar = (event)=>{
            setVerCdcs(!verCdcs);
        }

        useEffect(() => {
            load();
        }, [])
        
    //#endregion

    //#region  methods

    //#region methods
    const load = async ()=>{
        const {response = [], status} = await centrox.getEstadosDeCuentaDisponibles( centroDeCosto.uuid );
        setEstadosDeCuenta(response);
    }
    //#endregion
      
    //#endregion 
   

    return(
        <>
            <CuentaInfoCard key={ centroDeCosto.uuid } cdc_uuid={ centroDeCosto.uuid } cuenta={ centroDeCosto.rs } centros={ estadosDeCuenta } info={ [
                { label: 'CLABE', value: centroDeCosto.clabe  },{ label: 'RFC', value: centroDeCosto.rfc  }, { label: 'Licencia', value: centroDeCosto.serie  }
            ]} />

        </>
    )
    
}

//persona_uuid,  cuenta_uuid , rfc, serie
CentroItem.propTypes = {
    uuid: PropTypes.string,
    centroDeCosto: PropTypes.object
  };

export default CentroItem;