/**
 =========================================================
 * Estado de cuenta paypal
 =========================================================
 */

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Filtro from "../cuentas/components/Filtro";
import FormFiltro from "../terminales/components/FrmFiltro";
import EdoCuenta from "../edocuentanetpay/components/EdoCuenta";

function EdoCuentaPaypal() {

    return (
        <DashboardLayout >
            <DashboardNavbar light={true} />
            <EdoCuenta plataforma={ "paypal" } />
        </DashboardLayout>
    );

}

export default EdoCuentaPaypal;