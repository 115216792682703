import * as React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {useNumberFormat} from "../../../customHooks/useNumberFormat";
import {actualizarConfiguracionPago} from "../../../store/slice/cobranzaSlice";
import {Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";

const BtnVolverAListado = () => {
    const dispatch = useDispatch();
    const { partidasSelected } = useSelector(state => state.partidas);
    const { tipoCambio } = useSelector(state => state.tipoCambio);
    const { numberFormat } = useNumberFormat();
    const { navigate } = useNavigate();

    const onClickGuardar = ()=>{

        // console.log( payload )
        //
        // dispatch( actualizarConfiguracionPago(payload) )
        window.location.reload(false);
    }

    return <>
        <Grid container>
            <Grid item xs={12} md={8} xl={8}></Grid>
            <Grid item xs={12} md={4} xl={4}>
                <button className={ "btn-alpha-black pad-element " } type={"submit"} onClick={ onClickGuardar }  >
                    <i className="fa-solid fa-list-check"></i> Volver a seleccionar
                </button>
            </Grid>
        </Grid>
    </>

}

BtnVolverAListado.propTypes = {

};

export default BtnVolverAListado;