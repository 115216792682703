
import {useDispatch, useSelector} from "react-redux";
import {v4} from "uuid";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {listarTerminales} from "../../../store/thunk/reportes";
import AlphaTable from "../../cuentas/components/AlphaTable";
import WebModal from "../../registro/components/WebModal";
import FrmCancelarTpv from "./FrmCancelarTpv";
import BtnCambioEstadoTpv from "./BtnCambioEstadoTpv";
import * as React from "react";
import Paginador from "./Paginador";
import Grid from "@mui/material/Grid";
import SoftBox from "../../../components/SoftBox";
import {unselectServicio} from "../../../store/slice/cuentasSlice";
import {closeModal} from "../../../store/slice/alertSlice";

const defaultFiltro = {
    fechaaltadespuesde: "",
    fechaaltaantesde:"",
    textoBuscar:""
}

const TerminalesTable = (props) => {
    const navigate =  useNavigate();
    const dispatch = useDispatch();
    const { terminales } = useSelector(state => state.reportes);

    useEffect(
        ()=>{
            dispatch( listarTerminales(
                    {   fechaaltadespuesde:"",
                        fechaaltaantesde:"",
                        textoBuscar:""
                    }
                )
            )
        }, [])
    const onClickCuenta = (uuid) =>{
        localStorage.setItem("cCuentaUuid", uuid);
        localStorage.setItem("defaultOptionMenu",5);
        const uri = `/cuentas`;
        navigate(uri, { replace:true } );
    }

    const onClickTerminal = (uuid) => {
        const uri = `/terminales/registro/${ uuid }`;
        navigate(uri, { replace:true } );
    }

    const handleCloseModal = ()=>{
        dispatch( unselectServicio() );
        dispatch( closeModal() );
    }

    const nextEvent = (filtro = {}) =>{
        dispatch( listarTerminales(
            { ...defaultFiltro, ...filtro }
            )
        )
    }

    const prevEvent = (filtro = {}) =>{
        dispatch( listarTerminales(
            { ...defaultFiltro, ...filtro }
            )
        )
    }

    const headers = [
        { name:"Centro de Costos", width:"5%" },
        { name:"Licencia", width:"4%" },
        { name:"Nombre Comercial", width:"5%" },
        { name:"RFC", width:"5%" },
        { name:"Razón Social", width:"15%" },
        { name:"Numero de Serie", width:"8%" },
        { name:"Store ID", width:"5%" },
        { name:"Password", width:"5%" },
        { name:"Estatus", width:"5%" },
    ];

    const rows = terminales.records.map(element => {
        return (
            <tr key={ v4() }>
                <td><label className={"label-container"}>{ element.id }</label></td>
                <td>{ element.licencia }</td>
                <td>{ element.nombreComercial }</td>
                <td><label onClick={ (event)=> onClickCuenta(element.cuenta_auth_uuid) } className={ "ILabelLink" }>{ element.rfc }</label></td>
                <td>{ element.rs }</td>
                <td><label onClick={ (event)=> onClickTerminal(element.uuid) } className={ "ILabelLink" }>{ element.numeroSerie }</label></td>
                <td>{ element.storeId }</td>
                <td>{ element.password }</td>
                <td>
                    <BtnCambioEstadoTpv device={ element } />
                </td>
            </tr>
        );
    })

    return (
        <>

            <Grid container>
                <Grid item xs={12}>
                    <AlphaTable records={ rows } headers={ headers } />
                </Grid>

            </Grid>
            <Grid container justifyContent={"center"} direction={"row"} alignItems={"center"}>
                <Grid item xs={8} sm={8} md={5} xl={4} lg={4}>
                    <SoftBox sx={{ width:"100%" }}>
                        <Paginador cursor={ terminales.cursor } nextEvent={ nextEvent } prevEvent={ prevEvent } />
                    </SoftBox>
                </Grid>
            </Grid>


            <WebModal title={"Desactivar TPV"} formComponent={ <FrmCancelarTpv /> } fnModaCloseAction={ handleCloseModal } />
        </>
    );
}

TerminalesTable.propTypes = {

}

export default TerminalesTable;