import api_core from '../services/config';
import Operacion from './Operacion';
class Cuenta extends Operacion{
    constructor() {
        super();
    }

    /**
     * @comment Crear una cuenta
     * @param data
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async store(data){
        const url = `panel/cuentas`;
        return await this.transaction('POST',url, JSON.stringify(data) , true );
    }

    /**
     * @comment Regresamos una cuenta en particular
     * @param uuid
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async mostrar(uuid){
        const url = `panel/cuentas/${uuid}`;
        return await this.transaction('GET',url, null , true );
    }

    /**
     * @comment Listamos cuentas, para busqueda en el catalogo de terminales
     * @param data
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async listar(data){
        let params = [];
        for(const atributo in data){
            if(data[atributo] !== "") params.push( `${atributo}=${ data[atributo] }` );
        }

        const url = `panel/cuentas?${ params.join('&') }`;
        return await this.transaction('GET',url, null , true );
    }

    /**
     * @comment Mandamos un cambio de estado
     * @param data
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async cambiarEstado(data){
        const url = `panel/cuentas/${data.uuid}/${data.status}`;
        return await this.transaction('DELETE',url, JSON.stringify({observaciones:data.observaciones}) , true );
    }

    /**
     * @comment Actualiza la informacion de una cuenta
     * @param data
     * @param uuid
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async update(data, uuid){
        const url = `panel/cuentas/${uuid}`;
        return await this.transaction('PUT',url, JSON.stringify(data) , true );
    }

    /**
     * @comment Listamos cuentas
     * @param data
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async listarv2(cuenta_uuid, plataforma){

        const url = `panel/centro-de-costos/${cuenta_uuid}/${plataforma}`;
        return await this.transaction('GET',url, null , true );
    }

    /**
     * @comment Obtenemos la lista de empresas y licencias por asignar
     * @param data
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async listarEmpresas(cuenta_uuid, plataforma){
        const url = `panel/empresas/${cuenta_uuid}/${plataforma}`;
        return await this.transaction('GET',url, null , true );
    }

}

export default Cuenta;