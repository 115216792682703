import * as React from "react";
import Grid from "@mui/material/Grid";
import "flatpickr/dist/themes/dark.css";
import Flatpickr   from 'react-flatpickr';
import {useDateFormat} from "../../../customHooks/useDateFormat";
import moment from "moment";
import {useForm} from "../../../customHooks/useForm";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {listarTerminales} from "../../../store/thunk/reportes";
moment.locale("es-MX");

const initialForm = {
    fechaaltadespuesde: "",
    fechaaltaantesde:"",
    textoBuscar:""
}

const formValidation = {
    fechaaltadespuesde: [(value)=> value.trim() == "","Fecha inicial requerida."],
    fechaaltaantesde: [(value)=>{ return value.trim() == ""; } ,"Fecha final requerida."],
    textoBuscar: [()=>{ return false; } ,""]
}

function FrmFiltro (props) {

    const { localeFormat } = useDateFormat();
    const { fechaaltadespuesde, fechaaltaantesde,textoBuscar, onInputChange, isFormValid } = useForm( initialForm, formValidation );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onClickFiltro = () =>{
        dispatch( listarTerminales(
                {   fechaaltadespuesde,
                    fechaaltaantesde,
                    textoBuscar
                }
            )
        )
    }

    const onClickAdd = () => {
        navigate("/terminales/registro")
    }

    return (
        <Grid container  p={1}>
            <Grid item xs={12} md={3} xl={3}>
                <input type={"text"} className={"ITextbox"} placeholder={"Buscar"} name={"textoBuscar"} value={ textoBuscar }
                       onChange={ onInputChange }
                />
            </Grid>
            <Grid item xs={12} md={2} xl={2} pl={1}>
                <Flatpickr
                    value={fechaaltadespuesde}
                    options ={{ dateFormat:"d-m-Y", altFormat:"d-m-Y", locale:  localeFormat    }}
                    className={"ITextbox"}
                    placeholder={"dd-mm-aaaa"}
                    onChange={(selectedDates, dateStr, instance) => {
                        const customEvent =  {
                            target:{
                                name : "fechaaltadespuesde",
                                value: dateStr
                            }
                        }
                        onInputChange(customEvent);
                    }}
                />
            </Grid>
            <Grid item xs={12} md={2} xl={2} pl={1} >
                <Flatpickr
                    value={fechaaltaantesde}
                    options ={{ dateFormat:"d-m-Y", altFormat:"d-m-Y", locale:  localeFormat    }}
                    className={"ITextbox"}
                    placeholder={"dd-mm-aaaa"}
                    onChange={(selectedDates, dateStr, instance) => {
                        const customEvent =  {
                            target:{
                                name : "fechaaltaantesde",
                                value: dateStr
                            }
                        }
                        onInputChange(customEvent);
                    }}
                />
            </Grid>
            <Grid item xs={12} md={2} xl={2} pl={1}>
                <button className={"btn-alpha-black pad-element"} type={"submit"} onClick={ onClickFiltro }>
                    <i className="fa-solid fa-arrows-rotate"></i> Traer Datos
                </button>
            </Grid>
            <Grid item xs={12} md={2} xl={2} pl={1}>
                <button className={"btn-alpha-black pad-element"} type={"submit"} onClick={ onClickAdd }>
                    <i className="fa-solid fa-plus"></i> Agregar Terminal
                </button>
            </Grid>
        </Grid>
    );
}

export default FrmFiltro;