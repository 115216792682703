import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Filtro from "../../../cuentas/components/Filtro";
import ServicioContratado from "../../../../Repository/ServicioContratado";
import PropTypes from "prop-types";
import FrmLoop from "../netpay_form/FrmLoop";

const defaultCredenciales = {
    public_key:"",
    secret_key:""
}

const LOOP2 = ({ cuenta_uuid }) => {
    const  servContratado = new ServicioContratado();
    const [contratados, setContratados] = useState([]);
    const [ credenciales, setCredenciales ] = useState(defaultCredenciales);
    const [ device, setDevice ] = useState({});

    const loadComponent = async() =>{
        const result = await servContratado.listar(cuenta_uuid);
        if(result.status === "ok")
            setContratados( result.response.contratados );
    }

    useEffect(()=>{
        loadComponent();
    },[])

    useEffect(()=>{
        contratados.forEach(element =>{
            if(element.tipoServicio === 'NETPAY_LOOP'){
                setCredenciales( element.credenciales );
                setDevice( element );
            }
        })
    }, [contratados])

    return (
        <Box pt={1} m={1}>
            <Filtro bodyContent={ <FrmLoop cuenta_uuid={ cuenta_uuid } credenciales={credenciales} device={device } /> } title={"Llaves"} icon={ "fa-solid fa-key" } open={true} />
        </Box>
    );
}

LOOP2.propTypes = {
    cuenta_uuid:PropTypes.string.isRequired,
}

export default LOOP2;