import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    valid:false,
    errors:[]
}

export const captchaSlice = createSlice({
    name: 'captcha',
    initialState,
    reducers: {
        isValid :(state, action) => {
            state.valid = action.payload;
        },
    }
})

// Action creators are generated for each case reducer function
export const { isValid, } = captchaSlice.actions

export default captchaSlice.reducer