import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Select from 'react-select';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";
import SoftTypography from "components/SoftTypography";
import Switch from "@mui/material/Switch";
import { Card } from '@mui/material';
import Swal from 'sweetalert2';

import SoftButton from "components/SoftButton";
import NativeSelect  from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import SoftInput from "components/SoftInput";

import IconSave from '@mui/icons-material/Save';
import IconBack from '@mui/icons-material/ArrowBack';

import Licencias from 'Repository/Banca/Licencias';
import CentroDeCosto from 'Repository/Banca/CentroDeCosto';
import {v4} from "uuid";


function cambiarEstado(item, value,/* callback */ callback){
    callback(item, value);
}

const initialItemSelect = {
    value: '',
    label: 'SELECCIONE UNA OPCIÓN'
}

function AddCentroDeCosto({cuenta_uuid, empresasList = [], seriesList = [], onAdd, onCancel, editItem, reset, idClienteCdc = '' }) {
    
    const [LicenciasCollection, setLicenciasCollection] = useState(seriesList)
    const [EmpresaUuid, setEmpresaUuid] = useState(   );
    const [LicenciaUuid, setLicenciaUuid] = useState(  );
    const [idCliente, setIdCliente] = useState(idClienteCdc);
    const [stpNombre, setStpNombre] = useState();
    const [Editing, setEditing] = useState('');
    const [currentEdit, setCurrentEdit] = useState(editItem);

    const [error, setError] = useState({
        stpNombre: false
    });

    const licenciax = new Licencias();
    const centrox = new CentroDeCosto();

    //#region mmethods
    const load = async ()=>{
        const dataSeries = await Licencias.listar(cuenta_uuid);
        const data1 = dataSeries.response.data;
        setLicenciasCollection(data1);
    }
    //#endregion

    //#region eventos
       
        useEffect(()=>{
            if(editItem){
                setEditing( editItem.uuid );
                setEmpresaUuid( editItem.empresa_uuid );

                setLicenciaUuid( editItem.licencia_uuid );
                setIdCliente( editItem.idCliente );
                setStpNombre( editItem.stpNombre ? editItem.stpNombre : '' );
                setCurrentEdit(editItem);
                setLicenciasCollection(LicenciasCollection);
            }
        }, [editItem])

        useEffect(()=>{
            if(reset > 0){
                setEditing('' );
                setEmpresaUuid( '' );
                setLicenciaUuid( '' );
                setStpNombre( '' );
                setCurrentEdit('');
            }
        }, [reset])

        
        useEffect(()=>{ 
            
                var regex = new RegExp("^[a-zA-Z0-9]+$");
                if (!regex.test(stpNombre) ) {
                    setError({
                        ...error,
                        stpNombre: true
                    })
                }else{
                    setError({
                        ...error,
                        stpNombre: false
                    })
                }
            
            
        }, [stpNombre])
        
        

        useEffect(()=>{
            setIdCliente(idClienteCdc);
        }, [idClienteCdc])

        const onChangeEmpresa = async(event)=>{
            //TODO::revisar para que no me bloquee el item a la hora de la edicion
            setEmpresaUuid(event.target.value);
            await licenciax.licenciasDisponibles( cuenta_uuid ,event.target.value).then(response=>{
                setLicenciaUuid('');
                const data = response.response.data.map(licencia=>{
                    return {
                        value: licencia.uuid,
                        label: licencia.serie
                    }
                });

                setLicenciasCollection( [
                        { value:"", label:"SELECCIONE UNA OPCIÓN" },
                    ...data
                ]);
            });
        }

        const onChangeLicencia = (event)=>{
            setLicenciaUuid( event.target.value );
        }

        const handleCrearCDC = ()=>{

            if(EmpresaUuid.length === 0 ){
                Swal.fire({
                    icon: 'error',
                    title: 'Excelente',
                    text: 'Seleccione una empresa para poder continuar',
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar'
                });
                return false;
            }

            if(LicenciaUuid ===  ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Excelente',
                    text: 'Seleccione una licencia para poder continuar',
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar'
                });
                return false;
            }

            if(stpNombre.length === 0 || idCliente.length === 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Excelente',
                    text: 'Ingrese los datos relacionados a STP',
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar'
                });
                return false;
            }

            const params = {
                cuenta_uuid,
                empresa_uuid : EmpresaUuid,
                licencia : LicenciaUuid,
                idCliente: idCliente,
                stpNombre: stpNombre 
            }
            console.log(params)
            if (Editing === '' | Editing === undefined ){
                centrox.store(params).then(response=>{
                    if(response.status === 'ok'){
                        Swal.fire({
                            icon: 'success',
                            title: 'Excelente',
                            text: response.response.text,
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        });
                        onAdd();
                        setEmpresaUuid('');
                        setLicenciaUuid('');
                        setIdCliente('');
                        setStpNombre('');
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Notificaciones',
                            text: response.response.text,
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        });
                    }
                })
            }else{
                centrox.update(params, Editing ).then(response=>{
                    if(response.status === 'ok'){
                        Swal.fire({
                            icon: 'success',
                            title: 'Excelente',
                            text: response.response.text,
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        });
                        onAdd();
                    }else{
                        //console.log(response);
                        Swal.fire({
                            icon: 'error',
                            title: 'Notificaciones',
                            text: response.response.text,
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        });
                    }
                })
            }
            
           


        }
        
        const onClickCancelLicencia = ()=>{
            onCancel();
            setEditing('');
            setCurrentEdit({});
        }

        const onClickNuevoEmpresa = ()=>{
            setEmpresaUuid( '' );
            setLicenciaUuid( '' );
            setStpNombre( '' );
            setEditing('');
            setCurrentEdit({});
        }

     
    //#region 

   

    return(
        <>
            <Card>

                <SoftBox pt={2} pb={3} px={1} component="form" role="form" sx={{ border:"5px solid #f7f7f7", }} >
                    <Grid container pt={1} >
                        <Grid item xs={8} md={8} xl={5}>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" >
                                <label className={"IGenericLabel-Normal"}>Registrar</label>
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pt={1}>

                        <Grid item xs={12} md={6} xl={6}>
                            <SoftBox  lineHeight={0}>
                                <SoftTypography variant="caption" color="text">
                                    Empresa
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox>
                                <select className={"ITextbox"} name={"licencia"} value={ EmpresaUuid } onChange={ onChangeEmpresa }>
                                    { empresasList.map(element =>{
                                        return <option key={ v4() }  value={ element.value }> { element.label } </option>
                                    }) }
                                </select>

                                
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={6} xl={6}>
                            <SoftBox  lineHeight={0}>
                                <SoftTypography variant="caption" color="text">
                                    Licencia 
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox>

                                <select className={"ITextbox"} name={"licencia"} value={ LicenciaUuid } onChange={ onChangeLicencia }>
                                    { LicenciasCollection.map(element =>{
                                        return <option key={ v4() }  value={ element.value }> { element.label } </option>
                                    }) }
                                </select>

                            </SoftBox>
                        </Grid>

                        <Grid item xs={4} md={3} xl={3}>
                            <SoftBox  lineHeight={0}>
                                <SoftTypography variant="caption" color="text">
                                    Centro de Costos STP 
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox>
                                <input type={"text"} className={"ITextbox"} name={"idCliente"} value={ idCliente } maxLength={15}
                                       onChange={ e => setIdCliente(e.target.value) } autoComplete={"on"} />
                            </SoftBox>
                        </Grid>

                        <Grid item xs={4} md={3} xl={3}>
                            <SoftBox lineHeight={0}>
                                <SoftTypography variant="caption" color="text">
                                    Nombre Centro de Costos STP 
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox>
                                <input type={"text"} className={"ITextbox"} name={"stpNombre"} value={ stpNombre } maxLength={15}
                                       onChange={ e => setStpNombre(e.target.value) } autoComplete={"on"} />
                            </SoftBox>
                        </Grid>
                        
                        
                        <Grid item xs={12} md={4} xl={3}>
                            <SoftBox pt={2}>
                                <button className={"btn-alpha-black pad-element"} type={"button"} onClick={ (event) => onClickCancelLicencia(event) }>
                                    <i className="fa-solid fa-chevron-left"></i> Cancelar captura
                                </button>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <SoftBox pt={2}>
                                <button className={"btn-alpha-success-2 pad-element"} type={"button"} onClick={ (event) => handleCrearCDC(event) } >
                                    <i className="fa-solid fa-circle-check"></i>  Guardar
                                </button>
                            </SoftBox>
                        </Grid>

                    </Grid>
                </SoftBox>

            </Card>
        </>
    )
    
}

AddCentroDeCosto.propTypes = {
    cuenta_uuid: PropTypes.string,
    empresasList: PropTypes.any,
    seriesList: PropTypes.any,
    onAdd: PropTypes.func,
    onCancel: PropTypes.func,
    editItem: PropTypes.object,
    reset: PropTypes.any,
    idClienteCdc : PropTypes.any
  };

export default AddCentroDeCosto;