import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Switch from "@mui/material/Switch";

import Swal from 'sweetalert2';
//
import Persona from 'Repository/Banca/Personas';



function Estatus({item,clase}) {

    const [statex, setState] = useState( (item.disabled == null || item.disabled == true ? true: false) );
    const personax = new Persona();

    async function cambiarEstado(uuid, value,){

        const passtext = await Swal.fire({
            title: 'Confirmaciones',
            text: "¿Esta seguro de cambiar el estatus de esta persona?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            /*
            input: 'password',
            allowOutsideClick: false,
            preConfirm: (value)=>{
                if(value.trim().length != 6 ){
                    Swal.showValidationMessage(
                        `Ingrese el código de confirmación`
                      )
                }
            }
            */
            });

        if(passtext.isConfirmed){
            await personax.cambiarEstado(uuid,!statex).then((response) =>{
                if(response.status == 'ok'){
                    Swal.fire({
                        title: 'Notificaciones',
                        text: response.response.text,
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                        });
                        setState( value ) ;
                }else{
                    Swal.fire({
                        title: 'Notificaciones',
                        text: response.response.text,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                        });
                }
               
            }); 
        }
    }
    
    const handleCheckChange = ({target})=>{
        cambiarEstado(item.uuid, target.checked);
    }

    return(
        <SoftBox mt={0.25}>
            <Switch  checked={ (statex) } onChange={ (event) => handleCheckChange(event) } />
        </SoftBox>
    )
    
}

Estatus.propTypes = {
    item: PropTypes.object,
    clase: PropTypes.string,
  };

export default Estatus;