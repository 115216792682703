import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
// @mui material components
import Grid from "@mui/material/Grid";
import SoftButton from "components/SoftButton";

import IconClose from "@mui/icons-material/Close"
import IconAdd from "@mui/icons-material/Add"
import IconEdit from "@mui/icons-material/Edit"

import Empresas from 'Repository/Banca/Empresas';
import AddEmpresa from './AddEmpresa';
import EstatusV2 from './EstatusV2';
import AlphaTable from "../../../cuentas/components/AlphaTable";
import {v4} from "uuid";
import SoftBadge from "../../../../components/SoftBadge";
import Estatus from "./Estatus";
import Autorizador from "../../../../Repository/Banca/Autorizador";

function ListadoEmpresas({cuenta_uuid, onCloseComponent}) {

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]); 

    const [dataToEdit, setDataToEdit] = useState( {} );
    const [formOpen, setFormOpen] = useState(false)
    const [reset, setReset] = useState(0);

    const empresax = new Empresas();

    useEffect(()=>{
        listar();
    },[])

    //#region methods
    const listar = ()=>{
        empresax.listar(cuenta_uuid).then(response=>{
            setColumns(
                [
                  { name: "Nombre Comercial", width: "15%" },
                  { name: "RFC", width: "15%" },
                  { name: "Razon Social", width: "15%", },
                  { name: "Direccion", width: "15%" },
                  { name: "Estatus", width: "15%" },
                  { name: "Acciones", width: "10%" },
                ]
                );
                const items = (response.response.data).map(element => {
                    return (
                        <tr key={ v4() }>
                            <td > { element.nombreComercial }  </td>
                            <td>{  element.rfc }</td>
                            <td>{ element.rs }</td>
                            <td>
                               <label className={"IGenericLabel-sm"}>
                                   {  element.direccion }
                               </label>
                            </td>
                            <td align={"center"}>
                                <EstatusV2 key={element.uuid} item= {element} currentObject={ Empresas } onStateUpdatetem ={ (event)=>onUpdateStateItemEmpresas(event) } />
                            </td>
                            <td align={"center"}>{
                                <SoftButton fullWidth variant="text"  color="color7A" size="large" onClick={ (event) => onSelectEditItem(event, element.uuid) } title="Editar" >
                                    <IconEdit /> Editar
                                </SoftButton>
                            }
                            </td>
                        </tr>
                    );
                })
                setRows(items);
        })
    }
    //#endregion

    //#region events

    const onClickOpenForm= ()=>{
        setFormOpen(true);
        setReset(reset+1);
    }

    const onCancelHandle = ()=>{
        setFormOpen(false);
        setDataToEdit({
            ...dataToEdit, 
            'cuenta_uuid': '',
            'uuid': '',
            'nombreComercial': '',
            'rfc': '',
            'rs': ''
        });
    }

    const onAddHandle = ()=>{
        listar();
    }

    const onClickCloseComponent = ()=>{
        onCloseComponent();
    }

    const onSelectEditItem = (event, value)=>{
        empresax.mostrar(value).then(response=>{
            if(response.status  == 'ok'){
                let data = response.response.data;

                setDataToEdit({
                    ...dataToEdit, 
                    'cuenta_uuid': cuenta_uuid,
                    'uuid': data.uuid,
                    'nombreComercial': data.nombreComercial,
                    'rfc': data.rfc,
                    'rs': data.rs,
                    'direccion': data.direccion
                });
                setFormOpen(true);
            }else{

            }
            
        })
    }

    const onUpdateStateItemEmpresas = (event)=>{
        listar();
    }
    //#endregion

    return(
        <>
            <SoftBox  sx={{ border:"5px solid #f7f7f7", }}>
                <Card sx={{ padding:1 }}>

                    <Grid container >
                        <Grid item xs={8} md={4} xl={8}>
                            <SoftBox   alignItems="center" >
                                <label className={"IGenericLabel-1-5f"} > Empresas </label>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={4} md={4} xl={2} pt={2}>
                            
                        </Grid>
                        <Grid item xs={4} md={4} xl={2} mb={1}>
                            <button className={"btn-alpha-black pad-element"} type={"button"} onClick={ (event) => onClickOpenForm(event) } >
                                <i className="fa-solid fa-plus"></i> Agregar empresa
                            </button>
                        </Grid>
                    </Grid>

                    { formOpen && <AddEmpresa uuid = { cuenta_uuid } onAdd = { onAddHandle } onCancel={ onCancelHandle } data = { dataToEdit } reset= { reset } />  }

                    
                    <SoftBox
                        >
                        <AlphaTable headers={ columns } records={ rows } />
                    </SoftBox>
                </Card>
            </SoftBox>
        </>
    )
    
}

ListadoEmpresas.propTypes = {
    cuenta_uuid: PropTypes.string,
    onCloseComponent: PropTypes.func
  };

export default ListadoEmpresas;