/**
=========================================================
* Modúlo STP para banca
=========================================================
*/

import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import CentrosDeCostosListado from "./Components/CentrosDeCostosListado.jsx";
import CentroItem from "./Components/CentroItem.jsx";



function CentrosDeCostos() {
  
  const [centroSelected, setCentroSelected] = useState(null);

  const selectedCentro = (centro)=>{
    setCentroSelected(centro);
  }

  const handleVolverAPrincipal = ()=>{
    setCentroSelected(null);
  }

  //Si ya esta autenticado devolvemos el dashboard si no lo mandamos a loguearse

    return (
        <DashboardLayout>
          <DashboardNavbar light={ true} />
          <SoftBox py={2}>

            { !centroSelected && <CentrosDeCostosListado key="cdcdsPespectiva" selectedCentro = { (centro) => selectedCentro(centro) }  /> }
            { centroSelected && <CentroItem  centroDeCosto = { centroSelected  } handleVolverAPrincipal= {  ()=> handleVolverAPrincipal() }  />  }  
            

          </SoftBox>
      </DashboardLayout>
    );
  
}

export default CentrosDeCostos;
