/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// @mui material components
import {useDispatch, useSelector} from "react-redux";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Detalle from "./components/detalle";
import Filtro from "../cuentas/components/Filtro";
import WebAlert from "../cuentas/components/WebAlert";
import {clearErrorCuenta, unselectServicio} from "../../store/slice/cuentasSlice";
import {useEffect} from "react";
import {todosLosServicios} from "../../store/thunk/servicios";
import {mostrarCuenta, serviciosContratadosCuenta} from "../../store/thunk/cuentas";
import Plataformas from "./components/Plataformas";
import WebModal from "./components/WebModal";
import FrmCancelarTpv from "./components/FrmCancelarTpv";
import {closeModal} from "../../store/slice/alertSlice";

function Registro() {

    const dispatch = useDispatch();
    const { uuid:uuidSelected,errorMessage } = useSelector(state => state.cuentas);

    /** Todos los servicios listados */
    useEffect(()=>{ dispatch( todosLosServicios() ) }, [])


    useEffect(()=>{
        const tmp = localStorage.getItem("accountId")
        if(tmp == null) return;
        dispatch( mostrarCuenta(tmp) );
        dispatch( serviciosContratadosCuenta(tmp) );
    },[])

    const handleCloseModal = ()=>{
        dispatch( unselectServicio() );
        dispatch( closeModal() );
    }

    return (
        <DashboardLayout >
            <DashboardNavbar light={true} />
            <SoftBox pt={1}>
                <Filtro bodyContent={ <Detalle />} icon ={"fa-solid fa-file-invoice"} title={ "Información de la cuenta" } open={true}   />
                <WebModal title={"Desactivar Servicio"} formComponent={ <FrmCancelarTpv /> } fnModaCloseAction={ handleCloseModal } />
            </SoftBox>
            {
                uuidSelected && <SoftBox pt={1}>
                    <Plataformas />
                </SoftBox>
            }
        </DashboardLayout>
    );

}

export default Registro;