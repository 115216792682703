
import PropTypes from "prop-types";

/**
 * @description Boton generico para busquedas, recibe un función callback que se ejecuta en el evento desde el cual se declara
 * @param fnOnclick
 * @returns {JSX.Element}
 * @constructor
 */
const BtnBusqueda = ({ fnOnclick }) => {

    return (
        <>
            <button className={"IBoton-3p-gris c-whithe"} type={"submit"} onClick={ fnOnclick }>
                <i className="fa-solid fa-magnifying-glass c-whithe "></i>
            </button>
        </>
    )
}

BtnBusqueda.propTypes = {
    fnOnclick: PropTypes.func.isRequired,
}

export default BtnBusqueda;