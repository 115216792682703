import {closeGeneralLoader, openGeneralLoader} from "../slice/alertSlice";
import Cobranza from "../../Repository/Cobranza";
import {setCuenta, setError, setMetodoPago, setReferencia, setTransaccion, updateMonto} from "../slice/cobranzaSlice";
import {putCurrentValues} from "../slice/tipoCambioSlice";

export const consultarTransaccion = (data)=>{
    const cobros = new Cobranza();
    return async(dispatch, getState)=>{
        dispatch( openGeneralLoader() );
        const result = await cobros.consultarLiga(data);
        if(result.status === 'ok') {
            dispatch(setTransaccion(result.response));
            dispatch( putCurrentValues( result.response.transaccion.partidas.tipoCambio ) )
            dispatch(closeGeneralLoader() );
        }else{
            //Vamos a ver si existe como referencia
            if(result.response === "Servicio no disponible.")
            {
                dispatch(setError(result.response));
                dispatch(closeGeneralLoader() );
                return;
            }
            const res = await  cobros.consultarReferencia(data);
            if(res.status ==='ok'){
                console.log(res.response);
                dispatch(setReferencia(res.response.transaccion));
                dispatch( setCuenta(res.response.cuenta) );
                dispatch( updateMonto( {
                    monto: res.response.transaccion.monto,
                    tipoVenta: 0,
                } ) );
                dispatch(closeGeneralLoader() );
            }else{
                dispatch(setError(res.response));
                dispatch(closeGeneralLoader() );
            }
        }
    }
}