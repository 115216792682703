import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Filtro from "../../../cuentas/components/Filtro";
import FrmCheckout from "../netpay_form/FrmLoop";

const defaultCredenciales = {
    public_key:"",
    secret_key:""
}

const LOOP = (props) => {
    const { contratados } = useSelector(state => state.cuentas);
    const [ credenciales, setCredenciales ] = useState(defaultCredenciales);
    const [ device, setDevice ] = useState({});

    useEffect(()=>{
        contratados.forEach(element =>{
            if(element.tipoServicio === 'NETPAY_LOOP'){
                setCredenciales( element.credenciales );
                setDevice( element );
            }
        })
    }, [contratados])

    return (
        <Box pt={1} m={1}>
            <Filtro bodyContent={ <FrmCheckout credenciales={credenciales} device={device } /> } title={"Llaves"} icon={ "fa-solid fa-key" } open={true} />
        </Box>
    );
}

export default LOOP;