/**
 =========================================================
 * Banca castelec
 =========================================================
 */

import { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import {Table as MuiTable, TableCell} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import HeaderOperacionesEnTransito
    from "../../../layouts/stp/estadocuentastp/components/EstadoCuenta/HeaderOperacionesEnTransito";
import BodyRowComplete from "../../../layouts/stp/estadocuentastp/components/EstadoCuenta/BodyRowComplete";

function TableEstadoCuenta({ columns, rows, rowHeaders, rowTotalOperaciones, rowsEnTransito = [], rowTotalOperacionesEnTransito, total }) {
    const { light } = colors;
    const { size, fontWeightBold } = typography;
    const { borderWidth } = borders;

    const renderColumns = <>
        <TableCell variant={"head"}>
            <SoftBox
                key={ "Detalle"}
                width={ "auto"}
                pt={1.5}
                pb={1.25}
                textAlign={"left"}
                fontSize={"small"}
                fontWeight={"bold"}
                color="color1"
                opacity={0.7}
            >
                DETALLE
            </SoftBox>
        </TableCell>
        <TableCell variant={"head"}>
            <SoftBox
                key={ "Detalle"}
                width={ "auto"}
                pt={1.5}
                pb={1.25}
                textAlign={"right"}
                fontSize={"small"}
                fontWeight={"bold"}
                color="color1"
                opacity={0.7}
            >
                RETIROS
            </SoftBox>
        </TableCell>
        <TableCell variant={"head"}>
            <SoftBox
                key={ "Detalle"}
                width={ "auto"}
                pt={1.5}
                pb={1.25}
                textAlign={"right"}
                fontSize={"small"}
                fontWeight={"bold"}
                color="color1"
                opacity={0.7}
            >
                DEPÓSITOS
            </SoftBox>
        </TableCell>
        <TableCell variant={"head"}>
            <SoftBox
                key={ "Detalle"}
                width={ "auto"}
                pt={1.5}
                pb={1.25}
                textAlign={"right"}
                fontSize={"small"}
                fontWeight={"bold"}
                color="color1"
                opacity={0.7}
            >
                SALDO
            </SoftBox>
        </TableCell>
    </>

    const renderRows = rows.map((row, key) => {
        return <BodyRowComplete key={ uuidv4() } row={ row } show={true}  />;
    });

    const renderEntransito = rowsEnTransito.map((row, key) => {
         return <BodyRowComplete key={ uuidv4() } row={ row } show={false}  />;
    });

    return useMemo(
        () => (
            <TableContainer>
                <MuiTable>
                    <SoftBox component="thead">
                        <TableRow>{renderColumns}</TableRow>
                    </SoftBox>
                    <TableBody>
                        { rowHeaders ? rowHeaders : '' }
                        {renderRows}
                        { rows.length > 0 ? rowTotalOperaciones : '' }
                        { rowsEnTransito.length > 0 ? <HeaderOperacionesEnTransito /> : '' }
                        { renderEntransito }
                        { rowsEnTransito.length > 0 ? rowTotalOperacionesEnTransito : '' }
                        { total ? total: '' }
                    </TableBody>
                </MuiTable>
            </TableContainer>
        ),
        [columns, rows]
    );
}

// Setting default values for the props of Table
TableEstadoCuenta.defaultProps = {
    columns: [],
    rows: [{}],
};

// Typechecking props for the Table
TableEstadoCuenta.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    rowTotalOperaciones: PropTypes.object,
    rowsEnTransito: PropTypes.arrayOf( PropTypes.object ),
    total: PropTypes.object
};

export default TableEstadoCuenta;