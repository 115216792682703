import moment from "moment";
import {JavascriptOutlined} from "@mui/icons-material";
class Operacion {

    
    request = (method ,url, body, security = false )=>{

        let requestOptions = {
            method,
        }
        if(security){
            requestOptions.headers = {
                'Authorization': `Bearer ${localStorage.getItem('cToken')}`
            }
        }
        if(body){
            requestOptions.body = body 
        }

        return new Promise((resolve, fallback)=>{
            fetch(`${process.env.REACT_APP_API_CORE}${url}`, requestOptions)
                .then((response) =>{
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => {
                    if(response.response.hasOwnProperty(`refreshToken`)){
                        localStorage.setItem('cToken', (response.response.token) );   
                    }
                    resolve(response);
                })
                ;
        })
    }

    async transaction (method ,url, body, security = false ){
        try{

            let requestOptions = {
                method,
            }
            if(security){
                requestOptions.headers = {
                    'Content-Type': 'application/json','Authorization': `Bearer ${localStorage.getItem('cToken')}`
                }
            }else{
                requestOptions.headers = {
                    'Content-Type': 'application/json'
                }
            }

            if(body){
                requestOptions.body = body
            }
            let currentRequest = await fetch(`${process.env.REACT_APP_API_CORE}${url}`, requestOptions).then(response => (response))

            return currentRequest. json();
        }catch(error){
            return {
                status : 'error',
                response: {
                    text: error
                }
            };
        }
    }

    async blobTransaction (method ,url, body, security = false, headers = {} ){

        try{
            let requestOptions = {
                method,
            }
            if(security){
                requestOptions.headers = {
                    'Content-Type': 'application/json','Authorization': `Bearer ${localStorage.getItem('cToken')}`
                }
            }
            if(headers['Accept']){
                requestOptions.headers['Accept'] = headers['Accept'];
            }
            if(body){
                requestOptions.body = body
            }
            let currentRequest = await fetch(`${process.env.REACT_APP_API_CORE}${url}`, requestOptions).then(response => (response))
            return currentRequest;
        }catch(error){
            return {
                status : 'error',
                response: {
                    text: error
                }
            };
        }
    }

    async execute(isvalid, url,options, original, headers){
        if(isvalid){
            return original;
        }
        options.headers = headers;
        const currentRequest = await fetch(`${process.env.REACT_APP_API_CORE}${url}`, options).then(response => (response))
        return currentRequest;
    }

    isValidRequest ( currentRequestuest ){
        if(currentRequestuest.status == 401 ){
            return false;
        } 
        return true;
    }

    async refreshToken (isvalid){
        if (isvalid){
            return true;
        }

        const requestOptions = {
            'method': 'POST',
            headers : {
                'Authorization': `Bearer ${localStorage.getItem('cRefreshToken')}`
            }
        }
        const url = `panel/refreshtoken`;
         await fetch(`${process.env.REACT_APP_API_CORE}${url}`, requestOptions)
                .then((response) =>{
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                }).then((response) => response.json())
                .then((response) => {
                    const userSession = JSON.parse( localStorage.getItem("session") );
                    const newUser =  {
                        ...userSession,
                        expires: response.response.expires
                    }
                    localStorage.setItem('cToken', (response.response.token));
                    localStorage.setItem('session',  JSON.stringify(newUser) );
                })
                ;
    }

    isValidToken = async ()=>{
        return new Promise((resolve)=>{
            moment.locale('es-MX');
            const userSession = JSON.parse( localStorage.getItem("session") );
            const expires = moment( userSession.expires);
            const now = moment();
            if( expires.isValid() ){
                const diferencia = expires.diff( now, "minutes");
                if(diferencia > 0){
                    resolve({ status: true });
                }
            }
            resolve({ status: false });
        })

    }

   

}

export default Operacion;