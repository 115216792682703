import { createSlice } from '@reduxjs/toolkit'
import moment from "moment/moment";

const cursorDefault = {
    prev:null,
    next:null
}

const initialState = {
   terminales:
       {
           filtro:{
               fechaaltadespuesde:null,
               fechaaltaantesde:null,
               textoBuscar:null
           },
           records: [],
           cursor:cursorDefault,
           encontrar:"",
   }
}

export const reporteSlice = createSlice({
    name: 'reportes',
    initialState,
    reducers: {
        setRecordsTerminales:(state, action)=>{
            state.terminales.filtro = action.payload.filtro;
            state.terminales.records = action.payload.data;
            if(action.payload.cursor !== undefined) state.terminales.cursor = action.payload.cursor;
            if(action.payload.cursor === undefined) state.terminales.cursor = cursorDefault;
        },
        refreshTerminales:(state, action)=>{
            const deviceModificada = action.payload;
            state.terminales.records = state.terminales.records.map(element => {
                if(element.uuid === deviceModificada.uuid){
                    const disabled = deviceModificada.status === 0  ? moment().format():null;
                    const observaciones = deviceModificada.status === 0  ? deviceModificada.observaciones:"";
                    return {
                        ...element,
                        disabled,
                        observaciones
                    }
                }
                return element;
            });
        },
        setEncontrarTerminal:(state,action)=>{
            state.terminales.encontrar = action.payload.encontrar;
            state.terminales.records = action.payload.data;
            if(action.payload.cursor !== undefined) state.terminales.cursor = action.payload.cursor;
            if(action.payload.cursor === undefined) state.terminales.cursor = cursorDefault;
        },
    },
})

export const { setRecordsTerminales, refreshTerminales, setEncontrarTerminal } = reporteSlice.actions

export default reporteSlice.reducer