import ServicioService from "../../Repository/Servicio";
import {setServicios} from "../slice/servicioSlice";

export const todosLosServicios = ()=>{
    const serviciox = new ServicioService();
    return async(dispatch, getState)=>{
        const result = await serviciox.listar();
        if(result.status === 'ok') {

            let plataformas = {};
            result.response.forEach(element => {
                plataformas[element.plataforma_uuid] =  {
                    plataforma_uuid:element.plataforma_uuid,
                    plataforma:element.plataforma,
                };
            });

            const data = {
                plataformas,
                servicios: result.response
            }
            dispatch( setServicios(data) )
        }
    }
}