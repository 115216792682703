
import React, { useState} from 'react';
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";

import { v4 as uuidv4 } from "uuid";

import Notificaciones from "./Notificaciones";

const PermisosYNotificaciones =  ({ cuenta_autorizador_uuid,permisos = {}, notificaciones = {}, plataforma = "netpay" }) => {

    const [ toggleNotificaciones , setToggleNotificaciones ] = useState();

    const onClickToggleAtributos = (event)=>{
        event.preventDefault();
        setToggleNotificaciones( ()=> !toggleNotificaciones );
    }

    return(
        <SoftBox >
            <Grid container >
                <Grid item xs={12} md={9} xl={10} pt={1} >
                    <label className={"IGenericLabel-sm"}>Consulta</label>
                    { toggleNotificaciones &&
                        <Notificaciones key={ uuidv4() } cuenta_autorizador_uuid={ cuenta_autorizador_uuid } notificaciones={ notificaciones } plataforma={plataforma} />
                    }
                </Grid>
                <Grid item xs={12} md={3} xl={2} pt={1} sx={{ textAlign:"right" }} >
                    <IconButton  variant={ "text" } color={"dark"} title={ toggleNotificaciones ? "Ocultar notificaciones":"Ver notificaciones" }
                                 sx={{ fontSize:18, float:"right", opacity:"0.8" }}
                                 onClick={ (event)=>onClickToggleAtributos(event) }
                    >
                        <i className="fa-solid fa-gear"></i>
                    </IconButton>
                </Grid>
            </Grid>
        </SoftBox>
    )

}

PermisosYNotificaciones.propTypes = {
    cuenta_autorizador_uuid: PropTypes.string.isRequired,
    permisos: PropTypes.any,
    notificaciones: PropTypes.object,
    plataforma: PropTypes.string.isRequired,
};

export default PermisosYNotificaciones;