/**
 =========================================================
 * Estado de cuenta netpay para el comercio
 =========================================================
 */

// Soft UI Dashboard React components

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import EdoCuenta from "./components/EdoCuenta";


function EdoCuentaNetpay() {


    return (
        <DashboardLayout >
            <DashboardNavbar light={true} />
            <EdoCuenta plataforma={ "netpay" } />
        </DashboardLayout>
    );

}

export default EdoCuentaNetpay;