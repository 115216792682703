import CuentaService from "../../Repository/Cuenta";
import ContratoService from "../../Repository/ServicioContratado"
import {
    closeModalCuentas,
    openModalCuentas, refreshCuenta, setContratadosCuenta, setCuentaEditing,
    setCuentaFilter,
    setCuentaLoading,
    setCuentaRecords, setEncontrar, setErrorCuenta, unsetServicioAEditar
} from "../slice/cuentasSlice";
import {
    closeGeneralLoader,
    closeModal, closeModalFromCatalogo,
    closeNewModal,
    openGeneralLoader,
    openModalFromCatalogo,
    setAlertMessage
} from "../slice/alertSlice";
import {refreshTerminales} from "../slice/reporteSlice";
import {resolve} from "chart.js/helpers";

export const listarCuentas = (data)=>{
    const cuentax = new CuentaService();
    return async(dispatch, getState)=>{
        dispatch( setCuentaLoading(true) );
        dispatch( openGeneralLoader() );
        const result = await cuentax.listar(data);
        if(result.status === 'ok') {
            dispatch(setCuentaFilter(data));
            dispatch(setCuentaRecords(result.response));
            dispatch(closeGeneralLoader());
        }
    }
}

export const listarCuentasLite = (data)=>{
    const cuentax = new CuentaService();
    return async(dispatch, getState)=>{
        dispatch( setCuentaLoading(true) );
        dispatch( openGeneralLoader() );
        const result = await cuentax.listar({
            textoBuscar:data.encontrar
        });
        if(result.status === 'ok') {
            dispatch(setEncontrar(data.encontrar));
            dispatch(setCuentaRecords(result.response));
            dispatch(closeGeneralLoader());
        }
    }
}

export const abrirModalCuentas = (data)=>{
    return async(dispatch, getState)=>{
        dispatch( openModalCuentas(data) );
    }
}

export const cerrarModalCuentas = ()=>{
    return async(dispatch, getState)=>{
        dispatch( closeModalCuentas() );
    }
}

export const cambiarEstadoDeCuenta = (data)=>{
    const cuentax = new CuentaService();
    return async(dispatch, getState)=>{
        const result = await cuentax.cambiarEstado(data);
        if(result.status === 'ok') {
            const alert = {
                message: "La cuenta a cambiado de estado.",
                icon:""
            }
            dispatch( setAlertMessage(alert) );
            dispatch(refreshCuenta(data));
        }
    }
}

export const crearCuenta = (data)=>{
    const cuentax = new CuentaService();
    return async(dispatch, getState)=>{
        dispatch( setCuentaLoading(true) );
        const result = await cuentax.store(data);
        if(result.status === 'ok') {
            dispatch( setCuentaEditing(result.response) );
            dispatch( closeModalFromCatalogo({
                cuentas:{
                    open:false,
                }
            }) )
            const alert = {
                message: "Registro creado correctamente",
                icon:""
            }
            dispatch( setAlertMessage(alert) );
            localStorage.setItem("accountId", result.response.cuenta_uuid);
        }else{
            const alert = {
                message: result.response,
                icon:""
            }
            dispatch( setAlertMessage(alert) );
        }
        resolve(result);
    }
}

export const actualizarCuenta = (data)=>{
    const cuentax = new CuentaService();
    return async(dispatch, getState)=>{
        dispatch( setCuentaLoading(true) );
        const result = await cuentax.update( data, data.cuenta_uuid);
        if(result.status === 'ok') {
            dispatch( closeModalFromCatalogo({
                cuentas:{
                    open:false,
                }
            }) )
            dispatch( setCuentaEditing(result.response) );
            const alert = {
                message: "Registro actualizado correctamente",
                icon:""
            }
            dispatch( setAlertMessage(alert) );
        }else{
            const alert = {
                message: result.response,
                icon:""
            }
            dispatch( setAlertMessage(alert) );
        }
        resolve(result);
    }
}

export const mostrarCuenta = (uuid)=>{
    const cuentax = new CuentaService();
    return async(dispatch, getState)=>{
        dispatch( openGeneralLoader() );
        const result = await cuentax.mostrar(uuid);
        if(result.status === 'ok') {
            dispatch( setCuentaEditing(result.response) );
            dispatch( closeGeneralLoader() );
            dispatch( openModalFromCatalogo({
                cuentas:{
                    open:true
                }
            }) );
        }
    }
}

export const serviciosContratadosCuenta = (uuid)=>{
    const contratoSrv = new ContratoService();
    return async(dispatch, getState)=>{
        const result = await contratoSrv.listar(uuid);
        if(result.status === 'ok') {
            dispatch( setContratadosCuenta(result.response.contratados) );
        }
    }
}

export const registrarServicio = ( data) =>{
    const contratoSrv = new ContratoService();
    return async(dispatch, getState)=>{
        dispatch(openGeneralLoader());
        const result = await contratoSrv.store(data);
        if(result.status === 'ok') {
            const alert = {
                message: "Servicio registrado con éxito.",
                icon:""
            }
            dispatch( closeGeneralLoader() );
            dispatch(setAlertMessage( alert ));
            dispatch( closeNewModal() );
            const servicios =  await contratoSrv.listar(data.cuenta_uuid);
            dispatch( setContratadosCuenta(servicios.response.contratados) );
        }else{
            const alert = {
                message: result.response,
                icon:""
            }
            dispatch(setAlertMessage( alert ));
        }
    }
}

export const actualizarServicio = (uuid, data) =>{
    const contratoSrv = new ContratoService();
    return async(dispatch, getState)=>{
        dispatch( openGeneralLoader() );
        const result = await contratoSrv.update(uuid,data);
        if(result.status === 'ok') {
            const alert = {
                message: "Servicio actualizado con éxito.",
                icon:""
            }
            dispatch( closeNewModal() );
            const servicios =  await contratoSrv.listar(data.cuenta_uuid);
            dispatch( unsetServicioAEditar() );
            dispatch( unsetServicioAEditar() );
            dispatch(setAlertMessage( alert ));
            dispatch( setContratadosCuenta(servicios.response.contratados) );
            dispatch( closeGeneralLoader() );

        }else{
            const alert = {
                message: result.response,
                icon:""
            }
            dispatch(setAlertMessage( alert ));
        }
    }
}
export const cambiarEstadoDeServicio = (data)=>{
    const contratoSrv = new ContratoService();
    return async(dispatch, getState)=>{
        const result = await contratoSrv.cambiarEstado(data);
        if(result.status === 'ok') {
            dispatch( setAlertMessage({ message: result.response, icon:"" }) );
            dispatch( closeModal( { openModal:false } ) );
            const servicios =  await contratoSrv.listar(data.cuenta_uuid);
            dispatch( setContratadosCuenta(servicios.response.contratados) );
        }
    }
}

export const cambiarEstadoDeServicioDirecto = (data)=>{
    const contratoSrv = new ContratoService();
    return async(dispatch, getState)=>{
        const result = await contratoSrv.cambiarEstado(data);
        if(result.status === 'ok') {
            dispatch( setAlertMessage({ message: result.response, icon:"" }) );
            dispatch( closeModal( { openModal:false } ) );
            dispatch(refreshTerminales(data));
        }
    }
}