/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Document from "./examples/Icons/Document";
import CreditCard from "./examples/Icons/CreditCard";
import Cuentas from "./layouts/cuentas";
import BancaCuentas from "./layouts/stp/cuentas";
import Terminales from "./layouts/terminales";
import Personas from "./layouts/stp/personas";
import CentrosDeCostos from "./layouts/stp/centrosdecostos";
import Reportes from "./layouts/stp/reportes";
import EstadoCuentaNetpay from "./layouts/edocuentanetpay";
import EstadoCuentaPaypal from "./layouts/edocuentapaypal";
//If is logged in, we dont show the login component

var routes = [];

  routes = [
    {
      type: "collapse",
      name: "Principal",
      key: "dashboard",
      route: "/dashboard",
      icon: <i className="fa-solid fa-house icon-menu"></i>,
      component: <Dashboard />,
      noCollapse: false,
      role: ['Admin', 'AutorizadorStp','AutorizadorNetpay','AutorizadorPaypal']
    },
    {
      type: "collapse",
      name: "Personas",
      key: "personas",
      route: "/personas",
      icon: <i className="fa-solid fa-users icon-menu"></i>,
      component: <Personas />,
      noCollapse: false,
      role: ['Admin']
    },
    {
      type: "collapse",
      name: "Administración",
      key: "cuentas",
      route: "/cuentas",
      icon: <i className="fa-solid fa-people-roof icon-menu "></i>,
      component: <BancaCuentas />,
      noCollapse: false,
      role: ['Admin']
    },
    {
      type: "collapse",
      name: "Centros de Costos Stp",
      key: "cdc-stp",
      route: "/cdc-stp",
      icon: <i className="fa-solid fa-building-circle-arrow-right icon-menu"></i>,
      component: <CentrosDeCostos />,
      noCollapse: false,
      role: ['Admin']
    },
    {
      type: "collapse",
      name: "Reportes",
      key: "reportes",
      route: "/reportes",
      icon: <i className="fa-solid fa-file-contract icon-menu"></i>,
      component: <Reportes />,
      noCollapse: false,
      role: ['Admin']
    },
    {
      type: "collapse",
      name: "Terminales",
      key: "terminales",
      route: "/terminales",
      icon: <i className="fa-solid fa-mobile-retro  icon-menu"></i>,
      component: <Terminales />,
      noCollapse: false,
      role: ['Admin']
    }
    ,
    {
      type: "collapse",
      name: "Netpay",
      key: "netpay",
      route: "/netpay",
      icon: <i className="fa-solid fa-calculator icon-menu"></i>,
      component: <EstadoCuentaNetpay />,
      noCollapse: false,
      role: ['AutorizadorNetpay']
    }
    ,
    {
      type: "collapse",
      name: "Paypal",
      key: "paypal",
      route: "/paypal",
      icon: <i className="fa-brands fa-paypal icon-menu"></i>,
      component: <EstadoCuentaPaypal />,
      noCollapse: false,
      role: ['AutorizadorPaypal']
    }
  ];

export default routes;
