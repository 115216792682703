
import PropTypes from "prop-types";
import SoftBox from "../../../../components/SoftBox";
import Grid from "@mui/material/Grid";
import {v4} from "uuid";
import {useNumberFormat} from "../../../../customHooks/useNumberFormat";

const initTotales = {
    tpv:{ cantidad:0,monto:0  },
    tarjeta:{ cantidad:0,monto:0  },
    efectivo:{ cantidad:0,monto:0  },
    suscripcion:{ cantidad:0,monto:0  },
    en_proceso:{ cantidad:0,monto:0  },
    paypal:{ cantidad:0,monto:0  },
    total:{ cantidad:0,monto:0  },
}

export const Sumario = ({ totales = initTotales, plataforma =  "netpay" }) => {
    const { numberFormat } = useNumberFormat();

    const forNetpay = {
        title:<>
            <th className={"th-header"} colSpan={2}>Tpv</th>
            <th className={"th-header"} colSpan={2}>Tarjeta</th>
            <th className={"th-header"} colSpan={2}>Efectivo</th>
            <th className={"th-header"} colSpan={2}>Suscripción</th>
        </>,
        columns:<>
            <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"8%"}>Cantidad</th>
            <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"8%"}>Monto</th>
            <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"8%"}>Cantidad</th>
            <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"8%"}>Monto</th>
            <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"8%"}>Cantidad</th>
            <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"8%"}>Monto</th>
            <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"8%"}>Cantidad</th>
            <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"8%"}>Monto</th>
        </>,
        rows:<>
            <td  colSpan={1} align={"right"}> { numberFormat(totales.tpv?.cantidad,0) }</td>
            <td  colSpan={1} align={"right"}> { numberFormat(totales.tpv?.monto,2) } </td>
            <td  colSpan={1} align={"right"}> { numberFormat(totales.tarjeta?.cantidad,0) } </td>
            <td  colSpan={1} align={"right"}> { numberFormat(totales.tarjeta?.monto,2) } </td>
            <td  colSpan={1} align={"right"}> { numberFormat(totales.efectivo?.cantidad,0) }</td>
            <td  colSpan={1} align={"right"}> { numberFormat(totales.efectivo?.monto,2) } </td>
            <td  colSpan={1} align={"right"}> { numberFormat(totales.suscripcion?.cantidad,0) }</td>
            <td  colSpan={1} align={"right"}> { numberFormat(totales.suscripcion?.monto,2) } </td>
        </>
    }

    const forPaypal = {
        title:<>
            <th className={"th-header"} colSpan={2}>Paypal</th>
        </>,
        columns:<>
            <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"10%"}>Cantidad</th>
            <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"10%"}>Monto</th>
        </>,
        rows:<>
            <td  colSpan={1} align={"right"}> { numberFormat(totales.paypal?.cantidad,0) }</td>
            <td  colSpan={1} align={"right"}> { numberFormat(totales.paypal?.monto,2) } </td>
        </>
    }

    return (
        <SoftBox sx={{ border:"5px solid #f7f7f7", padding:1, }} >
            <table className={ "table-alpha-filter tabla-responsiva-movil" } width={"100%"}>
                <thead>
                   <tr>
                       { plataforma === 'netpay' ? forNetpay.title : forPaypal.title  }
                       <th className={"th-header"} colSpan={2}>En Proceso</th>
                       <th className={"th-header"} colSpan={2}>Total</th>
                   </tr>
                   <tr>
                       { plataforma === 'netpay' ? forNetpay.columns : forPaypal.columns  }
                       <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"10%"}>Cantidad</th>
                       <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"10%"}>Monto</th>
                       <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"10%"}>Cantidad</th>
                       <th className={"th-header-2 pr-2"} colSpan={1} align={"right"} width={"10%"}>Monto</th>
                   </tr>
               </thead>
                <tbody>
                    <tr>
                        { plataforma === 'netpay' ? forNetpay.rows : forPaypal.rows  }
                        <td  colSpan={1} align={"right"}> { numberFormat(totales.en_proceso.cantidad,0) }</td>
                        <td  colSpan={1} align={"right"}> { numberFormat(totales.en_proceso.monto,2) } </td>
                        <td  colSpan={1} align={"right"}> { numberFormat(totales.total.cantidad,0) } </td>
                        <td  colSpan={1} align={"right"}> { numberFormat(totales.total.monto,2) } </td>
                    </tr>
               </tbody>
            </table>
        </SoftBox>
    )
}

Sumario.propTypes = {
    totales: PropTypes.object.isRequired,
    plataforma: PropTypes.string.isRequired,
};

export default Sumario;