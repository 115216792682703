import {v4} from "uuid";
import {useEffect, useMemo, useState} from "react";
import TabPlataformaV2 from "./TabPlataformaV2";
import SoftBox from "../../../components/SoftBox";
import PropTypes from "prop-types";

const PlataformasV2 =   ({ cuenta = {}, plataforma}) => {

    const [manejadores, setManejadores] = useState([]);



    const loadPlataformas = () =>{
        let colec = [];
        Object.keys(cuenta.detalle.plataformas).forEach(key => {
            const item = cuenta.detalle.plataformas[key];
            if(item.plataforma.toLowerCase() === plataforma){
                const newItem = <SoftBox  key={ v4() } >
                     <TabPlataformaV2 key={ v4() } plataforma={ item } cuenta={ cuenta} />
                </SoftBox>;
                //colec.push( elemento );
                setManejadores((preview) =>{
                    return [
                        ...preview,
                        newItem
                    ]
                })
            }
        });
    }

    useEffect(()=>{ loadPlataformas(); },[]);

    return (
        <>
            {  manejadores }
        </>
    );
}

PlataformasV2.defaultProps = {
    plataforma: "netpay",
}

PlataformasV2.propTypes = {
    cuenta: PropTypes.object.isRequired,
    plataforma:PropTypes.string.isRequired,
}

export default PlataformasV2;