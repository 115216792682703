import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import IconDelete from '@mui/icons-material/Delete';

import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

import Swal from 'sweetalert2';

import CuentaRequest from 'Repository/Banca/Cuentas';

function cambiarEstado(item, value,/* callback */ callback){
    callback(item, value);
}

function ButtonCancelarCuenta({ cuenta}) {

    const [statex, setstatex ] = useState( cuenta.disabled == null ? true: false );
    
    const cuentax = new CuentaRequest();
    
    const handleOnDelete = (event)=>{    
        operaciones();
    }

    const  operaciones  = async ()=>{
        const passtext = await Swal.fire({
            title: 'Confirmaciones',
            text: "¿Esta seguro de cambiar el estado de esta cuenta?, ingrese su código de confirmación",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            input: 'password',
            allowOutsideClick: false,
            preConfirm: (value)=>{
                if(value.trim() == ''){
                    Swal.showValidationMessage(
                        `Ingrese el código de confirmación`
                      )
                }
            }
            });

        if(passtext.isConfirmed){
            cuentax.delete(cuenta.uuid,!statex,passtext.value).then((response) =>{
                if(response.status == 'ok'){
                    Swal.fire({
                        title: 'Notificaciones',
                        text: response.response.text,
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                        });
                        setstatex(!statex);
                }else{
                    Swal.fire({
                        title: 'Notificaciones',
                        text: response.response.text,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                        });
                }
               
            }); 
        }

    }

    const botonActivo = <button type={"button"} className={"btn-alpha-info pad-element"} onClick={ (event) => handleOnDelete(event) }  >
        Cuenta activa
    </button>;
    const botonInactivo = <button type={"button"} className={"btn-alpha-black pad-element"} onClick={ (event) => handleOnDelete(event) }  >
        Cuenta inactiva
    </button>

    return(
        <>
            {statex ? botonActivo:botonInactivo}
        </>
    )
    
}

ButtonCancelarCuenta.propTypes = {
    cuenta: PropTypes.object,
  };

export default ButtonCancelarCuenta;