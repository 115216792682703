import * as React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {useNumberFormat} from "../../../customHooks/useNumberFormat";
import {actualizarConfiguracionPago} from "../../../store/slice/cobranzaSlice";
import {useValidateMsi} from "../customHooks/useValidateMsi";

const BtnToPay = ({ monto }) => {
    const dispatch = useDispatch();
    const { partidasSelected } = useSelector(state => state.partidas);
    const { tipoCambio } = useSelector(state => state.tipoCambio);
    const { numberFormat } = useNumberFormat();
    const  { validarPlazo } = useValidateMsi();

    const onClickGuardar = async ()=>{
        const { deContado,plazos, montoReferencia, montoPaypal  } = await  getConfiguracionMsi();
        let confMsiValida = [];
        Object.keys(plazos).forEach(plazo =>{
            const result = validarPlazo(plazo, plazos[plazo]);
            if(result) confMsiValida[plazo] = plazos[plazo];
        })
        //TODO:: Configurar
        const configuracion = confMsiValida.map(( amount, key) => {
            return  {
                periodo: key,
                monto : numberFormat( amount,2 ),
                descripcion: `${ key } Meses de $ ${ numberFormat( amount/key ,2) }`
            }
        })

        //concat
        const unpago = [{
            periodo: 0,
            monto : numberFormat( deContado,2 ),
            descripcion: `Pago de contado`
        }]
        const opcionesPago = unpago.concat( configuracion );
       //actualizamos el estado principal

        const payload = {
            msi: opcionesPago,
            monto:  deContado,
            montoPaypal,
            montoReferencia,
            partidasAPagar:partidasSelected,
        };

        dispatch( actualizarConfiguracionPago(payload) )

    }

    const getConfiguracionMsi = async () =>
    {
        return new Promise((resolve,reject)=>{
            let deContado = 0;
            let montoReferencia = 0;
            let montoPaypal = 0;
            let plazos = [];
            partidasSelected.forEach(element=>{
                const tipoCambioDelDia = tipoCambio[element.moneda].value;
                deContado += element.monto * tipoCambioDelDia;
                montoReferencia += ( element.montoReferencia === undefined ? 0:element.montoReferencia * tipoCambioDelDia );
                montoPaypal += ( element.montoPaypal === undefined ? 0:element.montoPaypal * tipoCambioDelDia );
                if(element.hasOwnProperty("msi"))
                {
                    Object.keys(element.msi).forEach(plazo => {
                        if (Object.keys(plazos).includes( plazo) ){
                            plazos[plazo ] += (element.msi[plazo] * tipoCambioDelDia );
                        }else{
                            plazos[plazo ] = (element.msi[plazo] *  tipoCambioDelDia);
                        }
                    })
                }
            })

            resolve({
                deContado,
                montoReferencia,
                montoPaypal,
                plazos,
            })
        })
    }

    return  <button className={ partidasSelected.length > 0 ? "btn-alpha-success-2" : "btn-alpha-success-2-disabled "} type={"submit"} onClick={ onClickGuardar } disabled={ partidasSelected.length > 0 ? "":"disabled" }  >
        <i className="fa-regular fa-circle-play"></i> Pagar seleccionados
    </button>

}

BtnToPay.propTypes = {
    monto:PropTypes.string,
};

export default BtnToPay;