import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";
// @mui material components
import Card from "@mui/material/Card";
import Swal from 'sweetalert2';

import GridPlazas from './GridPlazas';

import CentroDeCostoRequest from 'Repository/Banca/CentroDeCosto';
import ListadoAutorizadoresCdcs from 'layouts/stp/cuentas/components/ListadoAutorizadoresCdcs';
import {v4} from "uuid";
import Filtro from "../../../cuentas/components/Filtro";
import Box from "@mui/material/Box";
import Empresas from "../../../../Repository/Banca/Empresas";

const  GridCdcs =  ({centroDeCosto, onSelectToEdit,refresh, inPersona = false}) => {
    const [verCdcs, setVerCdcs] = useState(false);
    const [statex, setStatex] = useState(centroDeCosto.estatus_cdcs == null ? true: false );
    const [cdcResult, setCdcResult] = useState({ rfc:'' });

    const handleSeleccionar = (event)=>{
        setVerCdcs(!verCdcs);
    }

    const handleEditar = (event, uuid)=>{
        onSelectToEdit(uuid);
    }

    useEffect(()=>{
        complementarCdc();
    },[])

    /**
     * @deprecated Ya no se utiliza
     * @returns {Promise<void>}
     */
    const complementarCdc =  async()=>{
        if(centroDeCosto?.rfc === undefined){
            const empresax = new Empresas();
            const result = await empresax.listar(centroDeCosto.cuenta_uuid);
            const empresas  = result.response.data;
            const item = empresas.find(element => element.uuid === centroDeCosto.empresa_uuid );
            setCdcResult({
                ...cdcResult,
                rfc:item.rfc,
            })
        }
    }

    const cambiarEstado = async (cdcs_uuid)=>{
        const passtext = await Swal.fire({
            title: 'Confirmaciones',
            text: "¿Para confirmar el cambio de estatus del CDC coloque su código de confirmación?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            input: 'password',
            allowOutsideClick: false
            });
        if(passtext.isConfirmed){
           
            CentroDeCostoRequest.cambiarEstado(cdcs_uuid, !statex, passtext.value ).then(response=>{
                if(response.status === 'ok'){
                    Swal.fire({
                        icon: 'success',
                        title: 'Excelente',
                        text: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                    setStatex(!statex);
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Notificaciones',
                        text: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                }
            }); 
        }
    }

    const handleCambiarEstado = (event, cdcs_uuid)=>{
        cambiarEstado(cdcs_uuid); 
        
    }

    const detalle = <Box sx={{ border:"5px solid #f7f7f7",  }} >
        <SoftBox sx={{ display:"flex", textAlign:"right"  }}
        >

            <SoftBox display="flex" alignItems="right" sx={{ paddingTop:2 }}>
                { !inPersona &&
                    (
                        <>

                            <SoftBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "pointer" }}
                                     onClick={ (event)=> handleEditar(event, centroDeCosto.cdcs_uuid) }>
                                <Icon fontSize="small">edit</Icon>
                                <SoftTypography variant="button" fontWeight="bold">
                                    EDITAR
                                </SoftTypography>
                            </SoftBox>

                            <SoftBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "pointer" }}
                                     onClick={ (event)=> handleCambiarEstado(event, centroDeCosto.cdcs_uuid) }>
                                <SoftTypography variant="button" color={ statex == true ? 'dark':'error'} fontWeight="bold">
                                    { statex == true ? 'ACTIVO ':'INACTIVO '}
                                </SoftTypography>
                            </SoftBox>
                        </>
                    )
                }


                <SoftBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "pointer" }}
                         onClick={ (event)=> handleSeleccionar(event) }>
                    <Icon fontSize="small">view_headline</Icon>
                    <SoftTypography variant="button" fontWeight="bold">
                        { verCdcs ? 'OCULTAR ':'VER '} PLAZAS
                    </SoftTypography>
                </SoftBox>
            </SoftBox>
        </SoftBox>

        { !inPersona && <ListadoAutorizadoresCdcs key={ v4() } centro = {centroDeCosto} refresh={ refresh } /> }

        { verCdcs && (
            <>
                <GridPlazas key={ v4() }  cdcs_uuid = { centroDeCosto?.cdcs_uuid || centroDeCosto.uuid } />
            </>


        )
        }
    </Box>

    return(
        <SoftBox pt={1}>
            <Filtro title={ `RFC:${centroDeCosto.rfc} - SERIE: ${centroDeCosto.serie} ${  centroDeCosto.clabe ? "- CLABE:"+centroDeCosto.clabe:""  }` } open={false} bodyContent={ detalle } icon={""} />

        </SoftBox>
    )
    
}


//persona_uuid,  cuenta_uuid , rfc, serie
GridCdcs.propTypes = {
    centroDeCosto: PropTypes.object,
    onSelectToEdit: PropTypes.func,
    inPersona: PropTypes.bool,
    refresh: PropTypes.any
  };

export default React.memo(GridCdcs) ;