import * as React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent  } from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import closePopupImg from "../../../assets/alpha-theme/images/cerrarPopup.png";
import SoftBadge from "../../../components/SoftBadge";

const WebModal = ({ title,formComponent, fnModaCloseAction , modalWidth= 500, modalSize = "xs" }) => {

    const dispatch = useDispatch();
    const { openModal } = useSelector( state => state.alertas );
    const handleClose = (event, reason) => {
        if( reason === 'backdropClick' ) return;
         fnModaCloseAction();
    };

    return (
        <>
            <Dialog open={openModal} onClose={handleClose} fullWidth={true}  maxWidth={ modalSize  }
                    PaperProps={{ style:{  borderRadius:"0.6rem", color:"white", overflowY:"unset" } }}
            >
                <DialogTitle>
                    <SoftBadge
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: -13,
                            top: -16,
                            color: "#fff",
                            backgroundColor: "#DB6161",
                            fontSize:"0.8rem",
                            border:"3px solid white",
                            borderRadius: "50%",
                            padding:"3px",
                            cursor:"pointer",
                        }}
                    >
                        <img src={ closePopupImg } alt={"Img Cerrar PopUp"} width={ "15px" } height={"15px"}  />
                    </SoftBadge>
                    <div className={ "titleSegment" } >
                        <i className="fa-solid fa-file-signature pl-element pad-element txt-success "></i> { title }
                    </div>
                </DialogTitle>
                <DialogContent>
                    { formComponent }
                </DialogContent>
            </Dialog>
        </>
    );
}

WebModal.propTypes = {
    formComponent : PropTypes.any,
    title: PropTypes.string,
    modalWidth: PropTypes.any,
    modalSize: PropTypes.string,
    fnModaCloseAction: PropTypes.any
}

export default WebModal;