/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Soft UI Dashboard React components
// Soft UI Dashboard React base styles

import {Grid, useMediaQuery} from "@mui/material";

import * as React from "react";
import SoftBox from "../../../components/SoftBox";
import {v4} from "uuid";
import SoftTypography from "../../../components/SoftTypography";
import Card from "@mui/material/Card";
import {useNavigate} from "react-router-dom";
import Cobranza from "../../../Repository/Cobranza";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useForm} from "../../../customHooks/useForm";
import {setLiquidado, setMetodoPago, updateMonto} from "../../../store/slice/cobranzaSlice";
import {closeGeneralLoader, openGeneralLoader, setAlertMessage} from "../../../store/slice/alertSlice";
import Box from "@mui/material/Box";
import {useRecaptchaVersion3} from "../../../customHooks/useRecaptchaVersion3";
import {useNumberFormat} from "../../../customHooks/useNumberFormat";
import {useCardFields} from "../customHooks/useCardFields";
import moment from "moment/moment";

function CardPayment() {
    const isMobile = useMediaQuery('(max-width:600px)');
    const { numberFormat } = useNumberFormat();
    const navigate = useNavigate();
    const transacciones = new Cobranza();
    const [saving, setSaving] = useState(false);


    const dispatch = useDispatch();
    const { uuid,msi,plazoVenta,public_key, transaccion:initialForm, transaccion, metodoPago,partidasAPagar,basicDataColect } = useSelector(state => state.cobranza);
    const [iconCard, setIconCard] = useState("");
    const { mapValidators,optionsEstados } = useCardFields();
    const { monto, generarReferencia, concepto } = transaccion;
    const {cardNumber,expiration,cvv2,firstName,fullName ,lastName,phone,email, city, postalCode,state,street1,
        onInputChange, isFormValid, setFormState,formState, validationResult } = useForm( initialForm, mapValidators(basicDataColect) );
    const { captchaTokenRequest } = useRecaptchaVersion3();

    //cargar libreria de netpay
    useEffect(()=>{
        const script = document.createElement('script');

        script.src = `${ process.env.REACT_APP_NETPAY_CDN }`;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    },[]);

    //cargar libreria de 3DS
    useEffect(()=>{
        const script3ds = document.createElement('script');

        script3ds.src = `${ process.env.REACT_APP_NETPAY_3DS }`;
        script3ds.async = true;

        document.body.appendChild(script3ds);

        return () => {
            document.body.removeChild(script3ds);
        }
    },[]);

    useEffect(()=>{
        setFormState( initialForm );
    } ,[uuid])

    const onClickBack = (event)=>{
        event.preventDefault();
        dispatch( setMetodoPago("") );
    }

    //extraemos los iconos
    useEffect(()=>{
        const visaRegEx = /^(?:4[0-9]{2}?)$/;
        const mastercardRegEx = /^(?:5[1-5][0-9]{3})$/;
        const amexpRegEx = /^(?:3[47][0-9]{3})$/;
        const discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{5})$/;

        let icon= "";
        if (visaRegEx.test(cardNumber) === true) {
            //eg:4651970022334445
            icon = "far fa fa-3x fa-cc-visa  carddetail-cardtype";
        } else if (mastercardRegEx.test(cardNumber) === true) {
            icon = "far fa fa-3x fa-cc-mastercard carddetail-cardtype";
        } else if (amexpRegEx.test(cardNumber) === true) {
            icon = "far fa fa-3x fa-cc-amex carddetail-cardtype";
        } else if (discovRegEx.test(cardNumber) === true) {
            icon = "far fa fa-3x fa-cc-discover carddetail-cardtype";
        }
        setIconCard(icon);
    },[cardNumber])

    const expriy_format = (value) => {
        const expdate = value;
        const expDateFormatter =
            expdate.replace(/\//g, "").substring(0, 2) +
            (expdate.length > 2 ? "/" : "") +
            expdate.replace(/\//g, "").substring(2, 4);

        return expDateFormatter;
    };

    const onClickGuardar = ()=>{
        setSaving(true);
        if(!isFormValid){
            const properties = Object.getOwnPropertyNames(validationResult);
            properties.forEach(element=>{
                if(validationResult[element] != null){
                    const alert = {
                        message : validationResult[element],
                        icon:"",
                    }
                    setSaving(false);
                    dispatch( setAlertMessage(alert) )
                    return;
                }
            })
            return;
        }
        let deviceFingerPrint;
        window.NetPay.setSandboxMode( process.env.REACT_APP_NETPAY_SANDBOX === "true");

        const partidasAPagarR = partidasAPagar.map(partida => {
            return {
                ...partida,
                fecha: moment(partida.fecha,"DD/MM/YYYY").format("YYYY-MM-DD"),
                vencimiento: moment(partida.vencimiento,"DD/MM/YYYY").format("YYYY-MM-DD")
            };
        });

        function generateDevice(callback) {
            deviceFingerPrint = window.NetPay.form.generateDeviceFingerPrint();
            callback();
        }
        window.NetPay.setApiKey( transaccion.device.netpay );
        let deviceInformation = window.NetPay.form.deviceInformation();
        const expiration_ = expiration.split("/");

        generateDevice(function() {
            let cardInformation = {
                cardNumber,
                expMonth:expiration_[0],
                expYear:expiration_[1],
                cvv2,
                vault: true,
                simpleUse:true,
                deviceFingerPrint : deviceFingerPrint
            };

            var validateNumber = window.NetPay.card.validateNumber(cardInformation.cardNumber);
            var validateExpiry = window.NetPay.card.validateExpiry(cardInformation.expMonth, cardInformation.expYear);
            var validateCVV = window.NetPay.card.validateCVV(cardInformation.cvv2, cardInformation.cardNumber);
            var validateNumberLength = window.NetPay.card.validateNumberLength(cardInformation.cardNumber);

            if (!validateNumberLength || !validateNumber
                || !validateExpiry || !validateCVV) {
                const alert = {
                    message : "Por favor verifique los datos de la tarjeta",
                    icon:"",
                }
                dispatch( setAlertMessage(alert) );
                setSaving(false);
                return false;
            }
            window.NetPay.token.create(cardInformation, success, error);

            const montoF = typeof monto === "string" ? monto.replace(",",""):monto;

            function success(e) {
                const cardData = JSON.parse (e.message.data);
                let _this = this;
                let refer;
                window.netpay3ds.setSandboxMode( process.env.REACT_APP_NETPAY_SANDBOX === "true" );
                window.netpay3ds.init(function () {
                    window.netpay3ds.config(_this, montoF , callback);//monto
                });

                const callback = function(_this, referenceId) {
                    charges(referenceId);
                }

                const charges = function(referenceId) {
                    let data = {
                        referenceID         :referenceId,
                        deviceFingerPrint   :deviceFingerPrint,
                        sessionId           :deviceFingerPrint,
                        amount              :montoF,
                        source              :cardData.token,
                        uuid,
                        msi:plazoVenta,
                        promotion:null,
                        billing:{
                            fullName: basicDataColect ? fullName: firstName +" "+ lastName,
                            firstName,
                            lastName,
                            email,
                            phone,
                            address:{
                                city,
                                postalCode,
                                state,
                                street1
                            }
                        },
                        'partidas':partidasAPagarR,
                        deviceInformation,
                    }

                    if(cardData.type === 'debit'&& plazoVenta > 0){
                        const alert = {
                            message : "Tarjeta no válida para el tipo de promoción",
                            icon:"",
                        }
                        dispatch( setAlertMessage(alert) )
                        setSaving(false);
                        return false;
                    }
                    dispatch( openGeneralLoader() );
                    realizarCobro(_this,data);

                }
            }

            function error(e) {
                const alert = {
                    message : "No fue posible realizar la operación ingresada, por favor verifiquela o comuniquese con su proveedor",
                    icon:"",
                }
                dispatch( setAlertMessage(alert) )
                setSaving( false);
            }
        });


    }

    const realizarCobro = async (_this,data) =>{
        const { token:captchaResponse } = await captchaTokenRequest();
        data = {
            ...data,
            captchaResponse,
        }
        const response = await transacciones.cobrar(data);
        dispatch( closeGeneralLoader() );
        if(response.response.status === 'review'){
            const canProceed = window.netpay3ds.canProceed(response.response.status, response.response.responseCode, response.response.acsUrl);
            if(canProceed) {
                const callbackProceed = function(_this, processorTransactionId, status) {
                    if(status === 'success') {
                        dispatch( openGeneralLoader());
                        // 3D-Secure (Success scenario with security code and rejected scenario with code)
                        enviarConfirmacion(processorTransactionId, response.response.transactionTokenId, uuid, data);
                    }else {
                        const alert = {
                            message : "Transacción rechazada por error de confirmación",
                            icon:"",
                        }
                        dispatch( setAlertMessage(alert) )
                        return false;
                    }
                }
                window.netpay3ds.proceed(_this, response.response.acsUrl , response.response.paReq, response.response.authenticationTransactionID, callbackProceed);
            }else {
                dispatch( openGeneralLoader());
                //3D-Secure Frictionless (success and reject) scenario without security code
                enviarConfirmacion('null', response.response.transactionTokenId, uuid, data);
            }
        }else if(response.response.status === 'PAGADO') {
            dispatch( setLiquidado(response.response) );
        }else{
            const alert = {
                message : response.response,
                icon:"",
            }
            setSaving(false);
            dispatch( setAlertMessage(alert) )
            return false;
        }
    }

    /**
     * Confirmación del 3ds
     * @param processorTransactionId
     * @param transactionTokenId
     * @param transaccion_uuid
     * @param dataToConfirm
     * @returns {Promise<boolean>}
     */
    const  enviarConfirmacion = async (processorTransactionId, transactionTokenId, transaccion_uuid, dataToConfirm = {} )=>{
        const transacciones = new Cobranza();
        const data = {
            processorTransactionId:processorTransactionId,
            transactionTokenId:transactionTokenId,
            transaccion_uuid:transaccion_uuid,
            amount:dataToConfirm.amount,
            'partidas':dataToConfirm.partidas,
        }

        const response = await  transacciones.confirmar(data);
        if(response.response.status === 'PAGADO') {
            dispatch( setLiquidado(response.response) );
            dispatch(closeGeneralLoader())
        }else{
            const alert = {
                message : response.response,
                icon:"",
            }
            dispatch( setAlertMessage(alert) )
            setTimeout(function (){
                navigate(0);
            },3000);

            return false
        }
    }

    const changePlazaPago = (event, plazo) =>{
        event.preventDefault();
        dispatch( updateMonto({monto:plazo.monto, msi: plazo.periodo}) );
    }

    const plazosComponent = <SoftBox key={v4()}  >
        <table className={"IRadioTable"}>
            <thead>
            <tr>
                <td colSpan={2} align={"center"}>
                    <label >Selecciona el plazo deseado que se aplicará para tu compra</label>
                </td>
            </tr>
            </thead>
            <tbody>
            { transaccion.netpay.msi.map(element =>{
                return <tr key={ v4() }  >
                    <td>
                        <input type={"radio"} id={"msi"+ element.periodo } name={"msi"} checked={  parseInt(element.periodo) === parseInt(plazoVenta) } value={ element.periodo } onChange={ (event) =>  changePlazaPago(event, element) } /> &nbsp;
                        <label htmlFor={ "msi"+ element.periodo } > { element.descripcion }</label>
                    </td>
                    <td align={"right"}>
                        <label >$ { element.monto }</label>
                    </td>
                </tr>
            }) }
            </tbody>
        </table>
    </SoftBox>




    return (
        <>
            <Grid item xs={12} md={7} xl={7}   >

                <SoftBox id="netpay-form" sx={{  border:"5px solid #E6E6E6",borderRadius:2,margin: isMobile?0:1,  }} >
                    <Grid container  p={1} >

                        <Grid item xs={12} md={12} xl={12}   >
                            <Grid container  >

                                <Grid item xs={12} md={12} xl={12}   >
                                    <label className={"IGenericLabel-sm"} >Información del cliente</label>
                                </Grid>



                                { !basicDataColect &&
                                    <>
                                        <Grid item xs={6} md={6} xl={6}   >
                                            <label className={"IGenericLabel-Normal2"}>Nombre(s)</label>
                                        </Grid>
                                        <Grid item xs={6} md={6} xl={6}>
                                            <input type={"text"} className={"ITextbox"} name={"firstName"} value={ firstName } onChange={ onInputChange } />
                                        </Grid>
                                        <Grid item xs={6} md={6} xl={6}  >
                                            <label className={"IGenericLabel-Normal2"}>Apellido(s)</label>
                                        </Grid>
                                        <Grid item xs={6} md={6} xl={6}>
                                            <input type={"text"} className={"ITextbox"} name={"lastName"} value={ lastName } onChange={ onInputChange } />
                                        </Grid>
                                    </>
                                }



                                <Grid item xs={6} md={6} xl={6}   >
                                    <label className={"IGenericLabel-Normal2"}>Correo electrónico</label>
                                </Grid>
                                <Grid item xs={6} md={6} xl={6}>
                                    <input type={"text"} className={"ITextbox"} name={"email"} maxLength={150} value={ email } onChange={ onInputChange } />
                                </Grid>
                                <Grid item xs={6} md={6} xl={6}   >
                                    <label className={"IGenericLabel-Normal2"}>Teléfono</label>
                                </Grid>
                                <Grid item xs={6} md={6} xl={6}>
                                    <input type={"text"} className={"ITextbox"} name={"phone"} value={ phone } onChange={ onInputChange } />
                                </Grid>


                                {
                                    !basicDataColect &&
                                    <>
                                        <Grid item xs={6} md={6} xl={6}   >
                                            <label className={"IGenericLabel-Normal2"}>Código postal</label>
                                        </Grid>
                                        <Grid item xs={6} md={6} xl={6}>
                                            <input type={"text"} className={"ITextbox"} name={"postalCode"} value={ postalCode } onChange={ onInputChange } />
                                        </Grid>
                                        <Grid item xs={6} md={6} xl={6}   >
                                            <label className={"IGenericLabel-Normal2"}>Calle</label>
                                        </Grid>
                                        <Grid item xs={6} md={6} xl={6}>
                                            <input type={"text"} className={"ITextbox"} name={"street1"} value={ street1 } onChange={ onInputChange } />
                                        </Grid>

                                        <Grid item xs={6} md={6} xl={6}   >
                                            <label className={"IGenericLabel-Normal2"}>Ciudad</label>
                                        </Grid>
                                        <Grid item xs={6} md={6} xl={6}>
                                            <input type={"text"} className={"ITextbox"} name={"city"} value={ city } onChange={ onInputChange } />
                                        </Grid>

                                        <Grid item xs={6} md={6} xl={6}   >
                                            <label className={"IGenericLabel-Normal2"}>Estado</label>
                                        </Grid>
                                        <Grid item xs={6} md={6} xl={6}>
                                            <select className={"ITextbox"} name={"state"} value={ state } onChange={ onInputChange }>
                                                { optionsEstados.map(element =>{
                                                    return <option key={ v4() }  value={ element.value }> { element.label } </option>
                                                }) }
                                            </select>
                                        </Grid>
                                    </>
                                }




                                <Grid item xs={12} md={12} xl={12}   >
                                    <label className={"IGenericLabel-sm"} >Información de la tarjeta</label>
                                </Grid>

                                {
                                    basicDataColect &&
                                    <>
                                        <Grid item xs={6} md={6} xl={6}   >
                                            <label className={"IGenericLabel-Normal2"} >Nombre del titular</label>
                                        </Grid>
                                        <Grid item xs={6} md={6} xl={6}>
                                            <input type={"text"} className={"ITextbox"} maxLength={150} name={"fullName"} value={ fullName } onChange={ onInputChange } />
                                        </Grid>
                                    </>
                                }

                                <Grid item xs={4} md={5} xl={5}   >
                                    <label className={"IGenericLabel-Normal2"}>Tarjeta</label>
                                </Grid>
                                <Grid item xs={2} md={1} xl={1} sx={{ fontSize:20,textAlign:"right",paddingRight:1 }}  >

                                </Grid>
                                <Grid item xs={6} md={6} xl={6}>
                                    <input type={"text"} className={"ITextbox"} name={"cardNumber"} maxLength={20} value={ cardNumber } onChange={ onInputChange } />
                                </Grid>

                                <Grid item xs={6} md={6} xl={6}   >
                                    <label className={"IGenericLabel-Normal2"}>Vencimiento (MM/YY)</label>
                                </Grid>
                                <Grid item xs={3} md={3} xl={3}>
                                    <input type={"text"} className={"ITextbox"} name={"expiration"} placeholder={"MM/YY"} value={ expriy_format(expiration) } onChange={ onInputChange } />
                                </Grid>
                                <Grid item xs={3} md={3} xl={3}>

                                </Grid>

                                <Grid item xs={6} md={6} xl={6}   >
                                    <label className={"IGenericLabel-Normal2"}>CVV</label>
                                </Grid>
                                <Grid item xs={6} md={6} xl={6}>
                                    <input type={"password"} className={"ITextbox"} name={"cvv2"} value={ cvv2 } maxLength={4} onChange={ onInputChange } />
                                </Grid>

                                <Grid item xs={12} md={12} xl={12} textAlign={"right"} p={0} >
                                    <Box sx={{ float:"right", paddingTop:"1rem",paddingRight:"2px",margin:0 }}>
                                        <i className="fa-brands fa-cc-amex fa-2xs icon-size-2"></i>
                                    </Box>
                                    <Box sx={{ float:"right",paddingTop:"1rem",paddingRight:"2px",margin:0 }}>
                                        <i className="fa-brands fa-cc-mastercard fa-2xs icon-size-2" ></i>
                                    </Box>
                                    <Box sx={{ float:"right",paddingTop:"1rem",paddingRight:"2px",margin:0 }}>
                                        <i className="fa-brands fa-cc-visa fa-2xs icon-size-2"></i>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={12} xl={12}>

                                </Grid>


                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
                {
                    !isMobile &&
                    <>
                        <Grid item xs={12} md={6} xl={6} textAlign={"center"} >
                            {
                                transaccion.netpay.metodos.length > 1 || transaccion.paypal !== null ?
                                    <button className={"btn-alpha-black ocultar-movil"} type={"submit"} onClick={ onClickBack }>
                                        <i className="fa-solid fa-chevron-left"></i> Volver al principio
                                    </button> : ""
                            }
                        </Grid>
                        <Grid item xs={6} md={6} xl={6}>

                        </Grid>
                    </>
                }
            </Grid>
            <Grid item xs={12} md={5} xl={5} >

                <Card sx={{ minHeight:"50%",backgroundColor:"#F7F7F7", marginTop:1 }} >
                    <SoftBox pt={2} px={2} >
                        <SoftBox key={v4()} sx={{ textAlign:"center", borderBottom:"3px solid #929292" }} >
                            <label className={"IGenericLabel-Normal2"}>Detalle del cargo</label>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox p={2}>
                        <SoftBox mb={2} lineHeight={1}>
                            <SoftTypography variant="button" color="text" fontWeight="regular">
                                { concepto }
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                </Card>

                <Card sx={{marginTop:1 }}>
                    {  transaccion.netpay.metodos.find(element => element === "TARJETA") ? plazosComponent:"" }
                </Card>

                <SoftBox sx={{ paddingBottom:3,marginTop:1 }}>
                    <button className={   !saving  ? "btn-alpha-success-2 " : "btn-alpha-success-2-disabled "} type={"submit"} onClick={ onClickGuardar } disabled={ !saving ? "":"disabled" }  >
                        Pagar  { numberFormat(monto,2)  } MXN
                    </button>
                    {
                        transaccion.netpay.metodos.length > 1 || transaccion.paypal !== null ?
                            <button className={"btn-alpha-black mostrar-movil"} type={"submit"} onClick={ onClickBack }>
                                <i className="fa-solid fa-chevron-left"></i> Volver al principio
                            </button> : ""
                    }
                </SoftBox>

            </Grid>
        </>
    );

}

export default CardPayment;