import {useState} from "react";
import PropTypes from "prop-types";
// @mui material components
import SoftBox from "components/SoftBox";
import {v4} from "uuid";
import SoftBadge from "../../../components/SoftBadge";
import DetailElement from "./DetailElement";
import Grid from "@mui/material/Grid";
import moment from "moment/moment";
import {useNumberFormat} from "../../../customHooks/useNumberFormat";

export const TableRow = ({transaccion = {}, bgColor=""}) => {
    //#endregion
    const [mostrar, setMostrar] = useState(false);
    const { numberFormat } = useNumberFormat();

    const changeDisplay = ()=>{
        setMostrar(!mostrar);
    }

    const iconoAMostrar = () =>{
        if(transaccion.status === 'EN PROCESO' || transaccion.status === 'INTENTO FALLIDO'){
            return <i className="fa-solid fa-spinner" title={"Operación en proceso de liquidación por el cliente final"}></i>;
        }
        return <SoftBadge onClick={ (event)=>changeDisplay(event, transaccion.uuid) } sx={{ cursor:"pointer"}}>
            {
                mostrar ? <i className="fa-solid fa-chevron-up icon-menu"></i>: <i className="fa-solid fa-chevron-down"></i>
            }
        </SoftBadge>
    }

    return (
        <>
            <>
                <tr key={ v4() } className={`rowx ${ bgColor  }`}>
                    <td className={"pl-2"}>
                        <Grid container >
                            <Grid item lg={10} md={10} sm={9} >
                                { transaccion.folio }
                            </Grid>
                            <Grid item lg={2} md={2} sm={3} pr={1}>
                                { iconoAMostrar() }
                            </Grid>
                        </Grid>
                    </td>
                    <td className={"pl-2"}> { transaccion.fechaOperacion ? moment(transaccion?.fechaOperacion).format("DD-MM-YYYY HH:mm"):"" }  </td>
                    <td className={"pl-2"}> { transaccion.status }  </td>
                    <td className={"pr-2"} align={"right"}> { numberFormat(transaccion.monto,2)  }  </td>
                    <td className={"pl-2"}> { transaccion.nombreCliente }  </td>
                    <td className={"pl-2"}> { transaccion.status !== 'EN PROCESO' ? transaccion.metodo: "" }  </td>
                </tr>
                {
                    mostrar && <tr key={ v4() }>
                        <td colSpan={6}>
                            <DetailElement transaccion={ transaccion }  />
                        </td>
                    </tr>
                }
            </>
        </>
    )
}

TableRow.propTypes = {
    transaccion: PropTypes.object,
    bgColor: PropTypes.string
};

export default TableRow;