/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// @mui material components
import {useDispatch, useSelector} from "react-redux";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React base styles

import Filtro from "../cuentas/components/Filtro";
import FormFiltro from "../terminales/components/FrmFiltro";
import SoftTypography from "../../components/SoftTypography";
import Grid from "@mui/material/Grid";
import TerminalesTable from "./components/TerminalesTable";
import TxtEncontrar from "./components/TxtEncontrar";
import {listarTerminalesLite} from "../../store/thunk/reportes";

function Terminales() {

    const { terminales } = useSelector(state => state.reportes);

    return (
        <DashboardLayout >
            <DashboardNavbar light={true} />
            <SoftBox pt={1} >
                <Filtro bodyContent={ <FormFiltro/> } icon ={"fa-solid fa-filter"} title={ "Filtro" } />
            </SoftBox>
            <SoftBox mt={1}  bgColor={"white"} >
                <Grid container>
                    <Grid item xs={12} md={9} lg={9} >
                        <SoftTypography  sx={{ fontFamily:"TTCRegular", fontSize:"15px" }} pl={2} pt={1} >Registros: { terminales.records.length }</SoftTypography>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} >
                        <TxtEncontrar selectorName={"reportes"} eventSearch={ listarTerminalesLite } textSearch={ terminales.encontrar }  />
                    </Grid>
                </Grid>
                <TerminalesTable  />
            </SoftBox>
        </DashboardLayout>
    );

}

export default Terminales;