import { useEffect} from 'react'

/**
 * Hook para correr el captcha
 * @returns {{captchaTokenRequest: (function(*, *=, *=, *=): Promise<unknown>)}}
 */
export const useRecaptchaVersion3 = () => {

    useEffect(()=>{
        const captchaV3 = document.createElement('script');
        captchaV3.src = `https://www.google.com/recaptcha/api.js?render=${ process.env.REACT_APP_GOOGLE_CAPTCHA_PK }`;
        document.body.appendChild(captchaV3);
        return () => {
            document.body.removeChild(captchaV3);
        }
    },[])

    const captchaTokenRequest = async()=>{
        return new Promise((resolve, reject)=>{
            window.grecaptcha.ready(function() {
                window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_CAPTCHA_PK, {action: 'submit'}).then(function(token) {
                    resolve({
                        token: token
                    });
                })
            })
        })
    }

    return {
        captchaTokenRequest
    }

}