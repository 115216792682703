import PropTypes from "prop-types";
import * as React from "react";
import { useState} from "react";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import SoftBadge from "../../../components/SoftBadge";
import closePopupImg from "../../../assets/alpha-theme/images/cerrarPopup.png";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {useForm} from "../../../customHooks/useForm";
import ServicioContratado from "../../../Repository/ServicioContratado";

const initialForm = {
    observaciones: "",
}

const formValidation = {
    observaciones: [(value)=> value.trim() !== "","Observaciones requeridas."],
}

/**
 * @commeet Si estado == 0 (Activo), 1 esta inactivo
 * @param cuentax
 * @returns {JSX.Element}
 * @constructor
 */
const BtnCambioEstadoTpv = ( {device} ) => {

    const { observaciones, onInputChange } = useForm( initialForm, formValidation );
    const [estado, setEstado] = useState( device.disabled ? 0:1 );
    const [openModal, setOpenModal] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");


    /**
     * @comment vamos a cambiar el estado de la cuenta por lo que si:
     * Vamos a desactivar: Requerimos que opcionalmenre puedan colocar observaciones para que recuerden porque dieron de baja el servicio
     * Vamos a activar: En este caso no importa que nos pongan observaciones de hecho las vamos a borrar
     * @param event
     */
    const changeState = (event)=> {
        const newState  = estado === 0 ? 1: 0;
        if(newState === 0){
            setOpenModal(true);
        }else{
            setEstado(1);
            cambiarEstado();
        }
    }

    const cambiarEstado = async () => {
        const params = {
            ...device,
            observaciones,
        }
        const servcontratado = new ServicioContratado();
        const result = await servcontratado.cambiarEstado({
            uuid:device.uuid,
            status:Number(!estado),
            observaciones,
        });
        if(result.status === 'ok')
        {
            setMessageAlert("Estado actualizado con éxito.");
            setOpenAlert(true);
        }else{
            setMessageAlert(result.response);
            setOpenAlert(true);
        }
    }


    const onClickGuardar = () =>{
        setEstado( 0);
        cambiarEstado();
        setOpenModal( false );
    }

    const handleClose = ()=>{
        setOpenModal(false);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    const modalCancelar = <Dialog open={true} onClose={ handleClose } fullWidth={true}  maxWidth={ "sm"  }
                                  PaperProps={{ style:{  borderRadius:"0.6rem", color:"white", overflowY:"unset" } }}
        >
        <DialogTitle>
            <SoftBadge
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: -13,
                    top: -16,
                    color: "#fff",
                    backgroundColor: "#DB6161",
                    fontSize:"0.8rem",
                    border:"3px solid white",
                    borderRadius: "50%",
                    padding:"3px",
                    cursor:"pointer",
                }}
            >
                <img src={ closePopupImg } alt={"Img Cerrar PopUp"} width={ "15px" } height={"15px"}  />
            </SoftBadge>
            <div className={ "titleSegment" } >
                <i className="fa-solid fa-file-signature pl-element pad-element txt-success "></i> { "Activar/Desactivar dispositivo" }
            </div>
        </DialogTitle>
        <DialogContent>
            <Box>
                <Grid container>
                    <Grid item xs={12} md={4} xl={4}   pr={1}>
                        <span className={"lbl-etiqueta"}>Observaciones</span>
                    </Grid>
                    <Grid item xs={12} md={8} xl={8}>
                    <textarea className={"elemento-input-v2 elemento-textarea"} rows={3}  placeholder={"observaciones"} name={"observaciones"} value={ observaciones }
                              onChange={ onInputChange }
                    ></textarea>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6} xl={6} mt={2}    >
                        <button className={"btn-alpha-success-2 pad-element"} type={"submit"} onClick={ onClickGuardar }>
                            Guardar
                        </button>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6} mt={2} pl={1}  >
                        <button className={"btn-alpha-danger pad-element"} type={"submit"} onClick={ handleClose }>
                            Cancelar
                        </button>
                    </Grid>
                </Grid>
            </Box>
        </DialogContent>
    </Dialog>

    const webAlert = <>
        <Dialog open={ true } onClose={handleCloseAlert} fullWidth={true}  maxWidth={ "sm" }
                PaperProps={{ style:{ backgroundColor:"#3d3d3d", borderRadius:"0.6rem", color:"white", overflowY:"unset" } }}
        >

            <DialogTitle >
                <div className={"IAlertTitle"} >
                    { "Notificaciones" }
                </div>
                <SoftBadge
                    onClick={handleCloseAlert}
                    sx={{
                        position: 'absolute',
                        right: -13,
                        top: -16,
                        color: "#fff",
                        backgroundColor: "#DB6161",
                        fontSize:"0.8rem",
                        border:"3px solid white",
                        borderRadius: "50%",
                        padding:"3px",
                        cursor:"pointer",
                    }}
                >
                    <img src={ closePopupImg } alt={"Img Cerrar PopUp"} width={ "20px" } height={"20px"}  />
                </SoftBadge>

            </DialogTitle>
            <DialogContent>

                <Grid container  >
                    <div className={"IAlertMessage"}>
                        { messageAlert }
                    </div>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={7} xl={7}  >
                    </Grid>
                    <Grid item xs={12} md={5} xl={5}  >

                        <button className={"IBtnAlertSuccess"} type={"submit"} onClick={ handleCloseAlert }>
                            <i className="fa-solid fa-circle-check"></i> <span>Aceptar</span>
                        </button>

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </>

    return (
        <div className={ "area-boton" }>
            <button className={ estado === 0 ? "btn-alpha-danger pad-element":"btn-alpha-success-2 " } onClick={ (event) => changeState(event) } title={ device.observaciones ??'' } >{ (estado === 0 ? "Inactivo": "Activo") }</button>
            {
                openModal && modalCancelar
            }
            {
                openAlert && webAlert
            }
        </div>
    );
}

BtnCambioEstadoTpv.propTypes = {
    device: PropTypes.object.isRequired
}

export default BtnCambioEstadoTpv;