import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slice/authSlice'
import counterSlice from './slice/counterSlice'
import ventanaSlice from "./slice/ventanaSlice";
import personaSlice from "./slice/personaSlice";
import cuentasSlice from "./slice/cuentasSlice";
import servicioSlice from "./slice/servicioSlice";
import alertSlice from "./slice/alertSlice";
import reporteSlice from "./slice/reporteSlice";
import cobranzaSlice from "./slice/cobranzaSlice";
import captchaSlice from "./slice/captchaSlice";
import PersonaAdmon from "./slice/banca/personaSlice"
import estadoCuentaSlice from "./slice/banca/estadoCuentaSlice";
import partidasSlice from "./slice/partidasSlice";
import tipoCambioSlice from "./slice/tipoCambioSlice";

export const store = configureStore({
  reducer: {
    counter: counterSlice,
    auth: authSlice,
    persona:personaSlice,
    ventana: ventanaSlice,
    cuentas: cuentasSlice,
    servicios_plataformas: servicioSlice,
    alertas: alertSlice,
    reportes: reporteSlice,
    cobranza: cobranzaSlice,
    catpcha: captchaSlice,
    personas_general:PersonaAdmon,
    estadocuenta: estadoCuentaSlice,
    partidas: partidasSlice,
    tipoCambio: tipoCambioSlice,
  },
})