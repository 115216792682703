
import React, {useEffect, useState} from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";
import PermisoCheck from "./PermisoCheck";
import Notificaciones from "./Notificaciones";
import IconButton from "@mui/material/IconButton";
const PermisosYNotificaciones =  ({ autorizador_uuid,permisos = {}, notificaciones = {} }) => {

    const [ toggleNotificaciones , setToggleNotificaciones ] = useState();

    const onClickToggleAtributos = (event)=>{
        event.preventDefault();
        setToggleNotificaciones( ()=> !toggleNotificaciones );
    }

    { /* sx={{ width:{ xs: 100, sm: 200, md: 400, xl:400 } }} poner esta linea cuando el softbox sea muy grande   */ }
    return(
        <SoftBox sx={{ width:{ xs: 100, sm: 200, md: 400, xl:500 } }}>
            <Grid container >
                <Grid item xs={12} md={9} xl={10} pt={1} >
                    <PermisoCheck key={ uuidv4() } autorizador_uuid={ autorizador_uuid } permisos={ permisos  } />
                    { toggleNotificaciones &&
                        <Notificaciones key={ uuidv4() } autorizador_uuid={ autorizador_uuid } notificaciones={ notificaciones } />
                    }
                </Grid>
                <Grid item xs={12} md={3} xl={2} pt={1} sx={{ textAlign:"right" }} >
                    <IconButton  variant={ "text" } color={"dark"} title={ toggleNotificaciones ? "Ocultar notificaciones":"Ver notificaciones" }
                                 sx={{ fontSize:18, float:"right", opacity:"0.8" }}
                                 onClick={ (event)=>onClickToggleAtributos(event) }
                    >
                        <i className="fa-solid fa-gear"></i>
                    </IconButton>
                </Grid>
            </Grid>
        </SoftBox>
    )

}

PermisosYNotificaciones.propTypes = {
    autorizador_uuid: PropTypes.string.isRequired,
    permisos: PropTypes.object,
    notificaciones: PropTypes.object,
};

export default PermisosYNotificaciones;