
import {estadoCuentaViewing, isDownloading, saveFiltro, saveLoading, savePeriodo} from 'store/slice/banca/estadoCuentaSlice';
import CentroDecostos from "../../../Repository/Banca/CentroDeCosto";
import {closeGeneralLoader, openGeneralLoader} from "../../slice/alertSlice";

export const verEstadocuenta =  (uuid, periodo)=>{
    const centrox = new CentroDecostos();
    return async(dispatch, getState)=>{
        dispatch (openGeneralLoader());
        dispatch( saveLoading(true) );
        const result = await centrox.estadoDeCuentaData(uuid, periodo);
        if(result.status === 'ok'){
            dispatch( savePeriodo( periodo ) )
            dispatch( estadoCuentaViewing(result.response ) );
            dispatch( saveFiltro({
                fecha_inicial: "",
                fecha_final: ""
            }) );
        }
        dispatch(closeGeneralLoader());
    }
}

export const verEstadocuentaPersonalizado =  (uuid, data)=>{
    const centrox = new CentroDecostos();
    return async(dispatch, getState)=>{
        dispatch( openGeneralLoader() );
        const result = await centrox.estadoDeCuentaDataPersonalizado(uuid, data);
        if(result.status === 'ok'){
            dispatch( savePeriodo("") );
            dispatch( estadoCuentaViewing(result.response ) );
            dispatch( saveFiltro( data ));
        }
        dispatch( closeGeneralLoader() );

    }
}

export const descargaArchivo = (params)=>{
    const centrox = new CentroDecostos();
    return async(dispatch, getState)=>{
        dispatch( openGeneralLoader() );
        dispatch( isDownloading(true) );
        await centrox.getEstadoDeCuentaPersonalizado(params.cdc_uuid, params.params, params.accept);
        dispatch( isDownloading(false) );
        dispatch( closeGeneralLoader() );
    }
}