
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import SoftBox from "../../../components/SoftBox";
import Filtro from "../../cuentas/components/Filtro";
import {useForm} from "../../../customHooks/useForm";

const TxtEncontrar = ({ selectorName, eventSearch, textSearch }) => {

    const dispatch = useDispatch();
    const stateResult = useSelector( state => state[ selectorName ] );

    const { encontrar, onInputChange, isFormValid } = useForm( { encontrar:textSearch }, {} );

    const handleEncontrar = (event)=> {
        if (event.key === "Enter") {
            dispatch(eventSearch({
                encontrar
            }))
        }
    }

    /**
     * @comment Esta es una funcion paleativa ya que en react no tenemos el evento search del input
     * @param event
     */
    const onInputChangeLocal = (event)=>{
        onInputChange(event);
        if(event.target.value.length === 0 ) {
            dispatch( eventSearch({ encontrar : event.target.value }) );
        }
    }

    return (
        <SoftBox sx={{ textAlign:"right" }} >
            <input type={"search"} className={"ITxtEncontrar"} name={"encontrar"} value = { encontrar } maxLength={"50"} placeholder={"Encontrar"}
                   onChange={ onInputChangeLocal } onKeyDown={  handleEncontrar }
                 />
        </SoftBox>
    );
}
TxtEncontrar.propTypes = {
    selectorName: PropTypes.string,
    eventSearch: PropTypes.any,
    textSearch:PropTypes.string,
}

export default TxtEncontrar;