import React, { Component, useEffect, useState } from 'react';
import Select from "react-select";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Card from "@mui/material/Card";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";
import Swal from 'sweetalert2';

import IconSave from '@mui/icons-material/Save';
import IconBack from '@mui/icons-material/ArrowBack';
//
import Persona from 'Repository/Banca/Personas';
import SendOtpActivate from './SendOtpActivate';
import OptDesactivate from './OptDesactivate';
import ButtonEstatusPersona from './ButtonEstatusPersona';
import Loading from 'examples/Components/Loading';
import SendPrimerCorreo from './SendPrimerCorreo';
import SendNuevaContrasenia from './SendNuevaContrasenia';
import {useDispatch, useSelector} from "react-redux";
import {personaCancelEdit} from "store/slice/personaSlice";
import {editPersona} from "store/thunk/persona";
import {useAlert} from "customHooks/useAlert";
import Box from "@mui/material/Box";
import {v4} from "uuid";

const initialItemSelect = {
    value: '',
    label: 'SIN NOTIFICACIÓN'
}

function Profile({ onCancel}) {
    const personax = new  Persona();
    const dispatch = useDispatch();
    const {currentRecord : viewPersona, uuid, config: personaConfig, tipoNotificaciones: catTipoNotificaciones } = useSelector(state => state.persona);
    const [errores, setErrores] = useState('');
    const [loading, setLoading] = useState(false);
    
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [celular, setCelular] = useState('');
    const [email, setEmail] = useState('');
    const [referencia, setReferencia] = useState('');
    const [disabled, setDisabled] = useState();
    const [ tipoNotificacion_uuid, setTipoNotificacionUuid ] = useState('');
    //const [ selectedItemNotificacion, setSelectedItemNotificacion ] = useState( initialItemSelect );
    const [periodo, setPeriodo] = useState('');
    //errors
    const [spanReferencia, setSpanReferencia] = useState(false);
    const { showAlert } = useAlert();

    const [hasOtp, setHasOtp ] = useState(false);

    function validate(){
        let hasError = false;
        let counter = 0;
        if(nombres.length < 3) {counter+=1; }
        if(apellidos.length < 3){counter+=1; }
        if(referencia.length < 3){counter+=1; showAlert('Ups', "Más de tres letras en el campo empresa", "warning"); return  true;}
    
        let restmpx = celular.match(/^\d{10}$/g)
        if(restmpx == null) {counter+=1; showAlert('Ups', "Celular incorrecto", "warning");return true;}

        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if(!emailRegex.test(email)) {counter+=1;showAlert('Ups', "Correo invalido", "warning");return true; }
        if(counter > 0){
            return (hasError = true);
        }
        let error = false;
        if(tipoNotificacion_uuid === undefined){
            showAlert('Ups', "Ingrese un periodo", "warning");
            error = true;
        }
        if( error) return true;
        const tipoNotificicacionItem = catTipoNotificaciones.find(element => element.value === tipoNotificacion_uuid);
        if(tipoNotificicacionItem.label === 'RESUMEN' || tipoNotificicacionItem.label === 'DETALLE' ){
            const rangoInvalido = periodo === '' ||  ( parseInt(periodo) <= 0 || parseInt(periodo) > 23 );
            if( rangoInvalido ){
                showAlert('Ups', "Ingrese un periodo de 1 a 23 horas", "warning");
                return true;
            }
        }
        return hasError;
    }

    const doActualizar = async  ()=>{
        const result = await Swal.fire({
            title: 'Confirmaciones',
            text: "¿Desea continuar con la actualización de datos?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
            });
           
            
            if(result.isConfirmed){
                let params = {
                        uuid: uuid,
                        apellidos: apellidos,
                        nombres : nombres,
                        celular: celular,
                        email: email,
                        referencia: referencia,
                        nt_uuid : tipoNotificacion_uuid !== "" ? tipoNotificacion_uuid: null,
                        periodo: (periodo ? periodo: null)
                    }
                    await personax.update(params, uuid).then((response)=>{
                        if(response.status === 'ok'){
                            Swal.fire({
                                title: 'Notificaciones',
                                text: response.response.text,
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            });
                            dispatch( editPersona(uuid) );
                        }else{
                            Swal.fire({
                                title: 'Notificaciones',
                                text: response.response.text,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Aceptar',
                                allowOutsideClick: false
                            });
                        }
                    });
            }
            
    }

    /* Nombres de los autorizadorees end   */
    const handledActualizarDatos = (event)=>{    
        event.preventDefault();
        if(!validate()){
            doActualizar();
        }
    }

    const onOtpCanceled = ()=>{
        setHasOtp(false);
    }

    useEffect(()=>{
        setLoading(true);
        setApellidos(  viewPersona.apellidos );
        setNombres( viewPersona.nombres );
        setEmail(  viewPersona.email );
        setCelular( viewPersona.cel );
        setReferencia( viewPersona.referencia !== null ? viewPersona.referencia:  '' );
        setDisabled((viewPersona.disabled === null ) );
        setHasOtp( (viewPersona.otpSecret !== '' && viewPersona.otpSecret !== null && viewPersona.otpLastUse !== null) );
        setLoading(false);
        setPeriodo( personaConfig?.data?.periodo || "" );
        setTipoNotificacionUuid( personaConfig?.nt_uuid  || "" );
        //TODO::poner el tipo de notificación seleccionado al momento de cargar la edición
    },[]);

    
    useEffect(()=>{
        if(referencia !== null && referencia !== undefined)
        {
            if(referencia.length < 3){
                setSpanReferencia(()=> true);
            }
            else{
                setSpanReferencia(()=> false);
            }
        }
    }, [referencia]);
    

    const handledCancelar = (event)=>{
       dispatch( personaCancelEdit() );
        onCancel();
    }

    return(
   
        <SoftBox py={3}>
          <Loading loading={ loading } />
            <Card  >
                <SoftBox p={3} mb={1} sx={{ border:"5px solid #f7f7f7", }}>
                    <SoftTypography variant="h5" fontWeight="medium">
                        Datos de la persona
                    </SoftTypography>
                    <SoftBox mb={1}>
                        <SoftTypography variant="button" fontSize="xxs" color="error" >
                            { errores }
                        </SoftTypography>
                    </SoftBox>

                    <SoftBox mt={1} mb={2}  >
                        <Grid container spacing={1}  >
                            
                            <Grid item xs={12} md={2} xl={2} >
                                { !hasOtp && <SendOtpActivate persona_uuid={uuid} />   }
                            </Grid>
                            <Grid item xs={12} md={3} xl={3} pl={1}>
                               { hasOtp &&  <OptDesactivate persona_uuid={uuid} onOtpCanceled = { onOtpCanceled } />  }
                            </Grid>
                            <Grid item xs={12} md={3} xl={3}>
                                <SendPrimerCorreo persona_uuid={uuid}  />
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <SendNuevaContrasenia persona_uuid={uuid}  />
                            </Grid>

                        </Grid>
                    </SoftBox>

                    <SoftBox component="form" role="form">

                        <Grid container spacing={1} pt={2}>

                            <Grid item xs={12} md={6} xl={4}>
                                <SoftBox lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                       Nombres
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <input type={"text"} className={"ITextbox"} name={"nombres"} value={ nombres }
                                           maxLength={150}  onChange={ e => setNombres(e.target.value) } autoComplete={"on"} />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={6} xl={8}>
                                <SoftBox lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                       Apellidos
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <input type={"text"} className={"ITextbox"} name={"apellidos"} value={ apellidos }
                                           maxLength={150}  onChange={ e => setApellidos(e.target.value) } autoComplete={"additional-name"} />
                                </SoftBox>
                            </Grid>



                            <Grid item xs={12} md={4} xl={4}>
                                <SoftBox lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                       Empresa
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <input type={"text"} className={"ITextbox"} name={"referencia"} value={ referencia }
                                           maxLength={250}  onChange={ e => setReferencia(e.target.value) } autoComplete={"on"} />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={4} xl={4}>
                                <SoftBox lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                       Celular
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <input type={"text"} className={"ITextbox"} name={"celular"} value={ celular }
                                           maxLength={250}  onChange={ e => setCelular(e.target.value) } autoComplete={"on"} />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={4} xl={4}>
                                <SoftBox lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                       Correo
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <input type={"email"} className={"ITextbox"} name={"email"} value={ email }
                                           maxLength={250}  onChange={ e => setEmail(e.target.value) } autoComplete={"email"} />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={6} xl={4}>
                                <SoftBox mb={1} lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                        Tipo de Notificación
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox lineHeight={0}>
                                    <select className={"ITextbox"} name={"tipoNotificacion_uuid"} value={ tipoNotificacion_uuid } onChange={ (event)=>setTipoNotificacionUuid(event.target.value) }>
                                        { catTipoNotificaciones.map(element =>{
                                            return <option key={ v4() }  value={ element.value }> { element.label } </option>
                                        }) }

                                    </select>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={4} xl={4}>
                                <SoftBox  lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                        Notificar cada(Horas / No aplica para UNITARIO)
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox >
                                    <input type={"number"} className={"ITextbox"} name={"periodo"} value={ periodo }
                                           maxLength={5}  onChange={ e => setPeriodo(e.target.value) } autoComplete={"periodo"} />
                                </SoftBox>
                            </Grid>


                        </Grid>

                        

                    </SoftBox>

                    

                    <SoftBox mt={1} mb={1}  >
                        <Grid container  >
                            <Grid item xs={12} md={3} xl={3}>
                                { disabled !== undefined  &&  <ButtonEstatusPersona key={ v4() } uuid={ uuid  } estatus = { disabled } /> }
                            </Grid>
                            <Grid item xs={12} md={3} xl={4}>
                                
                            </Grid>
                            <Grid item xs={12} md={3} xl={2} >
                                <button className={"btn-alpha-black pad-element"} type={"submit"} onClick={ handledCancelar }>
                                    <i className="fa-solid fa-chevron-left"></i> Volver al listado
                                </button>
                            </Grid>
                            <Grid item xs={12} md={3} xl={3} pl={1}>

                                <button className={"btn-alpha-success-2 pad-element"} type={"button"}  onClick={ handledActualizarDatos}  >
                                    <i className="fa-solid fa-circle-check"></i> Actualizar datos
                                </button>
                            </Grid>
                        </Grid>
                    </SoftBox>

                </SoftBox>

            </Card>
        </SoftBox>
        
    )
    
}

Profile.propTypes = {
    uuid: PropTypes.string,
    onCancel: PropTypes.func
  };

export default Profile;