/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/alphaerp.png";
import api_core from '../../../services/config.js'; 
import ConfirmOtp from "./Components/ConfirmOtp.jsx";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {doLogin} from "../../../store/thunk/auth";

const initialStateCredentials = {
    sesion:{},
    token: '',
    email : '',
    nombres: '',
    apellidos:'',
    expires_in:'',
    expires:'',
    key:'',
    hasOtp :'',
    uuid:'',
    password:''
}

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const navigate = useNavigate();
  const [errormessage, setErrorMessage] = useState('');
  const [credentials, setCredentials] = useState( initialStateCredentials );
  const { sesion,  token, email ,  nombres,  apellidos,  expires_i,  expire,  key,  hasOtp,  uuid, password} = credentials;
  let datax = {};

  const dispatch = useDispatch();
  const { user,  token:tokn, logged } = useSelector( state => state.auth );
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  /* Login from de server  */
 const login  =   event => {
    event.preventDefault();
    let url = `panel/login`;

    if(credentials.email == ''){
      setErrorMessage(<label>Email requerido</label>);
      return false;
    }

    if(credentials.password == ''){
      setErrorMessage(<label>Contraseña requerida</label>);
      return false;
    }
    setErrorMessage(()=>('') );
    var bodyFormData = new FormData();
    bodyFormData.append(`email`, `${credentials.email}`);
    bodyFormData.append(`password`, `${credentials.password}`);

    api_core.post(`${url}`, bodyFormData )
      .then(res => {
        let data = res.data;
        if(!data.hasError){
          //datax = data;
          sessionStorage.setItem('tmp', JSON.stringify(data) );

          setCredentials( credentials => {
            return {
              sesion: JSON.stringify(data),  
              token:data.response.token, 
              email :data.response.email ,
              nombres:data.response.nombres,
              apellidos :data.response.apellidos,
              expires_i :data.response.expires_in, 
              expire : data.response.expires,
              key :data.response.uuid,
              hasOtp :data.response.hasOpt, 
              uuid : data.response.uuid
            }
          });

          if(!data.response.hasOpt){
            setCredentials({
              ...credentials,
              'hasOpt' : false 
            });
            OnSuccessConfirm();
          }
        }else{
        }
      }).catch(function (error) {
        if (error.response) {
          setErrorMessage(()=>('Credenciales inválidas') );
        }
      })

 }

 const changePropiedades = ({target}) =>{
    setCredentials({
      ...credentials,
      [target.name] : target.value
    });
 }

 const OnSuccessConfirm = ()=>{
    const result =  JSON.parse(sessionStorage.getItem('tmp'));
    const {response} = result; 

     sessionStorage.removeItem('tmp');
     dispatch( doLogin( response ) );
 }

  return (
    <CoverLayout
      title={ "" }
      description=""
      image={curved9}
      >
        <SoftBox component="form" role="form" onSubmit={login} >

        {
          !credentials.uuid && (
              <>
                <SoftBox mb={2} pt={5}  >
          
                  <SoftBox mb={1} ml={1}>
                    <SoftTypography fontWeight="bold" sx={{ color:"#ababab",fontSize:"14px" }} >
                      { errormessage }
                    </SoftTypography>
                  </SoftBox>

                    <input type={"text"} name={"email"} value={email} placeholder={"Usuario"} className={"input-alpha"}
                           onChange={ event => changePropiedades(event) } required autoComplete="email"
                    />
                </SoftBox>
                <SoftBox mb={2}>
                    <input type={"password"} name={"password"} value={password} placeholder={"Contraseña"} className={"input-alpha"}
                           onChange={ event => changePropiedades(event) } required  autoComplete="current-password"
                    />
                </SoftBox>
                
                <SoftBox mt={4} mb={1}>
                    <button type={"submit"} className={ "btn-alpha-success" } >
                        Entrar
                    </button>
                </SoftBox>
                  <SoftBox mt={4} textAlign={  "right" } opacity={0.2} >
                      <img src={ curved9 }  height={"35vh"} />
                  </SoftBox>
              </>
          )
        }
        </SoftBox>

        {
          credentials.hasOtp && <ConfirmOtp credentials={credentials} OnSuccessConfirm ={ OnSuccessConfirm } />
        }
      
        
        
        

      
    </CoverLayout>
  );
}


export default SignIn;
