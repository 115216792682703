import Operacion from './Operacion';
class Reportes extends Operacion {
    constructor() {
        super();
    }

    /**
     * @comment Listamos todas las terminales de netpay
     * @param data
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async terminales(data){
        let params = [];
        for(const atributo in data){
            if(data[atributo] !== "") params.push( `${atributo}=${ data[atributo] }` );
        }

        const url = `panel/terminales/plataforma?${ params.join('&') }`;
        return await this.transaction('GET',url, null , true );
    }

    /**
     * @comment Buscamos el detalle de una transaccion en especifico
     * @param transaccion_uuid
     * @param tipo
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async detalleTransaccion(transaccion_uuid, tipo){
        const url = `panel/transaccion/${ transaccion_uuid }/${ tipo }`;
        return await this.transaction('GET',url, null , true );
    }

}

export default Reportes;