import React, {   useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";
// @mui material components
import Card from "@mui/material/Card";
import Swal from 'sweetalert2';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import GridPlazas from 'layouts/stp/personas/components/GridPlazas';

import CentroDeCostoRequest from 'Repository/Banca/CentroDeCosto';
import ListadoAutorizadoresCdcs from 'layouts/stp/cuentas/components/ListadoAutorizadoresCdcs';
import Filtro from "../../../cuentas/components/Filtro";


function CentroItem({centroDeCosto, handleVolverAPrincipal }) {

    const [verCdcs, setVerCdcs] = useState(false);
    const [statex, setStatex] = useState(centroDeCosto.disabled == null ? true: false );
    const navigate = useNavigate();
    //#region events
    
        const handleSeleccionar = (event)=>{
            setVerCdcs(!verCdcs);
        }

        const handleEditar = (event, uuid)=>{
            //onSelectToEdit(uuid);
        }

        const handleCambiarEstado = (event, cdcs_uuid)=>{
            cambiarEstado(cdcs_uuid); 
        }

        const handleIrACuenta = (cuenta_uuid)=>{
            localStorage.setItem(`cCuentaUuid`, cuenta_uuid);
            navigate('/cuentas');
        }

        const handleVolverAlListado = ()=>{
            handleVolverAPrincipal();
        }
    //#endregion

    //#region  methods
    const cambiarEstado = async (cdcs_uuid)=>{
        const passtext = await Swal.fire({
            title: 'Confirmaciones',
            text: "¿Para confirmar el cambio de estatus del CDC coloque su código de confirmación?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            input: 'password',
            allowOutsideClick: false
            });
        if(passtext.isConfirmed){
           
            CentroDeCostoRequest.cambiarEstado(cdcs_uuid, !statex, passtext.value ).then(response=>{
                if(response.status == 'ok'){
                    Swal.fire({
                        icon: 'success',
                        title: 'Excelente',
                        text: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                    setStatex(!statex);
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Notificaciones',
                        text: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                }
            }); 
        }
    }
    //#endregion 

    const cardCdc = <Card sx={{ border:"5px solid #f7f7f7",  }}>
        <SoftBox
        >
            <SoftBox lineHeight={1} >
                <SoftTypography display="block" color="white" variant="button" fontWeight="medium">
                    RFC:{centroDeCosto.rfc} - SERIE: {centroDeCosto.serie}
                </SoftTypography>
            </SoftBox>

            <SoftBox display="flex" alignItems="center">



                { 1== 2 && (
                    <SoftBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "pointer" }}
                             onClick={ (event)=> handleEditar(event, centroDeCosto.uuid) }>
                        <Icon fontSize="small">edit</Icon>
                        <SoftTypography variant="button" fontWeight="bold">
                            EDITAR
                        </SoftTypography>
                    </SoftBox>

                )
                }

                <SoftBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "pointer" }}
                         onClick={ ()=> handleVolverAlListado() }>
                    <ArrowBackIcon  color="error" />
                    <SoftTypography variant="button" fontWeight="bold" color="error">
                        Volver al Listado
                    </SoftTypography>
                </SoftBox>

                <SoftBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "pointer" }}
                         onClick={ ()=> handleIrACuenta(centroDeCosto.cuenta_uuid) }>
                    <AccountBalanceWalletIcon  color="primary" />
                    <SoftTypography variant="button" fontWeight="bold" color="primary">
                        CUENTA CONCENTRADORA
                    </SoftTypography>
                </SoftBox>

                <SoftBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "pointer" }}
                         onClick={ (event)=> handleCambiarEstado( centroDeCosto.uuid) }>
                    <SoftTypography variant="button" color={ statex == true ? 'dark':'error'} fontWeight="bold">
                        { statex == true ? 'ACTIVO ':'INACTIVO '}
                    </SoftTypography>
                </SoftBox>





                <SoftBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "pointer" }}
                         onClick={ (event)=> handleSeleccionar(event) }>
                    <Icon fontSize="small">view_headline</Icon>
                    <SoftTypography variant="button" fontWeight="bold">
                        { verCdcs ? 'OCULTAR ':'VER '} PLAZAS
                    </SoftTypography>
                </SoftBox>
            </SoftBox>
        </SoftBox>

        <ListadoAutorizadoresCdcs key={"1"+centroDeCosto.uuid} centro = {centroDeCosto} />

        { verCdcs &&    <GridPlazas key={ centroDeCosto.uuid }  cdcs_uuid = {centroDeCosto.uuid} />  }
    </Card>

    return(
        <>
            <Filtro title={ "Información del Centro de costos - STP" } open={true} bodyContent={ cardCdc } icon={""} />
        </>
    )
    
}

//persona_uuid,  cuenta_uuid , rfc, serie
CentroItem.propTypes = {
    uuid: PropTypes.string,
    centroDeCosto: PropTypes.object,
    handleVolverAPrincipal: PropTypes.func
    //onSelectToEdit: PropTypes.func,
    //inPersona: PropTypes.bool
  };

export default CentroItem;