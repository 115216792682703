import  { useState } from "react";
import PropTypes from "prop-types";
import SoftBox from "../../../components/SoftBox";
const Filtro = ({ bodyContent,icon, title,open= false }) => {

    const [isCollapsed, setIsCollapsed] = useState(open);

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    const iconDown = <i className="fa-solid fa-chevron-down icons-alpha"></i>;
    const iconUp = <i className="fa-solid fa-angle-up icons-alpha"></i>;

    return (
        <SoftBox bgColor={"white"}>
            <div className="accordion">
                <div className="accordion-header" onClick={handleToggle} >
                    <i className={ icon +" icons-alpha"}></i> <label className={"filtro-text"}>{ title }</label>
                    <label className={"icon-colapse-position"}>
                        { isCollapsed ? iconUp : iconDown  }
                    </label>
                </div>
                {isCollapsed && (
                    <div className="accordion-body">
                        { bodyContent }
                    </div>
                )}
            </div>
        </SoftBox>
    );
}

Filtro.propTypes = {
    bodyContent: PropTypes.any,
    icon: PropTypes.string,
    title:PropTypes.string,
    open:PropTypes.bool,
}

export default Filtro;