//RECT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

//Theme components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import IconRefresh from '@mui/icons-material/Refresh';
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table";
import SoftInput from "components/SoftInput";
//Domain class
import CentroDeCosto from "Repository/Banca/CentroDeCosto";
import Estatus from "./Estatus";
import {v4} from "uuid";
import AlphaTable from "../../../cuentas/components/AlphaTable";

const CentrosDeCostosListado = ({ selectedCentro }) => {
  
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [texto, setTexto] = useState(''); 

    const cdcsp = new CentroDeCosto
    const navigate = useNavigate();

    //#region events
    useEffect(()=>{
        listar('');
    },[])

    const handleChangeBusqueda = ({target}) =>{
        const value = target.value;
        setTexto(value);
        if(value.length > 3){
          listar(value);
        }else if(value === ''){
          listar('');
        }
    }

    const handleEncontrar = (event) => {
        if (event.key === "Enter") {
            listar(texto);
        }
    }

    const handleRefresh = ()=>{
        listar('');
    }

    const handleShowClick = (item)=>{
        selectedCentro(item);
    }

    const handleShowCuentaClick = (cuenta_uuid)=>{
        localStorage.setItem(`cCuentaUuid`, cuenta_uuid);
        navigate('/cuentas');
    }
    //#endregion

    //#region methods
    const listar = async (value)=>{
        await cdcsp.listadoEnPerspectiva(value).then(response=>{
            setColumns(
                [
                    { name: "Centro de Costo STP", width: "10%"},
                    { name: "Clabe", width: "10%" },
                    { name: "Centro de Costo", width: "10%" },
                    { name: "Razón Social", width: "10%" },
                    { name: "Cuenta", width: "10%" },
                    { name: "Cliente", width: "10%" },
                    { name: "Estatus", width: "10%" },
                ]
                );
                const items = (response.response.data).map(element =>{
                    return <tr key={ v4() }>
                        <td>
                            {element.idCliente + (element.stpNombre == null ? '': "-"+ element.stpNombre ) }
                        </td>
                        <td>
                            { element.clabe }
                        </td>
                        <td>
                            <label onClick={ ()=>handleShowClick({...element, 'cdcs_uuid': element.uuid})  } className={ "ILabelLink" }>
                                {   element.rfc+" - "+ element.serie}
                            </label>
                        </td>
                        <td>
                            { element.rs }
                        </td>
                        <td>
                            <label onClick={ ()=>handleShowCuentaClick(element.cuenta_uuid) } className={ "ILabelLink" }>
                                { element.alias }
                            </label>
                        </td>
                        <td align={"center"}>
                            { element.idCliente }
                        </td>
                        <td align={"center"}>
                            <Estatus key={element.uuid} item={element} clase="CentroDeCosto"   />
                        </td>
                    </tr>
                })
                setRows(items);
        })
    }
    //#endregion
    
    return (
        <>
            <SoftBox pt={1}>
                <Card>

                    
                    <Grid container>
                        <Grid item xs={8} md={6} xl={8} >
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" pl={1}>
                                <SoftTypography > Centros de Costos </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item pl={1} xs={4} md={1} xl={2}>
                            <button className={"btn-alpha-info"} type={"submit"} onClick={ handleRefresh }>
                                <i className="fa-solid fa-arrows-rotate"></i> Refrescar
                            </button>
                        </Grid>
                        <Grid item xs={8} md={4} xl={2} pr={3}>
                            <input type={"search"}  className={"ITxtEncontrar-Banca"} name={"texto"} value = { texto } maxLength={"50"} placeholder={"Buscar"}
                                   onChange={ (event)=> handleChangeBusqueda(event)  } onKeyDown={  handleEncontrar }  />
                        </Grid>

                    </Grid>

                    <SoftBox>
                        <AlphaTable headers={ columns } records={ rows } />
                    </SoftBox>

                </Card>
            </SoftBox>
        </>
    )

}

CentrosDeCostosListado.propTypes = {
    selectedCentro : PropTypes.func
}

export default CentrosDeCostosListado
