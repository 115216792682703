import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";
// @mui material components
import Card from "@mui/material/Card";
import Swal from 'sweetalert2';

import CentroDeCosto from 'Repository/Banca/CentroDeCosto';
import { Divider, Grid } from '@mui/material';
import {v4 as uuidv4} from "uuid";
import Autorizador from "../../../Repository/Banca/Autorizador";
import SoftBadge from "../../../components/SoftBadge";

function CentroItem({centro }) {
    const [ notificacionesLocal , setNotificacionesLocal ] = useState(centro.notificaciones);

    const autorizadorx = new Autorizador();
    const centrox = new CentroDeCosto();

    const labels = [
        { label: 'CLABE', value: centro.clabe  },{ label: 'RFC', value: centro.rfc  }, { label: 'Licencia', value: centro.serie  }
    ];

    const permisosInit =  {
        notificaDispersionLiquidadaEmail:{ 'true': 'Notificar Dispersión Liquidada por Correo', 'false': 'Notificar Dispersión Liquidada por Correo' },
        //notificaDispersionLiquidadaSMS:{ 'true': 'Notificar Dispersión Liquidada por SMS', 'false': 'Notificar Dispersión Liquidada por SMS' },
        //notificaDispersionEnDictamenSMS:{ 'true': 'Notificar Dispersión En Dictamen por SMS', 'false': 'Notificar Dispersión En Dictamen por SMS' },
        //notificaDispersionAutorizadaSMS:{ 'true': 'Notificar Dispersión Autorizada por SMS', 'false': 'Notificar Dispersión Autorizada por SMS' },
        //notificaAbonoSMS:{ 'true': 'Notificar Abonos recibido por SMS', 'false': 'Notificar Abonos recibido por SMS' },
    }

    const  operaciones  = async (permiso)=>{
        const result = await Swal.fire({
            title: 'Notificaciones',
            text: "¿Desea continuar la actualizaciónde de datos?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        });
        if(result.isConfirmed){
            const valor = notificacionesLocal[`${permiso}`] ? true : false;
            setNotificacionesLocal({
                ...notificacionesLocal,
                [`${ permiso }`]: !valor
            })
            notificacionesLocal[`${permiso}`] =  !valor;
            const params = {
                'notificaciones': notificacionesLocal
            }
            autorizadorx.permisos(centro.cdc_autorizador_uuid,params);
        }

    }

    const handleCheckChange = (permiso)=>{
        operaciones(permiso);
    }

    //#region events

    useEffect(() => {
        load();
    }, [])


    const handleSetResumen = () =>{
        console.log("oks");
    }


    //#endregion

    //#region  methods
        const load = ()=>{

        }
    //#region methods

    //#endregion

    //#endregion

    const renderLabel = labels.map((field) => (
        <SoftBox key={ uuidv4() } display="flex" py={1} pr={2}>
            <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                {field.label}: &nbsp;
            </SoftTypography>
            <SoftBox >

            </SoftBox>
            <SoftTypography variant="button" fontWeight="regular" color="text" >
                {field.value}
            </SoftTypography>
        </SoftBox>
    ));

    const permisosBtns = () =>{
        return Object.keys(permisosInit).map( property => {
            const checked   = notificacionesLocal[`${ property }`];
            const label     = notificacionesLocal[`${ property }`] ?  permisosInit[`${ property }`][true]: `${ permisosInit[`${ property }`][false] }`;
            return <SoftBadge key={ uuidv4() }
                              badgeContent={ label }
                              size="xs" container
                              color={ checked ? "info":"light"}
                              sx={{ paddingRight:"2px", cursor:"pointer" }}
                              onClick={ () => handleCheckChange(property ) }
            />
        })
    }

    return(
        <Card sx={{ marginBottom:1, border:"3px solid #E6E6E6", padding:1,  }} >
            <Grid container mb={1}>
                <Grid item xs={12} md={6} xl={6}>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={2} >
                        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize" color={"color6A"}>
                            { centro.rs }
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox pl={2}>
                        <SoftBox mb={1} lineHeight={1}>
                            {renderLabel}
                        </SoftBox>
                    </SoftBox>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>

                    <SoftBox pl={2} pb={1} pr={1} pt={2}>
                        <SoftBox sx={{ borderColor:"#123AAA", borderTop:2, borderBottom:2, textAlign:"center", marginBottom:1 }}>
                            <SoftTypography sx={{ fontSize:14 }} >
                                Configure las notificaciones que desea recibir en tiempo real
                            </SoftTypography>
                        </SoftBox>
                        { permisosBtns() }
                    </SoftBox>


                </Grid>
            </Grid>




        </Card>
    )

}

//persona_uuid,  cuenta_uuid , rfc, serie
CentroItem.propTypes = {
    uuid: PropTypes.string,
    centro: PropTypes.object
    //onSelectToEdit: PropTypes.func,
    //inPersona: PropTypes.bool
};

export default CentroItem;