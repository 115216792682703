import Operacion from "./Operacion";

class Cobranza
    extends Operacion
{
    /**
     * No usaremos la access token para estas operaciones
     */
    constructor() {
        super();
    }

    /**
     * @comment Consultamos la operación que nos propocionan
     * @param uuid
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async consultarLiga(uuid){
        const url = `alphaerp/ligadepago/${uuid}`;
        return await this.transaction('GET',url, null , false );
    }

    /**
     * @comment Mandamos a cobrar
     * @param uuid
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async cobrar(data){
        const url = `alphaerp/charges`;
        return await this.transaction('POST',url, JSON.stringify(data) , false );
    }

    /**
     * @comment Mandamos a confirmar aplica para 3ds
     * @param uuid
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async confirmar(data){
        const url = `alphaerp/confirm/charges`;
        return await this.transaction('POST',url, JSON.stringify(data) , false );
    }

    /**
     * @comment Solicitamos la referencia de pago
     * @param uuid
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async solicitarReferencia(uuid,data){
        const url = `alphaerp/payment/reference/${ uuid }`;
        return await this.transaction('POST',url, JSON.stringify(data) , false );
    }

    /**
     * @comment Consultamos la operación que nos propocionan
     * @param uuid
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async consultarReferencia(uuid){
        const url = `alphaerp/referenciadepago/${uuid}`;
        return await this.transaction('GET',url, null , false );
    }

    /**
     * @comment Validamos que el token que nos estan enviado es valido y vigentre
     * @param data Object
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async validarCaptcha(data){
        const url = `alphaerp/validar-rechaptcha/v2/im-not-robot`;
        return await this.transaction('POST',url, JSON.stringify(data) , false );
    }

    /**
     * @comment Creamos una orden de venta en paypal
     * @param data Object
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async crearOrdenPaypal(data){
        const url = `alphaerp/paypal/orden`;
        return await this.transaction('POST',url, JSON.stringify(data) , false );
    }

    /**
     * @comment Confirmamos que el cliente a completadoe el proceso de compra
     * @param data Object
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async captureOrdenPaypal(id, partidas){
        const url = `alphaerp/paypal/orden/${id}/capture`;
        return await this.transaction('PUT',url, JSON.stringify(partidas) , false );
    }

}

export  default Cobranza;