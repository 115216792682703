import * as React from "react";
import Box from "@mui/material/Box";
const Ambiente = () => {

    return  <>
                <Box margin={1} textAlign="center" >
                    <Box sx={{ backgroundColor:"#CDEBF7", borderRadius:1, color:"red" }}>
                        Ambiente demostrativo
                    </Box>
                </Box>
            </>
}

export default Ambiente;