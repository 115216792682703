import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {v4} from "uuid";

import SoftBox from "../../../components/SoftBox";
import Grid from "@mui/material/Grid";

import AutorizadorPlataforma from "../../../Repository/Autorizador";
import PermisosYNotificaciones from "./PermisosYNotificaciones";
import AlphaTable from "../../cuentas/components/AlphaTable";
import {useAlphaAlertModal} from "../../../customHooks/useAlphaAlertModal";
import StatusAutorizador from "./StatusAutorizador";

const Autorizadores = ({cuenta_uuid = '', plataforma = ''}) => {
    const authRepo = new AutorizadorPlataforma
    const [ autorizadoresList, setAutorizadoresList ] = useState([]);
    const [ autorizadoresDisponibles, setAutorizadoresDisponibles ] = useState([]);
    const [ autorizadorSelected_uuid, setAutorizadorSelected_uuid] = useState('');
    const [saving, setSaving] = useState(false);
    const {alphaAlert, openAlert, setOpenAlert, setMessageAlert} = useAlphaAlertModal();

    const tableHeaders  =[
        { name:"Autorizador", width:"2%" },
        { name:"Permisos", width:"2%" },
        { name:"Estatus", width:"1%" },
    ];

    const loadPlataformas = async() =>{
        const responseAsignados = await authRepo.listar(cuenta_uuid);
        fillTable(responseAsignados.response);
    }

    const fillTable = (data)  => {
        const rows = data.map(element =>{
            return <tr key={ v4() }>
                <td>
                    { element.nombre_completo.toUpperCase()  }
                </td>
                <td>
                    <PermisosYNotificaciones cuenta_autorizador_uuid={ element.uuid } notificaciones={ element.notificaciones } permisos={ element.permisos } plataforma={ plataforma }  />
                </td>
                <td>
                    <StatusAutorizador autorizador={ element } currentObject={ authRepo }  />
                </td>
            </tr>
        })
        setAutorizadoresList( rows );
    }

    const loadAutorizadores = async () => {
        const responseDisponibles = await authRepo.porAsociar(cuenta_uuid);
        setAutorizadoresDisponibles( responseDisponibles.response );
    }

    const registarAutorizador = async ()=>{
        const data = {
            cuenta_uuid,
            autorizador_uuid: autorizadorSelected_uuid,
        }
        const responseRegistro = await authRepo.store(data);
        if(responseRegistro.status === "ok")
        {
            fillTable(responseRegistro.response);
            loadAutorizadores();
            setMessageAlert("Configuración actualizada con éxito");
            setOpenAlert(true);
        }
        setAutorizadorSelected_uuid('');
        setSaving(false);
    }

    useEffect(()=>{ loadPlataformas(); loadAutorizadores(); },[]);

    const onClickGuardar = (event) => {
        event.preventDefault();
        console.log( autorizadorSelected_uuid );
        if(autorizadorSelected_uuid ==='' ) return;
        if(saving) return;
        setSaving(true);
        registarAutorizador();
    }

    return (
        <SoftBox sx={{ border:"0.4rem solid #EEEEEE",paddingLeft:1, paddingRight:1, paddingBottom:1,paddingTop:1  }}>
            <SoftBox>
                <label className={ "IGenericLabel-Normal" }>
                    Configuración de usuarios
                </label>
            </SoftBox>
            <SoftBox>
                <Grid container pl={1} pb={1}>
                    <Grid item sm={2} xl={1} md={1} >
                        <label className={"IGenericLabel-sm"}>Asignar</label>
                    </Grid>
                    <Grid item sm={6} xl={4} md={4} >
                        <select className={"ITextbox"} name={"autorizadorSelected_uuid"} value={ autorizadorSelected_uuid } onChange={ (event)=>setAutorizadorSelected_uuid(event.target.value) }>
                            <option key={ v4() }  value={ ""}> { "-" } </option>
                            { autorizadoresDisponibles.map(element =>{
                                return <option key={ v4() }  value={ element.autorizador_uuid }> { element.nombre_completo } </option>
                            }) }
                        </select>
                    </Grid>
                    <Grid item sm={4} xl={3} md={2} ml={1} >
                        <button className={"btn-alpha-success-2 pad-element"} type={"button"} onClick={ onClickGuardar }  >
                            <i className="fa-solid fa-circle-check"></i> Agregar
                        </button>
                    </Grid>
                </Grid>
            </SoftBox>
            <AlphaTable records={  autorizadoresList } headers={ tableHeaders } />
                { openAlert && alphaAlert }
        </SoftBox>
    );
}

Autorizadores.propTypes = {
    cuenta_uuid: PropTypes.string.isRequired,
    plataforma: PropTypes.string.isRequired,
}

export default Autorizadores;