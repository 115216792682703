import React, {  useEffect, useRef, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import Swal from 'sweetalert2';


import PersonaRequest from "Repository/Personas";

function ConfirmOtp({ credentials, OnSuccessConfirm }) {

    const [data, setData] = useState(credentials); 
    const [confirm, setConfirm] = useState('');
    const {uuid} = data;
    
    const onAccept = (event)=>{
      event.preventDefault();
      procesar();
    }
    const procesar = () =>{
        if(confirm.trim().length != 6 ){
            Swal.fire({
              title: 'Notificaciones',
              text: 'Por lo general son 6 números',
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
              allowOutsideClick: false
          }); 
          return false;
        }
    
        const params = {
            'personaUuid':uuid,
            'password': confirm
        }
        PersonaRequest.confirmar2Fa(params).then((response)=>{
          if(response.status == 'error'){
            Swal.fire({
                title: 'Notificaciones',
                text: response.response.text,
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            });
          }else{
            OnSuccessConfirm();
          
          }
        });
      }

    return(
        <>
          <SoftBox component="form" role="form" onSubmit={onAccept} >
            <SoftBox mb={2}>
                  <SoftBox mb={1} ml={0.5}>
                  <SoftTypography  fontWeight="bold"  sx={{ color:"#ffffff", fontSize:"12px" }}>
                      Código de confirmación (OTP)
                  </SoftTypography>
                  </SoftBox>
                  <SoftInput type="password" placeholder="*****" value={confirm}  autoFocus autoComplete="one-time-code"
                    onChange={ event => setConfirm(event.target.value) } />
              </SoftBox>
              <SoftBox mt={4} mb={1} pb={4}>
                  <button type={"submit"} className={ "btn-alpha-success" } >
                      Confirmar
                  </button>
              </SoftBox>
          </SoftBox>
            
        </>
    )
    
}

ConfirmOtp.propTypes = {
    credentials: PropTypes.object,
    OnSuccessConfirm: PropTypes.func
  };

export default ConfirmOtp;