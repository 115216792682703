import React, {   useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import IconAddTask from '@mui/icons-material/AddTask';
import IconMenu from '@mui/icons-material/Menu';
import Icon from "@mui/material/Icon";
import SoftInput from "components/SoftInput";

import GridCdcs from '../components/GridCdcs';
//
import PersonaRequest from 'Repository/Banca/Personas';
import {v4} from "uuid";

function ItemCuenta({cuenta, persona_uuid , onUpdate}) {

    const [records, setRecords] = useState([]);
    const [recordsOrigin, setRecordsOrigin] = useState([]);
    const [txtBuscar, setTxtBuscar] = useState('');
    
    //#region "events"
    useEffect(()=>{
        loadData();
    },[]);
    //#endregion

    //#region methods
    const loadData= (  )=>{
        PersonaRequest.CdcDePersona(cuenta.uuid, persona_uuid).then(response=>{
            setRecords(response.response.data);
            setRecordsOrigin(response.response.data);
        });
    }

    const handleChangeBusqueda = (event)=>{
        setTxtBuscar(event.target.value);
        const filter = recordsOrigin.filter( item => (item.rfc.toLowerCase().includes(event.target.value) || item.serie.includes(event.target.value)  )  );
        setRecords(filter);
    }

    const handleEncontrar = (event) => {
        if (event.key === "Enter") {
            const filter = recordsOrigin.filter( item => (item.rfc.toLowerCase().includes(txtBuscar) || item.serie.includes(txtBuscar)  )  );
        }
    }

    //#endregion

    return(
        <>
            <SoftBox
                p={1}
                 ml={2} mr={1} mb={1}
                >
                <SoftBox width="100%" display="flex" flexDirection="column">
                    <SoftBox >
                        <SoftBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                            mb={2}
                            >
                            <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
                            Cuenta: { cuenta.alias ? cuenta.alias :'CUENTA SIN ALIAS' }
                            </SoftTypography>

                                
                        </SoftBox>
                        

                    </SoftBox>
                    <SoftBox  display="flex"
                        justifyContent="space-between"
                        alignItems="center" pb={1}
                        >
                        <SoftBox mb={1} lineHeight={0}>
                            <SoftTypography variant="caption" color="text">
                                Centros de Costos
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" alignItems="center">
                            <SoftBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "pointer" }}  
                                >
                                <SoftBox pl={1} >
                                    <input type={"search"}  className={"ITxtEncontrar-Banca"} name={"encontrar"} value = { txtBuscar } maxLength={"50"} placeholder={"Buscar"}
                                           onChange={ (event)=> handleChangeBusqueda(event)  } onKeyDown={  handleEncontrar }  />
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>

                    { records.map((cdc) =>{
                        return  <GridCdcs key={ v4() } centroDeCosto={cdc} inPersona={ true }  />
                    })}

                </SoftBox>
            </SoftBox>
        </>
    )
    
}

ItemCuenta.propTypes = {
    cuenta: PropTypes.object,
    persona_uuid: PropTypes.string,
    onUpdate : PropTypes.func
  };

export default ItemCuenta;