import Operacion from './Operacion';
class Autorizador extends Operacion{

    constructor() {
        super();
    }

    //Crea una persona, sin asignarle ningun tipo de permiso
    async getAllByCuenta(cuenta_uuid){
        let url = `panel/autorizadores/${cuenta_uuid}/listar`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    static cambiarEstado(item, value){
        return new Promise((resolve, fallback)=>{
            let url = `${process.env.REACT_APP_API_CORE_BANCA}panel/autorizador/${item.autorizador_uuid}/estatus/${value}`;
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` }
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response) );
        });
    }

    static delete (uuid, passtext){
        return new Promise((resolve, fallback)=>{
            let url = `${process.env.REACT_APP_API_CORE_BANCA}panel/autorizador/${uuid}/delete`;
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body : JSON.stringify({passtext: passtext})
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response) );
        });
    }

    /*
    static add(params){
        return new Promise((resolve, fallback)=>{
            let url = `${process.env.REACT_APP_API_CORE}panel/autorizador/add`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: JSON.stringify(params) 
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response) );
        });
    }
    */

    async add(params){
        const url =`panel/autorizador/add`;
        const response = await this.transaction('POST',url, JSON.stringify(params)  , true );
        return response;
    }

    async permisos(autorizador_uuid,params){
        const url =`panel/autorizador/${autorizador_uuid}/permisos`;
        const response = await this.transaction('PUT',url, JSON.stringify(params)  , true );
        return response;
    }

}


export default Autorizador;