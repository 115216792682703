export const useValidateMsi = () => {

    /**
     * Validamos cuales son los meses si intereses aplicables a este monto
     * Para poder utilizar las promociones de meses sin intereses es necesario seguir las siguientes reglas de montos mínimos para cada promoción.
     * Cantidad en pesos	Promoción.
     * >= 300 && < 600	3 meses sin intereses
     * >= 600 && < 900	3 y 6 meses sin intereses
     * >= 900 && < 1200	3, 6 y 9 meses sin intereses
     * >= 1200 && < 1800	3, 6 y 9 12 meses sin intereses
     * >= 1800	3, 6, 9, 12 y 18 meses sin intereses*
     * @param plazo
     * @param amount
     * @return array
     */
    const validarPlazo = (plazo,amount)=>{
        let result = [];
        [300,600,900,1200,1800,2400].forEach(montoBase =>{
            const pl = montoBase.toString().replace(/0+$/, '');
            if(amount >= montoBase ) result = [...result,pl ];
        })
        return result.find(p => p === plazo ,result);
    }


    return {
        validarPlazo
    }

}