import { useState, useEffect, useMemo } from 'react';
import Cuenta from "../../../../Repository/Cuenta";
import {v4} from "uuid";
import BtnCambioEstadoTpv from "../BtnCambioEstadoTpv";
import * as React from "react";

export const useListadosHook = ( catalogoName = 'cuentas' ) => {

    const [catalogo, setCatalogo] = useState(catalogoName);
    const [tblColumns, setTblColumns] = useState([]);
    const [tblRows, setTblRows ] = useState([]);
    const [selectedItem, setSelectedItem ] = useState(null);
    /**
     * Eventos React
     */
    useEffect(()=>{  initForm();  },[])

    /**
     * Metoddos
     */

    const initForm = () => {
        switch (catalogoName){
            case 'cuentas':
                configColumnCuentas();
                break;
        }
    }

    const fillTable = (records = []) => {
        switch (catalogoName){
            case "cuentas":
                cuentasIteration(records);
                break;
        }
    }

    const rowSelected = (event,item) => {
        setSelectedItem(item);
    }

    const cuentasIteration = (records = []) => {
        const rows = records.map(element => {
            return <tr key={ v4() } className={"IClickable"}  onClick={ event =>  rowSelected(event ,element ) }>
                <td> <label className={"IClickable pl-5 "}>{ element.id }</label>  </td>
                <td><label className={"IClickable pl-5"}>{ element.licencia }</label></td>
                <td> <label className={"IClickable pl-5 "}>{ element.rfc }</label>  </td>
                <td><label className={"IClickable pl-5"}>{ element.nombreComercial }</label></td>
                <td><label className={"IClickable pl-5"}>{ element.rs }</label></td>
            </tr>
        })
        setTblRows(rows  );
    }

    const configColumnCuentas = () => {
        const columns =  [
            { name:"Centro de Costos", width:"10%" },
            { name:"Serie", width:"10%" },
            { name:"RFC", width:"10%" },
            { name:"Nombre Comercial", width:"10%" },
            { name:"Razón Social", width:"40%" },
        ];
        setTblColumns( columns );
    }

    return {
        catalogo,
        setCatalogo,
        tblColumns,
        selectedItem,
        tblRows,
        fillTable,
    }
}