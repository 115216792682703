import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import SoftBox from "../../../components/SoftBox";
import Reportes from "../../../Repository/Reportes";
import TPV from "./detalle/TPV";
import TARJETA from "./detalle/TARJETA";
import EFECTIVO from "./detalle/EFECTIVO";
import PAYPAL from "./detalle/PAYPAL";
import {useDispatch} from "react-redux";
import {closeGeneralLoader, openGeneralLoader} from "../../../store/slice/alertSlice";
import SUSCRIPCION from "./detalle/SUSCRIPCION";

export const DetailElement = ({ transaccion = {} }) => {
    //#endregion
    const reportex = new Reportes();
    const [detalle, setDetalle] = useState({});
    const [componentToDisplay, setComponentToDisplay] = useState(<></>);
    const dispatch = useDispatch();

    //TODO:: me quede armando de los componentes de detalle

    useEffect(()=>{
        load();
    },[])

    const load = async () =>{
        dispatch( openGeneralLoader() )
        const result = await reportex.detalleTransaccion(transaccion.uuid, transaccion.metodo);
        if(result.status === 'ok'){
            setDetalle( result.response );
            const tipo = transaccion.metodo;
            let componente = '';
            switch (tipo.toLowerCase()){
                case 'tpv':
                    componente = <TPV transaccion={  result.response } />
                    break;
                case 'tarjeta':
                    componente = <TARJETA transaccion={ result.response } />
                    break;
                case 'efectivo':
                    componente = <EFECTIVO transaccion={ result.response } />
                    break;
                case 'paypal':
                    componente = <PAYPAL transaccion={ result.response } />
                    break;
                case 'suscripcion':
                    componente = <SUSCRIPCION transaccion={ result.response } />
                    break;
            }
            setComponentToDisplay( componente );
            dispatch( closeGeneralLoader() )
        }
    }

    return (
        <SoftBox >
            { componentToDisplay }
        </SoftBox>
    )
}

DetailElement.propTypes = {
    transaccion: PropTypes.object,
};

export default DetailElement;