import * as React from "react";
import Grid from "@mui/material/Grid";
import "flatpickr/dist/themes/dark.css";
import moment from "moment";
import {useForm} from "../../../../customHooks/useForm";
import {useEffect, useState} from "react";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import SoftBox from "../../../../components/SoftBox";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import BtnBusqueda from "./BtnBusqueda";
import BuscarCentroDeCostos from "./BuscarCentroDeCostos";
import {useNavigate, useParams} from "react-router-dom";
import ServicioContratado from "../../../../Repository/ServicioContratado";
import {useAlphaAlertModal} from "../../../../customHooks/useAlphaAlertModal";
import Cuenta from "../../../../Repository/Cuenta";
import {useDispatch} from "react-redux";
import {closeGeneralLoader, openGeneralLoader} from "../../../../store/slice/alertSlice";
import  imgIrDet  from "assets/alpha-theme/images/icons/imgIrDet.gif"
import SoftTypography from "../../../../components/SoftTypography";
moment.locale("es-MX");

const initialForm = {
    storeId: "",
    serialNumber:"",
    password:"",
    cuenta_uuid:""
}

const formValidation = {
    cuenta_uuid: [(value)=> value.trim() !== "","Llave pública requerida."],
    serialNumber: [(value)=> value.trim() !== "","Llave pública requerida."],
    storeId: [(value)=> value.trim() !== "","Llave secreta requerida."],
    password: [(value)=> value.trim() !== "","Contraseña requerida."],
}

function FrmTerminalEdit (props) {
    const servContratado = new ServicioContratado();
    const cuentax = new Cuenta();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { uuid:device_uuid } = useParams();

    const [serialNumber, setSerialNumber] = useState('');
    const [storeId, setStoreId] = useState('');
    const [password, setPassword] = useState('');
    const [rfc, setRfc] = useState('');
    const [licencia, setLicencia] = useState('');
    const [rs, setRs] = useState('');
    const [cuenta_uuid, setCuentaUuid] = useState('');
    const [servicioPlataforma_uuid, setServicioPlataforma_uuid] = useState('');
    const [ showWebModal, setShowWenModal ] = useState(false);
    const [saving, setSaving] = useState(false);
    const { setOpenAlert,alphaAlert,setMessageAlert,openAlert } = useAlphaAlertModal();

    useEffect(()=>{
        loadCuentaInfo();
    },[])

    const onClickGuardar = (event) => {
        event.preventDefault();
        if(saving) return false;
        if(!validateForm()) {
            setMessageAlert("Complete los datos solicitados para poder continuar");
            setOpenAlert(true);
            return false
        };
        setSaving(true);
        update();
    }

    const handleBack = (event) => {
        event.preventDefault();
        navigate("/terminales");
    }

    const mostrarBuscador = () => {
        setShowWenModal(true);
    }

    const onSelectedITem = (item) => {
        setShowWenModal(false);
        setLicencia( item.licencia );
        setRfc( item.rfc );
        setRs( item.rs );
        setCuentaUuid(item.uuid);
        cargarServiciosContratados(item);
    }

    const onCloseWindow = () => {
        setShowWenModal(false);
    }

    const validateForm = () => {
        if(storeId.trim() === "") return false;
        if(serialNumber.trim() === "") return false;
        if(password.trim() === "") return false;
        if(cuenta_uuid.trim() === "") return false;
        return true;
    }

    const loadCuentaInfo = async () => {
        dispatch( openGeneralLoader() );
        const {response:device, status} = await servContratado.mostrar(device_uuid);
        setSerialNumber( device.credenciales.serialNumber);
        setPassword( device.credenciales.password );
        setStoreId( device.credenciales.storeId );

        const result = await cuentax.mostrar(device.cuenta_uuid);
        if(result.status === "error") return;
        setRfc( result.response.rfc );
        setRs( result.response.rs );
        setLicencia( result.response.licencia );
        setCuentaUuid( result.response.cuenta_uuid );
        setServicioPlataforma_uuid( device.servicioPlataforma_uuid );
        dispatch( closeGeneralLoader() );
    }

    const update = async() => {
        const params = {
            cuenta_uuid,
            credenciales:{
                serialNumber,
                storeId,
                password,
            },
            servicioPlataforma_uuid
        };
        const result = await servContratado.update(device_uuid,params);
        if(result.status === 'error') {
            setMessageAlert(result.response);
            return;
        }else{
            setMessageAlert("Registro actualizado con exito");
            setTimeout(()=>{
                //navigate("/terminales");
            },2000)
        }
        setSaving(false);
        setOpenAlert(  true );
    }

    const cargarServiciosContratados = async (cuenta = {}) => {
        const result = await servContratado.listar(cuenta.uuid);
        if(result.status === false) return;
        const contratado = result.response.contratados.find(element => element.tipoServicio === 'NETPAY_TPV');
        if(contratado === null) return;
        setServicioPlataforma_uuid( contratado.uuid );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar light={true}/>

            <SoftBox sx={{ backgroundColor:"#f2f2f2", color:"black", padding:2,
                borderRadius:3, marginTop:2, marginLeft:1, marginRight:1, display:"flex" }} >
               <img src={ imgIrDet } width={50} height={50} /> <SoftTypography sx ={{ paddingTop:1,fontSize:"1.5rem",fontFamily:"TTCDemibold" }}>Editar Terminal</SoftTypography>
            </SoftBox>

            <SoftBox sx={{border: "5px solid #f7f7f7", margin: 1}}>
                <Grid container p={1}>

                    <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                        <label className={"IGenericLabel"}>Razó social</label>
                    </Grid>
                    <Grid item xs={6} md={6} xl={6} display={"flex"}>
                        <input type={"text"} className={"ITextbox"} name={"rs"} value={rs} readOnly/>
                        <BtnBusqueda fnOnclick={mostrarBuscador}/>
                    </Grid>

                </Grid>
                <Grid container>
                    <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                        <label className={"IGenericLabel"}>RFC</label>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2}>
                        <input type={"text"} className={"ITextbox"} name={"rfc"} value={rfc} readOnly/>
                    </Grid>

                    <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                        <label className={"IGenericLabel"}>Licencia</label>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2}>
                        <input type={"text"} className={"ITextbox"} name={"licencia"} value={licencia} readOnly/>
                    </Grid>
                </Grid>
                <Grid container pr={1}>

                    <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                        <label className={"IGenericLabel"}>Número de serie</label>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2}>
                        <input type={"text"} className={"ITextbox"} name={"serialNumber"} value={serialNumber}
                               onChange={ (event) => setSerialNumber(event.target.value) }/>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                        <label className={"IGenericLabel"}>StoreID</label>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2}>
                        <input type={"text"} className={"ITextbox"} name={"storeId"} value={storeId}
                               onChange={ (event) => setStoreId(event.target.value )  }/>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                        <label className={"IGenericLabel"}>Contraseña</label>
                    </Grid>
                    <Grid item xs={6} md={2} xl={2}>
                        <input type={"text"} className={"ITextbox"} name={"password"} value={password}
                               onChange={ event => setPassword(event.target.value) }/>
                    </Grid>
                    <Grid item xs={12} md={2} xl={2} pl={1}>

                    </Grid>
                    <Grid item xs={12} md={2} xl={2} pl={1}>

                    </Grid>
                    <Grid item xs={12} md={2} xl={2} pl={1}>

                    </Grid>
                    <Grid item xs={6} md={3} xl={3} mt={2}>
                        <button className={"btn-alpha-success-2 pad-element"} type={"submit"} onClick={onClickGuardar}>
                            <i className="fa-solid fa-circle-check"></i> Aceptar
                        </button>
                    </Grid>
                    <Grid item xs={6} md={3} xl={3} mt={2} pl={1}>
                        <button className={"btn-alpha-danger pad-element"} type={"submit"} onClick={handleBack}>
                            <i className="fa-solid fa-circle-xmark"></i> Cancelar
                        </button>
                    </Grid>
                </Grid>
                {
                    showWebModal && <BuscarCentroDeCostos sizeAlert={"md"} fnOnSelectItem={onSelectedITem} fnOnCloseWindow={onCloseWindow}/>
                }
                {
                    openAlert && alphaAlert
                }
            </SoftBox>
        </DashboardLayout>
    );

}
export default FrmTerminalEdit;