import * as React from "react";
import SoftBox from "../../../components/SoftBox";
import Box from "@mui/material/Box";
import {Grid, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import SoftTypography from "../../../components/SoftTypography";
import {useState} from "react";
import {useForm} from "../../../customHooks/useForm";
import {closeGeneralLoader, openGeneralLoader, setAlertMessage} from "../../../store/slice/alertSlice";
import Cobranza from "../../../Repository/Cobranza";
import {
    setMetodoPago,
    setReferencia,
    updateMonto
} from "../../../store/slice/cobranzaSlice";
import {v4} from "uuid";
import Card from "@mui/material/Card";
import logox from "../../../assets/alpha-theme/images/cards/cobranza3v2.png";
import {useRecaptchaVersion3} from "../../../customHooks/useRecaptchaVersion3";
import {useNumberFormat} from "../../../customHooks/useNumberFormat";
import SectionHeader from "./SectionHeader";
import moment from "moment";

const validationCard = {
    firstName: [(value)=> {
        const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+([ ][a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+)*$/;
        return regex.test(value);
    },"Nombre inválido"],
    lastName: [(value)=> {
        const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+([ ][a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+)*$/;
        return regex.test(value);
    },"Apellido inválido."],
    email: [(value)=> {
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return regex.test(value);
    },"Correo inválido"],
}

const CashPayment = (props) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const { numberFormat  } = useNumberFormat();
    const transacciones = new Cobranza();
    const dispatch = useDispatch();
    const [saving, setSaving] = useState(false);
    const { uuid,tipoVenta,cuenta ,transaccion:initialForm, transaccion, metodoPago,partidasAPagar } = useSelector(state => state.cobranza);
    const { montoReferencia:monto, concepto } = transaccion;
    const {firstName, lastName,email,
        onInputChange, isFormValid, validationResult } = useForm( initialForm, validationCard );
    const { captchaTokenRequest } = useRecaptchaVersion3();

    const onClickGuardar = (event)=>{
        event.preventDefault();
        setSaving(true);
        if(!isFormValid){
            const properties = Object.getOwnPropertyNames(validationResult);
            properties.forEach(element=>{
                if(validationResult[element] != null){
                    const alert = {
                        message : validationResult[element],
                        icon:"",
                    }
                    setSaving(false);
                    dispatch( setAlertMessage(alert) )
                    return;
                }
            })
            return;
        }
        procesarReferencia();

    }

    const procesarReferencia =async () =>{
        const {token:captchaResponse} = await captchaTokenRequest();

        const partidasAPagarR = partidasAPagar.map(partida => {
            return {
                ...partida,
                fecha: moment(partida.fecha,"DD/MM/YYYY").format("YYYY-MM-DD"),
                vencimiento: moment(partida.vencimiento,"DD/MM/YYYY").format("YYYY-MM-DD")
            };
        });
        const data = {
            firstName,
            lastName,
            email,
            captchaResponse,
            'partidas':partidasAPagarR
        }
        dispatch( openGeneralLoader());
        const result = await  transacciones.solicitarReferencia(uuid, data);
        if(result.status === 'ok'){
            dispatch( setReferencia(result.response ) );
            dispatch( closeGeneralLoader());
        }else{
            const alert = {
                message : result.response,
                icon:"",
            }
            dispatch( setAlertMessage(alert) )
            dispatch( closeGeneralLoader());
            setSaving(false);
            return false;
        }

    }

    const onClickBack = (event)=>{
        event.preventDefault();
        dispatch( setMetodoPago("") );
    }

    const changePlazaPago = (event) =>{
        event.preventDefault();
        const periodoSelected = transaccion.netpay.msi.find(element => element.periodo  == event.target.value );
        dispatch( updateMonto({monto:periodoSelected.monto, msi: periodoSelected.periodo}) );
    }

    const plazosComponent = <SoftBox key={v4()}  >
        <table className={"IRadioTable"}>
            <thead>
            <tr>
                <th colSpan={2}>
                    <label className={"IGenericLabel"}>Selecciona el plazo deseado que se aplicará para tu compra</label>
                </th>
            </tr>
            </thead>
            <tbody>
            { transaccion.netpay.msi.map(element =>{
                return <tr key={ v4() } >
                    <td>
                        <input type={"radio"} id={"msi"+ element.periodo } name={"msi"} checked={  parseInt(element.periodo) === parseInt(tipoVenta) } value={ element.periodo } onChange={ changePlazaPago } /> &nbsp;
                        <label htmlFor={ "msi"+ element.periodo } > { element.descripcion }</label>
                    </td>
                    <td align={"right"}>
                        <label >$ { element.monto }</label>
                    </td>
                </tr>
            }) }
            </tbody>
        </table>
    </SoftBox>

    return (
        <SoftBox bgColor={"color18"} borderRadius={"12px"} >
            <SectionHeader cuenta={cuenta} />
            <Grid container >

                <Grid item xs={12} md={7} xl={7} >

                    <SoftBox id="netpay-form" sx={{  border:"5px solid #E6E6E6",borderRadius:2,margin: isMobile?0:1,  }}>
                        <Grid container  p={1}>

                            <Grid item xs={12} md={12} xl={12}  >
                                <Grid container  >

                                    <Grid item xs={12} md={12} xl={12}   >
                                        <label className={"IGenericLabel-sm"} >Información del cliente</label>
                                    </Grid>

                                    <Grid item xs={12} md={6} xl={6}   >
                                        <label className={"IGenericLabel-Normal2"}>Nombre(s)</label>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={6}>
                                        <input type={"text"} className={"ITextbox"} name={"firstName"} value={ firstName } onChange={ onInputChange } />
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={6}  >
                                        <label className={"IGenericLabel-Normal2"}>Apellido(s)</label>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={6}>
                                        <input type={"text"} className={"ITextbox"} name={"lastName"} value={ lastName } onChange={ onInputChange } />
                                    </Grid>

                                    <Grid item xs={12} md={6} xl={6}   >
                                        <label className={"IGenericLabel-Normal2"}>Correo electrónico</label>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={6}>
                                        <input type={"text"} className={"ITextbox"} name={"email"} value={ email } onChange={ onInputChange } />
                                    </Grid>


                                    <Grid item xs={12} md={12} xl={12}>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SoftBox>
                    <Grid item xs={12} md={6} xl={6} paddingBottom={isMobile?1:0} >
                        {
                            transaccion.netpay.metodos.length > 1 || transaccion.paypal !== null ?
                                <button className={"btn-alpha-black pad-element ocultar-movil"} type={"submit"} onClick={ onClickBack }>
                                    <i className="fa-solid fa-chevron-left"></i> Volver al principio
                                </button> : ""
                        }
                    </Grid>
                    <Grid item xs={6} md={6} xl={6}>

                    </Grid>
                </Grid>
                <Grid item xs={12} md={5} xl={5} >
                    <Card sx={{ height:"99%",backgroundColor:"#F7F7F7",marginTop:1 }} >
                        <SoftBox pt={2} px={2}>
                            <SoftBox key={v4()} sx={{ textAlign:"center", borderBottom:"3px solid #929292" }} >
                                <label className={"IGenericLabel-Normal2"}>Detalle del cargo</label>
                            </SoftBox>
                        </SoftBox>
                        <SoftBox p={2}>
                            <SoftBox mb={2} lineHeight={1}>
                                <SoftTypography variant="button" color="text" fontWeight="regular">
                                    { concepto }
                                </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                                { metodoPago === "CARD" ? plazosComponent:"" }
                            </SoftBox>

                        </SoftBox>
                    </Card>
                    <SoftBox sx={{ marginTop:1 }}>
                        <button className={ !saving ? "btn-alpha-success-2 " : "btn-alpha-success-2-disabled "} type={"submit"} onClick={ onClickGuardar } disabled={ !saving ? "":"disabled"  }  >
                            Generar por  { numberFormat(monto,2)   } MXN
                        </button>
                        {
                            transaccion.netpay.metodos.length > 1 || transaccion.paypal !== null ?
                                <button className={"btn-alpha-black mostrar-movil"} type={"submit"} onClick={ onClickBack }>
                                    <i className="fa-solid fa-chevron-left"></i> Volver al principio
                                </button> : ""
                        }
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    );
}

export default CashPayment;