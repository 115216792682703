import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    tipoCambio: [],
}

export const tipoCambioSlice = createSlice({
    name: 'tipoCambio',
    initialState,
    reducers: {
        putCurrentValues:(state, action) =>{
            state.tipoCambio = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const { putCurrentValues } = tipoCambioSlice.actions

export default tipoCambioSlice.reducer