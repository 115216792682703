import Operacion from './Operacion';
import Cuenta from "./Cuenta";
class Servicio extends Operacion{
    constructor() {
        super();
    }

    /**
     * @comment Listamos los servicios listados por las plataformas
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async listar(){
        const url = `panel/servicios`;
        return await this.transaction('GET',url, null , true );
    }

}

export default Servicio;