import api_core from '../../services/config';
import Operacion from './Operacion';
class Personas extends Operacion{

    constructor() {
        super();
    }
    /// prefix = `panel\persona`;

    //Crea una persona, sin asignarle ningun tipo de permiso
    static registrar(data){
        return new Promise((resolve)=>{
            let url = `panel/persona/registrar`;
            let bodyFormData = new FormData();
            bodyFormData.append(`nombres`, `${data.nombres}`);
            bodyFormData.append(`apellidos`, `${data.apellidos}`);
            bodyFormData.append(`celular`, `${data.celular}`);
            bodyFormData.append(`email`, `${data.email}`);
            bodyFormData.append(`referencia`, `${data.referencia}`);
            bodyFormData.append(`nt_uuid`, `${data.nt_uuid}`);
            bodyFormData.append(`periodo`, `${data.periodo}`);
            api_core.post(`${url}`,bodyFormData ,{
                    headers: 
                    { 
                        Authorization: `Bearer ${localStorage.getItem('cToken')}`
                    }
                })
            .then(res => {
                let data = res.data;
                resolve(data);
            })
        });
    }

    async mostrar(uuid){
        let url = `panel/persona/${uuid}/mostrar`;
        const response = await this.transaction('GET',url, null , true );
        return response;
    }

    //Crea una persona, sin asignarle ningun tipo de permiso
    async listar(data){
        let uri = `nombres=${data.nombres}`;
        uri +=  data.soloActivos ? '&soloActivos='+data.soloActivos:'';
        uri +=  data.cuenta_uuid ? '&cuenta_uuid='+data.cuenta_uuid:'';
        const url = `panel/persona/listar?${ uri }`;
        const response = await this.transaction('GET',url, null , true );
        return response;

    }

    async cambiarEstado(uuid, value){
        const url = `panel/persona/${uuid}/estatus/${value}`;
        const response = await this.transaction('DELETE',url, null , true );
        return response;
    }

    async update(data, uuid){
        let url = `panel/persona/${uuid}/actualizar`;
        const response = await this.transaction('PUT',url, JSON.stringify(data) , true );
        return response;
    }

    async updateNotificacionesPeriodicas(data, uuid){
        let url = `panel/persona/${uuid}/actualizar/notificaciones`;
        const response = await this.transaction('POST',url, JSON.stringify(data) , true );
        return response;
    }

    //Crea una persona, sin asignarle ningun tipo de permiso
    static test(){
        return new Promise((resolve, fallback)=>{
            let url = `panel/test`;
            let bodyFormData = new FormData();
            api_core.post(`${url}`,bodyFormData ,{
                headers: { Authorization: `Bearer ${localStorage.getItem('cToken')}` }
            } )
            .then(res => {
                let data = res.data;
                resolve(data);
            })
        });
    }

    static buscar(data){
        return new Promise((resolve)=>{
            let params = `${localStorage.getItem('cToken')}`;
            let url = `${process.env.REACT_APP_API_CORE_BANCA}panel/persona/buscar?nombres=${data}`;
            var xhr = new XMLHttpRequest();
            xhr.open(`GET`,`${url}`,true);
            xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
            xhr.setRequestHeader('Authorization',  `Bearer ${localStorage.getItem('cToken')}`);
            xhr.send({});
            xhr.onload=function(event){
                resolve((JSON.parse(xhr.responseText)));
            };
        });
    }

    async cuentasDePersona(persona_uuid){
        const url = `panel/persona/${persona_uuid}/cuentasasociadas`;
        const response = await this.transaction('GET',url, null , true );
        return response;
    }

    static CdcDePersona(cuenta_uuid,persona_uuid){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE_BANCA}panel/persona/cdcsasociadas`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: JSON.stringify({'cuenta_uuid': cuenta_uuid, 'persona_uuid':persona_uuid}) 
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
        
    }

    static activate2Fa(persona_uuid){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE_BANCA}panel/persona/otp/${persona_uuid}/activar`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` }
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
    }

    static delete2Fa(persona_uuid){
        return new Promise((resolve, fallback)=>{
            const url = `${process.env.REACT_APP_API_CORE_BANCA}panel/persona/otp/${persona_uuid}/desactivar`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` }
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
    }

    static getOtpQrcode(token_temporal){
        return new Promise((resolve, fallback)=>{
            const url = `${process.env.REACT_APP_API_CORE_BANCA}panel/persona/otp/${token_temporal}/qrcode`;
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` }
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    //console.log('...', response);
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) =>{
                    console.log(response);
                    return response.json()
                } )
                .then((response) => resolve(response))
                ;
        });
    }


    static confirmar2Fa(persona){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE_BANCA}panel/persona/otp/confirmar`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: JSON.stringify(persona) 
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
        
    }

    static notificarPorCorreoOTP(persona_uuid){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE_BANCA}panel/persona/otp/${persona_uuid}/enviarcorreo`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
    }

    async enviarCorreoInicial (persona_uuid){
        const url = `panel/persona/${persona_uuid}/correoinicial`;
        const response =  await this.transaction('POST',url, JSON.stringify({})  , true );
        return response;
    }

    async enviarRenovarContrasenia(persona_uuid){
        const url = `panel/persona/${persona_uuid}/renovarcontrasenia`;
        const response =  await this.transaction('POST',url, JSON.stringify({})  , true );
        return response;
    }

    async tipoNotificaciones(){
        const url = `panel/tiponotificaciones`;
        const response =  await this.transaction('GET',url,null, true );
        const lista =  response.response.map(element=>{
            return {
                label: element.nombre.toUpperCase(),
                value: element.uuid
            }
        });

        return [
            {
                value: '',
                label: 'SIN NOTIFICACIÓN'
            },
            ...lista
        ]
    }

    async personaConfig(uuid){
        let url = `panel/persona/${uuid}/config`;
        const response = await this.transaction('GET',url, null , true );
        return response;
    }

    static desactivarOTP(persona_uuid, otpCode ){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE_BANCA}panel/persona/otp/${persona_uuid}/desactivar`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: JSON.stringify({'password':otpCode}) 
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
    }

    
    static resetPassword(data){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE_BANCA}panel/persona/resetpassword`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: JSON.stringify(data) 
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
        
    }
    

    async resetPasswordAutorizador(data){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE_BANCA}panel/persona/resetpasswordautorizador`;
            const requestOptions = {
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((response) => resolve(response))
            ;
        });
    }

   

    static subirFoto(data){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE_BANCA}panel/persona/subirfoto`;
            const requestOptions = {
                method: 'POST',
                headers: {  'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: data 
            };
            
            fetch(url, requestOptions)
            .then((response) =>{
                if(response.status === 401){
                    localStorage.clear();
                    window.location.reload(false);
                }else{
                    return response;
                }
            })
            .then((response) => response.json())
            .then((response) => resolve(response))
            ;
        });
        
    }

    async bajarFoto(){
        const url = `panel/persona/bajarfoto`;
        //const response =  await this.request('POST',url, JSON.stringify({}) , true );
        const response =  await this.transaction('POST',url, JSON.stringify({}) , true );
        return response;
    }

    async getPersonaPorToken(token_temporal){

        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE_BANCA}panel/persona/otp/${token_temporal}/qrcode`;
            const requestOptions = {
                method: 'GET'
            };

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((response) => resolve(response))
            ;
        });
    }

    async loggin(credentials){
        const url = `panel/login`;
        const data = await api_core.post(`${url}`, JSON.stringify(credentials) );
        return data.data;
    }

    

}

export default Personas;