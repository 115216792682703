import {v4} from "uuid";
import * as React from "react";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {addPartida, rmPartida} from "../../../store/slice/partidasSlice";
import {useMediaQuery} from "@mui/material";
import {useNumberFormat} from "../../../customHooks/useNumberFormat";
import Box from "@mui/material/Box";
import SoftBox from "../../../components/SoftBox";

const DocumentToPay = ({ partida }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const { numberFormat } = useNumberFormat();
    const dispatch = useDispatch();
    const [state,setState] = useState(partida.status);
    const {  partidasSelected,checkedAll } = useSelector(state => state.partidas);

    const handleState = ()=>{
        setState(!state);
        if (!state) {
            dispatch( addPartida(partida) )
        }else {
            dispatch( rmPartida({...partida,moneda:partida.moneda.toLowerCase() }) )
        }
    }

    /** Esta funcion la vamos a usar para monitorear el cambio de estado de la partida actual, si fue marcada la marcamos si fue desmarcada la desmarcamos */
    useEffect(()=>{
        const ExternalChecked =  partidasSelected.find(cPartidaSelected => {
            const bReg = cPartidaSelected.folio + cPartidaSelected.monto + cPartidaSelected.moneda + cPartidaSelected.diasVencimiento;
            const currentReg = partida.folio + partida.monto + partida.moneda + partida.diasVencimiento;
            return bReg ===  currentReg;
        })
        setState( typeof ExternalChecked ==="object" )
    },[partidasSelected]);

    const webRow = <tr key={ v4() }  className={"rowx"} >
            <td align={"center"}>
                { partida.fecha }
            </td >
            <td align={"center"}>
               { partida.folio }
            </td>
            <td align={"left"}>
                { partida.concepto }
            </td>
            <td align={"center"}>
                { partida.vencimiento }
            </td>
            <td align={"center"}>
                { numberFormat(partida.diasVencimiento,0) }
            </td>
            <td align={"right"}>
                { numberFormat(partida.monto,2)  }
            </td>
            <td align={"center"}>
                { partida.moneda }
            </td>
            <td align={"center"}>
                {  state && <i className="fa-regular fa-square-check icon-size-1-5" onClick={ (event)=> handleState() }></i> }
                {  !state && <i className="fa-regular fa-square icon-size-1-5" onClick={ (event)=> handleState() }></i> }
            </td>
        </tr>

    const mobileRow = <tr key={ v4() }  className={"rowx"} >
        <td colSpan={8}>
            <div className={"cell-documento"}>
                <div className={"cell-documento-parte11"}>
                    <div className={"mini-sections"}>
                        <label className={"lbl-etiqueta-mobile"}>Fecha: </label> <label className={"lbl-etiqueta-mobile-value"}>{ partida.fecha }</label>
                    </div>
                </div>
                <div className={"cell-documento-parte22"}>
                    <div className={"mini-sections mini-monto"}>
                        <label className={"mini-monto"}>$ { numberFormat(partida.monto,2)  } </label>
                    </div>
                </div>
            </div>
            <div className={"cell-documento"}>
                <div className={"cell-documento-parte1"}>
                    <div className={"mini-sections"}>
                        <label className={"lbl-etiqueta-mobile"}>Documento: </label> <label className={"lbl-etiqueta-mobile-value"}> { partida.folio } { partida.concepto }</label>
                    </div>
                    <div className={"mini-sections"}>
                        <label className={"lbl-etiqueta-mobile"}>Vencimiento: </label> <label className={"lbl-etiqueta-mobile-value"}>{ partida.vencimiento } ({ numberFormat(partida.diasVencimiento,0) } días)</label>
                    </div>
                </div>
                <div className={"cell-documento-parte2"}>
                    <div className={"checkMobile"}>
                        {  state && <i className="fa-regular fa-square-check icon-size-1-5" onClick={ (event)=> handleState() }></i> }
                        {  !state && <i className="fa-regular fa-square icon-size-1-5" onClick={ (event)=> handleState() }></i> }
                    </div>
                </div>
            </div>
        </td >
    </tr>

    return isMobile ? mobileRow: webRow;
}
DocumentToPay.propTypes = {
    partida:PropTypes.object,
};

export default DocumentToPay;