import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import SoftButton from "components/SoftButton";
import IconVisivilityOff from '@mui/icons-material/VisibilityOff';
import Swal from 'sweetalert2';

//
import Persona from 'Repository/Banca/Personas';
import IconEmail from "@mui/icons-material/Email";



function OptDesactivate({persona_uuid, onOtpCanceled}) {

    //#region events
    const onClickBtnEnviarNotificacion = (event)=>{
        desactivarOtp(persona_uuid );
    }
    //#endregion

    //#region methods
    const  desactivarOtp = async ()=>{
        const passtext = await Swal.fire({
            title: 'Confirmaciones',
            text: "Para poder continuar con la cancelación del servicio OTP a este usuario por favor ingrese su código de confirmación",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            input: 'password',
            allowOutsideClick: false
            });
    
        if(passtext.isConfirmed){
            Persona.desactivarOTP(persona_uuid,passtext.value).then((response) =>{
                if(response.status == 'ok'){
                    Swal.fire({
                        title: 'Notificaciones',
                        text: response.response.text,
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                        });
                        onOtpCanceled();
                }else{
                    Swal.fire({
                        title: 'Notificaciones',
                        text: response.response.text,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                        });
                }
               
            }); 
        }
        
     
    }
    //#endregion

    return(
        <>
            <button type={"button"} className={ "btn-alpha-gray-1" }  onClick={ (event)=>onClickBtnEnviarNotificacion(event) } >
                <i className="fa-regular fa-eye-slash"></i> Desactivar OTP
            </button>
        </>
    )
    
}

OptDesactivate.propTypes = {
    persona_uuid: PropTypes.string,
    onOtpCanceled: PropTypes.func
  };

export default OptDesactivate;