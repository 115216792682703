import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import IconBussines from '@mui/icons-material/Business'
import IconVpnKey from '@mui/icons-material/VpnKey'
import {v4} from "uuid";
import Box from "@mui/material/Box";


function ActionMenu( { onSelectedMenu, defaultOption = 3} ) {

    const [value, setValue] = React.useState(defaultOption);

    const itemsMenu = [
        {
            option:3,
            icon: <i className="fa-solid fa-user-shield"></i>,
            title:"Usuarios",
        },
        {
            option:1,
            icon: <i className="fa-regular fa-building"></i>,
            title:"Empresas",
        },
        {
            option:2,
            icon: <i className="fa-solid fa-key"></i>,
            title:"Licencias",
        },
        {
            option:4,
            icon: <i className="fa-solid fa-building-columns"></i>,
            title:"STP",
        },
        {
            option:5,
            icon: <i className="fa-solid fa-money-check-dollar"></i>,
            title:"Netpay",
        },
        {
            option:6,
            icon: <i className="fa-brands fa-paypal"></i>,
            title:"Paypal",
        },
    ]

    const handleClickMenu = (event, option)=>{
        setValue (option);
        onSelectedMenu(option);
    }

    return(
            <Box paddingTop={3} paddingLeft={2}  >

                {
                    itemsMenu.map(element =>{
                        return <button key={ v4() } type={"button"} className={ value === element.option ? "ITabButtonActive2":"ITabButtonInactive2" }
                                        onClick={event=> handleClickMenu(event, element.option) } >  { element.icon } <label className={"IMovil-Display"}>{ element.title }</label>  </button>
                    })
                }

            </Box>
    )
    
}

ActionMenu.propTypes = {
    onSelectedMenu: PropTypes.func,
    defaultOption: PropTypes.number,
};

export default ActionMenu;