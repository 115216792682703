import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components

import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

import Swal from 'sweetalert2';

import Persona from 'Repository/Banca/Personas';

function ButtonEstatusPersona({ uuid, estatus}) {
    const [statex, setstatex ] = useState( estatus );
    const personax = new Persona();
    
    const handleOnDelete = (event)=>{    
        operaciones();
    }

    const  operaciones  = async ()=>{
        const passtext = await Swal.fire({
            title: 'Confirmaciones',
            text: "¿Esta seguro de cambiar el estatus de esta persona?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            });

        if(passtext.isConfirmed){
            personax.cambiarEstado(uuid,!statex).then((response) =>{
                if(response.status == 'ok'){
                    Swal.fire({
                        title: 'Notificaciones',
                        text: response.response.text,
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                        });
                        setstatex(!statex);
                }else{
                    Swal.fire({
                        title: 'Notificaciones',
                        text: response.response.text,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false
                        });
                }
               
            }); 
        }

    }

    return(
        <>
            <button type={"button"} className={ statex ? "btn-alpha-info-3":"btn-alpha-gray-1" }  onClick={ (event) => handleOnDelete(event) }>{ statex ? ' ACTIVA': ' INACTIVA'}</button>
        </>

    )
    
}

ButtonEstatusPersona.propTypes = {
    uuid: PropTypes.string,
    estatus: PropTypes.any
  };

export default ButtonEstatusPersona;