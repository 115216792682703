import Operacion from './Operacion';
class Licencias extends Operacion {

    constructor(){
        super();
    }

    async listar(uuid){
        const url = `panel/licencia/${uuid}/listar`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    async store (data){
        const url = `panel/licencia/registrar`;
        const response = await  this.transaction('POST',url, data , true );
        return response;
    }

    /*
    static store(data){
        return new Promise((resolve, fallback)=>{
            let url = `/panel/licencia/registrar`;
            api_core.post(`${url}`,data ,{
                    headers: 
                    { 
                        Authorization: `Bearer ${localStorage.getItem('cToken')}`
                    }
                })
            .then(res => {
                let data = res.data;
                resolve(data);
            })
        });
    }
    */

    static cambiarEstado(item, value, password){
        return new Promise((resolve, fallback)=>{
            let url = `${process.env.REACT_APP_API_CORE_BANCA}panel/licencia/${item.uuid}/estatus/${value}`;
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: JSON.stringify({password}) 
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response) );
        });
    }

    static delete (uuid, passtext){
        return new Promise((resolve, fallback)=>{
            let url = `${process.env.REACT_APP_API_CORE_BANCA}panel/licencia/${uuid}/delete`;
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body : JSON.stringify({passtext: passtext})
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response) );
        });
    }

    async mostrar(uuid){
        const url = `panel/licencia/${uuid}/mostrar`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    async update(data, uuid){
        const url = `panel/licencia/${uuid}/actualizar`;
        const response = await  this.transaction('POST',url, data , true );
        return response;
    }

    /*
    static update(data, uuid){
        
        return new Promise((resolve, fallback)=>{
            let url = `/panel/licencia/${uuid}/actualizar`;
            api_core.post(`${url}`,data ,{
                    headers: 
                    { 
                        Authorization: `Bearer ${localStorage.getItem('cToken')}`
                    }
                })
            .then(res => {
                let data = res.data;
                resolve(data);
            })
        });
    }
    */

    async licenciasDisponibles(cuenta_uuid, empresa_uuid){
        const url = `panel/licenciasdiponibles/${cuenta_uuid}/${empresa_uuid}/porcuenta`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

}

export default Licencias;