import { createSlice } from '@reduxjs/toolkit'
import moment from "moment";
moment.locale("es-MX");

const defaultCuenta ={
    cuenta_uuid:null,
    direccion:"",
    id:null,
    licencia:"",
    nombreComercial:"",
    rfc:"",
    rs:"",
    tiempoEspera:"30",
    nombreEnlace:"",
    apellidosEnlace:"",
    telefono:"",
    correo: "",
    notificarCada:1,
    notificarCadaPago: 0,
}

const cursorDefault = {
    prev:null,
    next:null
}

const initialState = {
    loading: false,
    currentRecord: defaultCuenta,
    uuid: null,
    records : [],
    cursor:cursorDefault,
    openModal: false,
    cuentaACancelar:null,
    servicioACancelar:null,
    servicioAEditar:null,
    errorMessage:"",
    filter: {
        fechaaltadespuesde:null,
        fechaaltaantesde:null,
        textoBuscar:null
    },
    contratados: [],
    encontrar:"",
}

export const cuentasSlice = createSlice({
    name: 'cuentas',
    initialState,
    reducers: {
        setCuentaEditing:(state, action) =>{
            state.currentRecord = action.payload;
            state.uuid = action.payload.cuenta_uuid;
            state.errorMessage = "";
            state.loading = false;
        },
        setCuentaFilter: (state, action) => {
            state.filter = action.payload;
            state.loading       = false;
        },
        cuentaCancelEdit: (state) =>{
            state.currentRecord = defaultCuenta;
            state.uuid          = null;
            state.errorMessage  = "";
            state.contratados   = [];
        },
        setCuentaRecords:(state, action)=>{
            state.records = action.payload.data
            if(action.payload.cursor !== undefined) state.cursor = action.payload.cursor;
            if(action.payload.cursor === undefined) state.cursor = cursorDefault;
            state.loading = false;
        },
        setCuentaLoading : (state)=>{
            state.loading = true;
            state.errorMessage = "";
        },
        openModalCuentas:(state, action)=>{
            state.openModal = true;
            state.cuentaACancelar =action.payload;
        },
        selectServicio:(state, action)=>{
            state.servicioACancelar =action.payload;
        },
        unselectServicio:(state, action)=>{
            state.servicioACancelar = null;
        },
        setServicioAEditar:(state, action)=>{
            state.servicioAEditar =action.payload;
        },
        unsetServicioAEditar:(state, action)=>{
            state.servicioAEditar = null;
        },
        closeModalCuentas:(state)=>{
            state.openModal = false;
            state.cuentaACancelar =null;
            state.errorMessage = "";
        },
        refreshCuenta:(state, action)=>{
            const cuentaModificada = action.payload;
            state.records = state.records.map(element => {
                if(element.uuid === cuentaModificada.uuid){
                    const disabled = cuentaModificada.status === 0  ? moment().format():null;
                    return {
                        ...element,
                        disabled
                    }
                }
                return element;
            });
            state.cuentaACancelar = null;
            state.openModal = false;
        },
        setErrorCuenta :(state, action) =>{
            state.errorMessage = action.payload;
            state.loading = false;
        },
        clearErrorCuenta:(state) =>{
            state.errorMessage = "";
        },
        setContratadosCuenta:(state, action) =>{
            state.contratados = action.payload;
        },
        setEncontrar:(state,action)=>{
            state.encontrar = action.payload;
        },
        clearEncontrar:(state)=>{
            state.encontrar = "";
        },
    }
})

// Action creators are generated for each case reducer function
export const { setCuentaFilter,clearErrorCuenta, setCuentaEditing,setErrorCuenta,  setContratadosCuenta,
    cuentaCancelEdit,refreshCuenta, setCuentaRecords, setCuentaLoading, openModalCuentas, closeModalCuentas,
    selectServicio, unselectServicio, setServicioAEditar, unsetServicioAEditar, setEncontrar, clearEncontrar,
} = cuentasSlice.actions

export default cuentasSlice.reducer