import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addAllPartidas, rmAllPartidas} from "../../../store/slice/partidasSlice";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {useMediaQuery} from "@mui/material";

const CheckAllPartidas = ( {moneda = "MXN"} ) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const {  transaccion:{documentosPorPagar} } = useSelector(state => state.cobranza);
    const {  checkedAll } = useSelector(state => state.partidas);
    const dispatch = useDispatch();
    const [state,setState] = useState(false);

    useEffect(()=>{
        const stateMoneda = checkedAll[moneda];
        if(stateMoneda !== state) setState( stateMoneda );
    },[checkedAll])

    const handleState = ()=>{
        setState(!state);
        if (!state) {
            dispatch( addAllPartidas( {
                partidas : documentosPorPagar.listado[moneda],
                moneda: moneda.toLowerCase(),
                monedaState:!state
            } ))
        }else {
            dispatch( rmAllPartidas({moneda}) )
        }
    }

    return <Box display={"inline-flex"} paddingLeft={1} alignItems={"center"} justifyContent={"center"} color={isMobile ? "#666666":"#fff" }  >
        {
            state ?
                <i className="fa-regular fa-square-check icon-size-black-1-5" onClick={ (event)=> handleState() }></i>:
                <i className="fa-regular fa-square icon-size-black-1-5" onClick={ (event)=> handleState() }></i>
        }
        </Box>;
}

CheckAllPartidas.propTypes = {
    moneda:PropTypes.string,//TODO::deberia ser oneOfType["",""]
};

export default CheckAllPartidas;