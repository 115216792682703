
import Operacion from './Operacion';
class CentroDeCosto extends Operacion{

    constructor(){
        super();
    }

    async plazas (cdcs_uuid){
        const url = `panel/cdcs/${cdcs_uuid}/plazas`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    static cambiarEstado(cdcs_uuid, value, password){
        return new Promise((resolve, fallback)=>{
            let url = `${process.env.REACT_APP_API_CORE_BANCA}panel/cdcs/${cdcs_uuid}/estatus/${value}`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: JSON.stringify({password}) 
            };
            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;

        });
    }

    async store(data){
        const url = `panel/cdcs/registrar`;
        const response = await  this.transaction('POST',url, JSON.stringify(data) , true );
        return response;
    }

    async mostrar(uuid){
        let url = `panel/cdcs/${uuid}/mostrar`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    async update(data, uuid){
        const url = `panel/cdcs/${uuid}/actualizar`;
        const response = await  this.transaction('PUT',url, JSON.stringify(data) , true );
        return response;
    }
    
    async listadoEnPerspectiva(texto){
        const url = `panel/perspectiva/cdcs/listar?texto=${texto}`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    async cambiarEstatus(cdcs_uuid, value, password){
        const url = `panel/cdcs/${cdcs_uuid}/estatus/${value}`;
        const response = await  this.transaction('POST',url, JSON.stringify({password}) , true );
        return response;
    }

    async updatePolitica(data){
        const url = `panel/cdcs/actualizarpolitica`;
        const response = await  this.transaction('POST',url, JSON.stringify(data) , true );
        return response;
    }

    async getCliente(){
        const url = `panel/cdcs/siguiente`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    async getEstadosDeCuentaDisponibles(uuid){
        const url = `panel/estadocuenta/${uuid}/disponibles`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    async getCentrosDeCostoPorPersona(){
        const url = `panel/cdcs/xpersona`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    async estadoDeCuentaData(uuid, periodo){
        const url = `${`panel/estadocuenta/${uuid}/${periodo}/vista`}`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    async estadoDeCuentaDataPersonalizado(uuid, data){
        const url = `${`panel/estadocuenta/${uuid}/personalizado`}`;
        const response = await  this.transaction('POST',url,JSON.stringify( data) , true );
        return response;
    }

    async getEstadoDeCuenta ( uuid, periodo ){

        const uri = `${`panel/estadocuenta/${uuid}/${periodo}/descargar`}`;
        const headers = {
            'Accept': 'application/pdf'
        }
        const date = new Date();
        const reporte = `reporte${ date.getFullYear() }${ date.getMonth() }${ date.getDay() } ${ date.getHours() }.pdf`;
        await  this.blobTransaction('GET',uri, null , true, headers )
            .then(response => (response.blob()))
            .then((blob)=>{
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    reporte
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
    }

    async exportarEstadocuentaXls ( uuid, periodo ){
        const uri = `${`panel/estadocuenta/${uuid}/${periodo}/excel`}`;
        const headers = {
            'Accept': 'application/vnd.ms-excel'
        }
        const date = new Date();
        const reporte = `Saldos y Movimientos ${ date.getFullYear() }${ date.getMonth() }${ date.getDay() } ${ date.getHours() }.xlsx`;
        await  this.blobTransaction('GET',uri, null , true, headers )
            .then(response => (response.blob()))
            .then((blob)=>{
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    reporte
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })



    }

    async getEstadoDeCuentaPersonalizado ( uuid, data, accept ){

        const uri = `${`panel/estadocuenta/${uuid}/personalizado`}`;
        const headers = {
            'Accept': accept
        }
        const extension = (accept === "application/pdf") ? "pdf":"xlsx";
        const date = new Date();
        const reporte = `estado de cuenta ${ date.getFullYear() }${ date.getMonth() }${ date.getDay() } ${ date.getHours() }.${extension}`;
        await  this.blobTransaction('POST',uri, JSON.stringify(data) , true, headers )
            .then(response => (response.blob()))
            .then((blob)=>{

                switch (extension){
                    case 'pdf':
                    case 'xlsx':
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(
                            new Blob([blob]),
                        );
                        link.setAttribute(
                            'download',
                            reporte
                        );

                        document.body.appendChild(link);

                        link.click();

                        link.parentNode.removeChild(link);
                        break;
                }
            })
    }


    async imprimirComprobante ( uuid, tipo ='retiro', accept ='pdf' ){

        let headers = {
            'Accept': 'application/pdf'
        }
        switch (accept){
            case 'pdf':
                headers['Accept'] = 'application/pdf';
                break;
            case 'zip':
                headers['Accept'] = 'application/zip';
                break;
        }
        const uri = `${`panel/estadocuenta/${uuid}/${tipo}/comprobante`}`;
        let tipoArchivoDevuelto = '';
        await  this.blobTransaction('GET',uri,null , true, headers )
            .then(response=>{
                if(response.status === 404){
                    throw new Error("Nos encontramos en el proceso de descarga del CEP, intentelo más tarde.");
                }else{
                    const tipoRespuesta = response.headers.get('content-type');
                    switch (tipoRespuesta){
                        case 'application/pdf':
                            tipoArchivoDevuelto = 'pdf';
                            break;
                        case 'application/zip':
                            tipoArchivoDevuelto = 'zip';
                            break;
                    }
                    return response;
                }
            })
            .then(response => (response.blob()))
            .then((blob)=>{
                switch (tipoArchivoDevuelto){
                    case 'pdf':
                    case 'zip':

                        const date = new Date();
                        const reporte = `comprobante ${ date.getFullYear() }${ date.getMonth() }${ date.getDay() } ${ date.getHours() }.${tipoArchivoDevuelto}`;
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(
                            new Blob([blob]),
                        );
                        link.setAttribute(
                            'download',
                            reporte
                        );

                        document.body.appendChild(link);

                        link.click();

                        link.parentNode.removeChild(link);
                        break;
                }

            }).catch(error=>{
                alert(error);
            })

    }



}

export default CentroDeCosto;