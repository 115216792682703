import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
// @mui material components
import Grid from "@mui/material/Grid";
import SoftBadge from "components/SoftBadge/index.js";
//
import AutorizadoresRequest from 'Repository/Banca/Autorizador';
import Autorizador from 'Repository/Banca/Autorizador';

import Estatus from '../components/Estatus';
import ButtonDelete from './ButtonDelete';
import AddAutorizador from './AddAutorizador';
import IconClose from "@mui/icons-material/Close"
import AlphaTable from "../../../cuentas/components/AlphaTable";
import {v4} from "uuid";
import IconAddTask from "@mui/icons-material/AddTask";

function Autorizadores({ cuenta_uuid , onCloseViewDetalle, onAddAutorizador, refreshDropDown, onCloseComponent }) {

    const [txtBuscar,setTxtBuscar ] = useState('');
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]); 

    const autorizadorx = new AutorizadoresRequest();

    const handleRefresh = ()=> listar();

    //#region events

    const onSelectedCuentaCurrent = (event, autorizador_uuid)=>{
     
    }

    const handleOnDeleteItem = ( autorizador_uuid)=> {
       
        listar();
    }

    //Agregaron otro registro debemos actualiazar  listado
    const onAddAutorizadorHandle = ()=>{
        listar();
        onAddAutorizador();
    }

    const onRefreshEventX = ()=>{
        refreshDropDown();
    }

    //#endregion

    //#region methods
    function listar(textox = ''){
        autorizadorx.getAllByCuenta(cuenta_uuid).then(response=>{

            setColumns(
                [
                    { name: "Autorizador", width: "10%" },
                    { name: "Celular", width: "5%",  },
                    { name: "Correo", width: "10%" },
                    { name: "Empresa", width: "10%" },
                    { name: "OTP", width: "5%" },
                    { name: "Estatus", width: "5%" }
                ]

            );
            const items = (response.response.data).map(element => {
                return (
                    <tr key={ v4() }>
                        <td > { element.autorizador }  </td>
                        <td>{ element.cel }</td>
                        <td>{ element.email }</td>
                        <td>
                            { element.referencia }
                        </td>
                        <td align={"center"}>
                            {
                                (element.otpSecret && element.otpLastUse) && (
                                    <SoftBadge variant={  (element.otpSecret && element.otpLastUse) ? "gradient": "contained"  } badgeContent={  (element.otpSecret && element.otpLastUse) ? " otp ": "  "  } size="lg" container />
                                )
                            }
                        </td>
                        <td align={"center"}>{
                            ( element.autorizador_uuid !== element.owner_uuid && <Estatus key={element.uuid} item={element}
                                                                                          objeto={ Autorizador } estado ={ (element.estatus_autorizador === null) } refresh={ onRefreshEventX } />  )
                        }
                        </td>
                    </tr>
                );
            })
          setRows(items);
        });
      }
    //#endregion

    
    useEffect(()=>{
        listar();
    },[]);

    return(
        <>
            <SoftBox  sx={{ border:"5px solid #f7f7f7", }}>
                <Card sx={{ padding:1 }}>
                    <Grid container >
                        <Grid item xs={8} md={4} xl={8}>
                            <SoftBox   alignItems="center" >
                                <label className={"IGenericLabel-1-5f"} > Usuarios </label>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={4} md={4} xl={2} pt={2}>
                            
                        </Grid>
                        <Grid item xs={4} md={3} xl={1} pt={1} sx={{ textAlign:"right" }} >

                        </Grid>
                    </Grid>

                    <AddAutorizador cuenta_uuid={ cuenta_uuid } onAdd = { () => onAddAutorizadorHandle() } />

                    <SoftBox
                        sx={{
                        "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                            },
                        },
                        }}
                        >
                        <AlphaTable records={ rows }  headers={ columns } />

                    </SoftBox>
                </Card>
            </SoftBox>
        </>
    )
    
}

Autorizadores.propTypes = {
    cuenta_uuid: PropTypes.string.isRequired,
    onCloseViewDetalle: PropTypes.func,
    onAddAutorizador : PropTypes.func,
    refreshDropDown: PropTypes.func,
    onCloseComponent: PropTypes.func
};

export default Autorizadores;