
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import SoftBox from "../../../components/SoftBox";
import {useForm} from "../../../customHooks/useForm";
import {useMediaQuery} from "@mui/material";

const TxtEncontrar = ({  eventSearch, textSearch }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();
    const { partidasSelected } = useSelector( state => state.partidas );

    const { encontrar, onInputChange, isFormValid } = useForm( { encontrar:textSearch }, {} );

    const handleEncontrar = (event)=> {
        if (event.key === "Enter") {
            dispatch(eventSearch({
                encontrar,"partidasSelected":partidasSelected
            }))
        }
    }

    /**
     * @comment Esta es una funcion paleativa ya que en react no tenemos el evento search del input
     * @param event
     */
    const onInputChangeLocal = (event)=>{
        onInputChange(event);
    }

    return (
        <SoftBox sx={{ width:isMobile ?"95%":"60%",marginLeft:1, marginRight:1 }}  >
            <input type={"search"} className={"ITxtEncontrar"} name={"encontrar"} value = { encontrar } maxLength={"50"} placeholder={"Encontrar"}
                   onChange={ onInputChangeLocal } onKeyDown={  handleEncontrar }
                 />
        </SoftBox>
    );
}
TxtEncontrar.propTypes = {
    eventSearch: PropTypes.any,
    textSearch:PropTypes.string,
}

export default TxtEncontrar;