import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import SoftBadge from "../components/SoftBadge";
import closePopupImg from "../assets/alpha-theme/images/cerrarPopup.png";
import Grid from "@mui/material/Grid";
import * as React from "react";
import {useState} from "react";

export const useAlphaAlertModal = () => {
    const [openAlert, setOpenAlert] = useState(false);
    const [messageAlert,setMessageAlert] = useState("");
    const [sizeAlert,setSizeAlert] = useState("sm");//xl,md,lg,sm, xs

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    const alphaAlert =  <>
        <Dialog open={ true } onClose={handleCloseAlert} fullWidth={true}  maxWidth={ sizeAlert }
                PaperProps={{ style:{ backgroundColor:"#3d3d3d", borderRadius:"0.6rem", color:"white", overflowY:"unset" } }}
        >

            <DialogTitle >
                <div className={"IAlertTitle"} >
                    { "Notificaciones" }
                </div>
                <SoftBadge
                    onClick={handleCloseAlert}
                    sx={{
                        position: 'absolute',
                        right: -13,
                        top: -16,
                        color: "#fff",
                        backgroundColor: "#DB6161",
                        fontSize:"0.8rem",
                        border:"3px solid white",
                        borderRadius: "50%",
                        padding:"3px",
                        cursor:"pointer",
                    }}
                >
                    <img src={ closePopupImg } alt={"Img Cerrar PopUp"} width={ "20px" } height={"20px"}  />
                </SoftBadge>

            </DialogTitle>
            <DialogContent>

                <Grid container  >
                    <div className={"IAlertMessage"}>
                        { messageAlert }
                    </div>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={7} xl={7}  >
                    </Grid>
                    <Grid item xs={12} md={5} xl={5}  >

                        <button className={"IBtnAlertSuccess"} type={"submit"} onClick={ handleCloseAlert }>
                            <i className="fa-solid fa-circle-check"></i> <span>Aceptar</span>
                        </button>

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </>

    return {
        alphaAlert, openAlert, setOpenAlert, setMessageAlert, setSizeAlert
    }

}