import {createSlice} from "@reduxjs/toolkit";

/**
 *
 * @type {{partidasSelected: *[], checkedAll: {mxn: boolean, eur: boolean, usd: boolean}}}
 */
const initialState = {
    partidasSelected: [],
    checkedAll: {
        mxn:false,
        usd:false,
        eur:false,
    }
}

export const partidasSlice = createSlice({
    name: 'partidas',
    initialState,
    reducers: {
        addPartida:(state, action) =>{
            state.partidasSelected = [...state.partidasSelected,action.payload];
        },
        rmPartida:(state, action) =>{
            state.partidasSelected =  state.partidasSelected.filter(element => element.folioOrigen !== action.payload.folioOrigen );
            const mony = action.payload.moneda;
            state.checkedAll = {...state.checkedAll,[action.payload.moneda]:false  };
        },
        addAllPartidas:(state, action) =>{
            state.partidasSelected =  [...state.partidasSelected,...action.payload.partidas];
        },
        rmAllPartidas:(state, action) =>{
            state.partidasSelected =  state.partidasSelected.filter(element => element.moneda !== action.payload.moneda );
        },
    }
})

// Action creators are generated for each case reducer function
export const { addPartida,rmPartida, addAllPartidas, rmAllPartidas } = partidasSlice.actions

export default partidasSlice.reducer