
import Operacion from './Operacion';
import Cuenta from "./Cuenta";
class Autorizador extends Operacion {
    constructor() {
        super();
    }

    /**
     * @comment Listamos los autorizadores asociados a una cuenta
     * @param cuenta_uuid
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async listar(cuenta_uuid){
        const url = `panel/autorizadores/${ cuenta_uuid }`;
        return await this.transaction('GET',url, null , true );
    }

    /**
     * @comment Listamos los autorizadores no asociados a una cuenta
     * @param cuenta_uuid
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async porAsociar(cuenta_uuid){
        const url = `panel/autorizadores/${ cuenta_uuid }/porasociar`;
        return await this.transaction('GET',url, null , true );
    }

    /**
     * @comment Asignar autorizador a una cuenta
     * @param data
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async store(data){
        const url = `panel/autorizadores`;
        return await this.transaction('POST',url, JSON.stringify(data) , true );
    }

    /**
     * @comment Mandamos un cambio de estado
     * @param data
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async cambiarEstado(data){
        const url = `panel/autorizadores/${data.uuid}/${data.status}`;
        return await this.transaction('DELETE',url, null , true );
    }

    /**
     * @comment Guardar la configuración de notificaciones del autorizador
     * @param data
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async notificaciones(data){
        const url = `panel/autorizadores/notificaciones`;
        return await this.transaction('POST',url, JSON.stringify(data) , true );
    }

    /**
     * @comment Centro de costos que tiene el usuario en sesión en la plataforma especificada
     * @param plataforma
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async cdcsAutorizados(plataforma){
        const url = `panel/cdcsautorizados/${ plataforma }`;
        return await this.transaction('GET',url, null , true );
    }

    /**
     * @comment traemos el estado de cuenta consultado con los parametros especificados
     * @param data
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async estadoCuenta(data){
        const url = `panel/estadodecuenta?${ new URLSearchParams(data) }`;
        return await this.transaction('GET',url,  null  , true );
    }

    async exportEstadoCuentaToPdf ( data = {} ){

        const url = `panel/estadodecuenta/exportar?${ new URLSearchParams(data) }`;
        const headers = {
            'Accept': 'application/pdf'
        }
        const date = new Date();
        const reporte = `estado-de-cuenta-${data.plataforma}-${ date.getFullYear() }${ date.getMonth() }${ date.getDay() } ${ date.getHours() }.pdf`;
        await  this.blobTransaction('GET',url, null , true, headers )
            .then(response => (response.blob()))
            .then((blob)=>{
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    reporte
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
    }

    async exportEstadoCuentaToExcel ( data ){
        const url = `panel/estadodecuenta/exportar?${ new URLSearchParams(data) }`;
        const headers = {
            'Accept': 'application/vnd.ms-excel'
        }
        const date = new Date();
        const reporte = `estado-de-cuenta-${data.plataforma}-${ date.getFullYear() }${ date.getMonth() }${ date.getDay() } ${ date.getHours() }.xlsx`;
        await  this.blobTransaction('GET',url, null , true, headers )
            .then(response => (response.blob()))
            .then((blob)=>{
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    reporte
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
    }

}

export default Autorizador;