

const menuData = [
    {
        key: "item0022",
        text: "Ordenes de dispersión",
        percentage : { color: "success", text:"" },
        icon : { color: "info", component: "paid" },
        componentUrl: "/reportes/ordenes-de-dispersion",
        bgColor: "color6V",
        direction: "right"
    }
   
];

export default menuData;