import PropTypes from "prop-types";
import * as React from "react";
import { useState} from "react";
import ServicioContratado from "../../../Repository/ServicioContratado";
import AutorizadorPlataforma from "../../../Repository/Autorizador";
import {useAlphaAlertModal} from "../../../customHooks/useAlphaAlertModal";

/**
 * @commeet Si estado == 0 (Activo), 1 esta inactivo
 * @param cuentax
 * @returns {JSX.Element}
 * @constructor
 */
const StatusAutorizador = ( {autorizador} ) => {
    const authRepo = new AutorizadorPlataforma
    const [estado, setEstado] = useState( autorizador.disabled );
    const {alphaAlert, openAlert, setOpenAlert, setMessageAlert} = useAlphaAlertModal();

    /**
     * @comment vamos a cambiar el estado de la cuenta por lo que si:
     * Vamos a desactivar: Requerimos que opcionalmenre puedan colocar observaciones para que recuerden porque dieron de baja el servicio
     * Vamos a activar: En este caso no importa que nos pongan observaciones de hecho las vamos a borrar
     * @param event
     */
    const changeState = (event)=> {
        setEstado(!estado);
        cambiarEstado();
    }

    const cambiarEstado = async () => {
        const result = await authRepo.cambiarEstado({
            uuid:autorizador.uuid,
            status:Number(!estado),
        });
        if(result.status === 'ok')
        {
            setMessageAlert("Estado actualizado con éxito.");
            setOpenAlert(true);
        }else{
            setMessageAlert(result.response);
            setOpenAlert(true);
        }
    }

    return (
        <div className={ "area-boton" }>
            <button className={ estado === false ? "btn-alpha-danger pad-element":"btn-alpha-success-2 " } onClick={ (event) => changeState(event) } title={ "Estado del autorizador" } >{ (estado === false ? "Inactivo": "Activo") }</button>
            {
                openAlert && alphaAlert
            }
        </div>
    );
}

StatusAutorizador.propTypes = {
    autorizador: PropTypes.object.isRequired
}

export default StatusAutorizador;