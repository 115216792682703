
import PropTypes from "prop-types";
import {Grid, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {v4} from "uuid";
import Card from "@mui/material/Card";
import SoftBox from "../../../components/SoftBox";
import {useSelector} from "react-redux";
import {useNumberFormat} from "../../../customHooks/useNumberFormat";

const Resumen = ({  monto = 0 }) => {
    const { numberFormat } = useNumberFormat();
    const isMobile = useMediaQuery('(max-width:600px)');
    const {  transaccion } = useSelector(state => state.cobranza);

    const forMobile = <>
        <Box display={ "block"} padding={1}  >
            <SoftBox key={v4() } sx={{ textAlign:"center" }} >
                <label className={"lbl-etiqueta-desk"} > { transaccion.concepto } </label>
            </SoftBox>
            {
                transaccion.monto === transaccion.montoReferencia &&
                <SoftBox key={v4()} sx={{ textAlign:"center" }} >
                    <label className={"lbl-etiqueta-desk"} >De contado:</label><label className={"IGenericLabel-1-5f"}> { numberFormat(monto,2)  } MXN</label>
                </SoftBox>
            }
        </Box>
    </>

    const forDesktop = <>
        <Box display={ isMobile?"block": "inline-flex"} padding={1}  >
            <SoftBox key={v4()} sx={{ width:("60%") }} >
                <label className={"lbl-etiqueta-desk"} >Concepto: { transaccion.concepto } </label>
            </SoftBox>
            {
                transaccion.monto === transaccion.montoReferencia &&
                <SoftBox key={v4()} sx={{ width:("40%"),textAlign:"right" }} >
                    <label className={"lbl-etiqueta-desk"} >Pago de contado:</label><label className={"IGenericLabel-1-5f"}> { numberFormat(monto,2)  } MXN</label>
                </SoftBox>
            }
        </Box>
    </>

    return <Grid item xs={12} md={ 12 } xl={ 12 } marginBottom={1} pl={1}  key={ v4() } >

        <Card sx={{ height:"99%",backgroundColor:"#E6E6E6",marginRight:1,borderRadius: isMobile ?3:1 }}  >
            {
                isMobile ? forMobile: forDesktop
            }
        </Card>
    </Grid>
}
Resumen.propTypes = {
    monto: PropTypes.number,
}

export default Resumen;