import { createSlice } from '@reduxjs/toolkit'
import {Grid} from "@mui/material";
import {v4} from "uuid";
import * as React from "react";

const initialState = {
    uuid:null,
    status:"LOADING",
    statusMessage:"",
    transaccion: {
        uuid:"",
        concepto:"",
        monto:0,
        montoReferencia: null,
        //generarReferencia:false,
        plazo:0,
        cardNumber:"",cvv2:"",firstName:"", lastName:"",phone:"",email:"", city:"", postalCode:"",state:"",street1:"",expiration:"",fullName:"",
        //cardNumber:"4000000000000002",cvv2:"345",firstName:"Carlos", lastName:"Peña Flores",phone:"",email:"accept@netpay.com.mx", city:"Mineral de reforma", postalCode:"",state:"Hidalgo",street1:"Rio balsas",expiration:"12/25",fullName:"Carlos Peña Moralez",
        netpay:/*null*/{
            msi: [],
            metodos: []
        },
        paypal:null,
        device:{
            paypal:null,
            netpay:null,
        },
        documentosPorPagar:{},
    },
    cuenta : {
        rs:"",//"Empresa Demostrativa SA de CV",
        rfc:"",
        direccion:"",//"Av. Anillo Periférico #1629 Col. Rincón de San Jerónimo. Mty, N.L, Mex, C.P.64637",
        id:"",
    },
    generarReferenciaNetPay:false,
    plazoVenta:0,
    referencia:"",
    metodoPago:"",
    vencimiento: "",
    partidasAPagar: [],
    basicDataColect:true,//Indica si vamos a recolectar todos los datos del cliente o solo los datos basicos
    partidasFiltro:[],
    liquidado: null, /*{
        nombreCliente:"JOSE ALBERTO SAN JUAN SAN AGUSTIN",
        monto:"123456.70",
        fechaOperacion:"2023-04-19 18:49:11",
        transaccionId:"a31abc0c-134e-418b-b336-170896357ddd",
        folio:"1-3434-34343-90",
        tipoTarjeta:"VISA DEBITO",
        ultimos4Digitos:"1091",
        nombreBanco:"ABC CAPITAL",
        plataforma:"paypal",
        orden:"a31abc0c-134e-418b-b336-170896357ddd",
    }
    */
}

export const cobranzaSlice = createSlice({
    name: 'cobranza',
    initialState,
    reducers: {
        setTransaccion: (state, action) => {
            state.transaccion.uuid = action.payload.transaccion.uuid;
            state.transaccion.monto = action.payload.transaccion.monto;
            state.transaccion.montoReferencia = action.payload.transaccion.montoReferencia;
            state.transaccion.concepto = action.payload.transaccion.concepto;
            state.transaccion.generarReferencia = action.payload.transaccion.generarReferencia;
            state.cuenta = action.payload.cuenta;
            state.transaccion.netpay.msi = action.payload.transaccion.msi;
            state.transaccion.netpay.metodos = action.payload.transaccion.netpay.metodos;
            state.uuid = action.payload.transaccion.uuid;
            state.status = action.payload.transaccion.status;
            state.statusMessage = "";
            state.transaccion.device = action.payload.device;
            state.transaccion.paypal = action.payload.transaccion.paypal;
            state.transaccion.documentosPorPagar = action.payload.transaccion.partidas;
            state.basicDataColect = action.payload.basicDataColect;
            state.partidasFiltro = action.payload.transaccion.partidas.listado;
        },
        updateMonto :(state, action) => {
            state.transaccion.monto = action.payload.monto;
            state.plazoVenta = action.payload.msi;
        },
        updateEstatus:(state, action) => {
            state.status = action.payload.status;
            state.statusMessage = action.payload.statusMessage;
        },
        setError:(state, action) => {
            state.status = "error";
            state.statusMessage = action.payload;
        },
        setReferencia:(state, action) => {
            state.referencia  = action.payload.referencia;
            state.vencimiento = action.payload.vencimiento;
            state.cliente = action.payload.cliente;
            state.transaccion.monto = action.payload.monto; //Aqui estamos actualizando directamente el monto por que esta operación se ha convertido en solo lectura.
        },
        setMetodoPago:(state, action) => {
            state.metodoPago  = action.payload;
        },
        setCuenta:(state, action) => {
            state.cuenta = action.payload;
        },
        setLiquidado:(state, action) => {
            state.status = action.payload.status;
            state.statusMessage = "CHECKOUT_LINK";
            state.liquidado = action.payload;
        },
        actualizarConfiguracionPago: (state, action) =>{
            state.transaccion.netpay.msi = action.payload.msi;
            state.transaccion.monto = action.payload.monto;
            state.partidasAPagar = action.payload.partidasAPagar;
            state.transaccion.documentosPorPagar.cantidadDocumentos = 0;
            if(action.payload.montoPaypal > 0)
            {
                state.transaccion.paypal.monto = action.payload.montoPaypal;
            }
            if(action.payload.montoReferencia > 0)
            {
                state.transaccion.montoReferencia = action.payload.montoReferencia;
            }
        },
        filtroPartidas:(state, action) =>{
            state.transaccion.documentosPorPagar.monedas.forEach(moneda =>{
                const partidasPorMoneda = state.transaccion.documentosPorPagar.listado[moneda];
                const tmpListado = [];
                partidasPorMoneda.forEach(partida =>{
                    if (partida.folio.toLowerCase().includes( action.payload.encontrar.toLowerCase()) || partida.monto.toString().includes( action.payload.encontrar.toLowerCase()) )
                    {
                        const folioMonto = partida.folio.toLowerCase() +""+partida.monto+""+partida.diasVencimiento;
                        const isChecked = action.payload.partidasSelected.find(element => (element.folio.toLowerCase() +""+element.monto+""+element.diasVencimiento).includes(folioMonto)  );
                        tmpListado.push( {
                            ...partida,
                            status:isChecked !== undefined,
                        });
                    }
                })
                state.partidasFiltro[moneda] = tmpListado;
            });
        }
    }
})

// Action creators are generated for each case reducer function
export const { setTransaccion, updateMonto, updateEstatus, setError, setReferencia, setMetodoPago, setCuenta,setLiquidado,actualizarConfiguracionPago,filtroPartidas } = cobranzaSlice.actions

export default cobranzaSlice.reducer