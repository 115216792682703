import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import * as React from "react";
import { cambiarEstadoDeServicioDirecto} from "../../../store/thunk/cuentas";
import { useState} from "react";
import {selectServicio} from "../../../store/slice/cuentasSlice";
import {openModal} from "../../../store/slice/alertSlice";

const defaultDevice = {
    cuenta_uuid: null,
    uuid:null,
    disabled: true,
    observaciones: ""
}

/**
 * @commeet Si estado == 0 (Activo), 1 esta inactivo
 * @param cuentax
 * @returns {JSX.Element}
 * @constructor
 */
const BtnCambioEstadoTpv = ({ device = defaultDevice }) => {

    const [estado, setEstado] = useState( device.disabled ? 0:1 );
    const dispatch = useDispatch();

    /**
     * @comment vamos a cambiar el estado de la cuenta por lo que si:
     * Vamos a desactivar: Requerimos que opcionalmenre puedan colocar observaciones para que recuerden porque dieron de baja el servicio
     * Vamos a activar: En este caso no importa que nos pongan observaciones de hecho las vamos a borrar
     * @param event
     */
    const changeState = (event)=> {
        const newState  = estado === 0 ? 1: 0;
        const data      = { uuid:device.uuid, status:newState, observaciones:null, cuenta_uuid: device.cuenta_uuid };
        if(estado  === 1){
            dispatch( openModal() )
            dispatch( selectServicio( data ) );
            return;
        }
        dispatch( cambiarEstadoDeServicioDirecto(data) );
    }

    return (
        <div className={ "area-boton" }>
            <button className={ estado === 0 ? "btn-alpha-danger pad-element":"btn-alpha-success-2 " } onClick={ (event) => changeState(event) } title={ device.observaciones ??'' } >{ (estado == 0 ? "Inactivo": "Activo") }</button>
        </div>
    );
}

BtnCambioEstadoTpv.propTypes = {
    device: PropTypes.object.isRequired
}

export default BtnCambioEstadoTpv;