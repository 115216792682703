
import PropTypes from "prop-types";
import SoftBox from "../../../../components/SoftBox";
import {useForm} from "../../../../customHooks/useForm";

const initialForm = {
    encontrar:''
}

const TxtEncontrar = ({  fnOnSearch }) => {

    const { encontrar, onInputChange, isFormValid } = useForm( initialForm, {} );

    const handleEncontrar = (event)=> {
        if (event.key === "Enter") {
            console.log(encontrar);
            fnOnSearch(event , encontrar);
        }
    }

    return (
        <SoftBox sx={{ textAlign:"right" }} >
            <input type={"search"} className={"ITxtEncontrar"} name={"encontrar"} value = { encontrar } maxLength={"50"} placeholder={"Encontrar"}
                   onChange={ onInputChange } onKeyDown={  handleEncontrar }
            />
        </SoftBox>
    );
}
TxtEncontrar.propTypes = {
    fnOnSearch: PropTypes.func.isRequired,
}

export default TxtEncontrar;