
import Operacion from './Operacion';
class ServicioContratado extends Operacion {
    constructor() {
        super();
    }

    /**
     * @comment Listamos los servicios contratados
     * @param cuenta_uuid
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async listar(cuenta_uuid) {
        const url = `panel/cuentas/${cuenta_uuid}/servicioscontratados`;
        return await this.transaction('GET',url, null , true );
    }

    /**
     * @comment Registrar un servicio
     * @param data
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async store(data){
        const url = `panel/contratarservicio`;
        return await this.transaction('POST',url, JSON.stringify(data) , true );
    }

    /**
     * @comment Actualizar un servicio
     * @param uuid
     * @param data
     * @returns {Promise<*|{response: {text: *}, status: string}>}
     */
    async update(uuid, data){
        const url = `panel/contratarservicio/${ uuid }`;
        return await this.transaction('PUT',url, JSON.stringify(data) , true );
    }

    /**
     * @comment Mandamos un cambio de estado del servicio
     * @param data
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async cambiarEstado(data){
        const url = `panel/contratarservicio/${data.uuid}/${data.status}`;
        return await this.transaction('DELETE',url, JSON.stringify({observaciones:data.observaciones}) , true );
    }

    /**
     * @comment Mostramos un dispositivo contratado
     * @param uuid
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async mostrar(uuid) {
        const url = `panel/contratarservicio/${uuid}`;
        return await this.transaction('GET',url, null , true );
    }

}

export default ServicioContratado;