/**
 =========================================================
 * Servicios de cobranza castelec
 =========================================================
 */
// @mui material components
import {useDispatch, useSelector} from "react-redux";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import {useEffect, useState} from "react";
import {todosLosServicios} from "../../../store/thunk/servicios";
import {closeModalFromCatalogo, openModalFromCatalogo} from "../../../store/slice/alertSlice";
import PropTypes from "prop-types";
import CuentaRepo from "../../../Repository/Cuenta";
import {v4} from "uuid";
import PlataformasV2 from "./PlataformasV2";
import WebModalDinamic from "./WebModalDinamic";
import DetalleV2 from "./DetalleV2";
import Grid from "@mui/material/Grid";
import {cuentaCancelEdit} from "../../../store/slice/cuentasSlice";
import {mostrarCuenta} from "../../../store/thunk/cuentas";
import Filtrov2 from "../../cuentas/components/Filtrov2";
import CdcPlataforma from "./CdcPlataforma";

function ServicioPaypal({ cuentaUuidSelected }) {
    const cuentaRepository = new CuentaRepo();
    const dispatch = useDispatch();
    const [ cuentaPlataforma, setCuentaPlataforma ] = useState([]);
    const [ openCdcWindow, setOpenCdcWindow ] = useState(false);

    useEffect(()=>{
        onLoadComponent();
    },[]);

    const onLoadComponent = async () =>{
        const {status = "", response = []} = await cuentaRepository.listarv2(cuentaUuidSelected, 'paypal');
        if(status === 'ok' && response.length !== cuentaPlataforma.length)
        {
            setCuentaPlataforma( response )
        }
    }

    /** Todos los servicios listados */
    // useEffect(()=>{ dispatch( todosLosServicios() ) }, [])

    const handleCloseModalCuenta = ()=>{
        dispatch( closeModalFromCatalogo({
            cuentas:{
                open:false,
            }
        }) )
        setOpenCdcWindow(false);
        onLoadComponent();
    }

    const onClickAdd = () => {
        dispatch( cuentaCancelEdit() );
        dispatch( openModalFromCatalogo({
            cuentas:{
                open:true,
            }
        }) )
        setOpenCdcWindow(true);
    }

    return (
        <SoftBox sx={{ border:"5px solid #f7f7f7", }}>
            <SoftBox p={1}>
                <SoftBox pt={1}>

                    {
                        openCdcWindow && <WebModalDinamic title={"Información del centro de costos"}
                                                                     formComponent={ <DetalleV2 plataforma={ "paypal" } auth_cuenta_uuid={ cuentaUuidSelected }  handleClose={ handleCloseModalCuenta } /> }
                                                                     fnModaCloseAction={ handleCloseModalCuenta } modalSize={"lg"} />
                    }

                    <Grid container>
                        <Grid item sm={9} xl={9} md={9} >
                            <SoftBox sx={{  fontFamily:"TTCDemibold" }}>
                                Centros de Costos
                            </SoftBox>
                        </Grid>
                        <Grid item sm={3} xl={3} md={3} >
                            <button className={"btn-alpha-black pad-element"} type={"submit"} onClick={ onClickAdd }>
                                <i className="fa-solid fa-plus"></i> Agregar
                            </button>
                        </Grid>
                    </Grid>
                    <SoftBox sx={{ padding:1 }}>
                        {
                            cuentaPlataforma.map(cdc=>{
                                return <CdcPlataforma key={ v4() } auth_cuenta_uuid={cuentaUuidSelected} cdcInfo={ cdc } plataforma={"paypal"} />
                            })
                        }
                    </SoftBox>
                </SoftBox>
            </SoftBox>
        </SoftBox>
    );
}

ServicioPaypal.propTypes = {
    cuentaUuidSelected: PropTypes.string,
}

export default ServicioPaypal;