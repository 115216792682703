
import PropTypes from "prop-types";
import {Grid, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import logox from "../../../assets/alpha-theme/images/cards/cobranza3v2.png";
import Typography from "@mui/material/Typography";
import * as React from "react";

const SectionHeader = ({  cuenta = {} }) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return <>

        <Box sx={{ backgroundColor:"#4d4d4d",marginBottom:"5px",color:"#fff",borderRadius:2,margin:1 }} >
            <Grid container>
                <Grid item xs={12} md={6} xl={6}  textAlign={"left"}>
                    <Box className={"sec-imagen"}  >
                        <img className={"Img-PrintInvoice"} src={ logox } alt={"Cobranza Automatizada"} />
                    </Box>
                </Grid>
                {
                    !isMobile  &&
                    <Grid item xs={12} md={6} xl={6} pl={1} className={"sec-titulos"}>
                        <Box className={"sec-titulos-rs"}>
                            {cuenta.rs}
                        </Box>
                        <Box >
                            <Typography fontSize={14} >
                                {cuenta.direccion}
                            </Typography>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Box>
        {
            isMobile &&
            <Box className={"rs-mobile"}>
                {cuenta.rs}
            </Box>
        }
    </>
}
SectionHeader.propTypes = {
    cuenta: PropTypes.object,
}

export default SectionHeader;