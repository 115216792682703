import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "../../../../customHooks/useForm";
import {useEffect, useState} from "react";
import { actualizarServicio, registrarServicio} from "../../../../store/thunk/cuentas";
import Grid from "@mui/material/Grid";
import * as React from "react";
import PropTypes from "prop-types";
import {
    closeGeneralLoader,
    closeNewModal,
    openGeneralLoader,
    setAlertMessage
} from "../../../../store/slice/alertSlice";
import {setContratadosCuenta, unsetServicioAEditar} from "../../../../store/slice/cuentasSlice";
import ContratoService from "../../../../Repository/ServicioContratado";

const initialCredentials = {
    serialNumber:"",
    storeId:"",
    password:"",
}

const formValidation = {
    serialNumber: [(value)=> value.trim() !== "","Llave pública requerida."],
    storeId: [(value)=> value.trim() !== "","Llave secreta requerida."],
    password: [(value)=> value.trim() !== "","Contraseña requerida."],
}

const FrmTpv = ({cuenta_uuid, credenciales = initialCredentials, device = {}, fnCloseModal }) => {
    const contratoSrv = new ContratoService();
    const dispatch = useDispatch();
    const { servicios } = useSelector(state => state.servicios_plataformas);
    //const { uuid } = useSelector(state => state.cuentas);
    const frmResult = useForm( credenciales, formValidation );
    const {serialNumber,storeId, password, onInputChange, isFormValid, setFormState } = frmResult;
    const [tipoServicio, setTipoServicio ] = useState();

    useEffect(()=>{
        const checkout = servicios.find( element => element.servicioTipo === 'NETPAY_TPV' );
        setTipoServicio(checkout);
    },[servicios])

    useEffect(()=>{
        setFormState(credenciales);
    }, [credenciales])

    const onClickGuardar = ()=>{
        if(!isFormValid) return;
        const params = {
            ...credenciales,
            serialNumber,
            storeId,
            password,
        };
        if(device.uuid !== undefined){
            const data = {
                credenciales: params,
                cuenta_uuid
            }
            actualizar(device.uuid, data);
        }else{
            const newContrato = {
                credenciales: params,
                cuenta_uuid,
                servicioPlataforma_uuid:tipoServicio.uuid
            }
            dispatch( registrarServicio(newContrato) );
        }
    }

    const actualizar= async (uuid,data) => {
        const contratoSrv = new ContratoService();
        //dispatch( openGeneralLoader() );
        const result = await contratoSrv.update(uuid,data);
        if(result.status === 'ok') {
            fnCloseModal();
        }
    }

    const handleClose = ()=>{
        fnCloseModal();
    }

    return (
        <>
            <Grid container  p={1}>
                <Grid item xs={6} md={2} xl={2} textAlign={"right"} >
                    <label className={"IGenericLabel"}>Número de serie</label>
                </Grid>
                <Grid item xs={6} md={2} xl={2}>
                    <input type={"text"} className={"ITextbox"} name={"serialNumber"} value={ serialNumber } onChange={ onInputChange } />
                </Grid>
                <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                    <label className={"IGenericLabel"} >StoreID</label>
                </Grid>
                <Grid item xs={6} md={2} xl={2}>
                    <input type={"text"} className={"ITextbox"} name={"storeId"} value={ storeId } onChange={ onInputChange } />
                </Grid>
                <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                    <label className={"IGenericLabel"} >Contraseña</label>
                </Grid>
                <Grid item xs={6} md={2} xl={2}>
                    <input type={"text"} className={"ITextbox"} name={"password"} value={ password } onChange={ onInputChange } />
                </Grid>

            </Grid>

            <Grid container>
                <Grid item xs={6} md={3} xl={3} mt={2}    >

                </Grid>
                <Grid item xs={6} md={3} xl={3} mt={2}    >
                </Grid>
                <Grid item xs={6} md={3} xl={3} mt={2}    >
                    <button className={"btn-alpha-success-2 pad-element"} type={"submit"} onClick={ onClickGuardar }  >
                        <i className="fa-solid fa-circle-check"></i> Aceptar
                    </button>
                </Grid>
                <Grid item xs={6} md={3} xl={3} mt={2} pl={1}  >
                    <button className={"btn-alpha-danger pad-element"} type={"submit"} onClick={ handleClose }>
                        <i className="fa-solid fa-circle-xmark"></i> Cancelar
                    </button>
                </Grid>
            </Grid>
        </>
    )

}

FrmTpv.propTypes = {
    cuenta_uuid: PropTypes.string.isRequired,
    credenciales: PropTypes.object,
    device: PropTypes.object,
    fnCloseModal: PropTypes.func,
}

export default FrmTpv;