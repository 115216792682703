import PropTypes from "prop-types";
import SoftBox from "../../../../components/SoftBox";
import Grid from "@mui/material/Grid";
import moment from "moment";

export const SUSCRIPCION = ({ transaccion = {} }) => {

    const transaccionIdFormat = ()=>{
        if(transaccion.transaccionId){
            return "****"+transaccion.transaccionId.slice(-8)
        }
        return "";
    }

    const fechaOperacionFormat = () =>{
        if(transaccion.fechaOperacion){
            return moment(transaccion?.fechaOperacion).format("DD-MM-YYYY HH:mm");
        }
        return "";
    }

    return (
        <SoftBox sx={{ border:"5px solid #f7f7f7", padding:1 }} >
            <Grid container >
                <Grid item md={2} lg={2} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Fecha de Operación</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ fechaOperacionFormat() } readOnly />
                </Grid>

                <Grid item md={2} lg={2} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Código Autorización</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.codigoAutorizacion } readOnly />
                </Grid>

                <Grid item md={2} lg={2} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Correo</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.correoCliente } readOnly />
                </Grid>

                <Grid item md={2} lg={2} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Cliente</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.nombreCliente } readOnly />
                </Grid>

                <Grid item md={2} lg={2} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Banco</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.nombreBanco } readOnly />
                </Grid>

                <Grid item md={2} lg={2} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Plan</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.nombrePlan } readOnly />
                </Grid>

                <Grid item md={2} lg={2} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Folio</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.folio } readOnly />
                </Grid>

                <Grid item md={2} lg={2} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Transacción ID</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccionIdFormat() } readOnly />
                </Grid>

            </Grid>

        </SoftBox>
    )
}

SUSCRIPCION.propTypes = {
    transaccion: PropTypes.object,
};

export default SUSCRIPCION;