import React, {  useEffect, useRef, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";
import SoftAvatar from "components/SoftAvatar";

import IconSave from '@mui/icons-material/Save';
import IconCancel from '@mui/icons-material/Delete'

import Swal from 'sweetalert2';
import Personas from 'Repository/Banca/Personas';
import {useSelector} from "react-redux";

function cambiarEstado(item, value,/* callback */ callback){
    callback(item, value);
}

function Photo() {
    const { user  } = useSelector(state => state.auth);
    const [avatar, setAvatar] = useState(  );
    const [sourceAvatar, setSourceAvatar] = useState();
    const [fileName, setFileName] = useState('');

    const personax = new Personas();

    //#region events

    useEffect(()=>{
        load();
    },[]);

    const handleChangeAvatar = (event)=>{
        save();
    }

    const addAvatar = (event)=>{
        const input = event.target;
        const filename = input.value;
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if(!allowedExtensions.exec(filename)){
            Swal.fire({
                icon: 'error',
                title: 'Notificaciones',
                html: 'Solo se aceptan archivos en formato jpg,jpeg,png',
                showConfirmButton: true,
                confirmButtonText: 'Aceptar'
            });
            setFileName('');
            setSourceAvatar(null);
            return false;
        }
        else{
            if (input.files && input.files[0]) {
                setAvatar(input.files[0]);
                var reader = new FileReader();
                reader.onload = function (e) {
                    setSourceAvatar(e.target.result);
                }
                reader.readAsDataURL(input.files[0]);
                setFileName(input.value);
            }
        }
    }

    const handleRemoveAvatar = () =>{
        const passtext = Swal.fire({
            title: 'Confirmaciones',
            text: "¿Desea retirar la imagen?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
            }).then(result=>{
                if(result.isConfirmed){
                    console.log('Me confirmaron eliminar');
                    destroy();
                }
            });
    }
    //#endregion

    //#region methods
        const load = ()=>{
            const persona_uuid = user.uuid;
            personax.mostrar(persona_uuid).then(response=>{
                if(response.status=='ok'){
                    if(response.response.data.urlFoto != null || response.response.data.urlFoto != undefined  ){
                        const photo = response.response.data.urlFoto;
                        //const url = `https://banca-assets.s3.amazonaws.com/${photo}`;
                        setSourceAvatar(photo);
                        setAvatar(photo);
                    }
                }
            })
        }

        const save = async (event)=>{

            if(avatar != null || avatar != undefined){
                var bodyFormData = new FormData();
                bodyFormData.append(`photo`, avatar);

                Personas.subirFoto(bodyFormData).then(data=>{
                    const {response, status} = data;
                    if(status == 'ok'){
                        Swal.fire({
                            icon: 'success',
                            title: 'Notificaciones',
                            html: response.text,
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Notificaciones',
                            html: response.text,
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        });
                    }
                });

            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Notificaciones',
                    html: 'Agregue una imagen para poder continuar',
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar'
                });
            }
           
        }

        const destroy = ()=>{
            let persona = new Personas();
            persona.bajarFoto().then(response=>{
                if(response.status == 'ok'){
                    Swal.fire({
                        icon: 'success',
                        title: 'Notificaciones',
                        html: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                    setAvatar(null);
                    setSourceAvatar(null);
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Notificaciones',
                        html: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                }
                
            });
        }

    //#region end

    return(
        <>
            <Grid container mb={2}>
                <Grid item xs={12} md={12} xl={12}>
                    <SoftBox mb={1} lineHeight={0}>
                        <SoftTypography variant="caption" color="text">
                            Subir photo de perfil
                        </SoftTypography>
                    </SoftBox>
                </Grid>

                <Grid item xs={12} md={12} xl={12}>
                    <SoftBox mb={1} lineHeight={0}  sx={{ justifyContent: 'center' }}>
                        <SoftAvatar src={ sourceAvatar } alt="Foto" size="xxl" />
                    </SoftBox>
                   
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                    <SoftBox pt={1} > 
                        <SoftInput  
                             type="file" value={fileName}
                             onChange={ event => addAvatar(event) } autoComplete="off" accept="image/*"
                        />
                    </SoftBox>                   
                </Grid>

                <Grid item xs={12} md={12} xl={12} pt={1}>
                    <SoftBox >
                        <SoftButton fullWidth variant="gradient"  color="dark"  onClick={ handleChangeAvatar } >
                            <IconSave /> Agregar Foto
                        </SoftButton>
                    </SoftBox>
                </Grid>
                {
                    avatar && (
                        <Grid item xs={12} md={12} xl={12} pt={1}>
                            <SoftBox >
                                <SoftButton fullWidth variant="gradient"  color="error"  onClick={ handleRemoveAvatar } >
                                    <IconCancel /> Quitar Foto
                                </SoftButton>
                            </SoftBox>
                        </Grid>
                    )
                }
                


           </Grid>
        </>
    )
    
}


export default Photo;