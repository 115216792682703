import React, {  useState, useEffect } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
// @mui material components
import Grid from "@mui/material/Grid";
import IconRefresh from '@mui/icons-material/Refresh';
import Icon from "@mui/material/Icon";
//
import PersonaRequest from 'Repository/Banca/Personas';
import ItemCuenta from './ItemCuenta';
import {useDispatch, useSelector} from "react-redux";
import {v4} from "uuid";

function CuentasRelacionadas() {


    const {currentRecord : viewPersona, uuid: persona_uuid } = useSelector(state => state.personas_general);
    const [cuentasList, setCuentasList] = useState([]); 

    const handleRefresh = ()=> listar(persona_uuid);

    const personax = new PersonaRequest();

    const  listar = (persona_uuid) => {
        personax.cuentasDePersona(persona_uuid).then(response=>{
            setCuentasList(response.response.data);
        });
    }
 
    useEffect(()=>{
        listar(persona_uuid);
    },[]);

    return(
        <>
            <SoftBox  sx={{ border:"5px solid #f7f7f7", }}>
                <Card>
                    <Grid container >
                        <Grid item xs={8} md={9} xl={10}>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <label className={"IGenericLabel-Normal"}>Miembro en Centro de Costo STP</label>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={4} md={3} xl={2} pt={2} pr={2}>
                            <button className={"btn-alpha-info"} type={"submit"} onClick={ handleRefresh }>
                                <i className="fa-solid fa-arrows-rotate"></i> Refrescar
                            </button>
                        </Grid>

                    </Grid>
                    <SoftBox >
                        
                        { cuentasList.map((cuenta) =>{
                            return <ItemCuenta key={ v4() }
                            cuenta={cuenta} persona_uuid={persona_uuid}  />
                        })}

                    </SoftBox>
                </Card>
            </SoftBox>
        </>
    )
    
}

CuentasRelacionadas.propTypes = {
    persona_uuid: PropTypes.string,
  };

export default CuentasRelacionadas;