
import { useSelector} from "react-redux";
import { useForm } from "../../../../customHooks/useForm";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import * as React from "react";
import PropTypes from "prop-types";
import BtnCambioEstadoTpv from "../BtnCambioEstadoTpv";
import servicioContratado from "../../../../Repository/ServicioContratado";
import {useAlphaAlertModal} from "../../../../customHooks/useAlphaAlertModal";

const initialCredentials = {
    public_key:"",
    secret_key:""
}

const formValidation = {
    public_key: [(value)=> {
        const regex = /^pk_netpay_[a-zA-Z]+$/;
        return regex.test(value);
    },"La llave publia es requerida."],
    secret_key: [(value)=> {
        const regex = /^sk_netpay_[a-zA-Z]+$/;
        return regex.test(value);
    },"Llave secreta requerida."],
}

const FrmCheckout = ({ cuenta_uuid = '',credenciales = initialCredentials,  device = {}, tipoServicioName='NETPAY_CHECKOUT' }) => {
    const servContratado = new servicioContratado();
    const { servicios } = useSelector(state => state.servicios_plataformas);
    const initialForm = credenciales;
    const frmResult = useForm( initialForm, formValidation );
    const {public_key,secret_key,  onInputChange, isFormValid, setFormState } = frmResult;
    const [tipoServicio, setTipoServicio ] = useState();
    const { alphaAlert, openAlert, setOpenAlert, setMessageAlert } = useAlphaAlertModal();
    const [saving, setSaving] = useState(false);
    const [currentDevice, setCurrentDevice]= useState(device);

    useEffect(()=>{
        setFormState(credenciales);
    }, [credenciales])

    useEffect(()=>{
        setCurrentDevice(device);
    }, [device])

    useEffect(()=>{
        const checkout = servicios.find( element => element.servicioTipo === tipoServicioName );
        setTipoServicio(checkout);
    },[servicios])

    const loadComponent = async() =>{
        const result = await servContratado.listar(cuenta_uuid);
        if(result.status === "ok"){
            result.response.contratados.forEach(element =>{
                if(element.tipoServicio === tipoServicioName){
                    setCurrentDevice( element );
                }
            })
        }
    }

    const onClickGuardar = ()=>{
        if(!isFormValid) return;
        if(saving) return;
        const params = {
            ...initialForm,
            public_key,
            secret_key,
        };
        setSaving( true);
        if(device.uuid !== undefined){
            const data = {
                credenciales: params,
                cuenta_uuid
            }
            actualizar(device.uuid , data  )
        }else{
            const newContrato = {
                credenciales: params,
                cuenta_uuid,
                servicioPlataforma_uuid:tipoServicio.uuid
            }
            guardar( newContrato);
        }
    }

    const actualizar = async (uuid, data)=>{
         const result = await servContratado.update(uuid, data);
         if(result.status === 'ok'){
             setMessageAlert("Registro actualizado")
             setOpenAlert(true);
         }else{
             setMessageAlert (result.response);
             setOpenAlert(true);
         }
        await loadComponent();
        setSaving(false);
    }

    const guardar = async (data) =>{
        const result = await servContratado.store(data);
        if(result.status === 'ok'){
            setMessageAlert("Registro creado.")
            setOpenAlert(true);
        }else{
            setMessageAlert (result.response);
            setOpenAlert(true);
        }
        await loadComponent();
        setSaving(false);
    }

    return (
        <>
            <Grid container  p={1}>
                <Grid item xs={6} md={2} xl={2} textAlign={"right"} >
                    <label className={"IGenericLabel"}>Llave pública</label>
                </Grid>
                <Grid item xs={6} md={4} xl={4}>
                    <input type={"text"} className={"ITextbox"} name={"public_key"} value={ public_key } onChange={ onInputChange } />
                </Grid>
                <Grid item xs={6} md={2} xl={2} textAlign={"right"}>
                    <label className={"IGenericLabel"} >Llave secreta</label>
                </Grid>
                <Grid item xs={6} md={4} xl={4}>
                    <input type={"text"} className={"ITextbox"} name={"secret_key"} value={ secret_key } onChange={ onInputChange } />
                </Grid>

            </Grid>

            <Grid container>
                <Grid item xs={6} md={3} xl={3} mt={2}    >
                    {
                      currentDevice?.uuid && <BtnCambioEstadoTpv device={ currentDevice } />
                    }
                </Grid>
                <Grid item xs={6} md={3} xl={3} mt={2}    >
                </Grid>
                <Grid item xs={6} md={3} xl={3} mt={2} pl={1}  >
                </Grid>
                <Grid item xs={6} md={3} xl={3} mt={2}    >
                    <button className={"btn-alpha-success-2 pad-element"} type={"submit"} onClick={ onClickGuardar }  >
                        Aceptar
                    </button>
                </Grid>

            </Grid>
            {
                openAlert && alphaAlert
            }
        </>
    )

}

FrmCheckout.propTypes = {
    cuenta_uuid: PropTypes.string.isRequired,
    credenciales: PropTypes.object,
    device: PropTypes.object,
    tipoServicioName:PropTypes.string.isRequired,
}

export default FrmCheckout;