import React, {  useState, useEffect } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import IconSave from '@mui/icons-material/Save';
import Swal from 'sweetalert2';


import CentroDeCostos from 'Repository/Banca/CentroDeCosto';
import CentroDeCostoAutorizadores from 'Repository/Banca/CentroDeCostoAutorizadores';

export const DictamenCDC = ({ uuid }) => {

    const [errores, setErrores] = useState('' );
    const [minimoAutorizaciones, setMinimoAutorizaciones] = useState('');
    const [maximoAutorizaciones, setMaximoAutorizaciones] = useState('');
    const centrox = new CentroDeCostos();
    const autorizadoresx = new CentroDeCostoAutorizadores();


    //#region events

    useEffect(()=>{
       load(uuid);
    }, [])

    const onClickSaveDictamen = () => {
        centrox.updatePolitica({uuid,minimoAutorizaciones,maximoAutorizaciones })
            .then(response=>{
                if(response.status == 'ok'){
                    Swal.fire({
                        icon: 'success',
                        title: 'Notificaciones',
                        text: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                 }else{
                     Swal.fire({
                         icon: 'error',
                         title: 'Notificaciones',
                         text: response.response.text,
                         showConfirmButton: true,
                         confirmButtonText: 'Aceptar'
                     });
                 }
            });
    }

   
    //#endregion

    //#region methods
        const load = (uuid)=>{
            centrox.mostrar(uuid).then(response=>{
                if(response.status == 'ok'){
                   const data = response.response.data;
                   if(data.dictamen_config != null){
                        const config = JSON.parse(data.dictamen_config);
                        setMinimoAutorizaciones((oldValue)=>config.m);
                        setMaximoAutorizaciones((oldValue)=>config.n);
                   }else{

                   }
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Nodificaciones',
                        text: response.response.text,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar'
                    });
                }
            });
        }

        
        
    //#endregion

    return (
    <>
        <SoftBox>
                <hr/>
                    <Grid container >
                        <Grid item xs={8} md={8} xl={12} pt={2}>
                            <SoftBox pl={5} display="flex" alignItems="center" lineHeight={0}>
                                <SoftTypography variant="button" fontSize="xxs" color="error" >
                                    { errores }
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                    <SoftBox  px={3} component="form" role="form" mb={2}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} md={4} xl={3}>
                                <SoftBox lineHeight={0}>
                                    <SoftTypography variant="caption" color="text">
                                        Mínimo de autorizaciones
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox>
                                    <input type={"number"} className={"ITextbox"} name={"minimoAutorizaciones"} value={ minimoAutorizaciones } maxLength={10}
                                           onChange={ e => setMinimoAutorizaciones(e.target.value) } autoComplete={"on"} />
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} md={3} xl={3}>
                                <SoftBox>
                                    <SoftBox lineHeight={0}>
                                        <SoftTypography variant="caption" color="text">
                                            De un máximo a evaluar
                                        </SoftTypography>
                                    </SoftBox>
                                    <input type={"number"} className={"ITextbox"} name={"minimoAutorizaciones"} value={ maximoAutorizaciones } maxLength={10}
                                           onChange={ e => setMaximoAutorizaciones(e.target.value) } autoComplete={"on"} />
                                </SoftBox>
                            </Grid>
                            
                            <Grid item xs={12} md={3} xl={4}>
                                <SoftBox pt={2}>
                                    <button className={"btn-alpha-success-2 pad-element"} type={"button"} onClick={ (event) => onClickSaveDictamen(event) } >
                                        <i className="fa-solid fa-circle-check"></i>   Actualizar política de dictamen
                                    </button>
                                </SoftBox>
                            </Grid>
                            
                            

                        </Grid>
                    </SoftBox>
                <hr/>
            </SoftBox>
    </>
    )
}

DictamenCDC.propTypes = {
    uuid: PropTypes.string,
    
};
