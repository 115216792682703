/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";
import ChargersLayout from "../../../../examples/LayoutContainers/ChargersLayout";

function PaymentLayout({ title, description, image, children }) {
    return (
        <ChargersLayout>

            <SoftBox  mt={6}>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={11} sm={10} md={11} lg={10} xl={9}>
                        {children}
                    </Grid>
                </Grid>
            </SoftBox>
        </ChargersLayout>
    );
}

// Setting default values for the props of PaymentLayout
PaymentLayout.defaultProps = {
    title: "",
    description: "",
};

// Typechecking props for the PaymentLayout
PaymentLayout.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default PaymentLayout;