export const useCardFields = () => {

    const validators = [
        {
            basicDataColectApply : [true, false],
            field :'cardNumber',
            execute : [(value)=> {
                const regex = /^\d+$/;
                return regex.test(value);
                },
                "Número de tarjeta inválido."
            ]
        },
        {
            basicDataColectApply : [true, false],
            field : 'cvv2',
            execute : [(value)=> {
                const regex = /^\d+$/;
                return regex.test(value);
            },"CVV consta de máximo 4 dígitos"]
        },
        {
            basicDataColectApply : [true, false],
            field : 'expiration',
            execute : [(value)=> {
                const regex = /^(0[1-9]|1[0-2])\/(?!00)\d{2}$/;
                return regex.test(value);
            },"Vigencia no válida"]
        },
        {
            basicDataColectApply : [true],
            field : 'fullName',
            execute:[(value)=> {
                const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+([ ][a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+)*$/;
                return regex.test(value);
            },"Nombre inválido"]
        },
        {
            basicDataColectApply : [true, false],
            field : 'email',
            execute : [(value)=> {
                const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                return regex.test(value);
            },"Correo inválido"]
        }
        ,
        {
            basicDataColectApply : [true, false],
            field : 'phone',
            execute :[ (value)=> {
                const regex = /^[1-9]\d{9}$/;
                return regex.test(value);
            },"Número de teléfono inválido"],
        },
        {
            basicDataColectApply : [false],
            field : 'lastName',
            execute : [(value)=> {
                const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+([ ][a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+)*$/;
                return regex.test(value);
            },"Apellido inválido."],
        },
        {
            basicDataColectApply : [false],
            field : 'city',
            execute : [(value)=> {
                const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+([ ][a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+)*$/;
                return regex.test(value);
            },"Especifique su ciudad"],
        },
        {
            basicDataColectApply : [false],
            field : 'postalCode',
            execute : [(value)=> {
                const regex = /^\d+$/;
                return regex.test(value);
            },"Código postal inválido"],
        },
        {
            basicDataColectApply : [false],
            field : 'state',
            execute :[(value)=> {
                const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+([ ][a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+)*$/;
                return regex.test(value);
            },"No ha seleccionado un estado"],
        },
        {
            basicDataColectApply : [false],
            field : 'street1',
            execute :[(value)=> {
                const regex = /^.+/;
                return regex.test(value);
            },"No se ingreso el nombre de la calle"],
        },
        {
            basicDataColectApply : [false],
            field : 'firstName',
            execute : [(value)=> {
                const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+([ ][a-zA-ZáéíóúÁÉÍÓÚñÑüÜ]+)*$/;
                return regex.test(value);
            },"Nombre inválido"],
        },
        ];

    const mapValidators = (basicDataColect)=>{
        let result = {};
        validators.forEach(feature=>{
            const  apply = feature.basicDataColectApply.includes( basicDataColect );
            if(apply) result[feature.field] = feature.execute;
        })
        return result;
    }

    const optionsEstados = [
        { value: '', label: '' },
        { value: 'Aguascalientes', label: 'Aguascalientes' }, { value: 'Baja California', label: 'Baja California' },
        { value: 'Baja California Sur', label: 'Baja California Sur' },{ value: 'Campeche', label: 'Campeche' },
        { value: 'Coahuila', label: 'Coahuila' },
        { value: 'Colima', label: 'Colima' }, { value: 'Chiapas', label: 'Chiapas' },
        { value: 'Chihuahua', label: 'Chihuahua' },{ value: 'Ciudad de México', label: 'Ciudad de México' },
        { value: 'Durango', label: 'Durango' },{ value: 'Guanajuato', label: 'Guanajuato' },
        { value: 'Guerrero', label: 'Guerrero' },{ value: 'Hidalgo', label: 'Hidalgo' },
        { value: 'Jalisco', label: 'Jalisco' },
        { value: 'México', label: 'México' },{ value: 'Michoacan', label: 'Michoacan' },
        { value: 'Morelos', label: 'Morelos' },{ value: 'Nayarit', label: 'Nayarit' },
        { value: 'Nuevo León', label: 'Nuevo León' },{ value: 'Oaxaca', label: 'Oaxaca' },
        { value: 'Puebla', label: 'Puebla' },{ value: 'Queretaro', label: 'Queretaro' },
        { value: 'Quintana Roo', label: 'Quintana Roo' },{ value: 'San Luis Potosi', label: 'San Luis Potosi' },
        { value: 'Sinaloa', label: 'Sinaloa' },{ value: 'Sonora', label: 'Sonora' },
        { value: 'Tabasco', label: 'Tabasco' },{ value: 'Tamaulipas', label: 'Tamaulipas' },
        { value: 'Tlaxcala', label: 'Tlaxcala' },{ value: 'Veracruz', label: 'Veracruz' },
        { value: 'Yucatan', label: 'Yucatan' },{ value: 'Zacatecas', label: 'Zacatecas' },
    ]

    return {
        mapValidators, optionsEstados
    }

}