import React, {useEffect, useState} from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

import Swal from 'sweetalert2';

import Autorizador from "Repository/Banca/Autorizador";
import IconButton from "@mui/material/IconButton";
import SoftBadge from "../../../../components/SoftBadge";

function PermisoCheck({ autorizador_uuid,permisos = {} }) {
    const [ permisosLocal , setPermisosLocal ] = useState(permisos);
    const autorizadorx = new Autorizador();

    const permisosInit =  {
        autorizaOds: { 'true': 'Autoriza', 'false': 'consulta' },
        /*
        notificaDispersionLiquidadaEmail:{ 'true': 'Notificar Dispersión Liquidada por Correo', 'false': 'Notificar Dispersión Liquidada por Correo' },
        notificaDispersionLiquidadaSMS:{ 'true': 'Notificar Dispersión Liquidada por SMS', 'false': 'Notificar Dispersión Liquidada por SMS' },
        notificaDispersionEnDictamenSMS:{ 'true': 'Notificar Dispersión En Dictamen por SMS', 'false': 'Notificar Dispersión En Dictamen por SMS' },
        notificaDispersionAutorizadaSMS:{ 'true': 'Notificar Dispersión Autorizada por SMS', 'false': 'Notificar Dispersión Autorizada por SMS' },
        notificaAbonoSMS:{ 'true': 'Notificar Abonos recibido por SMS', 'false': 'Notificar Abonos recibido por SMS' },
        */
    }

    const  operaciones  = async (permiso)=>{
        const result = await Swal.fire({
            title: 'Permisos',
            text: "¿Desea continuar con el cambio de permisos?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        });
        if(result.isConfirmed){
            const valor = permisosLocal[`${permiso}`] ? true : false;
            setPermisosLocal({
                ...permisosLocal,
                [`${ permiso }`]: !valor
            })
            permisosLocal[`${permiso}`] =  !valor;
            const params = {
                'permisos': permisosLocal
            }
            autorizadorx.permisos(autorizador_uuid,params) ;
            //console.log( permisosLocal );
        }

    }

    const handleCheckChange = (permiso)=>{
        operaciones(permiso);
    }

    const permisosBtns = () =>{
        return Object.keys(permisosInit).map( property => {
            const checked   = permisosLocal[`${ property }`];
            const label     = permisosLocal[`${ property }`] ?  permisosInit[`${ property }`][true]: `${ permisosInit[`${ property }`][false] }`;
            return <SoftBadge key={ uuidv4() }
                              badgeContent={ label }
                              size="xs" container
                              color={ checked ? "info":"light"}
                              sx={{ paddingRight:"2px", cursor:"pointer" }}
                              onClick={ () => handleCheckChange(property ) }
            />
        })
    }
    { /* sx={{ width:{ xs: 100, sm: 200, md: 400, xl:400 } }} poner esta linea cuando el softbox sea muy grande   */ }
    return(
        <SoftBox   >
            { permisosBtns() }
        </SoftBox>
    )

}

PermisoCheck.propTypes = {
    autorizador_uuid: PropTypes.string.isRequired,
    permisos: PropTypes.object,
};

export default PermisoCheck;