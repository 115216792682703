import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
// @mui material components
import Grid from "@mui/material/Grid";
import IconRefresh from '@mui/icons-material/Refresh';
import IconAddTask from '@mui/icons-material/AddTask';
//
import CuentasRequest from 'Repository/Banca/Cuentas';
import AlphaTable from "../../../cuentas/components/AlphaTable";
import {v4} from "uuid";
import SoftBadge from "../../../../components/SoftBadge";


function ListadoCuentas({subtitle, onSelectedCuenta}) {

    const [txtBuscar,setTxtBuscar ] = useState('');
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]); 

   let cuentasx = new CuentasRequest();

    //#region events
    const handleRefresh = ()=> listar();

    const handleChangeBusqueda = ({target})=>{
        const value = target.value;
       
        setTxtBuscar(value);
        if(value.length > 3){
          listar(value);
        }else if(value == ''){
          listar('');
        }
        
    }

    const onSelectedCuentaCurrent = (event, uuid)=>{
        //event.preventDefault();
        onSelectedCuenta(uuid )
    }

    //#endregion

    //#region methods
    function listar(textox = ''){
        let params = {'txtbuscar':textox};
        cuentasx.listar(params).then(response=>{
          setColumns(
              [
                  { name:"Identificador", width:"10%" },
                  { name:"Responsable", width:"5%" },
                  { name:"Creado", width:"5%" },
              ]
          );
          const items = (response.response.data).map(element => {
                return (
                    <tr key={ v4() }>
                        <td >
                            <label onClick={ (event) => onSelectedCuentaCurrent(event, element.uuid) } className={ "ILabelLink" }>{  !element.alias ? element.propietario: element.alias }</label>
                        </td>
                        <td>{ (element.propietario ? element.propietario:'' ) }</td>
                        <td>
                            { element.created }
                        </td>
                    </tr>
                );
            })
          setRows(items);
        });
      }
    //#endregion

    
    useEffect(()=>{
        listar();
    },[]);

    const handleEncontrar = (event) => {
        if (event.key === "Enter") {
            listar(txtBuscar);
        }
    }

    return(
        <>
            <SoftBox >
                <Card>
                    <Grid container pt={1}>
                        <Grid item xs={8} md={6} xl={8} >
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" pl={1}>
                                <SoftTypography >{subtitle}</SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item pl={1} xs={4} md={2} xl={2}>
                            <button className={"btn-alpha-info"} type={"submit"} onClick={ handleRefresh }>
                                <i className="fa-solid fa-arrows-rotate"></i> Refrescar
                            </button>
                        </Grid>
                        <Grid item xs={8} md={4} xl={2} pr={3}>
                            <input type={"search"}  className={"ITxtEncontrar-Banca"} name={"encontrar"} value = { txtBuscar } maxLength={"50"} placeholder={"Buscar"}
                                   onChange={ (event)=> handleChangeBusqueda(event)  } onKeyDown={  handleEncontrar }  />
                        </Grid>
                        

                    </Grid>
                    <SoftBox
                        sx={{
                            width:"100%"
                        }}
                        >
                        <AlphaTable records={ rows } headers={ columns } />
                    </SoftBox>
                </Card>
            </SoftBox>
        </>
    )
    
}

ListadoCuentas.propTypes = {
    subtitle: PropTypes.string,
    onSelectedCuenta: PropTypes.func
};

export default ListadoCuentas;