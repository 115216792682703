import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Filtro from "../../../cuentas/components/Filtro";
import FrmCheckout from "../paypal_form/FrmCheckout";
import PropTypes from "prop-types";
import ServicioContratado from "../../../../Repository/ServicioContratado";

const defaultCredenciales = {
    clientID:"",
    secretID:""
}

const CHECKOUT = ({ cuenta_uuid }) => {
    const  servContratado = new ServicioContratado();
    const [contratados, setContratados] = useState([]);
    const [ credenciales, setCredenciales ] = useState(defaultCredenciales);
    const [ device, setDevice ] = useState({});

    useEffect(()=>{
        contratados.forEach(element =>{
            if(element.tipoServicio === 'PAYPAL_CHECKOUT'){
                setCredenciales( element.credenciales );
                setDevice( element );
            }
        })
    }, [contratados])

    const loadComponent = async() =>{
        const result = await servContratado.listar(cuenta_uuid);
        if(result.status === "ok")
            setContratados( result.response.contratados );
    }

    useEffect(()=>{
        loadComponent();
    },[])

    return (
        <Box pt={1} m={1}>
            <Filtro bodyContent={ <FrmCheckout cuenta_uuid={ cuenta_uuid } credenciales={credenciales} device={device } /> } title={"Llaves"} icon={ "fa-solid fa-key" } open={true} />
        </Box>
    );
}

CHECKOUT.propTypes = {
    cuenta_uuid:PropTypes.string.isRequired,
}

export default CHECKOUT;