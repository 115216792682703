
import SoftBox from "../../../components/SoftBox";
import PropTypes from "prop-types";
import {v4} from "uuid";

function AlphaTable ({headers = [], records = [], cssClass = "table-alpha-filter"}) {

    const columns  = headers.map(element =>{
        return <th key={ v4() } className={"th-header"} width={element.width}>{ element.name }</th>;
    })


    return (
        <SoftBox bgColor={"white"}>
            <table className={cssClass+" tabla-responsiva-movil"}>
                <thead>
                    <tr>
                        { columns }
                    </tr>
                </thead>
                <tbody>
                    { records }
                </tbody>
            </table>
        </SoftBox>
    );
}

AlphaTable.propTypes = {
    headers: PropTypes.array,
    records: PropTypes.array,
    cssClass: PropTypes.string,
}

export default AlphaTable;