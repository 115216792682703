import * as React from "react";
import SoftBox from "../../../components/SoftBox";
import {Grid, useMediaQuery} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useNumberFormat} from "../../../customHooks/useNumberFormat";
import {v4} from "uuid";
import Card from "@mui/material/Card";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import Cobranza from "../../../Repository/Cobranza";
import {setAlertMessage} from "../../../store/slice/alertSlice";
import {setLiquidado} from "../../../store/slice/cobranzaSlice";
import PropTypes from "prop-types";
import moment from "moment/moment";

const PaypalPayment = ({ columns }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();
    const {  transaccion,partidasAPagar } = useSelector(state => state.cobranza);
    const { numberFormat } = useNumberFormat();
    const cobranza = new Cobranza();

    function partidasSeleccionadas()
    {
        return partidasAPagar.map(partida => {
            return {
                ...partida,
                fecha: moment(partida.fecha,"DD/MM/YYYY").format("YYYY-MM-DD"),
                vencimiento: moment(partida.vencimiento,"DD/MM/YYYY").format("YYYY-MM-DD")
            };
        });
    }

    return (
        <>
            <Card sx={{ height:"95%",backgroundColor:"#E4F2F9",marginRight:1 }}  >
                {
                    !isMobile &&
                    <SoftBox  justifyContent="space-between" alignItems="center" pt={1} px={2}>
                        <SoftBox key={v4()}  sx={{ textAlign:"center" }} >
                            <label className={"IGenericLabel-2f"}>Paypal</label>
                        </SoftBox>
                    </SoftBox>
                }
                <SoftBox pt={2.5} >
                    {
                        !isMobile &&
                        <SoftBox>

                        </SoftBox>
                    }
                    <SoftBox>
                        {
                            transaccion.monto !== transaccion.paypal.monto &&
                            <SoftBox className={ "IGenericLabel-1-5f" }>
                                $ { numberFormat(transaccion.paypal.monto, 2)  } MXN
                            </SoftBox>
                        }
                    </SoftBox>

                    <Grid container direction="row"
                          justifyContent="center" padding={2}
                          alignItems="flex-end">
                        <Grid item xs={12} xl={ (columns < 12 ? 12:4 ) } md={ (columns < 12 ? 12:6 ) } >
                            <PayPalScriptProvider options={  { "client-id": transaccion.device.paypal, currency:"MXN","disable-funding":"card,credit"  } }>
                                <PayPalButtons style={{ layout: "vertical",label:"pay" }}
                                               createOrder={ async ()=> {
                                                   const result =    await cobranza.crearOrdenPaypal({transaccion_uuid: transaccion.uuid,amount:transaccion.paypal.monto,"partidas":partidasSeleccionadas()  });

                                                   return result.response.id;
                                               }
                                               }
                                               onApprove={ async (data)=>{
                                                   const result = await cobranza.captureOrdenPaypal( transaccion.uuid,{partidas:partidasSeleccionadas(),amount:transaccion.paypal.monto });
                                                   if(result.status === 'ok'){
                                                       dispatch( setLiquidado(result.response) );
                                                   }else{
                                                       const alert = {
                                                           message :  result.response,
                                                           icon:"",
                                                       }
                                                       dispatch( setAlertMessage(
                                                           alert
                                                       ) );
                                                   }

                                               }
                                               }
                                               onCancel={()=>{
                                                   console.log("Operación cancelada por el usuario");
                                               }
                                               }
                                               onError={(err)=>{
                                                   console.log(err, "Algo paso");
                                               }}
                                />
                            </PayPalScriptProvider>
                        </Grid>
                    </Grid>

                </SoftBox>
            </Card>
        </>
    );
}

PaypalPayment.propTypes = {
    columns: PropTypes.number.isRequired,
}

export default PaypalPayment;