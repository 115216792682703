import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import {useDispatch} from "react-redux";
import * as React from "react";
import Grid from "@mui/material/Grid";
import SoftBadge from "../../../components/SoftBadge";
import closePopupImg from "assets/alpha-theme/images/cerrarPopup.png";

const WebAlert = ({ title,message, modalWidth= 500, modalSize = "xs",  fnCancelDispath, icon="" }) => {
    const dispatch = useDispatch();
    const handleClose = (event, reason) => {
        dispatch( fnCancelDispath() );
    };

    return (
        <>
            <Dialog open={ true } onClose={handleClose} fullWidth={true}  maxWidth={ modalSize  }
                    PaperProps={{ style:{ backgroundColor:"#3d3d3d", borderRadius:"0.6rem", color:"white", overflowY:"unset" } }}
            >

                <DialogTitle >
                    <div className={"IAlertTitle"} >
                        { title }
                    </div>
                    <SoftBadge
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: -13,
                            top: -16,
                            color: "#fff",
                            backgroundColor: "#DB6161",
                            fontSize:"0.8rem",
                            border:"3px solid white",
                            borderRadius: "50%",
                            padding:"3px",
                            cursor:"pointer",
                        }}
                    >
                        <img src={ closePopupImg } alt={"Img Cerrar PopUp"} width={ "20px" } height={"20px"}  />
                    </SoftBadge>

                </DialogTitle>
                <DialogContent>

                    <Grid container  >
                        <div className={"IAlertMessage"}>
                            {message}
                        </div>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={7} xl={7}  >
                        </Grid>
                        <Grid item xs={12} md={5} xl={5}  >

                            <button className={"IBtnAlertSuccess"} type={"submit"} onClick={ handleClose }>
                                <i className="fa-solid fa-circle-check"></i> <span>Aceptar</span>
                            </button>

                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

WebAlert.propTypes = {
    message : PropTypes.string.isRequired,
    title: PropTypes.object.isRequired,
    modalWidth: PropTypes.any,
    modalSize: PropTypes.string,
    fnCancelDispath: PropTypes.any.isRequired,
    icon: PropTypes.string
}

export default WebAlert;