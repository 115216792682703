import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import { unselectServicio} from "../../../store/slice/cuentasSlice";
import Grid from "@mui/material/Grid";
import * as React from "react";
import {useForm} from "../../../customHooks/useForm";
import { cambiarEstadoDeServicioDirecto} from "../../../store/thunk/cuentas";
import {closeModal} from "../../../store/slice/alertSlice";

const initialForm = {
    observaciones: "",
}

const formValidation = {
    observaciones: [(value)=> value.trim() !== "","Observaciones requeridas."],
}

const FrmCancelarTpv = (props) => {

    const dispatch = useDispatch();
    const { observaciones, onInputChange } = useForm( initialForm, formValidation );
    const { servicioACancelar } = useSelector( state => state.cuentas );

    const handleClose = () => {
        dispatch( unselectServicio() );
        dispatch( closeModal() );
    };

    const onClickGuardar = () =>{
        const params = {
            ...servicioACancelar,
            observaciones
        }
        dispatch( cambiarEstadoDeServicioDirecto( params ) );
    }

    return (
        <Box>
            <Grid container>
                <Grid item xs={12} md={4} xl={4}   pr={1}>
                    <span className={"lbl-etiqueta"}>Observaciones</span>
                </Grid>
                <Grid item xs={12} md={8} xl={8}>
                    <textarea className={"elemento-input-v2 elemento-textarea"} rows={3}  placeholder={"observaciones"} name={"observaciones"} value={ observaciones }
                              onChange={ onInputChange }
                    ></textarea>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} xl={6} mt={2}    >
                    <button className={"btn-alpha-success-2 pad-element"} type={"submit"} onClick={ onClickGuardar }>
                        Guardar
                    </button>
                </Grid>
                <Grid item xs={12} md={6} xl={6} mt={2} pl={1}  >
                    <button className={"btn-alpha-danger pad-element"} type={"submit"} onClick={ handleClose }>
                        Cancelar
                    </button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default FrmCancelarTpv;