import * as React from "react";
import SoftBox from "../../../components/SoftBox";
import Box from "@mui/material/Box";
import {Grid, useMediaQuery} from "@mui/material";
import { useSelector} from "react-redux";
import {v4} from "uuid";
import Card from "@mui/material/Card";
import BtnToPay from "./BtnToPay";
import TableDocuments from "./TableDocuments";
import {filtroPartidas} from "../../../store/slice/cobranzaSlice";
import TxtEncontrar from "./TxtEncontrar";
import SectionHeader from "./SectionHeader";

const DocumentsToPay = (props) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const { cuenta , transaccion, partidasFiltro } = useSelector(state => state.cobranza);
    const { monto } = transaccion;

    return <>
        <SoftBox bgColor={"color18"} borderRadius={"12px"} >
            <SectionHeader cuenta={ cuenta } />

            <Grid container >
                <Grid item xs={12} md={12} xl={12} mt={1} >
                    <Box sx={{ border:"5px solid #BEBEBE",borderRadius:2 }}>
                        <Card sx={{ height:"99%" }} >
                            <SoftBox px={2}>
                                <SoftBox key={v4()} sx={{ textAlign:"center", borderBottom:"3px solid #929292" }} >
                                    <label className={"IGenericLabel-Normal2"}>Documentos por Pagar</label>
                                </SoftBox>
                            </SoftBox>
                            <Grid container paddingTop={1} >
                                <Grid item xs={12} md={8} xl={8}  textAlign={"center"}>
                                    <TxtEncontrar eventSearch={ filtroPartidas } textSearch={""} />
                                </Grid>
                                <Grid item xs={12} md={4} xl={4}  textAlign={"center"} paddingLeft={1} paddingRight={1}>
                                    <BtnToPay monto={ monto } />
                                </Grid>
                            </Grid>
                            <SoftBox pl={1} pr={ isMobile ?0:1} >
                                {
                                    <TableDocuments partidas={ partidasFiltro }  />
                                }
                            </SoftBox>
                        </Card>
                        <SoftBox sx={{ padding:1 }}>
                            <Grid container >
                                <Grid item xs={12} md={8} xl={8}  textAlign={"center"}>
                                </Grid>
                                <Grid item xs={12} md={4} xl={4}  textAlign={"center"}>
                                    <BtnToPay monto={ monto } />
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Box>
                </Grid>
            </Grid>
        </SoftBox>
    </>
    ;
}

export default DocumentsToPay;