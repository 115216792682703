
import PropTypes from "prop-types";

import TableRow from "@mui/material/TableRow";
import { TableCell} from "@mui/material";
import colors from "assets/theme/base/colors";
import borders from "../../../../../assets/theme/base/borders";
import SoftBox from "../../../../../components/SoftBox";

function HeaderOperacionesEnTransito(  ) {
    const { borderWidth } = borders;

    return (<>
            <TableRow>
                <TableCell colSpan={4} sx={{ borderBottom:"0px" }}
                >
                    <SoftBox
                        borderBottom={`${borderWidth[3]} solid ${ colors.color5A.main }` }
                        color={ "color1" }
                        fontWeight={"bold"}
                        sx={{ fontSize:"14px" }}
                    >
                        { "Operaciones en tránsito"  }
                    </SoftBox>
                </TableCell>
            </TableRow>

        </>
    );
}

// Typechecking props for the ListItem

export default HeaderOperacionesEnTransito;