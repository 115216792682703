
import PropTypes from "prop-types";

import TableRow from "@mui/material/TableRow";
import { TableCell} from "@mui/material";
import SoftTypography from "../../../../../components/SoftTypography";
import colors from "assets/theme/base/colors";
import borders from "../../../../../assets/theme/base/borders";
import SoftBox from "../../../../../components/SoftBox";
import {useNumberFormat} from "../../../../../customHooks/useNumberFormat";

function RowHeaders({ transacciones } ) {
    const { borderWidth } = borders;
    const { numberFormat } = useNumberFormat();

    return (<>
        <TableRow>
            <TableCell colSpan={4} sx={{ borderBottom:"0px" }}
            >
                <SoftBox
                    borderBottom={`${borderWidth[3]} solid ${ colors.color5A.main }` }
                    color={ "color1" }
                    fontWeight={"bold"}
                    sx={{ fontSize:"14px" }}
                >
                    { "Operaciones"  }
                </SoftBox>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell colSpan={4} sx={{ borderBottom:"0px" }}>
                <SoftBox
                    borderBottom={`${borderWidth[3]} solid ${ colors.color5A.main }` }
                    color={ "color1" }
                    fontWeight={"bold"}
                    sx={{ fontSize:"14px" }}
                >
                    { `Saldo Inicial a la fecha ${ transacciones.desde }` }
                    <SoftTypography sx={{ float: "right", fontSize:"14px"}} fontWeight={"bold"} >
                        { numberFormat( parseFloat( transacciones.saldoPeriodoAnterior ), 2 ) }
                    </SoftTypography>
                </SoftBox>
            </TableCell>
        </TableRow>
        </>
    );
}

// Typechecking props for the ListItem
RowHeaders.propTypes = {
    transacciones: PropTypes.any.isRequired
};

export default RowHeaders;