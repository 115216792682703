import React, { useState, useEffect } from "react";

import Box from '@mui/material/Box';
import {useDispatch, useSelector} from "react-redux";
import {v4} from "uuid";
import NETPAYCHECKOUT from "./netpay/CHECKOUTV2";
import NETPAYTPV from "./netpay/TPV2";
import NETPAYLOOP from "./netpay/LOOPV2";
import PAYPALCHECKOUT from "./paypal/CHECKOUT";
import PropTypes from "prop-types";
import SoftBox from "../../../components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "../../../components/SoftTypography";
import Grid from "@mui/material/Grid";
import {mostrarCuenta} from "../../../store/thunk/cuentas";
import BtnCambioEstadoCuenta from "../../cuentas/components/BtnCambioEstadoCuenta";
import Autorizadores from "./Autorizadores";

const  TabPlataformaV2 = ({ cuenta = {},plataforma = {} }) => {

    const [value, setValue] = useState("TPV");
    const [servicioSeleccionadoComponent, setServicioSeleccionadoComponent] = useState();
    const { servicios } = useSelector(state => state.servicios_plataformas);
    const dispatch = useDispatch();

    const defaultComponents ={
        netpay  :<NETPAYTPV cuenta_uuid={ cuenta.uuid }  />,
        paypal  :<PAYPALCHECKOUT cuenta_uuid={ cuenta.uuid }  />
    }

    useEffect(()=>{
        setServicioSeleccionadoComponent( defaultComponents[ plataforma.plataforma.toLowerCase() ] );
    }, [  ])

    const listarCdc = (selected) => {
        setValue(selected.categoria);
        let serviciox = <NETPAYTPV cuenta_uuid={ cuenta.uuid } />;
        if(selected.plataforma.toLowerCase() ==='netpay' ){
            switch (selected.categoria){
                case 'TPV':
                    serviciox = <NETPAYTPV cuenta_uuid={ cuenta.uuid }  />;
                    break;
                case 'CHECKOUT':
                    serviciox = <NETPAYCHECKOUT cuenta_uuid={ cuenta.uuid }  />;
                    break;
                case 'LOOP':
                    serviciox = <NETPAYLOOP cuenta_uuid={ cuenta.uuid } />;
                    break;
            }
        }
        if(selected.plataforma.toLowerCase() ==='paypal' )
        {
            switch (selected.categoria){
                case 'CHECKOUT':
                    serviciox = <PAYPALCHECKOUT cuenta_uuid={  cuenta.uuid }  />;
                    break;
            }
        }
        //else otra plataforma
        setServicioSeleccionadoComponent( serviciox );
    }

    const handleChange = (event, selected) => {
        //console.log(selected);
        listarCdc(selected);
    };

    const tabButtons = servicios.map(element =>{
        if(element.plataforma_uuid === plataforma.uuid ){
            return (<button key={ v4() } type={"button"} className={ value === element.categoria ? "ITabButtonActive":"ITabButtonInactive" }
                            onClick={event=>handleChange(event,element)} > { element.categoria } </button>);
        }
    })

    const handleEditar = (event,cuenta_uuid) => {
        dispatch( mostrarCuenta(cuenta_uuid) );
    }

    return (
        <Box sx={{ width: '100%' }} bgcolor={"white"} pt={1} >
            <Grid container >
                <Grid item sm={6} xs={6} md={10} >
                </Grid>
                <Grid item sm={3} xs={3} md={2} textAlign={"right"}  >

                </Grid>
                <Grid item sm={3} xs={3} md={2} >
                    <BtnCambioEstadoCuenta cuentax={  cuenta } />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={12} xs={12} md={12} pt={1} pb={1}>
                    <Autorizadores cuenta_uuid={ cuenta.uuid } plataforma={ plataforma.plataforma.toLowerCase()} />
                </Grid>
            </Grid>
            <Box >
                { tabButtons }
            </Box>
            <Box sx={{ border:"0.4rem solid #EEEEEE",paddingLeft:1, paddingRight:1, paddingBottom:1,  }} >
                { servicioSeleccionadoComponent }
            </Box>
        </Box>

    );
}

TabPlataformaV2.propTypes = {
    cuenta:PropTypes.object.isRequired,
    plataforma: PropTypes.object.isRequired,
}

export default TabPlataformaV2;