/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React context
import { useSoftUIController, setLayout } from "context";
import LoaderAlpha from "../../../layouts/dashboard/components/LoaderAlpha";

function ChargersLayout({ background, children }) {
    const [, dispatch] = useSoftUIController();
    const { pathname } = useLocation();

    useEffect(() => {
        setLayout(dispatch, "page");
    }, [pathname]);

    return (
        <SoftBox
            width="98vw"
            height="100%"
            minHeight="98vh"
            sx={{ overflowX: "hidden" }}
        >
            <LoaderAlpha />
            {children}
        </SoftBox>
    );
}

// Setting default values for the props for PageLayout
ChargersLayout.defaultProps = {
    background: "default",
};

// Typechecking props for the PageLayout
ChargersLayout.propTypes = {
    background: PropTypes.oneOf(["white", "light", "default","color17"]),
    children: PropTypes.node.isRequired,
};

export default ChargersLayout;
