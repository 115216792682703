import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Filtro from "../../cuentas/components/Filtro";
import SoftBox from "../../../components/SoftBox";
import {closeGeneralLoader, openGeneralLoader} from "../../../store/slice/alertSlice";
import {useDispatch} from "react-redux";
import Autorizador from "../../../Repository/Autorizador";
import {Grid} from "@mui/material";
import SoftTypography from "../../../components/SoftTypography";
import Card from "@mui/material/Card";
import { v4} from "uuid";
import Notificaciones from "../../registro/components/Notificaciones";
const CdcsPlataforma = ({plataforma = "netpay" }) => {

    const dispatch = useDispatch();
    const autorizadorx = new Autorizador();
    const plataformaTitle = {netpay: "Netpay", paypal:"Paypal"};
    const [cdcAutorizados, setCdcAutorizados] = useState([]);
    const labels = [
        { label: 'RFC', value: 'rfc'  }, { label: 'Licencia', value: 'licencia'  }
    ];

    /**
     * State events
     */
    useEffect(()=>{ loadComponent() }, []);

    /**
     * Custom Events
     */
    const loadComponent = async ()=> {
        dispatch( openGeneralLoader() );
        const result = await autorizadorx.cdcsAutorizados(plataforma);
        dispatch( closeGeneralLoader() );
        if(result.status === 'ok') {
            setCdcAutorizados(result.response);
        }
    }

    const renderLabel = (centro) => {
        return labels.map(field => (
            <SoftBox key={ v4() } display="flex" py={1} pr={2}>
                <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    {field.label}: &nbsp;
                </SoftTypography>
                <SoftBox >

                </SoftBox>
                <SoftTypography variant="button" fontWeight="regular" color="text" >
                    { centro[field.value] }
                </SoftTypography>
            </SoftBox>
        ))
    }

    const listCdcs = <Filtro open={false} title={ plataformaTitle[plataforma] } icon={""} bodyContent={
        <SoftBox >
            {
                cdcAutorizados.map(centro => {
                    return <Card key={ v4() } sx={{ marginBottom:1,paddingTop:1, border:"3px solid #E6E6E6"  }} >
                            <Grid container mb={1} p={1}>
                                <Grid item xs={12} md={6} xl={6}>
                                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={2} >
                                        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize" color={"color6A"}>
                                            { centro.rs }
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox pl={2}>
                                        <SoftBox mb={1} lineHeight={1}>
                                            { renderLabel(centro) }
                                        </SoftBox>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} md={6} xl={6}>

                                    <SoftBox pl={2} pb={1} pr={1} pt={2}>
                                        <SoftBox sx={{ borderColor:"#123AAA", borderTop:2, borderBottom:2, textAlign:"center", marginBottom:1 }}>
                                            <SoftTypography sx={{ fontSize:14 }} >
                                                Configure las notificaciones que desea recibir en tiempo real
                                            </SoftTypography>
                                        </SoftBox>
                                        <Notificaciones key={ v4() } cuenta_autorizador_uuid={ centro.cuenta_autorizador_uuid} notificaciones={ centro.notificaciones } plataforma={plataforma} />
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </Card>
                })
            }
        </SoftBox>
    } />;

    return (  cdcAutorizados.length > 0 && listCdcs);
}

CdcsPlataforma.propTypes = {
    plataforma: PropTypes.string.isRequired,
}

export default CdcsPlataforma;