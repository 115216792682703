import {useSelector} from "react-redux";
import {v4} from "uuid";
import {useEffect, useState} from "react";
import Filtro from "../../cuentas/components/Filtro";
import TabPlataforma from "./TabPlataforma";
import SoftBox from "../../../components/SoftBox";

const Plataformas = (props) => {

    const { plataformas } = useSelector(state => state.servicios_plataformas);

    const [ manejadores, setManejadores ] = useState();

    const loadPlataformas = () =>{
        const colec = [];
        Object.keys(plataformas).forEach(key => {
            const item = plataformas[key]
            const elemento = <SoftBox  key={ v4() } sx={{ marginBottom:2  }}>
                <Filtro  key={ v4() } bodyContent={ <TabPlataforma plataforma={ item } /> } title={  item.plataforma } icon={"fa-solid fa-credit-card"} open={true}  />
            </SoftBox>;
            colec.push( elemento );
        });
        setManejadores(colec);
    }

    useEffect(()=>{ loadPlataformas(); },[plataformas]);

    return (
        <>
            {  manejadores }
        </>
    );
}

export default Plataformas;