/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React base styles

import {Grid} from "@mui/material";
import EstadoDeCuentaStp from "../stp/estadocuentastp/EstadoCuentaStp";


function Dashboard() {

    const dispatch = useDispatch();
    const { windowHeight } = useSelector( state => state.ventana );
    const [esUsuarioEnBanca, setEsUsuarioEnBanca]   = useState(false);
    const {  roles  } = useSelector(state => state.auth);

    useEffect(()=>{
        const tienePermiso = roles.find(element => element === 'AutorizadorStp');
        setEsUsuarioEnBanca(tienePermiso);
    },[roles])

    return (
      <DashboardLayout sx={{ overflow:"hidden" }}>
        <DashboardNavbar light={true} />
        <SoftBox >
            { esUsuarioEnBanca && <EstadoDeCuentaStp /> }
        </SoftBox>
      </DashboardLayout>
    );
  
}

export default Dashboard;
