import api_core from '../../services/config';
import Operacion from './Operacion';
class CentroDeCostoAutorizadores extends Operacion{

    constructor(){
        super();
    }

    async listar(data){
        const url = `panel/autorizadorcdcs/${data}/listar`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }

    async autorizadoresPorAsignar(cdcs_uuid){
        const url = `panel/autorizadorcdcs/${cdcs_uuid}/disponibles`;
        const response = await  this.transaction('GET',url, null , true );
        return response;
    }


    async store(data){
        const url = `panel/autorizadorcdcs/registrar`;
        const response = await  this.transaction('POST',url, JSON.stringify(data)  , true );
        return response;
    }

    static cambiarEstado(item, value, password){
        return new Promise((resolve, fallback)=>{
            let url = `${process.env.REACT_APP_API_CORE_BANCA}panel/autorizadorcdcs/${item.uuid}/estatus/${value}`;
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: JSON.stringify({password}) 
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response) );
        });
    }

}

export default CentroDeCostoAutorizadores