import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    plataformas: [],
    servicios : [],
}

export const servicioSlice = createSlice({
    name: 'servicios',
    initialState,
    reducers: {
        setServicios:(state, action) =>{
            state.plataformas = action.payload.plataformas;
            state.servicios = action.payload.servicios;
        }
    }
})

// Action creators are generated for each case reducer function
export const { setServicios } = servicioSlice.actions

export default servicioSlice.reducer