import PropTypes from "prop-types";
import * as React from "react";
import {useState} from "react";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import SoftBadge from "../../../components/SoftBadge";
import closePopupImg from "../../../assets/alpha-theme/images/cerrarPopup.png";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {useForm} from "../../../customHooks/useForm";
import Cuenta from "../../../Repository/Cuenta";
import {useAlphaAlertModal} from "../../../customHooks/useAlphaAlertModal";

const initialForm = {
    observaciones: "",
}

const formValidation = {
    observaciones: [(value)=> value.trim() !== "","Observaciones requeridas."],
}
/**
 * @commeet Si estado == 0 (Activo), 1 esta inactivo
 * @param cuentax
 * @returns {JSX.Element}
 * @constructor
 */
const BtnCambioEstadoCuenta = ( {cuentax} ) => {

    const { observaciones, onInputChange } = useForm( initialForm, formValidation );
    const [estado, setEstado] = useState( cuentax.status ===true?1:0 );
    const [openModal, setOpenModal] = useState(false);
    const { alphaAlert, openAlert, setOpenAlert, setMessageAlert } = useAlphaAlertModal();

    const cambiarEstado = async (data) => {
        const cuenta = new Cuenta();
        const result = await cuenta.cambiarEstado(data);
        if(result.status === 'ok'){
            setOpenModal(false);
        }
        setMessageAlert( result.response );
        setOpenAlert(true);
    }

    /**
     * @comment vamos a cambiar el estado de la cuenta por lo que si:
     * Vamos a desactivar: Requerimos que opcionalmenre puedan colocar observaciones para que recuerden porque dieron de baja el servicio
     * Vamos a activar: En este caso no importa que nos pongan observaciones de hecho las vamos a borrar
     * @param event
     */
    const changeState = (event)=> {
        const newState  = estado === 0 ? 1: 0;
        const data      = { uuid:cuentax.uuid, status:newState, observaciones:null };
        if(estado  === 1){
            setOpenModal(true);
            return;
        }
        setEstado( 1 );
        cambiarEstado(data);
    }

    const handleClose = ()=>{
        setOpenModal(false);
    }

    const onClickGuardar = () =>{
        const data      = { uuid:cuentax.uuid, status:0, observaciones };
        setEstado(0);
        cambiarEstado(data);
    }

    const modalCancelar = <Dialog open={true} onClose={ handleClose } fullWidth={true}  maxWidth={ "sm"  }
                                  PaperProps={{ style:{  borderRadius:"0.6rem", color:"white", overflowY:"unset" } }}
    >
        <DialogTitle>
            <SoftBadge
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: -13,
                    top: -16,
                    color: "#fff",
                    backgroundColor: "#DB6161",
                    fontSize:"0.8rem",
                    border:"3px solid white",
                    borderRadius: "50%",
                    padding:"3px",
                    cursor:"pointer",
                }}
            >
                <img src={ closePopupImg } alt={"Img Cerrar PopUp"} width={ "15px" } height={"15px"}  />
            </SoftBadge>
            <div className={ "titleSegment" } >
                <i className="fa-solid fa-file-signature pl-element pad-element txt-success "></i> { "Activar/Desactivar dispositivo" }
            </div>
        </DialogTitle>
        <DialogContent>
            <Box>
                <Grid container>
                    <Grid item xs={12} md={4} xl={4}   pr={1}>
                        <span className={"lbl-etiqueta"}>Observaciones</span>
                    </Grid>
                    <Grid item xs={12} md={8} xl={8}>
                    <textarea className={"elemento-input-v2 elemento-textarea"} rows={3}  placeholder={"observaciones"} name={"observaciones"} value={ observaciones }
                              onChange={ onInputChange }
                    ></textarea>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6} xl={6} mt={2}    >
                        <button className={"btn-alpha-success-2 pad-element"} type={"submit"} onClick={ onClickGuardar }>
                            Guardar
                        </button>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6} mt={2} pl={1}  >
                        <button className={"btn-alpha-danger pad-element"} type={"submit"} onClick={ handleClose }>
                            Cancelar
                        </button>
                    </Grid>
                </Grid>
            </Box>
        </DialogContent>
    </Dialog>

    return (
        <div className={ "area-boton" }>
            <button className={ estado === 0 ? "btn-alpha-danger pad-element":"btn-alpha-success-2 " } onClick={ (event) => changeState(event) } title={ cuentax.observaciones ??'' } >{ (estado === 0 ? "Inactivo": "Activo") }</button>
            { openModal && modalCancelar }
            { openAlert && alphaAlert }
        </div>
    );
}

BtnCambioEstadoCuenta.propTypes = {
    cuentax: PropTypes.object.isRequired
}

export default BtnCambioEstadoCuenta;