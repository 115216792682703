import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
// @mui material components
import Grid from "@mui/material/Grid";
import IconRefresh from '@mui/icons-material/Refresh';

import OrdenClass from '../../../../../Repository/Banca/Ordenes';
import { Typography } from '@mui/material';
import AlphaTable from "../../../../cuentas/components/AlphaTable";
import {v4} from "uuid";
import Paginador from "../../../../terminales/components/Paginador";

export const Ordenes = ({filter}) => {

    const [txtBuscar,setTxtBuscar ] = useState('');
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]); 
    const [cursor, setCursor] = useState(null);
    const orden = new OrdenClass();

    //#region events
    const onClickOds = () => {

    }

    useEffect (()=>{
        let args = {};
        if(filter?.cdcs != undefined){
            args.cdcs = filter.cdcs ;
        }
        if(filter?.fechaaltaantesde != undefined){
            args.fechaaltaantesde = filter.fechaaltaantesde ;
        }
        if(filter?.fechaaltadespuesde != undefined){
            args.fechaaltadespuesde = filter.fechaaltadespuesde ;
        }
        listar(args);
    },[filter])

    const onCursorClick = (type)=>{

    }

    const paginatorEvent = (type)=>{
        if(cursor){
            let query = '';
            if(type === 'next')
            {
                query = cursor.next;
            }else{
                query = cursor.prev;
            }
            listarPorCursor(query);
        }
    }

    const nextEvent = ()=>{
        paginatorEvent("next")
    }
    const prevEvent = ()=>{
        paginatorEvent("prev")
    }
    //#endregion

    //#region methods
    const  listar = async (params) =>{
        const result = await orden.listarOperaciones(params);
        fillTable(result);
    }

    const listarPorCursor = async (query) =>{
        const result = await orden.listarOperacionesCursor(query);
        fillTable(result);
    }
    
    const fillTable = (response)=>{
        
        setColumns(
          [
            { name: "RFC", width: "10%" },
            { name: "Serie", width: "10%" },
            { name: "Cliente STP", width: "10%" },
            { name: "Nombre STP", width: "10%" },
            { name: "Razon Social", width: "10%" },
            { name: "Dispersiones", width: "7%" },
            { name: "Abonos", width: "7%" },
            { name: "Total", width: "7%" },
            { name: "Rechazadas", width: "7%" },
          ]
          );

          let items =(response.response.data).map(element=>{
              const rfc = <label className={"pl-2"} >{ element.rfc.toUpperCase() }</label>;
              const serie = <label className={"pl-2"} >{ element.serie }</label>;
              const cliente = <label >{ element.idCliente }</label>;
              const stp = <label className={"pl-2"} >{ (element.stpNombre != null ?element.stpNombre.toUpperCase()  :'')  }</label>;

              const total = parseInt(element.abonos) + parseInt(element.dispersiones);
              const totalLabel = <SoftTypography color={ total == 0? "color7":"color1" } variant='button'>{ ( total ).toLocaleString('es-MX')  }</SoftTypography>;

              const dispersion = parseInt(element.dispersiones);
              const dispersionLabel = <SoftTypography color={ dispersion == 0? "color7":"color1" }  variant='button'> { dispersion.toLocaleString('es-MX') } </SoftTypography>;

              const rechazadas = parseInt(element.rechazadas);
              const rechazadasLabel = <SoftTypography color={ rechazadas == 0? "color7":"color1" } variant='button'> { rechazadas.toLocaleString('es-MX') } </SoftTypography>;

              const abonos = parseInt(element.abonos);
              const abonosLabel = <SoftTypography  color={ abonos == 0? "color7":"color1" }  variant='button'> {abonos.toLocaleString('es-MX') } </SoftTypography>;

              return <tr key={ v4() }>
                      <td>
                          { rfc }
                      </td>
                      <td>
                          { serie }
                      </td>
                      <td align={"center"}>
                          { cliente }
                      </td>
                      <td >
                          { stp }
                      </td>
                      <td>
                          <label className={"pl-2"}>{ (element.rs).toUpperCase() }</label>
                      </td>
                      <td>
                          <label className={"pl-3"}>{ dispersionLabel }</label>
                      </td>
                      <td>
                          <label className={"pl-3"}>{ abonosLabel }</label>
                      </td>
                      <td>
                          <label className={"pl-3"}>{ totalLabel }</label>
                      </td>
                      <td>
                          <label className={"pl-3"}>{ rechazadasLabel }</label>
                      </td>
              </tr>
          })


          const tdispersion     = parseInt(response.response.totales.liquidado);
          const tabonos         = parseInt(response.response.totales.abonos);
          const ttotales        = parseInt(response.response.totales.totales);
          const trechazadas     = parseInt(response.response.totales.rechazadas);

          let row = <tr key={ v4() }>
              <td>
                  <SoftTypography>Totales</SoftTypography>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td> <SoftTypography color={ tdispersion == 0? "color7":"color1" }> {tdispersion.toLocaleString(`es-MX`) } </SoftTypography> </td>
              <td> <SoftTypography color={ tabonos == 0? "color7":"color1" }> { tabonos.toLocaleString(`es-MX`) } </SoftTypography> </td>
              <td> <SoftTypography color={ ttotales == 0? "color7":"color1" }> {ttotales.toLocaleString(`es-MX`) } </SoftTypography> </td>
              <td> <SoftTypography  color={ trechazadas == 0? "color7":"color1" }> { trechazadas.toLocaleString(`es-MX`) } </SoftTypography> </td>
          </tr>
        items = [...items, row];

        setRows(items);
        setCursor(()=>response.response.cursor);
    }

    //#endregion

    return (
        <>
                
                    
                <SoftBox pt={2}>
                    <AlphaTable records={ rows } headers={ columns } />
                </SoftBox>

            <Grid container justifyContent={"center"} direction={"row"} alignItems={"center"}>
                <Grid item xs={8} sm={8} md={5} xl={4} lg={4}>
                    <SoftBox sx={{ width:"100%" }}>
                        {
                            cursor && <Paginador cursor={ cursor } nextEvent={ nextEvent } prevEvent={ prevEvent } />
                        }
                    </SoftBox>
                </Grid>
            </Grid>

          
        </>
    )
}

Ordenes.propTypes = {
    filter: PropTypes.any
};

export default Ordenes;