import React,{ useEffect, useState } from 'react'
import CentroDeCosto from 'Repository/Banca/CentroDeCosto';
import CentroItem from './CentroItem';
import SoftBox from "../../../../components/SoftBox";

export const CentrosDeCosto = () => {
    const [centros, setCentros] = useState([]);
    const centrox = new CentroDeCosto();

    useEffect(() => {
        load();
    }, [])

    //#region methods
    const load = async ()=>{
        const data = await centrox.getCentrosDeCostoPorPersona(); 
        if(data.status !== 'ok') return;
        setCentros( data.response ); 
    }
    //#endregion
    return (

        <SoftBox pb={2} pr={1}>
            {
                centros.map(element=>{
                    return <CentroItem key={ element.uuid } centroDeCosto = { element  }  />
                })
            }
        </SoftBox>
    )
}
