import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
// @mui material components
import Grid from "@mui/material/Grid";
import IconRefresh from '@mui/icons-material/Refresh';
import IconAddTask from '@mui/icons-material/AddTask';
//
import PersonasRequest from 'Repository/Banca/Personas';
import SoftBadge from "components/SoftBadge/index.js";
import AlphaTable from "../../../cuentas/components/AlphaTable";
import {v4} from "uuid";
import BtnCambioEstadoCuenta from "../../../cuentas/components/BtnCambioEstadoCuenta";
import TxtEncontrar from "../../../terminales/components/TxtEncontrar";
import {listadoLitePersonas} from "../../../../store/thunk/persona";

function cambiarEstado(item, value,/* callback */ callback){
    callback(item, value);
}

function ListadoPersonas({subtitle,additionalFilter ={}, onSelectedPersona}) {

    const [txtBuscar,setTxtBuscar ] = useState('');
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]); 

    let personax = new PersonasRequest();

    const handleCheckChange = ({target})=>{
        //setState( target.checked ) ;
        //cambiarEstado(item, target.checked,  Persona.cambiarEstado);
    }

    const handleRefresh = ()=> listar();

    //#region events
    const handleChangeBusqueda = ({target})=>{
        const value = target.value;
        setTxtBuscar(value);
        if(value.length > 3){
          listar(value);
        }else if(value == ''){
          listar('');
        }
        
    }

    const handleSeleccionar = (event, uuid, nombres_completo)=>{
        onSelectedPersona(uuid, nombres_completo)
    }

    //#endregion

    //#region methods
    function listar(textox = ''){
        let params = {nombres:textox, soloActivos: true};
        if(additionalFilter.hasOwnProperty('cuenta_uuid')){  params.cuenta_uuid = additionalFilter.cuenta_uuid; }
        personax.listar(params).then(response=>{
          
          setColumns(
              [
                  { name:"Nombre", width:"10%" },
                  { name:"Correo", width:"5%" },
                  { name:"Celular", width:"5%" },
                  { name:"Empresa", width:"7%" },
                  { name:"OTP", width:"15%" },
                  { name:"Seleccionar", width:"5%" },
              ]
          );
            const items = (response.response.data).map(element => {
                return (
                    <tr key={ v4() }>
                        <td > { element.nombres+" "+ element.apellidos }  </td>
                        <td>{ element.email }</td>
                        <td>{ element.celular }</td>
                        <td>
                                { element.referencia }
                        </td>
                        <td align={"center"}>
                            {
                                (element.otpSecret && element.otpLastUse) ? (
                                    <SoftBadge variant={  (element.otpSecret && element.otpLastUse) ? "gradient": "contained"  } badgeContent={   " otp "  } size="lg" container />
                                ):''
                            }
                        </td>
                        <td align={"center"}>{
                            ( !element.disabled &&
                                <SoftButton  color="color6A" onClick={ (event)=> handleSeleccionar(event,element.uuid,`${element.nombres} ${element.apellidos}`) } iconOnly >
                                    <IconAddTask fontSize="36px"  color="white" title="Seleccionar" />
                                </SoftButton>
                            )
                            }
                        </td>
                    </tr>
                );
            })

          setRows(items);
          
          
        });
      }
    //#endregion

    
    useEffect(()=>{
        listar();
    },[]);

    const handleEncontrar = (event) => {
        if (event.key === "Enter") {
            listar(txtBuscar);
        }
    }

    return(
        <>
            <SoftBox mb={3}>
                <Card>
                    <Grid container>
                        <Grid item xs={8} md={6} xl={6}>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2}>
                                <SoftTypography >{subtitle}</SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={8} md={1} xl={3} pt={2}>
                            <button className={"btn-alpha-info"} type={"submit"} onClick={ handleRefresh }>
                                <i className="fa-solid fa-arrows-rotate"></i> Refrescar
                            </button>
                        </Grid>
                        <Grid item xs={8} md={5} xl={3} pt={2} pr={3}>
                            <input type={"search"}  className={"ITxtEncontrar-Banca"} name={"encontrar"} value = { txtBuscar } maxLength={"50"} placeholder={"Buscar"}
                                   onChange={ (event)=> handleChangeBusqueda(event)  } onKeyDown={  handleEncontrar }  />
                        </Grid>

                    </Grid>
                    <SoftBox
                        sx={{
                        "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                            },
                        },
                        }}
                        >
                        <AlphaTable records={ rows } headers={ columns } />
                    </SoftBox>
                </Card>
            </SoftBox>
        </>
    )
    
}

ListadoPersonas.propTypes = {
    subtitle: PropTypes.string.isRequired,
    onSelectedPersona: PropTypes.func,
    additionalFilter: PropTypes.object
};

export default ListadoPersonas;