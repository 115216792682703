
import {autorizaciones, login} from 'store/slice/authSlice';
import Persona from "../../Repository/Personas";
import moment from "moment";

export const doLogin =  (credentials)=>{

    return async(dispatch, getState)=>{

        /*
        const response = await  persona.loggin(credentials);
        if(response.status == 'error'){
            dispatch( logout() );
            sessionStorage.clear();
        }else {
            dispatch(login(response));
            sessionStorage.setItem("session", JSON.stringify( response.response ) );
            console.log(getState().auth);
        }
        */

        localStorage.setItem("cToken", credentials.token);
        localStorage.setItem("session", JSON.stringify( {
            ...credentials,
             refreshToken: credentials.refreshToken,
            expires : moment(credentials.expires).format("YYYY-MM-DD HH:mm:ss")
        } ) );

        dispatch(login(credentials));
    }
    
    
}

export const doAutorizaciones =  ()=>{
    const personax = new Persona();
    return async(dispatch, getState)=>{
        const result = await personax.personaAutorizaciones();
        if(result.status === 'ok') dispatch( autorizaciones(result.response) );
    }
}