import * as React from "react";
import Grid from "@mui/material/Grid";
import {useForm} from "../../../customHooks/useForm";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {actualizarCuenta, crearCuenta, mostrarCuenta} from "../../../store/thunk/cuentas";
import {closeModalFromCatalogo, openModal, setAlertMessage} from "../../../store/slice/alertSlice";
import {v4} from "uuid";
import {cuentaCancelEdit} from "../../../store/slice/cuentasSlice";
import Cuenta from "../../../Repository/Cuenta";
import PropTypes from "prop-types";

const formValidation = {
    licencia_uuid: [(value)=> value !== "","Licencia es un campo requerido."],
    empresa_uuid: [(value)=> value !== "","Empresa es un campo requerido."],
    tiempoEspera: [(value)=> {
        const regex = /^[1-9]\d{0,5}$/;
        return regex.test(value);
    },"Por favor coloque el tiempo que esperara antes de iniciar la consulta de estado de la transacción."],
}

function DetalleV2 ({ auth_cuenta_uuid = '', plataforma = "netpay", handleClose  }) {
    const dispatch = useDispatch();
    const { uuid, currentRecord:initialForm } = useSelector(state => state.cuentas);
    const frmResult = useForm( initialForm, formValidation );
    const {tiempoEspera,empresa_uuid = '', licencia_uuid = '',  onInputChange, isFormValid, setFormState } = frmResult;
    const [licenciasList, setLicenciasList] = useState([]);
    const [empresasList, setEmpresasList] = useState([]);

    useEffect(()=>{
        setFormState( initialForm );
    } ,[uuid])

    //consultar empresas y licencias
    useEffect(()=>{
        consultarEmpresa();
    },[])

    const consultarEmpresa = async ()=>{
        const cuentax = new Cuenta();
        const result = await cuentax.listarEmpresas(auth_cuenta_uuid, plataforma);
        if(result.status !== 'error'){
            setEmpresasList(result.response.empresas);
            setLicenciasList(result.response.licencias);
        }else{
            setEmpresasList([]);
            setLicenciasList([]);
        }

    }


    const onClickGuardar = ()=>{
        if(!isFormValid){
            for (const prop in frmResult.validationResult) {
                if (frmResult.validationResult[prop] !== null) {
                    const alert = {
                        message : frmResult.validationResult[prop],
                        icon:"",
                    }
                    dispatch( setAlertMessage( alert )  );
                    return;
                }
            }
            return;
        }
        const params = {
            ...initialForm,
            licencia_uuid,
            empresa_uuid,
            tiempoEspera,
            plataforma,
            auth_cuenta_uuid:auth_cuenta_uuid,//esta es la cuenta concentrado proveniente del auth service
        };

        if(uuid){
            dispatch( actualizarCuenta( params )).then((result)=>{
                handleClose();
            })
        }else{
            dispatch( crearCuenta( params )).then(()=>{
                handleClose();
            });
        }
    }

    /*
    const handleClose = ()=>{
        localStorage.removeItem("accountId");
        navigate('/cuentas-stp',{replace:true} );
    }
    */

    const handleClose2 = ()=>{
        localStorage.removeItem("accountId");
        dispatch( cuentaCancelEdit() );
        dispatch( closeModalFromCatalogo({cuentas:{ open:false } }  ));
        handleClose();
    }

    return (
        <>
            <Grid container  p={1}>
                <Grid item xs={6} md={2} xl={2}  textAlign={"right"} pr={1} >
                    <span className={"lbl-etiqueta"}  >Licencia</span>
                </Grid>
                <Grid item xs={12} md={2} xl={2}>
                    <select className={"ITextbox"} name={"licencia_uuid"} value={ licencia_uuid } onChange={ onInputChange }>
                        <option key={ v4() }  value={ ""}> { "-" } </option>
                        { licenciasList.map(element =>{
                            return <option key={ v4() }  value={ element.value }> { element.label } </option>
                        }) }
                    </select>
                </Grid>
                <Grid item xs={6} md={1} xl={1} textAlign={"right"} pr={1} >
                    <span className={"lbl-etiqueta"}>RFC</span>
                </Grid>
                <Grid item xs={12} md={4} xl={4}>
                    <select className={"ITextbox"} name={"empresa_uuid"} value={ empresa_uuid } onChange={ onInputChange }>
                        <option key={ v4() }  value={ ""}> { "-" } </option>
                        { empresasList.map(element =>{
                            return <option key={ v4() }  value={ element.value }> { element.label } </option>
                        }) }
                    </select>
                </Grid>
                <Grid item xs={6} md={2} xl={2} textAlign={"right"} pr={1}   >
                    <span className={"lbl-etiqueta"}>Tiempo de espera <i className="fa-solid fa-circle-info" title={"Tiempo que debe transcurrir para poder consultar una transacció tomando como punto de partida su envio a la TPV"}></i></span>
                </Grid>
                <Grid item xs={12} md={1} xl={1}>
                    <input type={"text"} className={"ITextbox"} name={"tiempoEspera"}  value={ tiempoEspera } onChange={ onInputChange } />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} xl={8} mt={2}    >
                </Grid>
                <Grid item xs={6} md={3} xl={2} mt={2}    >
                    <button className={"btn-alpha-success-2 pad-element"} type={"submit"} onClick={ onClickGuardar }  >
                        <i className="fa-solid fa-circle-check"></i> Aceptar
                    </button>
                </Grid>
                <Grid item xs={6} md={3} xl={2} mt={2} pl={1}  >
                    <button className={"btn-alpha-danger pad-element"} type={"submit"} onClick={ handleClose2 }>
                        <i className="fa-solid fa-circle-xmark"></i> Cancelar
                    </button>
                </Grid>
            </Grid>
        </>
    )



}

DetalleV2.propTypes = {
    auth_cuenta_uuid: PropTypes.string.isRequired,
    plataforma: PropTypes.oneOf(["netpay","paypal"]),
    handleClose: PropTypes.func,
}

export default DetalleV2;