
import PropTypes from "prop-types";
import SoftBox from "../../../../components/SoftBox";
import Grid from "@mui/material/Grid";
import moment from "moment";

export const TPV = ({ transaccion = {} }) => {

    return (
        <SoftBox sx={{ border:"5px solid #f7f7f7", padding:1 }} >
            <Grid container >
                <Grid item md={2} lg={2} xs={6} textAlign={"right"} >
                    <label className={"IGenericLabel-sm"}>Fecha de Operación</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ moment(transaccion?.fechaOperacion).format("DD-MM-YYYY HH:mm")   } readOnly />
                </Grid>
                <Grid item md={2} lg={2} xs={6} textAlign={"right"}>
                    <label className={"IGenericLabel-sm"}>Terminal</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.terminalId } readOnly />
                </Grid>
                <Grid item md={2} lg={2} xs={6} textAlign={"right"}>
                    <label className={"IGenericLabel-sm"}>Autorización</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.codigoAutorizacion } readOnly />
                </Grid>

                <Grid item md={2} lg={2} xs={6} textAlign={"right"}>
                    <label className={"IGenericLabel-sm"}>Monto</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} textAlign={"right"}>
                    <input type={"text"} className={"ITextbox"} value={ transaccion.monto } readOnly />
                </Grid>
                <Grid item md={2} lg={2} xs={6} textAlign={"right"}>
                    <label className={"IGenericLabel-sm"}>Banco</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.nombreBanco } readOnly />
                </Grid>
                <Grid item md={2} lg={2} xs={6} textAlign={"right"}>
                    <label className={"IGenericLabel-sm"}>Tarjeta</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ "****"+ (transaccion?.ultimos4Digitos || "") } readOnly />
                </Grid>
                <Grid item md={2} lg={2} xs={6} textAlign={"right"}>
                    <label className={"IGenericLabel-sm"}>Tipo de Tarjeta</label>
                </Grid>
                <Grid item md={2} lg={2} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.tipoTarjeta } readOnly />
                </Grid>
                <Grid item md={2} lg={2} xs={6} textAlign={"right"}>
                    <label className={"IGenericLabel-sm"}>No. orden</label>
                </Grid>
                <Grid item md={4} lg={4} xs={6} >
                    <input type={"text"} className={"ITextbox"} value={ transaccion.ordenId } readOnly />
                </Grid>
            </Grid>
        </SoftBox>
    )
}

TPV.propTypes = {
    transaccion: PropTypes.object,
};

export default TPV;