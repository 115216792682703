import * as React from "react";
import SoftBox from "../../../components/SoftBox";
import Box from "@mui/material/Box";
import {Grid, useMediaQuery} from "@mui/material";
import PropTypes from "prop-types";
import logox from "../../../assets/alpha-theme/images/cards/cobranza3v2.png";

const CardMessages = ({ message, iconx }) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <SoftBox borderRadius={"12px"} sx={{ borderBottomColor:"#c3c3c3", margin:1, background:"linear-gradient(to bottom left, #c3c3c3, #FBFBFB)", paddingBottom:1 }}  >
                <Box sx={{ borderTopRightRadius:5,paddingTop: 1,paddingLeft:1, paddingRight:1, borderTopLeftRadius:3 }} >
                    <Box sx={{ backgroundColor:"#4d4d4d",color:"#fff",borderRadius:2,margin:1 }} >
                        <Grid container>
                            <Grid item xs={12} md={6} xl={6}  textAlign={"left"}>
                                <Box className={"sec-imagen"}  >
                                    <img className={"Img-PrintInvoice"} src={ logox } alt={"Cobranza Automatizada"} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <SoftBox bgColor="color18" borderRadius="lg"
                         shadow="lg"
                         opacity={1} sx={{ margin:2, padding:2 }}>
                    <Box sx={{ textAlign:"center", color:"#4A80A8" }}>
                        { message }
                    </Box>
                </SoftBox>
            </SoftBox>
        </>
    );
}

CardMessages.propTypes = {
    message: PropTypes.string.isRequired,
    iconx: PropTypes.string.isRequired,
}

export default CardMessages;