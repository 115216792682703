/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import {useDispatch, useSelector} from "react-redux";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React base styles

// Data
import { CentrosDeCosto } from "./components/CentrosDeCosto";
import {Grid, useMediaQuery, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {EstadoDeCuenta} from "./components/EstadoCuenta/EstadoDeCuenta";
import {estadoCuentaCancelWiewing} from "../../../store/slice/banca/estadoCuentaSlice";
import LoaderAlpha from "../../dashboard/components/LoaderAlpha";

const EstadoDeCuentaStp = (props) => {
    const theme = useTheme();
    const smMovil = useMediaQuery(theme.breakpoints.up('sm'));
    const dispatch = useDispatch();
    const { windowHeight } = useSelector( state => state.ventana );
    const { cdc_uuid, loading, refreshing, transacciones } = useSelector( state => state.estadocuenta );
    const [ smShow, setSmShow ] = useState( smMovil );

    //limpiamos el estado al ingesar a la pagina
    useEffect(()=>{
        dispatch( estadoCuentaCancelWiewing() );
    },[]);
  //Si ya esta autenticado devolvemos el dashboard si no lo mandamos a loguearse

    useEffect(()=>{
        setSmShow(smMovil);
    },[smMovil])

    useEffect(()=>{
        //code
        if( smMovil === false && cdc_uuid !== null ) setSmShow(false);
    },[transacciones]);

    useEffect(()=>{
        setSmShow(true);
    },[])

    const onClickShowFilters = () =>{
        setSmShow(!smShow);
    }

    return (
        <SoftBox >
            <Grid container>
                <Grid item xs={12} md={12} xl={12} pt={1} >
                    { !smMovil &&
                        <SoftBox  sx={{ float:"right", paddingRight:1, display:"flex" }}  >
                            <SoftBox sx={{ color:"#80C1AA", cursor:"pointer" }} onClick={ onClickShowFilters }>
                                <label className={"IGenericLabel-Bold"} >{ ( smShow ? "Ocultar Filtros":"Mostrar Filtros") }</label>
                            </SoftBox>
                            <button type={"button"} className={"Icon-filter"} onClick={ onClickShowFilters } title={"Mostrar u ocultar filtros"}>
                                 <i className="fa-brands fa-slack" ></i>
                            </button>
                        </SoftBox>
                    }
                </Grid>
                <Grid item xs={12} md={3} xl={3}
                >
                    {
                        <SoftBox className={"edo-cuenta"} sx={{ display: ( smShow ? "block":"none"), height: windowHeight +"px",position:"relative", overflow:"auto", minHeight: windowHeight+"px",  border:"5px solid #f7f7f7",marginTop:1 }}   >
                            <CentrosDeCosto />
                        </SoftBox>
                    }
                </Grid>
                <Grid item xs={12} md={9} xl={9} pt={1} >
                    <SoftBox  sx={{ border:"5px solid #f7f7f7", }}   >
                        { cdc_uuid && (!loading || !refreshing) ? <EstadoDeCuenta /> : '' }
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    );
  
}

export default EstadoDeCuentaStp;
