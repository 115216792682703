import * as React from "react";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import SoftBox from "../../../../components/SoftBox";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import SoftBadge from "../../../../components/SoftBadge";
import closePopupImg from "../../../../assets/alpha-theme/images/cerrarPopup.png";
import PropTypes from "prop-types";
import TxtEncontrarDesacoplado from "./TxtEncontrarDesacoplado";
import Cuenta from "../../../../Repository/Cuenta";
import {useListadosHook} from "../customHook/useListadosHook.";
import AlphaTable from "../../../cuentas/components/AlphaTable";

const initialForm = {
    txtBuscar: "",
}

const formValidation = {
    txtBuscar: [(value)=> true],
}

function BuscarCentroDeCostos ({fnOnSelectItem ,fnOnCloseWindow, sizeAlert="sm" }) {

    const { catalogo, tblColumns, tblRows,selectedItem, fillTable} = useListadosHook('cuentas');
    const [textoBuscar, setTextoBuscar]  = useState('');

    /**
     * Eventos React
     */
    useEffect(()=>{   loadCatalogoCuentas();  },[])

    useEffect(()=>{
        if(selectedItem === null) return;
        fnOnSelectItem(selectedItem);
    },[selectedItem])

    const onSearch = (event, item) => {
        setTextoBuscar(item);
        loadCatalogoCuentas(item);
    }

    /**
     * Metoddos
     */

    const loadCatalogoCuentas = async ( txt = textoBuscar) => {
        const cuentasCat = new Cuenta();
        console.log({ 'textoBuscar':txt });
        const result = await cuentasCat.listar({ 'textoBuscar':txt });
        if(result.status === "error") return;
        fillTable( result.response.data );
    }


    //TODO hacer tabla de datos

    return (
        <Dialog open={ true } onClose={ fnOnCloseWindow } fullWidth={true}  maxWidth={ sizeAlert }
                PaperProps={{ style:{  borderRadius:"0.6rem", color:"white", overflowY:"unset" } }}
        >

            <DialogTitle >
                <SoftBadge
                    onClick={ fnOnCloseWindow }
                    sx={{
                        position: 'absolute',
                        right: -13,
                        top: -16,
                        color: "#fff",
                        backgroundColor: "#DB6161",
                        fontSize:"0.8rem",
                        border:"3px solid white",
                        borderRadius: "50%",
                        padding:"3px",
                        cursor:"pointer",
                    }}
                >
                    <img src={ closePopupImg } alt={"Img Cerrar PopUp"} width={ "20px" } height={"20px"}  />
                </SoftBadge>
                <div className={ "titleSegment" } >
                    <i className="fa-solid fa-file-signature pl-element pad-element txt-success "></i> { "Buscar centro de costos" }
                </div>
            </DialogTitle>
            <DialogContent>

                <Grid container  >
                    <Grid item xs={1} md={8} xl={8}  >
                    </Grid>
                    <Grid item xs={11} md={4} xl={4}  >
                        <SoftBox sx={{ textAlign:"right" }} >
                            <TxtEncontrarDesacoplado fnOnSearch={ onSearch } />
                        </SoftBox>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={12} xl={12}  >
                        <AlphaTable headers={ tblColumns} records={  tblRows } />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

BuscarCentroDeCostos.propTypes = {
    fnOnSelectItem : PropTypes.func.isRequired,
    fnOnCloseWindow : PropTypes.func.isRequired,
    sizeAlert: PropTypes.string.isRequired
}

export default BuscarCentroDeCostos;