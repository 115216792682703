import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SoftBox from "../../../components/SoftBox";
import Icon from "@mui/material/Icon";
import PlataformasV2 from "./PlataformasV2";
import {mostrarCuenta} from "../../../store/thunk/cuentas";
import {useDispatch} from "react-redux";
import WebModalDinamic from "./WebModalDinamic";
import DetalleV2 from "./DetalleV2";
import {closeModalFromCatalogo} from "../../../store/slice/alertSlice";
import Cuenta from "../../../Repository/Cuenta";
const CdcPlataforma = ({auth_cuenta_uuid = "",cdcInfo= {}, plataforma="netpay" }) => {
    const dispatch = useDispatch();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [ openCdcWindow, setOpenCdcWindow ] = useState(false);
    const [rfc, setRfc] = useState(cdcInfo.rfc);
    const [licencia, setLicencia] = useState(cdcInfo.licencia);
    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    const iconDown = <i className="fa-solid fa-chevron-down icons-alpha"></i>;
    const iconUp = <i className="fa-solid fa-angle-up icons-alpha"></i>;

    function fnItemToCheck (uuid = ""){
        dispatch( mostrarCuenta(uuid) );
        setOpenCdcWindow(true);
    }

    async function handleCloseModalCuenta (){
        dispatch( closeModalFromCatalogo({
            cuentas:{
                open:false,
            }
        }) )
        setOpenCdcWindow(false);
        const cuentax = new Cuenta();
        const {status= "", response = {}} = await cuentax.mostrar(cdcInfo.uuid);
        if(status === 'ok' )
        {
            setRfc( response.rfc );
            setLicencia(response.licencia)
        }

    }

    return (
        <SoftBox bgColor={"white"}>
            <div className="accordion">

                <div className="accordion-header" >
                    <SoftBox sx={{ display:"inline-flex", width:"100%" }}  >
                        <SoftBox onClick={()=>fnItemToCheck(cdcInfo.uuid)} sx={{paddingRight: 1, paddingTop:0.5,cursor:"pointer" }}>
                            <Icon sx={{ color:"#DB6161" }}  fontSize="small">edit</Icon>
                        </SoftBox>
                        <SoftBox  onClick={handleToggle} sx={{ width:"100%" }} >
                            <i className={""}></i> <label className={"filtro-text-v2"}>{`RFC: ${ rfc } - Serie: ${ licencia }`}</label>
                            <label className={"icon-colapse-position"}>
                                {isCollapsed ? iconUp : iconDown}
                            </label>
                        </SoftBox>
                    </SoftBox>

                </div>
                {isCollapsed && (
                    <div className="accordion-body">
                        <PlataformasV2 cuenta = { cdcInfo } plataforma={ plataforma } />
                    </div>
                )}
            </div>
            {
                openCdcWindow && <WebModalDinamic title={"Información del centro de costos"}
                                                  formComponent={ <DetalleV2 plataforma={ plataforma } auth_cuenta_uuid={ auth_cuenta_uuid } handleClose={ handleCloseModalCuenta }  /> }
                                                  fnModaCloseAction={ handleCloseModalCuenta } modalSize={"lg"} />
            }
        </SoftBox>
    );
}

CdcPlataforma.propTypes = {
    cdcInfo: PropTypes.object,
    plataforma: PropTypes.string,
    auth_cuenta_uuid: PropTypes.string,
}

export default CdcPlataforma;