import React, {   useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import IconAddTask from '@mui/icons-material/AddTask';
import IconMenu from '@mui/icons-material/Menu';

import Grid from "@mui/material/Grid";
import SoftInput from "components/SoftInput";
//
// Soft UI Dashboard React base styles
import borders from "assets/theme/base/borders";
import CdcsRequest from 'Repository/Banca/CentroDeCosto';
import Plaza from '../components/ItemPlaza';
import {v4} from "uuid";

function GridPlazas({cdcs_uuid}) {

    const [records, setRecords] = useState([]);
    const [recordsOrigin, setRecordsOrigin] = useState([]);
    const { borderWidth, borderColor } = borders;
    const [txtBuscar, setTxtBuscar] = useState('');
    const cdcsx = new CdcsRequest();
    
    //#region "events"
    useEffect(()=>{
        loadData();
    },[]);

    const handleChangeBusqueda = (event)=>{
        setTxtBuscar(event.target.value);
        const filter = recordsOrigin.filter( item => (item.cdc.includes(event.target.value)  )  );
        setRecords(filter);
    }

    const handleRefreshPlazas = (event)=>{
        loadData();
    }
    //#endregion

    //#region methods
    const loadData= (  )=>{
        cdcsx.plazas(cdcs_uuid).then(response=>{
            setRecords(response.response.data);
            setRecordsOrigin(response.response.data);
        });
    }
    //#endregion

    return(
        <>
            <SoftBox p={2} bgColor="color4V" color="color4"  opacity={1}>
               
                <Grid container pl={1} pt={2} >
                    <Grid item xs={3} md={6} xl={7}>
                        Plazas creadas
                    </Grid>
                    <Grid item xs={3} md={2} xl={2} alignItems="right">
                            Buscar
                    </Grid>
                    <Grid item xs={6} md={3} xl={3} pr={3}>
                        <SoftBox pl={1} >
                            <SoftInput placeholder="Buscar" type="text"
                            value={txtBuscar}
                            onChange={ (event)=> handleChangeBusqueda(event) } 
                            />
                        </SoftBox>
                    </Grid>
                    <Grid item xs={1} md={1} xl={1} ></Grid>
                </Grid>
            </SoftBox>
            
            <SoftBox p={2}>
                <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    { records.map((cdc) =>{
                        return  <Plaza key={ v4() } plaza={cdc} onAdd={ (event) => handleRefreshPlazas(event) } />
                    })}
                </SoftBox>
            </SoftBox>
        </>
    )
    
}

GridPlazas.propTypes = {
    cdcs_uuid: PropTypes.string
  };

export default GridPlazas;