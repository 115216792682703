import { Routes, Navigate, Route } from "react-router-dom"
import routes from "../routes";
import routespages from "../route-pages";
import {useSelector} from "react-redux";
import Registro from "../layouts/registro";
import FrmTerminal from "../layouts/terminales/components/forms/FrmTerminal";
import FrmTerminalEdit from "../layouts/terminales/components/forms/FrmTerminalEdit";

export const PanelRoutes = () => {
    const { roles } = useSelector(state=> state.auth);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                const attachRoute = roles.filter (element => route.role.includes(element) );
                if(attachRoute){
                    return <Route exact path={route.route} element={route.component} key={route.key} />;
                }
            }

            return null;
        });

    return (
        <Routes>
            { getRoutes(routes) }
            { getRoutes(routespages) }
            <Route exact path="/cuenta/registro" element={ <Registro /> } key="cuenta-detalle2"  />
            <Route exact path="/cuenta/registro/:uuid" element={ <Registro /> } key="cuenta-detalle"  />
            <Route exact path="/terminales/registro" element={ <FrmTerminal /> } key="terminales-registro"  />
            <Route exact path="/terminales/registro/:uuid" element={ <FrmTerminalEdit /> } key="terminales-registro-edit"  />
            <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
    )
}