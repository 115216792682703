import * as React from "react";
import {useDispatch, useSelector} from "react-redux";

import SoftBox from "../../../components/SoftBox";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";

import "assets/alpha-theme/loader.css";
import imgMovil from "assets/alpha-theme/images/movilst.png"
import {closeGeneralLoader} from "../../../store/slice/alertSlice";
import rgba from "../../../assets/theme/functions/rgba";

const LoaderAlpha = (props) => {

    const { showLoading } = useSelector( state => state.alertas );
    const dispatch = useDispatch();

    const handleClose = ()=>{
        dispatch( closeGeneralLoader() );
    }

    return (
        <Dialog open={ showLoading } onClose={handleClose} fullWidth={true}  maxWidth={ "xs"  }
                PaperProps={{ style:{ boxShadow:"none" ,border:"0px solid #7f7f7f", textAlign:"center" , backgroundColor: rgba("#7f7f7f",0.0) } }}
        >
            <DialogContent>
                <SoftBox  >
                        <div className="contenedor-loading-animacion">
                            <span className="loader"></span>
                            <img src={ imgMovil } width={"88px"} alt="loading"/>
                        </div>
                </SoftBox>
            </DialogContent>
        </Dialog>

        )
}

export default LoaderAlpha;