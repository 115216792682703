import React, {  useEffect, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/Table"; 
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
// @mui material components
import Grid from "@mui/material/Grid";
import SoftButton from "components/SoftButton";

import IconClose from "@mui/icons-material/Close"
import IconEdit from "@mui/icons-material/Edit"
import IconAdd from "@mui/icons-material/Add"

import Licencias from 'Repository/Banca/Licencias';
import EstatusV2 from './EstatusV2';
import AddLicencia from './AddLicencia';
import AlphaTable from "../../../cuentas/components/AlphaTable";
import {v4} from "uuid";
import SoftBadge from "../../../../components/SoftBadge";
import Estatus from "./Estatus";
import Autorizador from "../../../../Repository/Banca/Autorizador";

function ListadoLicencias({cuenta_uuid, onCloseComponent}) {

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    const [dataToEdit, setDataToEdit] = useState( {} );
    const [formOpen, setFormOpen] = useState(false) 
    const [reset, setReset] = useState(0);

    const licenciax = new Licencias();

    useEffect(()=>{
        listar();
    },[])

    //#region methods
    const listar = ()=>{
        licenciax.listar(cuenta_uuid).then(response=>{
            setColumns(
                [
                  { name: "Licencia", width: "7%" },
                  { name: "Estatus", width: "10%" },
                  { name: "Acciones", width: "10%" },
                ]
                );

                const items = (response.response.data).map(element => {
                    return (
                        <tr key={ v4() }>
                            <td >  { element.serie }  </td>
                            <td align={"center"}>
                                <EstatusV2 key={element.uuid} item= {element} currentObject={ Licencias } onStateUpdatetem ={ (event)=>onUpdateStateItemEmpresas(event) } />
                            </td>
                            <td>
                                <SoftButton fullWidth variant="text"  color="color7A" size="large" onClick={ (event) => onSelectEditItem(event, element.uuid) } title="Editar" >
                                    <IconEdit /> Editar
                                </SoftButton>
                            </td>
                        </tr>
                    );
                })
                setRows(items);
        })
    }
    //#endregion

    //#region events
    const onAddHandle = ()=>{
        listar();
    }

    const onClickCloseComponent = ()=>{
        onCloseComponent();
    }

    const onItemChanged = ()=>{
        //
        console.log('lo cambiaron de estatus we');
    }

    const onSelectEditItem = (event, value)=>{
        licenciax.mostrar(value).then(response=>{
            if(response.status  == 'ok'){
                let data = response.response.data;

                //console.log(data);
                setDataToEdit({
                    ...dataToEdit, 
                    'cuenta_uuid': cuenta_uuid,
                    'uuid': data.uuid,
                    'serie': data.serie,
                });
                setFormOpen(true);
            }else{

            }
            
        })
    }

    const onClickOpenForm= ()=>{
        setFormOpen(true);
        setReset(reset +1 );
    }

    const onCancelHandle = ()=>{
        setFormOpen(false);
        setDataToEdit({
            ...dataToEdit, 
            'cuenta_uuid': '',
            'uuid': '',
            'serie': '',
        });
    }

    const onUpdateStateItemEmpresas = (event)=>{
        listar();
    }

    //#endregion

    return(
        <>
            <SoftBox sx={{ border:"5px solid #f7f7f7", }} >
                <Card sx={{ padding:1 }}>

                    <Grid container >
                        <Grid item xs={8} md={4} xl={8}>
                            <SoftBox   alignItems="center" >
                                <label className={"IGenericLabel-1-5f"} > Licencias </label>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={4} md={4} xl={2} pt={2}>
                            
                        </Grid>
                        <Grid item xs={4} md={4} xl={2}  mb={1}>
                            <button className={"btn-alpha-black pad-element"} type={"button"} onClick={ (event) => onClickOpenForm(event) } >
                                <i className="fa-solid fa-plus"></i> Agregar licencia
                            </button>
                        </Grid>
                    </Grid>

                    { formOpen &&  <AddLicencia uuid = { cuenta_uuid } onAdd = { onAddHandle } onCancel={ onCancelHandle }  data = { dataToEdit } reset={ reset } /> }

                    
                    <SoftBox
                        >
                        <AlphaTable records={ rows } headers={ columns } />
                    </SoftBox>
                </Card>
            </SoftBox>
        </>
    )
    
}

ListadoLicencias.propTypes = {
    cuenta_uuid: PropTypes.string,
    onCloseComponent: PropTypes.func
  };

export default ListadoLicencias;