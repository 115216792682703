import React, { useState} from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Swal from 'sweetalert2';

import Autorizador from "Repository/Autorizador";
import SoftBadge from "../../../components/SoftBadge";

function Notificaciones({ cuenta_autorizador_uuid,notificaciones = {}, plataforma }) {
    const [ notificacionesLocal , setNotificacionesLocal ] = useState(notificaciones);
    const autorizadorx = new Autorizador();
    console.log( notificaciones, plataforma );
    const permisosInit = {
        netpay:
            {
                recibirNotificacionTpv: {
                    'true': 'Notificar operaciones de Terminal Punto de Venta',
                    'false': 'Notificar operaciones de Terminal Punto de Venta'
                },
                recibirNotificacionLigadePago: {
                    'true': 'Notificar operaciones de Liga de Pago',
                    'false': 'Notificar operaciones de Liga de Pago'
                },
                recibirNotificacionPagoRecurrente: {
                    'true': 'Notificar operaciones de Cobros Recurrentes',
                    'false': 'Notificar operaciones de Cobros Recurrentes'
                },
            },
        paypal:{
            recibirNotificacionPagoPorPaypal:{
                'true': 'Notificar operaciones de Cobranza',
                'false': 'Notificar operaciones de Cobranza'
            }
        }
    }

    const  operaciones  = async (permiso)=>{
        const result = await Swal.fire({
            title: 'Notificaciones',
            text: "¿Desea continuar con el cambio en la configuración?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        });
        if(result.isConfirmed){
            const valor = !!notificacionesLocal[`${permiso}`];
            setNotificacionesLocal({
                ...notificacionesLocal,
                [`${ permiso }`]: !valor
            })
            notificacionesLocal[`${permiso}`] =  !valor;
            const params = {
                uuid:cuenta_autorizador_uuid,
                'notificaciones': notificacionesLocal
            }
            await autorizadorx.notificaciones(params);
        }

    }

    const handleCheckChange = (permiso)=>{
        operaciones(permiso);
    }

    const permisosBtns = () =>{
        return Object.keys(permisosInit[plataforma]).map( property => {
            const checked   = notificacionesLocal[`${ property }`];
            const label     = notificacionesLocal[`${ property }`] ?  permisosInit[plataforma][`${ property }`][true]: `${ permisosInit[plataforma][`${ property }`][false] }`;
            return <SoftBadge key={ uuidv4() }
                              badgeContent={ label }
                              size="xs" container
                              color={ checked ? "info":"light"}
                              sx={{ paddingRight:"2px", cursor:"pointer" }}
                              onClick={ () => handleCheckChange(property ) }
            />
        })
    }
    return(
        <SoftBox   >
            { permisosBtns() }
        </SoftBox>
    )

}

Notificaciones.propTypes = {
    cuenta_autorizador_uuid: PropTypes.string.isRequired,
    notificaciones: PropTypes.object,
    plataforma: PropTypes.string.isRequired,
};

export default Notificaciones;