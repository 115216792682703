/**
 * @date 03/2023
 * @author JOSE ALBERTO SAN JUAN SAN AGUSTIN
 * Almacenamiento de las fuentes personalizadas que vamos a estar utilizando, TTCommons para ALPHa WEB
 *
 */

import TTCRegular  from "assets/alpha-theme/fonts/TTCommons-Regular.ttf";
import TTCBold  from "assets/alpha-theme/fonts/TTCommons-Bold.ttf";
import TTCDemibold  from "assets/alpha-theme/fonts/TTCommons-DemiBold.ttf";
import TTCItalic  from "assets/alpha-theme/fonts/TTCommons-Italic.ttf";
import TTCBlack  from "assets/alpha-theme/fonts/TTCommons-Black.ttf";
import TTCBoldItalic  from "assets/alpha-theme/fonts/TTCommons-BoldItalic.ttf";

const fonts = {
    "global":{
        "@font-face":[
            {
                fontFamily: 'TTCRegular',
                fontStyle: "normal",
                fontWeight: "normal",
                src: `url(${ TTCRegular }) format('ttf')`,
            },
            {
                fontFamily: 'TTCBold',
                fontStyle: "normal",
                fontWeight: "normal",
                src: `url(${ TTCBold }) format('ttf')`,
            },
            {
                fontFamily: 'TTCDemibold',
                fontStyle: "normal",
                fontWeight: "normal",
                src: `url(${ TTCDemibold }) format('ttf')`,
            },
            {
                fontFamily: 'TTCItalic',
                fontStyle: "normal",
                fontWeight: "normal",
                src: `url(${ TTCItalic }) format('ttf')`,
            },
            {
                fontFamily: 'TTCBlack',
                fontStyle: "normal",
                fontWeight: "normal",
                src: `url(${ TTCBlack }) format('ttf')`,
            },
            {
                fontFamily: 'TTCBoldItalic',
                fontStyle: "normal",
                fontWeight: "normal",
                src: `url(${ TTCBoldItalic }) format('ttf')`,
            },
        ]
    }
}

export default fonts;