import * as React from "react";
import SoftBox from "../../../components/SoftBox";
import Box from "@mui/material/Box";
import {Grid, useMediaQuery} from "@mui/material";
import {useSelector} from "react-redux";
import {useRef} from "react";
import {useReactToPrint} from "react-to-print";

import imgOxxoPay from "assets/alpha-theme/images/cards/option/oxxong.png";
import {v4} from "uuid";
import moment from "moment";
import Barcode from "react-barcode";
import logox from "../../../assets/alpha-theme/images/cards/cobranza3v2.png";
import Typography from "@mui/material/Typography";
import SectionHeader from "./SectionHeader";
import {useNumberFormat} from "../../../customHooks/useNumberFormat";
import Ambiente from "../../../appComponents/Ambiente";

const CardPrintReference2 = (props) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const componentRef = useRef();
    const { numberFormat } = useNumberFormat();
    const { cuenta,transaccion, referencia, vencimiento, cliente } = useSelector(state => state.cobranza);


    const getMes = () =>{
        if(!moment(vencimiento).isValid()) return <></>
        const expiracion = moment(vencimiento);
        return <>{ expiracion.format("DD") } de { expiracion.format("MMMM") } </>
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const defaultComponent = <SoftBox borderRadius={"12px"} sx={{ borderBottomColor:"#c3c3c3", margin:1, background:"linear-gradient(to bottom left, #D9D9E8, #FBFBFB)", paddingBottom:1 }} ref={componentRef}  >
        <Box sx={{ borderTopRightRadius:5,paddingTop:1, borderTopLeftRadius:5 }} >

            <Box sx={{ backgroundColor:"#4d4d4d",marginBottom:"5px", paddingTop:2,color:"#fff",borderRadius:2,marginRight:3, marginLeft:3, marginTop:3 }} >
                <Grid container>
                    <Grid item xs={6} md={6} xl={6}  textAlign={"left"}>
                        <Box sx={{  paddingLeft:7 }} >

                            <img src={ logox } alt={"Cobranza Automatizada"} className={"Img-PrintReference"} />

                        </Box>
                    </Grid>

                    <Grid item xs={6} md={6} xl={6} pl={1} className={"sec-titulos"}>
                        <Box  className={"sec-titulos-rs"}>
                            {cuenta.rs}
                        </Box>
                        <Box >
                            <Typography fontSize={14} >
                                {cuenta.direccion}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

        </Box>

        <SoftBox bgColor="color18" borderRadius="lg"
                 shadow="lg"
                 opacity={1} sx={{ margin:3, padding:3 }}>
            <Grid container >

                <Grid item xs={12} md={12} xl={12} className={""} >
                    <table>
                        <tbody>
                        <tr>
                            <td colSpan={2} className={"IGenericLabel-Normal2"} >
                                Hola, <label className={"IRefCliente"}>{ cliente }</label>, tienes un pago pendiente por <label className={"IGenericLabel-2f"}>$ { numberFormat(transaccion.monto,2)  }</label>   para <label className={"IRefCliente"}> { cuenta.rs } </label>, realízalo en tu tienda oxxo más cercano hasta el { getMes() }.
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} className={"IGenericLabel"}>
                                Necesitarás tu número de referencia:
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} align={"center"}>
                                <SoftBox>
                                    { referencia !== "" ? <Barcode value={ referencia } width={2}  displayValue={true}   /> :"" }
                                </SoftBox>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    ¿Cómo pagar?
                                </div>
                            </td>
                            <td>
                                <div className={"pad-l-1"}>

                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td  className={"pad-l-1 vAlign"} width={"50%"} >

                                <ol className={"IGenericLabel-sm"}>
                                    <li>
                                        Ve al OXXO e indica al cajero que realizarás un pago en efectivo con <label className={"ILabelImportant"}>OXXO Pay</label>.
                                    </li>
                                    <li>
                                        Proporciona tu número de referencia de pago y verifica que la información en pantalla sea correcta.
                                    </li>
                                    <li>
                                        Realiza tu pago en efectivo.*. <br/>
                                        * Se te cobrará una comisión establecida por OXXO
                                    </li>
                                </ol>
                            </td>
                            <td className={"pad-l-1"} width={"50%"}>
                                <img src={ imgOxxoPay } width={300} alt={"Imagen OXXO PAY"}/>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </Grid>
            </Grid>

        </SoftBox>


    </SoftBox>;

    const mobilComponent = <SoftBox borderRadius={"12px"}  ref={componentRef}  >
        <SoftBox bgColor="color18" borderRadius="lg"
                 shadow="lg"
                 opacity={1} sx={{ margin:1, padding:1 }}>

            <SectionHeader cuenta={ cuenta  } />
            <Box sx={{ border:"1px solid #2D5375",padding:0.3,borderRadius:1,backgroundColor:"#2D5375" }}></Box>
            <Grid container >

                <Grid item xs={12} md={12} xl={12}  >
                    <table>
                        <tbody>
                        <tr>
                            <td colSpan={2} className={"IGenericLabel-Normal2"} >
                                Hola, <label className={"IRefCliente"}>{ cliente }</label>, tienes un pago pendiente por <label className={"IGenericLabel-2f"}>$ { transaccion.monto }</label>   para <label className={"IRefCliente"}> { cuenta.rs } </label>, realízalo en tu tienda más cercana antes del { getMes() }.
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} className={"IGenericLabel"}>
                                Necesitarás tu número de referencia:
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} align={"center"}>
                                <SoftBox>
                                    { referencia !== "" ? <Barcode value={ referencia } width={1}  displayValue={true}   /> :"" }
                                </SoftBox>

                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <div>
                                    ¿Cómo pagar?
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td  className={"pad-l-1 vAlign"} width={"50%"} colSpan={2} >

                                <ol className={"IGenericLabel-sm"}>
                                    <li>
                                        Ve al OXXO e indica al cajero que realizarás un pago en efectivo con <label
                                        className={"ILabelImportant"}>OXXO Pay</label>.
                                    </li>
                                    <li>
                                        Proporciona tu número de referencia de pago y verifica que la información en
                                        pantalla sea correcta.
                                    </li>
                                    <li>
                                        Realiza tu pago en efectivo.*. <br/>
                                        * Se te cobrará una comisión establecida por OXXO
                                    </li>
                                </ol>
                            </td>

                        </tr>
                        <tr>
                            <td className={"pad-l-1"} width={"50%"} colSpan={2}>
                                <img src={imgOxxoPay} width={280} alt={"Imagen OXXO PAY"} height={70}/>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </Grid>
            </Grid>

        </SoftBox>


    </SoftBox>;

    return (
        <>
            { isMobile ? mobilComponent: defaultComponent }
            <Grid container>
                <Grid item xs={12} md={12} xl={12} alignItems={"center"} >
                    <button className={"IBtn-alpha-success-z2"} type={"button"} onClick={ handlePrint }  >
                        Imprimir
                    </button>
                </Grid>
            </Grid>
        </>
    );
}

export default CardPrintReference2;