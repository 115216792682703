import * as React from 'react';
import Box from '@mui/material/Box';
import { useSelector} from "react-redux";
import {v4} from "uuid";
import NETPAYCHECKOUT from "./netpay/CHECKOUT";
import NETPAYTPV from "./netpay/TPV";
import NETPAYLOOP from "./netpay/LOOP";
import PAYPALCHECKOUT from "./paypal/CHECKOUT";
import PropTypes from "prop-types";
import {useEffect} from "react";

const  TabPlataforma = ({ plataforma }) => {

    const defaultComponents ={
        netpay  :<NETPAYTPV  />,
        paypal  :<PAYPALCHECKOUT  />
    }

    const [value, setValue] = React.useState("TPV");
    const [servicioSeleccionadoComponent, setServicioSeleccionadoComponent] = React.useState();

    const { servicios } = useSelector(state => state.servicios_plataformas);

    useEffect(()=>{
        setServicioSeleccionadoComponent( defaultComponents[ plataforma.plataforma.toLowerCase() ] );
    }, [  ])

    const handleChange = (event, selected) => {
        setValue(selected.categoria);
        let serviciox = <NETPAYTPV />;
        if(selected.plataforma.toLowerCase() ==='netpay' ){
            switch (selected.categoria){
                case 'TPV':
                    serviciox = <NETPAYTPV  />;
                    break;
                case 'CHECKOUT':
                    serviciox = <NETPAYCHECKOUT  />;
                    break;
                case 'LOOP':
                    serviciox = <NETPAYLOOP />;
                    break;
            }
        }
        if(selected.plataforma.toLowerCase() ==='paypal' )
        {
            switch (selected.categoria){
                case 'CHECKOUT':
                    serviciox = <PAYPALCHECKOUT  />;
                    break;
            }
        }
        //else otra plataforma
        setServicioSeleccionadoComponent( serviciox );
    };

    const tabButtons = servicios.map(element =>{
        if(element.plataforma_uuid === plataforma.plataforma_uuid ){
            return (<button key={ v4() } type={"button"} className={ value === element.categoria ? "ITabButtonActive":"ITabButtonInactive" }
                            onClick={event=>handleChange(event,element)} > { element.categoria } </button>);
        }
    })


    return (
        <Box sx={{ width: '100%' }} bgcolor={"white"} pt={2} >
            <Box>
                { tabButtons }
            </Box>
            <Box sx={{ border:"0.4rem solid #EEEEEE" }} >
                { servicioSeleccionadoComponent }
            </Box>
        </Box>

    );
}

TabPlataforma.propTypes = {
    plataforma: PropTypes.object.isRequired,
}

export default TabPlataforma;