import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";

import IconFilterAlt from '@mui/icons-material/FilterAlt';
import IconRefresh from "@mui/icons-material/RefreshOutlined";
import IconArticleIcon from '@mui/icons-material/Article';

import Swal from 'sweetalert2';
import { Card } from '@mui/material';
import { useEffect } from 'react';

import Ordenes from 'Repository/Banca/Ordenes';

const initialValues = {
    fechaaltaantesde: '',
    fechaaltadespuesde: ''
}

 export const Filtro = ({ onApliyFilter }) => {

    const [inputValues, setInputValues] = useState(initialValues);
    const ordenesx = new Ordenes();
    //#region events

        const onInputChange = ({target}) =>{
            setInputValues({
                ...inputValues,
                [target.name]: target.value
            })
        }

        const resetForm = () =>{
            setInputValues(initialValues)
            onApliyFilter(initialValues);
        }

        const onExport = ()=>{
            ordenesx.exportarOperaciones(inputValues);
        }

        useEffect(()=>{
            currentDate();
        }, [])

    //#endregion

    //#region methods
    const currentDate = async ()=>{
       const data = await ordenesx.consultarFechas();
       setInputValues({
        ...inputValues,
        fechaaltaantesde: data.response.data.antesde,
        fechaaltadespuesde: data.response.data.despuesde,
       });
    }
    //#endregion

    return (
        <>
          
                <SoftBox pl={1} pt={2} pb={1} component="form" role="form"  borderRadius="lg"  >
                    <Grid container spacing={1} >

                        <Grid item xs={3} md={2} xl={2}>
                            <SoftBox lineHeight={0}>
                                <SoftTypography variant="caption" color="color4" >
                                    Desde
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox>
                                <input type={"date"} className={"ITextbox"} name={"fechaaltadespuesde"} value={ inputValues.fechaaltadespuesde }
                                       maxLength={20}  onChange={ (event)=>onInputChange(event) } />
                            </SoftBox>
                        </Grid>

                        <Grid item xs={3} md={2} xl={2}>
                            <SoftBox lineHeight={0}>
                                <SoftTypography variant="caption"  color="color4" >
                                    Hasta
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox>
                                <input type={"date"} className={"ITextbox"} name={"fechaaltaantesde"} value={ inputValues.fechaaltaantesde }
                                       maxLength={20}  onChange={ (event)=>onInputChange(event) } />
                            </SoftBox>
                        </Grid>
                            
                        <Grid item xs={2} md={2} xl={2}>
                            <SoftBox pt={2} >
                                <button className={"btn-alpha-info"} type={"button"} onClick={ (event) => onApliyFilter(inputValues) }   >
                                    <i className="fa-solid fa-filter"></i> Filtrar
                                </button>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={2} md={2} xl={2}>
                            <SoftBox pt={2}>
                                <button className={"btn-alpha-gray-1"} type={"button"} onClick={ resetForm  }   >
                                    <i className="fa-solid fa-filter-circle-xmark"></i> Quitar filtro
                                </button>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={2} md={2} xl={2}>
                            <SoftBox pt={2}>
                                <button className={"btn-alpha-success-2"} type={"button"} onClick={ onExport  }   >
                                    <i className="fa-solid fa-file-arrow-down"></i> Exportar
                                </button>
                            </SoftBox>
                        </Grid>
                        
                        
                    </Grid>
                </SoftBox>

           
        </>
    )
}

Filtro.propTypes = {
    onApliyFilter: PropTypes.func
};

export default Filtro;