import React, {  useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import IconEmail from '@mui/icons-material/Email';
import Swal from 'sweetalert2';

//
import Persona from 'Repository/Banca/Personas';

function enviarNotificacion(value, /* callback */ callback){
    callback( value).then(response =>{
        if(response.status ='ok'){
            Swal.fire({
                title: 'Notificaciones',
                text: response.response.text,
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
                });
        }else{
            Swal.fire({
                title: 'Notificaciones',
                text: response.response.text,
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
                });
        }
    });
}

function SendOtpActivate({persona_uuid}) {

    //#region events
    const onClickBtnEnviarNotificacion = (event)=>{
        enviarNotificacion(persona_uuid,  Persona.notificarPorCorreoOTP );
    }
    //#endregion

    return(
        <>
            <button type={"button"} className={ "btn-alpha-info-3" }  onClick={ (event)=>onClickBtnEnviarNotificacion(event) } >
                <i className="fa-solid fa-envelope"></i>  Activar OTP
            </button>
        </>
    )
    
}

SendOtpActivate.propTypes = {
    persona_uuid: PropTypes.string,
  };

export default SendOtpActivate;