/**
=========================================================
 * Jose Alberto San Juan San Agustin
 * Componentes para la administración de usuarios para el comercio
=========================================================
*/

import React, { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// @mui material components
import Grid from "@mui/material/Grid";

import Swal from 'sweetalert2';

import PersonasRequest from 'Repository/Banca/Personas.js';

import Table from "examples/Tables/Table"; 
import IconRefresh from '@mui/icons-material/Refresh';
import SoftBadge from "components/SoftBadge/index.js";

import Estatus from "layouts/stp/personas/components/Estatus";
import Profile from "../personas/components/Profile";
import CuentasRelacionadas from '../personas/components/CuentasRelacionadas';
import { Loading } from "examples/Components/Loading.jsx";
import {useDispatch, useSelector} from "react-redux";
import {consultaTiposNotificaciones, editPersona} from "store/thunk/banca/persona";
import Select from 'react-select';

import {useAlert} from "customHooks/useAlert";
import Filtro from "../../cuentas/components/Filtro";
import AlphaTable from "../../cuentas/components/AlphaTable";
import {v4} from "uuid";

const initialItemSelect = {
  value: '',
  label: 'SIN NOTIFICACIÓN'
}

function Personas() {

  const dispatch = useDispatch();
  const {currentRecord : viewPersona, uuid: uuidShowed, tipoNotificaciones: catTipoNotificaciones } = useSelector(state => state.persona);

  const [errores, setErrores] = useState('');
  const { showAlert } = useAlert();

  /* Estado de autorizador */
  const [nombres_autorizador, setNombresAutorizador] = useState('');
  const [apellidos_autorizador, setApellidosAutorizador] = useState('');
  const [celular_autorizador, setCelularAutorizador] = useState('');
  const [email_autorizador, setEmailAutorizador] = useState('');
  const [referencia_autorizador, setReferenciaAutorizador] = useState('');
  const [ tipoNotificacion_uuid, setTipoNotificacionUuid ] = useState('');
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [periodo, setPeriodo] = useState(1);
  /* Estado de autorizador */
  const [txtBuscar,setTxtBuscar ] = useState('');

  const [loading, setLoading] = useState(false);

  /* Estado del detalle */
  /* Estado del detalle  */

  let personax = new PersonasRequest();

  useEffect(()=>{
    dispatch( consultaTiposNotificaciones() );
  }, [])


  /* Nombres de los autorizadorees end   */


  const sendRegisterAutorizador = async  e=>{
    e.preventDefault();
    
    if(!validateAutorizador()){
      setLoading(true);
      let params = {
        nombres   : nombres_autorizador,
        apellidos : apellidos_autorizador,
        celular   : celular_autorizador,
        email     : email_autorizador,
        referencia: referencia_autorizador,
        nt_uuid   :tipoNotificacion_uuid,
        periodo: periodo
      }
      PersonasRequest.registrar(params).then(response=>{
        //limpiamos componentes
        if(response.status === 'ok'){
          setNombresAutorizador(()=> '');
          setApellidosAutorizador(()=> '');
          setCelularAutorizador(()=> '');
          setEmailAutorizador(()=> '');
          setReferenciaAutorizador( ()=> '' );
          setTipoNotificacionUuid('');
          setPeriodo('');
          listar();
          Swal.fire({
            title: 'Notificaciones',
            text: response.response.text,
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
          });
          setErrores(()=> '');
        }else{
          setErrores(()=> response.response.error );
        }
        setLoading(false);
      });

    }else{
      setErrores('Por favor rellene los campos necesarios para poder continuar');
    }
  }

  function handleShowClick(event, uuid){
    event.preventDefault();
    //setIuuidShowed(uuid);
    dispatch( editPersona(uuid) );
  }

  const onCancelHandle = ()=>{
    //setIuuidShowed('');
    listar('');
  }

  const handleChangeBusqueda = ({target})=>{
    const value = target.value;
    setTxtBuscar(value);
    if(value.length > 3){
      listar(value);
    }else if(value === ''){
      setLoading(true);
      listar('');
    }
    
  }

  const handleEncontrar = (event) => {
    if (event.key === "Enter") {
      listar(txtBuscar);
    }
  }

  const handleRefresh = ()=> listar();

  function listar(texto = ''){
    
    let params = {nombres:texto, soloActivos: false};
    personax.listar(params).then(response=>{
      
      setColumns(
      [
        { name: "Nombre", width: "10%" },
        { name: "Correo", width: "10%" },
        { name: "Celular", width: "10%" },
        { name: "Empresa", width: "10%" },
        { name: "OTP",    width: "10%" },
        { name: "Activo", width: "10%" },
      ]
      );
      const items = (response.response.data).map(element => {
        return <tr key={ v4() }>
            <td>
              <label onClick={ (event) => handleShowClick(event,element.uuid)  } className={ "ILabelLink" }>
                {   element.nombres+" "+ element.apellidos}
              </label>
            </td>
          <td>
            { element.email }
          </td>
          <td>
            { element.celular }
          </td>
          <td>
            <label className={"IGenericLabel-sm"}>
              { element.referencia ? element.referencia : "" }
            </label>
          </td>
          <td align={"center"}>
            {
              (element.otpSecret && element.otpLastUse) ? (
                  <SoftBadge variant={  (element.otpSecret && element.otpLastUse) ? "gradient": "contained"  }
                             badgeContent={  (element.otpSecret && element.otpLastUse) ? " otp ": "  "  } size="lg" container />
              ) : ''
            }
          </td>
          <td align={"center"}>
            <Estatus key={element.uuid} item={element} clase="persona"   />
          </td>
        </tr>
      });
      setRows(items);
      setLoading(false);
      
    });
  }

  function validateAutorizador(){
    let hasError = false;
    let counter = 0;
    if(nombres_autorizador.length < 3) {counter+=1; }
    if(apellidos_autorizador.length < 3){counter+=1; }
    if(referencia_autorizador.length < 3){counter+=1; showAlert('Ups', "Más de tres letras en el campo empresa", "warning"); return  true;}

    let restmpx = celular_autorizador.match(/^\d{10}$/g)
    if(restmpx == null) {counter+=1; showAlert('Ups', "Celular incorrecto", "warning");return true;}

    let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if(!emailRegex.test(email_autorizador)) {counter+=1;showAlert('Ups', "Correo invalido", "warning");return true; }
    if(counter > 0){
      return (hasError = true);
    }
    let error = false;
    if(tipoNotificacion_uuid === undefined){
      showAlert('Ups', "Ingrese un periodo", "warning");
      error = true;
    }
    if( error) return true;
    const tipoNotificicacionItem = catTipoNotificaciones.find(element => element.value === tipoNotificacion_uuid);
    if(tipoNotificicacionItem.label === 'RESUMEN' || tipoNotificicacionItem.label === 'DETALLE' ){
      const rangoInvalido = periodo === '' ||  ( parseInt(periodo) <= 0 || parseInt(periodo) > 23 );
      if( rangoInvalido ){
        showAlert('Ups', "Ingrese un periodo de 1 a 23 horas", "warning");
        return true;
      }
    }
    return hasError;

  }


  useEffect(()=>{
    listar('');
  },[]);


  const formularioDeRegistro = <>
    <SoftBox p={2} sx={{ border:"5px solid #f7f7f7", }}>

      <SoftBox mb={1}>
        <SoftTypography variant="button" fontSize="xxs" color="error" >
          { errores }
        </SoftTypography>
      </SoftBox>


      <SoftBox component="form" role="form" onSubmit={sendRegisterAutorizador}>

        <Grid container spacing={3} pt={1}>

          <Grid item xs={12} md={6} xl={4}>
            <SoftBox lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Nombres *
              </SoftTypography>
            </SoftBox>
            <SoftBox >
              <input type={"text"} className={"ITextbox"} name={"nombres_autorizador"} value={ nombres_autorizador }
                     maxLength={150}  onChange={ e => setNombresAutorizador(e.target.value) } autoComplete={"given-name"} />
            </SoftBox>
          </Grid>

          <Grid item xs={12} md={3} xl={8}>
            <SoftBox lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Apellidos *
              </SoftTypography>
            </SoftBox>
            <SoftBox >
              <input type={"text"} className={"ITextbox"} name={"apellidos_autorizador"} value={ apellidos_autorizador }
                     maxLength={150}  onChange={ e => setApellidosAutorizador(e.target.value) } autoComplete={"additional-name"} />
            </SoftBox>
          </Grid>

          <Grid item xs={12} md={4} xl={4}>
            <SoftBox lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Empresa *
              </SoftTypography>
            </SoftBox>
            <SoftBox >
              <input type={"text"} className={"ITextbox"} name={"referencia_autorizador"} value={ referencia_autorizador }
                     maxLength={150}  onChange={ e => setReferenciaAutorizador(e.target.value) } autoComplete={"on"} />
            </SoftBox>
          </Grid>

          <Grid item xs={12} md={4} xl={4}>
            <SoftBox lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Celular *
              </SoftTypography>
            </SoftBox>
            <SoftBox >
              <input type={"text"} className={"ITextbox"} name={"celular_autorizador"} value={ celular_autorizador }
                     maxLength={10}  onChange={ e => setCelularAutorizador(e.target.value) } autoComplete={"on"} />
            </SoftBox>
          </Grid>

          <Grid item xs={12} md={4} xl={4}>
            <SoftBox lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Email *
              </SoftTypography>
            </SoftBox>
            <SoftBox >
              <input type={"text"} className={"ITextbox"} name={"email_autorizador"} value={ email_autorizador }
                     maxLength={200}  onChange={ e => setEmailAutorizador(e.target.value) } autoComplete={"on"} />
            </SoftBox>
          </Grid>


          <Grid item xs={12} md={6} xl={4}>
            <SoftBox lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Tipo de notificación
              </SoftTypography>
            </SoftBox>
            <SoftBox>
              <select className={"ITextbox"} name={"tipoNotificacion_uuid"} value={ tipoNotificacion_uuid } onChange={ (event)=>setTipoNotificacionUuid(event.target.value) }>
                { catTipoNotificaciones.map(element =>{
                  return <option key={ v4() }  value={ element.value }> { element.label } </option>
                }) }

              </select>
            </SoftBox>

          </Grid>

          <Grid item xs={12} md={4} xl={4}>
            <SoftBox lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Periodo (Cada 1 horas,  hasta máximo cada 23)
              </SoftTypography>
            </SoftBox>
            <SoftBox >
              <input type={"number"} className={"ITextbox"} name={"periodo"} value={ periodo }
                     maxLength={5}  onChange={ e => setPeriodo(e.target.value) } autoComplete={"periodo"} />
            </SoftBox>
          </Grid>

          <Grid item xs={12} md={9} xl={9} lg={9}  >

          </Grid>
          <Grid item xs={12} md={3} xl={3} >
            <button className={"btn-alpha-success-2"} type={"submit"}   >
              <i className="fa-solid fa-circle-check"></i> Guardar
            </button>
          </Grid>

        </Grid>



      </SoftBox>


    </SoftBox>
  </>

  //

  //Si ya esta autenticado devolvemos el dashboard si no lo mandamos a loguearse

    return (
      <DashboardLayout >
      <DashboardNavbar light={ true} />
      <SoftBox py={3}>
        <Loading loading={ loading } />

      { uuidShowed === null && (
          <Card  >

            <Filtro bodyContent={  formularioDeRegistro } title={"Registrar Persona"} open={ false } icon={""} />
          

          <SoftBox mb={3} pt={1}>
            <Card>
              
              <Grid container>
                <Grid item xs={8} md={6} xl={8} >
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" pl={1}>
                    <SoftTypography variant="h6">Personas</SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item pl={1} xs={4} md={1} xl={2}>
                  <button className={"btn-alpha-info"} type={"submit"} onClick={ handleRefresh }>
                    <i className="fa-solid fa-arrows-rotate"></i> Refrescar
                  </button>
                </Grid>
                <Grid item xs={8} md={4} xl={2} pr={3}>
                    <input type={"search"}  className={"ITxtEncontrar-Banca"} name={"encontrar"} value = { txtBuscar } maxLength={"50"} placeholder={"Buscar"}
                           onChange={ (event)=> handleChangeBusqueda(event)  } onKeyDown={  handleEncontrar }  />
                </Grid>

              </Grid>
              
              <SoftBox>
                <AlphaTable records={ rows } headers={ columns } />
              </SoftBox>
            </Card>
          </SoftBox>

        </Card>
      )}
          
      
      { uuidShowed != null && (
        <>
          <Profile  onCancel={ (value)=>onCancelHandle(value) } />
          <CuentasRelacionadas  />
        </>
          
      )}
      

      </SoftBox>
    </DashboardLayout>
    );

}

export default Personas;
